import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const mockData = [
    {
        img: "https://media.licdn.com/dms/image/C4D0BAQG4mLB63VgFCA/company-logo_200_200/0/1587505654611?e=2147483647&v=beta&t=OX5PUGNNHPK_Xxz_Qane5E5YmfYAg2fBHZCsc-iUaRU",
        id: '1',
        name: 'Xocialive Invoices Fawateer Book',
        organization: 'Xocialive Company',
        language: "English",
        category: 'Sales Invoice',
        status: 'Active',
    },
    {
        id: '2',
        name: 'Xocialive Invoices Fawateer Book',
        organization: 'Xocialive Company',
        language: "English",
        category: 'Sales Invoice',
        status: 'Active',
    },
    {
        id: '3',
        name: 'Xocialive Invoices Fawateer Book',
        organization: 'Xocialive Company',
        language: "English",
        category: 'Sales Invoice',
        status: 'Active',
    },
    {
        id: '4',
        name: 'Xocialive Invoices Fawateer Book',
        organization: 'Xocialive Company',
        language: "English",
        category: 'Sales Invoice',
        status: 'Active',
    },
    {
        id: '5',
        name: 'Xocialive Invoices Fawateer Book',
        organization: 'Xocialive Company',
        language: "English",
        category: 'Sales Invoice',
        status: 'Active',
    },
    {
        id: '6',
        name: 'Xocialive Invoices Fawateer Book',
        organization: 'Xocialive Company',
        language: "English",
        category: 'Sales Invoice',
        status: 'Active',
    },
];

const fawateer = createSlice({
    name: 'fawateer',
    initialState: {
        fawateer: mockData,
        keys: [],
        fawateerList: [],
        fawateerDetails: {},
        searchCriteria: {
            metaData: "",
        },
        showError: false,
        error: null
    },

    reducers: {
        fawateerReceived: (fawateer: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                fawateer.fawateer[item.id] = item;
                fawateer.keys = [...fawateer.keys, item.id]
            });
            fawateer.customerDetails = {};
        },

        setFawateerDetails: (fawateer: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            fawateer.fawateerDetails = data;
        },

        setFawateerList: (fawateer: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            fawateer.fawateerList = data;
        },

        setFawateers: (fawateer, { payload }) => {
            const data = mockData;
            fawateer.fawateer = data;
        },

        setSearchCriteria: (fawateer: any, { payload }: PayloadAction<any>) => {
            fawateer.searchCriteria = payload.body;
        },

        setError: (fawateer: any, { payload }: PayloadAction<any>) => {
            fawateer.error = payload.response;
            fawateer.showError = true;
        }
    }
});


export const { fawateerReceived, setFawateers, setFawateerList, setFawateerDetails, setSearchCriteria, setError } = fawateer.actions;
export default fawateer.reducer;

//#region :: Selectors
export const getFawateerData = (state: any) => {
    return state.fawateer.fawateer;
};

export const getFawateerDetails = (state: any) => {
    return state.fawateer.fawateerDetails;
};

export const getFawateerLists = (state: any) => {
    return state.fawateer.fawateerList;
};

export const getSearchCriteria = (state: any) => {
    return state.fawateer.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.fawateer.showError;
};

export const getError = (state: any) => {
    return state.fawateer.error;
};

export const fawateerSelectors = {
    getFawateerData,
    getSearchCriteria,
    getFawateerDetails,
    getFawateerLists,
    getShowError,
    getError,
};
//#endregion
