import { useEffect, useState } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RoleManagementSearchModel } from '../Shared/roleMappings.model';
import { getSearchCriteria, setIsSearching, setSearchCriteria } from '../../../../Store/Reducers/RolesManagement';

const useRoleMappingSearchHook = () => {
    const { T, Resources } = useTranslation();
    const state = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchCriteria = getSearchCriteria(state);
    const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
    const [searchForm, setSearchForm] = useState(RoleManagementSearchModel.getFieldsModel());
    
    // const navigate = useAppNavigate();
    


    //#endregion

    
  //#region :: Handlers
  const searchHandler = () => {
    const request = RoleManagementSearchModel.toDBModel(searchForm);
    dispatch(setSearchCriteria({ body: request }));
  };

  const handleInputChange = (event: any) => {
    var { name, value } = event.target;
    handleInputChangeValue(name, value);
  };

  const handleInputChangeValue = (name: any, value: any) => {
    setSearchForm({
      ...searchForm,
      [name]: {
        ...searchForm[name],
        value: value
      }
    });
  };

  const resetForm = () => {
    setSearchForm(RoleManagementSearchModel.getFieldsModel());
    dispatch(setSearchCriteria({ body: {} }))
  };

  const handleCPressEnter = (e) => {
      if (e.key === "Enter") {
          searchHandler()
      }
  }
  //#endregion

  //#region :: [useEffect]
  useEffect(() => {
    if (!searchCriteria) return;
    setSearchForm({
      ...searchForm,
      name: {
        ...searchForm.name,
        value: searchCriteria.name
      },
    });
  }, [searchCriteria]);

  useEffect(() => {
    return () => {
      dispatch(setIsSearching({ isSearching: false }));
    }
  }, []);
  //#endregion
 
    return { handleInputChange, searchHandler, searchForm, resetForm, handleCPressEnter }
}

export default useRoleMappingSearchHook