import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalService } from '../../../Services/global.service';

const tenantUsersManagement = createSlice({
    name: 'tenantUsersManagement',
    initialState: {
        tenantUsersManagement: [],
        keys: [],
        tenantUsersManagementDetails: {},
        users: {},
        userDetails: null,
        facilities: [],
        ruleList: [],
        rulePageList:null,
        searchCriteria: {
            arabicName: '',
            englishName: '',
            mobileNumber: '',
            email: '',
            username: '',
            status: null
        },
        isSearching: true,
        error: null,
        showError: false
    },
    reducers: {
        usersRecived: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                tenantUsersManagement.users[item.id] = item;
                tenantUsersManagement.keys = [...tenantUsersManagement.keys, item.id]
            });

            tenantUsersManagement.userDetails = null;
        },
        userUpdated: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            //fixers here
            let dataFild = data?.body;
            tenantUsersManagement.users[data.id] = {
                ...tenantUsersManagement.users[data.id],
                englishName: dataFild?.englishName,
                arabicName: dataFild?.arabicName,
                email:dataFild?.email,
                username:dataFild?.username
            };
            tenantUsersManagement.keys = [...tenantUsersManagement.keys, data.id];
        },
        tenantUsersManagementReceived: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                tenantUsersManagement.tenantUsersManagement[item.id] = item;
                tenantUsersManagement.keys = [...tenantUsersManagement.keys, item.id]
            });
            tenantUsersManagement.customerDetails = {};
        },

        setUserDetails: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            const currentUser = payload.body;
            tenantUsersManagement.userDetails = currentUser;
        },
        setFacilities: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            const facilities = payload.body;
            tenantUsersManagement.facilities = facilities;
        },
        setRuleList: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            const rule = payload.body;
            tenantUsersManagement.ruleList = rule;
        },
        setSearchCriteria: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            tenantUsersManagement.searchCriteria = payload.body;
            tenantUsersManagement.isSearching = true;
        },
        setIsSearching: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            tenantUsersManagement.isSearching = payload.isSearching;
        },
        setRulePageList: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            tenantUsersManagement.rulePageList ={...tenantUsersManagement.rulePageList,['page'+payload.pageIndex]:payload.ruleList};
        },
        clearRulePageList: (tenantUsersManagement: any, { payload }: PayloadAction<any>) => {
            tenantUsersManagement.rulePageList =null;
        },
        setError: (tenantUsersManagement: any, { payload }: any) => {
            tenantUsersManagement.error = payload.response;
            tenantUsersManagement.showError = true;
        }
    }
});

export const {
    usersRecived, userUpdated, setUserDetails, setSearchCriteria, setIsSearching, setError, setFacilities, setRuleList, tenantUsersManagementReceived
    ,setRulePageList,clearRulePageList
} = tenantUsersManagement.actions;

export default tenantUsersManagement.reducer;

//#region :: Selectors
export const getUsers = (state: any) => {
    return state.tenantUsersManagement.users;
};

export const getFacilities = (state: any) => {
    return state.tenantUsersManagement.facilities;
};

export const getRuleList = (state: any) => {
    return state.tenantUsersManagement.ruleList;
};

export const getUserDetails = (state: any) => {
    return state.tenantUsersManagement.userDetails;
};

export const getUserKeys = (state: any) => {
    return state.tenantUsersManagement.keys;
};

export const getShowError = (state: any) => {
    return state.tenantUsersManagement.showError;
};

export const getError = (state: any) => {
    return state.tenantUsersManagement.error;
};

export const getSearchCriteria = (state: any) => {
    return state.tenantUsersManagement.searchCriteria;
};

export const getIsSearching = (state: any) => {
    return state.tenantUsersManagement.isSearching;
};
export const getRulePageList = (state: any) => {
    return state.tenantUsersManagement.rulePageList;
};
export const getTenantUsersManagementsBookData = (state: any) => {
    return state.tenantUsersManagement.tenantUsersManagement;
};

export const tenantUsersManagementSelectors = {
    getTenantUsersManagementsBookData,
    getUsers,
    getUserDetails,
    getUserKeys,
    getShowError,
    getError,
    getSearchCriteria,
    getIsSearching,
    getFacilities,
    getRuleList
};
//#endregion