import React from 'react';
import styled from 'styled-components';
import { EmptyTable } from '../Icons/Icons';
import useTranslation from '../../Hooks/Shared/useTranslation';


const EmptyTableComp = () => {

    //#region :: State
    const { T, Resources } = useTranslation();
    //#endregion
    return (
        <StyledCenterDiv>
            <EmptyTable width={24} height={41} />
            <StyledEmptyIcon>{T(Resources.AppResources.NODATA)}</StyledEmptyIcon>
        </StyledCenterDiv>
    )
}

export default EmptyTableComp;

//#region :: Styled Components
const StyledCenterDiv: any = styled.div`
    text-align: center;
`;

const StyledEmptyIcon: any = styled.div`
    color: ${({ theme }: any) => theme && theme.status.lightGray};
`;
//#endregion