import React from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

const TenentNotAvailable = () => {
    return (
        <Grid container item xs={12} spacing={1}>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404" style={{height:0}}>
                        <h2>{'Tenant Not Available'}</h2>
                    </div>
                <span className='message404' >
                    <Button
                        variant="text"
                        onClick={() => window.location.reload()}
                        title='Retry'
                        // label={"Retry"} 
                        />
                </span>
                </div>
            </div>
        </Grid>
    );
}

export default TenentNotAvailable;