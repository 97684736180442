import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import Chip from "@mui/material/Chip";
import { emphasize } from "@mui/material/styles";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { regexType } from "../../Config/Settings/Constants/Validations";
import { AppConfigService } from "../../Config/Settings/AppConfig";

const BreadCrumb = () => {
  //#region :: Handlers
  const location = useLocation();
  const { T, Resources } = useTranslation();

  const getRoutParts = () => {
    const regex = regexType.NUMBER;
    const baseRoute = AppConfigService.getConfig().reactAppBaseUrl || "/";
    const breadPath = location.pathname.replace(baseRoute, "");
    let pathList = breadPath.split("/");
    let index;

    pathList.map((item) => {
      let checkNumber = regex.test(item);
      if (checkNumber) {
        index = pathList.indexOf(item);
      }
    });

    if (index != undefined) {
      pathList.splice(index, 1);
    }

    return pathList;
  };
  const routeParts = getRoutParts();
  //#endregion

  return (
    <>
      {routeParts.length ? (
        <Breadcrumbs aria-label="breadcrumb">
          {routeParts.map((item, index) => {
            return routeParts[routeParts.length - 1] !== item ? (
              <StyledLink
                key={index}
                to={`${AppConfigService.getConfig().reactAppBaseUrl}/${item}`}
              >
                <StyledBreadcrumb
                  label={
                    !item ? (
                      <StyledIcon />
                    ) : (
                      T(Resources.AppResources[item.toString().toUpperCase()])
                    )
                  }
                ></StyledBreadcrumb>
              </StyledLink>
            ) : null;
          })}
          {routeParts[routeParts.length - 1] !== "" ? (
            <StyledBreadcrumb
              label={T(
                Resources.AppResources[
                  routeParts[routeParts.length - 1].toUpperCase()
                ]
              )}
            />
          ) : null}
        </Breadcrumbs>
      ) : (
        ""
      )}
    </>
  );
};

//#region :: Styled Components
const StyledBreadcrumb = styled(Chip)`
  &.MuiChip-root {
    background-color: ${({ theme }) => theme.palette.primary.whiteColor};
    height: ${({ theme }) => theme.spacing(3)};
    box-shadow: -1px 1px 6px 0px #dddcdc;
    color: ${({ theme }) => theme.palette.grey[800]};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    text-transform: capitalize;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.palette.grey[300]};
    }
    &:active {
      box-shadow: ${({ theme }) => theme.shadows[1]};
      background-color: ${({ theme }) => emphasize(theme.palette.grey[300], 3)};
    }
  }
  & .MuiChip-label {
    display: flex !important;
    align-self: center !important;
  }
`;

const StyledIcon = styled(HomeIcon)`
  &.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
`;
//#endregion

export default BreadCrumb;
