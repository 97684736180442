import Locales from '../Locales';
import { AppResources } from '../Resources';


const arSa = {
        [Locales.ARABIC]: {
                [AppResources.FAWATEER]: 'Fawateer',
                [AppResources.GREETING]: 'مرحباً',
                [AppResources.X4FATOORA]: 'X4Fatoora',


                [AppResources.QUALIFICATION_ID]: 'رقم المؤهل',
                [AppResources.QUALIFICATION_CODE]: 'المؤهل',
                [AppResources.MAJOR_CODE]: 'التخصص',
                [AppResources.UNIVERSITY_CODE]: 'الجامعة',
                [AppResources.QUALIFICATION_STATUS]: 'حالة المؤهل',



                [AppResources.NET_PAY]: 'المبلغ المدفوع',
                [AppResources.PAID_DATE]: 'تاريخ تنفيذ المسير',
                [AppResources.GREGORIAN_DATE]: 'الشهر',
                [AppResources.ELEMENTS]: 'العناصر',


                [AppResources.JOB_POSITION_CODE]: 'الرمز التعريفي للوظيفة',
                [AppResources.JOB_CLASS_CODE]: 'الفئة الوظيفية',
                [AppResources.POSITION_STATUS]: 'حالة الوظيفة',


                [AppResources.APPRAISAL_ID]: 'الرمز المعرف للتقييم ',
                [AppResources.APPRAISAL_TYPE_CODE]: 'التقييم',
                [AppResources.RESULT]: 'النتيجة',
                [AppResources.RATING_CODE]: 'التقدير',

                [AppResources.VACATION_ID]: 'رقم الإجازة',
                [AppResources.PERIOD]: 'المدة',
                [AppResources.VACATION_CODE]: 'الإجازة',




                [AppResources.DATE_TO]: 'التاريخ الى',
                [AppResources.TRANSACTION_TYPE]: 'نوع المعاملة',
                [AppResources.INSURANCE_COMPANY]: 'شركة التأمين',
                [AppResources.PURPOSE]: 'السبب',
                [AppResources.STATUS]: 'الحالة',
                [AppResources.BRANCH]: 'الفرع',
                [AppResources.TPA]: 'TPA',
                [AppResources.ACTIONS]: 'الإجراءات',
                [AppResources.PAYSLIP]: 'الرواتب',
                [AppResources.REPORTS]: 'التقارير',
                [AppResources.REPORT]: 'تقرير',
                [AppResources.REQUESTS]: 'الطلبات',
                [AppResources.REQUESTREPORT]: 'تقارير الطلبات',
                [AppResources.MOBILE_NUMBER]: 'رقم الهاتف',
                [AppResources.SEARCH]: 'بحث',
                [AppResources.RESET]: 'تفريغ الحقول',
                [AppResources.DASHBOARD]: 'لوحة المؤشرات',
                [AppResources.BENEFICIARY]: 'المستفيد',
                [AppResources.BLANKPAGE]: 'صفحة فارغة',
                [AppResources.CLAIMS]: 'المطالبات',
                [AppResources.ELIGIBILITY]: 'قانونية',
                [AppResources.HOME]: 'الرئيسية',
                [AppResources.SETTINGS]: 'الأعدادت',
                [AppResources.PAYMENT]: 'الدفع',
                [AppResources.PREAUTHORIZATION]: 'التفويض المسبق',
                [AppResources.EMPLOYEE_TYPE]: ' نوع الموظف',

                [AppResources.AR_FIRSTNAME]: ' الاسم الاول باللغة العربية ',
                [AppResources.AR_SECONDNAME]: 'الاسم الثاني باللغة العربية ',
                [AppResources.AR_thirdAME]: ' الاسم الثالث باللغة العربية  ',
                [AppResources.AR_FourthNAME]: ' الاسم العائلة باللغة العربية  ',
                [AppResources.POSITION]: ' المركز   ',
                [AppResources.IS_MANGER]: ' هو مدير؟   ',
                [AppResources.IS_SYSTEM_ADMIN]: ' هو مدير النظام؟   ',
                [AppResources.LOGOUT]: 'تسجيل خروج',
                [AppResources.MANAGER]: 'المدير',
                [AppResources.CREATE_EMP]: ' انشاء موظف',
                [AppResources.EDIT_EMP]: ' تعديل موظف',
                [AppResources.EN_FIRSTNAME]: '  الاسم الاول باللغة الانجليزية ',
                [AppResources.EN_SECONDNAME]: ' الاسم الثاني باللغة الانجليزية  ',
                [AppResources.EN_thirdAME]: ' الاسم الثالث باللغة الانجليزية',
                [AppResources.EN_FourthNAME]: ' الاسم العائلة باللغة الانجليزية',

                [AppResources.MATERIAL_STATUS]: ' الحالة الاجتماعية   ',
                [AppResources.QUARTRY]: ' ربعي ',
                [AppResources.MONTHLY]: ' شهري ',
                [AppResources.YEARLY]: ' سنوي    ',
                [AppResources.WEEKLY]: ' اسبوعي    ',
                [AppResources.MALE]: ' ذكر   ',
                [AppResources.FEMALE]: ' انثى    ',
                [AppResources.SINGLE]: 'اعزب',
                [AppResources.MARRIED]: 'متزوج',
                [AppResources.DIVORCED]: 'مطلق/ة',
                [AppResources.WIDOWER]: 'ارمل/ة',
                [AppResources.UNSELECT]: 'غير محدد',
                [AppResources.TOTAL]: ' المجموع',


                // [AppResources.ADD_CHARGE_CODE_CONF]: 'هل أنت متأكد أنك تريد إضافة رمز الشحن',
                [AppResources.ADD_CHARGE_CODE_CONF]: 'هل أنت متأكد أنك تريد إضافة الرمز المحاسبي',

                [AppResources.PROFILE]: 'الملف الشخصي',
                [AppResources.FILTER]: 'فلترة ',
                [AppResources.GENDER]: '  الجنس ',
                [AppResources.JOINING_DATE]: '  تاريخ التعيين ',
                [AppResources.TERMINATE_DATE]: 'تاريخ الإنهاء',
                [AppResources.TOTAL_HOURS]: '  عدد الساعات',
                [AppResources.WEEK_ONE]: ' الاسبوع الاول ',
                [AppResources.WEEK_TWO]: '  الاسبوع الثاني  ',
                [AppResources.WEEK_THREE]: ' الاسبوع الثالث ',
                [AppResources.WEEK_FOUR]: '  الاسبوع الرابع  ',
                [AppResources.WEEK_FIVE]: '  الاسبوع الخامس  ',
                [AppResources.GENERATE]: 'إنشاء سجل الدوام   ',


                [AppResources.THEME]: 'الموضوعات',
                [AppResources.MY_ACCOUNT]: 'حسابي الشخصي',
                [AppResources.USER_IMAGE]: 'صورة المستخدم',
                [AppResources.DOWNLOAD]: 'تحميل',
                [AppResources.CANCEL]: 'إلغاء',
                [AppResources.DATE_VALIDATION]: 'التاريخ من يجب ان يكون قبل التاريخ الى',
                [AppResources.SELECT]: 'اختيار',
                [AppResources.NODATA]: 'لا يوجد بيانات',
                [AppResources.NAME]: 'الاسم',
                [AppResources.DOCUMENTID]: 'رقم الوثيقة',
                [AppResources.FILEID]: 'رقم الملف',
                [AppResources.CONTACTNO]: 'رقم التواصل',
                [AppResources.DATEOFBIRTH]: 'تاريخ الولادة',
                [AppResources.INSURANCEPLAN]: 'خطة التأمين',
                [AppResources.ELIGIBILITYSTATUS]: 'حالة الأهلية',


                [AppResources.DATE]: 'التاريخ',
                [AppResources.TOTAL_RESULTS]: 'المجموع',
                [AppResources.MEMBER_CARD_ID]: 'معرف بطاقة العضو',
                [AppResources.ELIGIBILITY_ID]: 'معرف الأهلية',
                [AppResources.TRANSACTION_DATE]: 'تاريخ الحركة',
                [AppResources.INSURANCE_PLAN]: 'خطة التأمين',
                [AppResources.REQUEST_NEW_ELIGIBILITY]: 'طلب أهلية جديدة',
                [AppResources.BENEFICIARY_ID]: 'معرف المستفيد',
                [AppResources.ENGLISH_LANGUAGE]: 'English',
                [AppResources.ARABIC_LANGUAGE]: 'العربية',
                [AppResources.CHECK_ELIGIBILITY]: 'التحقق من الأهلية',
                [AppResources.FROM]: 'من',
                [AppResources.TO]: 'الى',
                [AppResources.OK]: 'موافق',
                [AppResources.CLEAR]: 'تفريغ',
                [AppResources.INSURER]: 'التامين',
                [AppResources.SERVICE_DATE]: 'تاريخ الخدمة',
                [AppResources.ADD_NEW_BENEFICIARY]: 'إضافة مستفيد جديد',
                [AppResources.NAME]: 'الاسم',
                [AppResources.AGE]: 'العمر',
                [AppResources.VIEWELIGIBILITY]: 'عرض التفاصيل',
                [AppResources.ITEMS]: 'الاصناف',
                [AppResources.ITEM]: 'الصنف',
                [AppResources.CHAPTER]: 'فصل',
                [AppResources.BLOCK]: 'منع',
                [AppResources.ALIAS_CODE]: 'رمز الاسم المستعار',
                [AppResources.ALIAS]: 'الاسم المستعار',
                [AppResources.CATEGORY]: 'الفئة',
                [AppResources.CREATED_DATE]: 'تاريخ الإنشاء ',
                [AppResources.SHORT_DESCRIPTION]: 'وصف مختصر',
                [AppResources.ITEM_CODE]: 'كود الصنف',
                [AppResources.ADD_NEW_ITEM]: 'اضافة صنف جديد',
                [AppResources.ADDITEM]: 'اضافة صنف',
                [AppResources.UPLOAD]: 'تحميل',
                [AppResources.EXPORT]: 'تصدير',
                [AppResources.CODE]: 'الرمز',
                [AppResources.LONG_DESCRIPTION]: 'وصف طويل',
                [AppResources.OWNER]: 'المالك',
                [AppResources.TERMINOLOGY]: 'المصطلح',
                [AppResources.ADDTERMINOLOGY]: 'اضافة مصطلح',
                [AppResources.EDITTERMINOLOGY]: 'تعديل مصطلح',
                [AppResources.VIEWTERMINOLOGY]: 'عرض مصطلح',
                [AppResources.ADD_NEW_TERMINOLOGY]: 'اضافة مصطلح جديد',
                [AppResources.CODE_SYSTEM]: 'رمز النظام',
                [AppResources.VALUE_SET]: 'قيمة العناصر',

                [AppResources.PAYERS]: 'المكلف بالدفع',
                [AppResources.PAYER]: 'المكلفين بالدفع',
                [AppResources.ADDPAYER]: 'اضافة مكلف',
                [AppResources.EDITPAYER]: 'تعديل مكلف',
                [AppResources.VIEWPAYER]: 'عرض مكلف',
                [AppResources.ADD_NEW_PAYER]: 'اضافة مكلف جديد',
                [AppResources.NAME_AR]: 'الاسم بالعربية',
                [AppResources.NAME_EN]: 'الاسم بالانجليزية',
                [AppResources.UNIFIED_ID]: 'الهوية الموحدة',
                [AppResources.VAT_NUMBER]: 'ظريبه الشراء',
                [AppResources.COUNTRY]: 'البلد',
                [AppResources.CITY]: 'المدينة',
                [AppResources.CONTACT_NAME]: 'اسم جهة الاتصال',
                [AppResources.PHONE_NUMBER]: 'رقم التليفون',
                [AppResources.BUILDING_NUMBER]: 'رقم المبنى',
                [AppResources.STREET_NAME]: 'اسم الشارع',
                [AppResources.DISTRICT]: 'الحي',
                [AppResources.POSTAL_CODE]: 'الرمز البريدي',
                [AppResources.ADDITIONAL_NUMBER]: 'رقم إضافي',
                [AppResources.CREATE]: 'إنشاء',
                [AppResources.CREATE_AND_NEW]: 'إنشاء و تجديد',
                [AppResources.EXT]: 'Ext',

                [AppResources.ADD_CATEGORY]: 'اضافة فئة',
                [AppResources.ADD_CHAPTER]: 'اضافة فصل',
                [AppResources.ADD_BLOCK]: 'اضافة',
                [AppResources.ID]: 'الرقم',
                [AppResources.SAVE]: 'حفظ',
                [AppResources.VIEWITEM]: 'عرض الصنف',
                [AppResources.BACK]: 'رجوع',
                [AppResources.EDIT]: 'تعديل',
                [AppResources.DELETE]: 'حذف',
                [AppResources.YES]: 'نعم',
                [AppResources.NO]: 'لا',
                [AppResources.DIAGNOSIS]: 'التشخيص',
                [AppResources.ADD_NEW_DIAGNOSIS]: 'اضافة تشخيص جديد',
                [AppResources.PARENT]: 'الرئيسي',
                [AppResources.ADDDIAGNOSIS]: 'اضافة تشخيص',
                [AppResources.VERSION]: 'نسخة',
                [AppResources.YEAR]: 'سنة',
                [AppResources.SECONDARY_ASSOCIATIONS]: 'الجمعيات الثانوية',
                [AppResources.ASSOCIATION]: 'الثانوية',
                [AppResources.EDITITEM]: 'تعديل',

                // Start View Beneficiary
                [AppResources.CREATEBENEFICIARY]: 'إنشاء مستفيد',
                [AppResources.EDITBENEFICIARY]: 'تعديل المستفيد',
                [AppResources.PERSONAL_INFORMATION]: 'المعلومات الشخصية',
                [AppResources.ENEFICIARY_FILE_ID]: 'معرف ملف المستفيد',
                [AppResources.BENEFICIARY_NAME]: 'أسم المستفيد',
                [AppResources.DATE_OF_BIRTH]: 'تاريخ الولادة',
                [AppResources.BLOOD_GROUP]: 'فصيلة الدم',
                [AppResources.HIJRI]: 'هجري',
                [AppResources.NATIONALITY]: 'الجنسية',
                [AppResources.RESIDENT_TYPE]: 'نوع المقيم',
                [AppResources.DOCUMENT_TYPE]: 'نوع الوثيقة',
                [AppResources.DOCUMENT_ID]: 'معرف المستند',
                [AppResources.PATIENT_PHONE_NUMBER]: 'رقم هاتف المريض',
                [AppResources.EMERGENCY_PHONE_NUMBER]: 'رقم هاتف الطوارئ',
                [AppResources.EMERGENCY_CONTACT_RELATION]: 'علاقة الاتصال في حالات الطوارئ',
                [AppResources.EMAIL]: 'البريد الالكتروني',
                [AppResources.MARTIAL_STATUS]: 'الحالة الاجتماعية',
                [AppResources.PREFERRED_LANGUAGE]: 'اللغة المفضلة',
                [AppResources.ADDRESS]: 'العنوان',
                [AppResources.MEMBERSHIP_NO]: 'رقم العضوية',
                [AppResources.PPOLICY_NO]: 'رقم السياسة',
                [AppResources.GROUP_NAME]: 'أسم المجموعة',
                [AppResources.APPROVAL_LIMIT]: 'حد الاعتماد',
                [AppResources.NETWORK_NO]: 'رقم الشبكة',
                [AppResources.TERM]: 'المدة',
                [AppResources.CO_PAY]: 'دفع المشترك',
                [AppResources.NOTES]: 'الملاحظات',
                [AppResources.APPROVALS]: 'الموافقات',
                [AppResources.VIEWBENEFICIARY]: 'تفاصيل المستفيد',
                // End View Beneficiary


                // Start View Eligibility 


                [AppResources.ELIGIBILITY_DETAILS]: 'تفاصيل الأهلية',
                [AppResources.ELIGIBILITY_ID]: 'معرف الأهلية',
                [AppResources.CONTACT_NUMBER]: 'رقم الاتصال',
                [AppResources.COVARAGE]: 'التغطية',
                [AppResources.CLASS]: 'الصف',
                [AppResources.NETWORK]: 'شبكة الاتصال',
                [AppResources.DEPENDENT_RELATIONSHIP]: 'علاقة التبعية',
                [AppResources.ROOM_TYPE]: 'نوع الغرفة',
                [AppResources.PERIOD]: 'المده',
                [AppResources.MAX_ALLOWED]: 'الحد الأقصى المسموح به',
                [AppResources.ELIGIBILITY]: 'الاهليه',
                [AppResources.SERVICE_DATE]: 'تاريخ الخدمة',
                [AppResources.END_DATE]: 'تاريخ الانتهاء',
                [AppResources.DEPARTMENT_NAME]: 'اسم القسم',
                [AppResources.DEPARTMENT_ROLE]: 'دور القسم',
                [AppResources.TRANSACTION_DATE]: 'تاريخ المعاملة',
                [AppResources.RESPONSE_TIME]: 'وقت الاستجابة',
                [AppResources.DOWNLOAD_TIME]: 'وقت التحميل',
                [AppResources.ELIGIBILITY_PURPOSE]: 'الغرض من الأهلية',
                [AppResources.INCLUSIONS]: 'التضمينات',
                [AppResources.UNIT]: 'الوحدة',
                [AppResources.TYPE]: 'النوع',
                [AppResources.LIMIT]: 'الحد',
                [AppResources.MAX_OUT_OF_POCKET]: 'دفع من جيبه',
                [AppResources.EXCEPTIONS]: 'استثناءات',
                [AppResources.EXCLUSIONS]: 'الاستثناءات',

                // End View Eligibility 


                // Start Practitioners 
                [AppResources.PRACTITIONERS]: 'الممارسين',
                [AppResources.DESCRIPTION]: 'التفاصيل',
                [AppResources.ADD_NEW_PARTITIONER]: 'إضافة قسم جديد',
                [AppResources.LICENSE]: 'الترخيص',
                [AppResources.ENGLISH_NAME]: 'الاسم بالانجليزي',
                [AppResources.SPECIALTY]: 'التخصص',
                [AppResources.ROLE]: 'الدور',
                [AppResources.ACTION_FROM]: 'الإجراء من',
                [AppResources.ACTION_TO]: 'الإجراء الى',
                [AppResources.CREATEPRACTITIONERS]: 'إنشاء ممارسين',
                [AppResources.EDITPRACTITIONERS]: 'تعديل الممارسين',
                [AppResources.PARTITIONER_CODE]: 'رمز التقسيم',
                [AppResources.PRACTITIONER_SPECIALTY]: 'التخصص الممارس',
                [AppResources.PRACTITIONER_ROLE]: 'دور الممارس',
                [AppResources.VIEWPRACTITIONERS]: 'تفاصيل الممارسين',
                [AppResources.BACK]: 'رجوع',
                [AppResources.ADD]: 'إضافة',
                [AppResources.EDIT]: 'تعديل',



                // Start TypesManagement 
                [AppResources.TYPESMANAGEMENT]: 'إدارة الأنواع',
                [AppResources.CODE_SYSTEM_URL]: 'رابط عنوان النظام',
                [AppResources.ADD_NEW_TYPE]: 'إضافة نوع جديد',
                [AppResources.CREATETYPESMANAGEMENT]: 'إنشاء نوع جديد',
                [AppResources.EDITTYPESMANAGEMENT]: 'تعديل النوع ',
                [AppResources.VIEWTYPESMANAGEMENT]: 'تفاصيل إدارة الأنواع ',



                // Start PROVIDER_GROUPS 
                [AppResources.PROVIDERGROUPS]: 'مجموعات المزودين',
                [AppResources.ADDNEWGROUP]: 'إضافة مجموعة جديدة',
                [AppResources.CREATEPROVIDERGROUPS]: 'تعديل مجموعة المزودين',
                [AppResources.VIEWPROVIDERGROUPS]: 'تفاصيل مجموعة المزودين',


                [AppResources.SERVICECATEGORIES]: 'فئات الخدمات الإضافية',
                [AppResources.VIEWSERVICECATEGORIES]: 'عرض فئات الخدمات الإضافية',
                [AppResources.EDITSERVICECATEGORIES]: 'تعديل فئات الخدمات الإضافية',
                [AppResources.ADDSERVICECATEGORIES]: 'اضافة فئات الخدمات الإضافية',
                [AppResources.ADD_NEW_SERVICE_CATEGORIE]: 'اضافة فئات جديدة',
                [AppResources.SERVICESUBGROUPS]: 'مجموعات الخدمة الفرعية',
                [AppResources.VIEWSERVICESUBGROUPS]: 'عرض مجموعات الخدمة الفرعية',
                [AppResources.EDITSERVICESUBGROUPS]: 'تعديل مجموعات الخدمة الفرعية',
                [AppResources.ADDSERVICESUBGROUPS]: 'اضافة مجموعات الخدمة الفرعية',
                [AppResources.ADD_NEW_SERVICE_SUB_GROUPS]: 'اضافة خدمة فرعية',
                [AppResources.SERVICE_GROUP]: 'مجموعة الخدمة',


                // Start Facilities 
                [AppResources.FACILITIES]: 'الإمكانيات',
                [AppResources.LICENSE_NO]: 'رقم الرخصة',
                [AppResources.LICENSE_PERIOD]: 'مده الرخصة',
                [AppResources.LICENSE_START]: 'بدء الترخيص',
                [AppResources.LICENSE_END]: 'انتهاء الترخيص',
                [AppResources.CHHI_ID]: 'CHHI معرف',
                [AppResources.ADDFACILITIES]: 'أضافه إمكانيه',
                [AppResources.EDITFACILITIES]: 'تعديل الإمكانيه',
                [AppResources.VIEWFACILITIES]: 'تفاصيل الإمكانيه',

                [AppResources.SERVICECATALOG]: 'كتالوج الخدمة',
                [AppResources.ADDSERVICECATALOG]: 'اضافة كتالوج الخدمة',
                [AppResources.EDITSERVICECATALOG]: 'تعديل كتالوج الخدمة',
                [AppResources.VIEWSERVICECATALOG]: 'عرض كتالوج الخدمة',
                [AppResources.CATALOG_NAME]: 'اسم الكتالوج',
                [AppResources.ADD_NEW_SERVICE_CATALOG]: 'اضافة كتالوج جديد',
                [AppResources.SERVICE_CATEGORY]: 'خدمة الفئة',
                [AppResources.SERVICE]: 'الخدمة',
                [AppResources.SERVICE_SUB_GROUP]: 'المجموعة الفرعية للخدمة',

                [AppResources.PRICELISTS]: 'قائمة الاسعار',
                [AppResources.ADDPRICELISTS]: 'اضافة قائمة اسعار',
                [AppResources.EDITPRICELISTS]: 'تعديل قائمة اسعار',
                [AppResources.VIEWPRICELISTS]: 'عرض قائمة اسعار',
                [AppResources.ADD_NEW_PRICE_LISTS]: 'اضافة قائمة اسعار جديدة',
                [AppResources.START_DATE]: 'تاريخ البداية',
                [AppResources.END_DATE]: 'تاريخ النهاية',
                [AppResources.SELECTED_FILES]: 'الملفات المختارة',
                [AppResources.DRAG_AND_DROP_A_FILE_HERE_OR_CLICK]: 'Drag And Drop A File Here Or Click',

                [AppResources.UNITPRICE]: 'Unit Price',
                [AppResources.VAT_CATEGORY]: 'Vat Category',
                [AppResources.NON_STANDARD_CODE]: 'Non Standard Code',
                [AppResources.PACKAGE]: 'Package',
                // Start ServiceGroups 
                [AppResources.SERVICEGROUPS]: 'مجموعات الخدمة',
                [AppResources.ADD__NEW_SERVICE_GROUPS]: 'إضافة مجموعات خدمة جديدة',
                [AppResources.ADDSERVICEGROUPS]: 'إضافة مجموعات الخدمة',
                [AppResources.EDITSERVICEGROUPS]: 'تعديل مجموعات الخدمة',
                [AppResources.VIEWSERVICEGROUPS]: 'تفاصيل مجموعات الخدمة',
                [AppResources.LOGIN]: 'تسجيل دخول',
                [AppResources.USERNAME]: 'اسم المستخدم',
                [AppResources.PASSWORD]: 'كلمة السر',


                // Start PriceLists 
                [AppResources.ITEM_TYPE]: 'نوع العنصر',
                [AppResources.ITEM_CATEGORY]: 'فئة العنصر',
                [AppResources.ITEM_BLOCK]: 'كتلة العنصر',


                [AppResources.PRE_AUTHORIZATION_ID]: 'PreAuthorization ID',
                [AppResources.ADD_NEW_PREAUTHORIZATION]: 'اضافة تفويض مسبق جديد',
                [AppResources.ADDPREAUTHORIZATION]: 'اضافة تفويض مسبق',
                [AppResources.EDITPREAUTHORIZATION]: 'تعديل تفويض مسبق',
                [AppResources.VIEWPREAUTHORIZATION]: 'عرض تفويض مسبق',

                [AppResources.PRE_AUTHORIZATION_INFO]: 'Pre Authorization Info',
                [AppResources.DATE_ORDERED]: 'Date Ordered',
                [AppResources.TYPE]: 'النوع',
                [AppResources.SUBTYPE]: 'النوع الفرعي',
                [AppResources.SEQUENCE]: 'Sequence',
                [AppResources.CODE_DESCRIPTION]: 'Code - Description',
                [AppResources.ON_ADMISSION]: 'On Admission',

                [AppResources.VIEWDIAGNOSIS]: 'View Diagnosis',
                [AppResources.EDITDIAGNOSIS]: 'Edit Diagnosis',

                [AppResources.DOWNLOAD_SAMPLE]: 'Download Sample',
                [AppResources.VIEWPAYMENT]: 'View Payment',

                [AppResources.POLICYLISTS]: 'Policy Lists',
                [AppResources.ADDPOLICIESLISTS]: 'Add Policy',
                [AppResources.EDITPOLICIESLISTS]: 'Edit Policy',
                [AppResources.VIEWPOLICIESLISTS]: 'View Policy',
                [AppResources.ENCOUNTERS]: 'Encounters',
                [AppResources.ADDCLAIM]: 'Add Claim',
                [AppResources.EDITCLAIM]: 'Edit Claim',
                [AppResources.VIEWCLAIM]: 'View Claim',


                // Start Reports 
                [AppResources.REPORT]: 'ملخص التقرير',
                [AppResources.APPROVAL]: 'الموافقات',
                [AppResources.DETAILED_REPORT]: 'التقرير المفصل',
                [AppResources.COLLECTIONREPORT]: 'تقرير المجموعة',
                [AppResources.AGINGREPORT]: 'تقرير العمر',
                [AppResources.ENCOUNTERS_CLAIMS]: 'Encounters',
                [AppResources.BENEFITS]: 'المنافع',
                [AppResources.ADDBENEFIT]: 'Add Benefit',
                [AppResources.EDITBENEFIT]: 'Edit Benefit',
                [AppResources.VIEWBENEFIT]: 'View Benefit',
                [AppResources.ADD_NEW_BENEFIT]: 'Add New Benefit',

                [AppResources.PATIENT]: 'المريض',
                [AppResources.CREATEPATIENT]: 'اضافة مريض',
                [AppResources.EDITPATIENT]: 'تعديل مريض',
                [AppResources.VIEWPATIENT]: 'عرض مريض',
                [AppResources.PATIENT_ID]: 'Patient ID',
                [AppResources.ADD_NEW_PATIENT]: 'اضافة مريض جديد',

                [AppResources.SUMMARY]: 'ملخص',
                [AppResources.REJECTION]: 'الرفض',
                [AppResources.LOCAION]: 'الموقع',
                [AppResources.TITLE]: 'العنوان',

                [AppResources.PENDING]: 'قيد الانتظار',
                [AppResources.SUBMITTED]: 'تم الحفظ',
                [AppResources.SUBMITTE]: 'ترحيل',

                [AppResources.FOLLOWUPS]: 'المتابعات',
                [AppResources.HOSPITALCODE]: 'رمز المستشفى',
                [AppResources.SERVICEITEMS]: 'Service Items',

                [AppResources.ADDSERVICEITEMS]: 'Add Service Items',
                [AppResources.EDITSERVICEITEMS]: 'Edit Service Items',
                [AppResources.VIEWSERVICEITEMS]: 'View Service Items',
                [AppResources.POLICYMANAGEMENT]: 'اداره خطه العمل',
                [AppResources.REJECTED]: 'مرفوض',
                [AppResources.DICTIONARIES]: 'القواميس',
                [AppResources.PLANS]: 'الخطط',
                [AppResources.INSURANCEPOLICIES]: 'سياسات التأمين',
                [AppResources.POLICIES]: 'السياسات',
                [AppResources.PROVIDER]: 'المزود',
                [AppResources.SERVICES_MANAGEMENT]: 'إدارة الخدمات',
                [AppResources.SYSTEM_SETUP]: 'إعداد النظام',
                [AppResources.ENCOUNTER]: 'المواجهات',
                [AppResources.PERSONALINFORMATION]: 'البيانات الشخصية',
                [AppResources.JOBDATA]: 'بيانات الوظيفة',
                [AppResources.VACATIONDATA]: 'بيانات الاجازات',
                [AppResources.PERFORMANCEDATA]: 'بيانات الاداء',
                [AppResources.RATINGDATA]: 'بيانات التقييم',
                [AppResources.SALARYDATA]: 'بيانات الرواتب',
                [AppResources.LOCATIONDATA]: 'اخرى',
                [AppResources.CONFIRMATION]: 'التأكيد',
                [AppResources.CHANGE_STATUS_CONFIRMATION_MSG]: 'هل انت متأكد من تغيير الحالة؟',
                [AppResources.EMPLOYEESPROCEDURES]: 'طلبات الموظفين',
                [AppResources.VIEWEMPLOYEEPROCEDURE]: 'عرض طلبات الموظف',
                [AppResources.JOBSPROCEDURES]: 'طلبات الوظائف',
                [AppResources.SALARYRALLIES]: 'طلبات مسيرات الرواتب',
                [AppResources.VIEWSALARYRALLIES]: 'عرض مسيرات الرواتب',
                [AppResources.VACATIONS]: 'طلبات الاجازات',
                [AppResources.VIEWVACATIONS]: 'عرض الاجازات',

                [AppResources.QUALIFICATIONS]: 'طلبات المؤهلات',
                [AppResources.VIEWQUALIFICATIONS]: 'عرض المؤهلات',

                [AppResources.REVIEWS]: 'طلبات التقييمات',
                [AppResources.VIEWREVIEWS]: 'عرض التقييمات',

                [AppResources.EMPLOYEESREPORTS]: 'تقاير الموظفين',
                [AppResources.PERFORMANCEREPORTS]: 'تقارير اداء الالتزام',
                [AppResources.REQUESTS_MANAGEMENT]: 'ادارة الطلبات',
                [AppResources.CATEGORIES_MATCHING_MANAGEMENT]: 'ادارة مطابقة التصنيفات',
                [AppResources.REPORTS_MANAGEMENT]: 'ادارة التقارير',
                [AppResources.RESEND]: 'اعادة ارسال',
                [AppResources.COMMITMENT_RATE]: 'نسبة الالتزام',
                [AppResources.PROCEDURES_TYPES]: 'انواع الاجراءات',


                [AppResources.SYSTEM_PROPERTIES_TITLE]: 'خصائص النظام',
                [AppResources.MATCHING_DATA]: 'مطابقة بيانات',
                [AppResources.VIEWJOBPROCEDURE]: 'عرض طلبات الوظيفة',
                [AppResources.CLOSE]: 'اغلاق',


                //Start

                [AppResources.CONFIGURATIONS]: 'الاعدادات',
                [AppResources.ORGANIZATION_STRUCTURE]: 'الهيكل التنظيمي',
                [AppResources.REVENUE_STREAMS]: 'مصادر الدخل',
                [AppResources.ADD_NEW_CHARGE_CODES]: 'اضافة رمز مشروع',
                [AppResources.PROJECTS]: 'المشاريع',
                [AppResources.PROJECT_NUMBER]: 'رقم المشروع',
                [AppResources.CHARGE_CODES]: 'الرمز المحاسبي',
                [AppResources.ACTIVE]: 'فعال',
                [AppResources.IN_ACTIVE]: 'غير فعال',
                [AppResources.EMPLOYEES]: 'الموظفين',
                [AppResources.EMPLOYEE_TIME_SHEET]: 'جدول مواعيدي',
                [AppResources.EXAMPLE]: 'نموذج',
                [AppResources.AGREE]: 'تاكيد',
                [AppResources.DISAGREE]: 'الغاء',
                [AppResources.WUTHDRAWAL]: 'انسحاب',
                [AppResources.TODAY]: 'اليوم',
                [AppResources.PREVIOUS]: 'السابق',
                [AppResources.NEXT]: 'التالي',
                [AppResources.DEPARTMENT]: 'القسم',
                [AppResources.EMPLOYEE_DEPARTMENT]: 'م.القسم',
                [AppResources.UPDATE]: 'تعديل',
                [AppResources.SUMMARY_REPORT]: 'التقرير الموجز',
                [AppResources.GENERAL_REPORT]: 'التقرير العام',
                [AppResources.COST_REPORT]: 'تقرير التكاليف',
                [AppResources.COPY_WEEK]: 'نسخ الاسبوع السابق',
                [AppResources.SOME_THING_WENT_WRONG]: 'اسم مستخدم أو كلمة سر  غير صحيحين',
                [AppResources.DELETED_DEILY_WORK_ROW]: 'هل أنت متأكد أنك تريد حذف هذا الصف',
                [AppResources.DRAFT]: 'مسودة',
                [AppResources.APPROVED]: 'معتمد',
                [AppResources.APPROVE]: 'موافقة',
                [AppResources.CUSTOMER]: 'العميل',
                [AppResources.STARTDATE]: 'تاريخ البدأ',
                [AppResources.ENDDATE]: 'تاريخ النهاية',
                [AppResources.CREATED_SUCCESS]: 'تم الانشاء بنجاح',
                [AppResources.DELETED_SUCCESS]: 'تم الحذف بنجاح',
                [AppResources.UPDATED_SUCCESS]: 'تم التحديث بنجاح',
                [AppResources.DELETE_CONFIRMATION_MESSAGE]: '؟هل أنت متأكد أنك تريد حذف هذا السجل',
                [AppResources.ADMINISTRATIVE]: 'مدير',
                [AppResources.BILLABLE]: 'مدفوع',
                [AppResources.DEVELOPMENT]: 'تطوير',
                [AppResources.OTHERS]: 'اخرى',
                [AppResources.OOPS]: "!Oops",
                [AppResources.HOME_PAGE]: 'الصفحة الرئيسية',
                [AppResources.MSG404]: '404 - لا يمكن العثور على هذه الصفحة',
                [AppResources.SOMETHING_WENT_WRONG_MESSAGE]: 'حدث خطأ ما',
                [AppResources.SOME_THING_WENT_WRONG]: 'حدث خطأ',
                [AppResources.CHARGECODE_ALREDY_EXIST]: 'تم الاضافة مسبقا',
                [AppResources.REJECT_REASON]: 'سبب الرفض',
                [AppResources.CHANGE_PASSWORD]: 'تغيير كلمة المرور',
                [AppResources.OLD_PASSWORD]: 'كلمة المرور القديمة',
                [AppResources.NEW_PASSWORD]: 'كلمة المرور الجديدة',
                [AppResources.CONFIRM_PASSWORD]: 'تاكيد كلمة المرور',
                [AppResources.FORGET_PASSWORD]: 'نسيت كلمة المرور ',
                [AppResources.WELCOME_LOGIN]: 'مرحبًا بك في متصفح المحتوى  X4Fatoora ',
                [AppResources.SEND]: 'ارسال',
                [AppResources.RESET_PASSWORD]: 'اعادة تعيين كلمة المرور',
                [AppResources.RESET_PASSWORD_MESSAGE]: 'تم ارسال رابط اعادة تعيين كلمة المرو',
                [AppResources.HOURS_VALIDATIONS_MESSAGE]: 'يجب ان لا يقل عدد الساعات عن 40 ساعة',
                [AppResources.NUMBER]: 'الرقم',
                [AppResources.NO_ACTIONS]: 'لا يوجد حركات',
                [AppResources.DIRECTREPORTEES]: 'التابعين درجة اولى',
                [AppResources.TOTAL_HOURS_8_Ratio]: 'معامل 8س',
                [AppResources.HOURS_Ratio]: 'معامل الساعة',
                [AppResources.UNLOCK]: 'إلغاء قفل الحساب',
                [AppResources.CONFIGURATION_HOUR]: 'اعدادات الوقت',
                [AppResources.HOURS]: 'ساعات',



                /////////////////////////////// Start X4Fatoora
                [AppResources.ORGANIZATION_MANAGEMENT]: 'إدارة المنظمة',
                [AppResources.INVOICES_BOOK_MANAGEMENT]: 'إدارة دفتر الفواتير',
                [AppResources.FAWATEER]: 'الفواتير',
                [AppResources.ONBOARDING]: 'الإعداد',
                [AppResources.SIMULATION]: 'Simulation',
                [AppResources.PRODUCTION]: 'Production',

                [AppResources.ADD_ORGANIZATION]: 'أضف منظمة',
                [AppResources.EDIT_ORGANIZATION]: 'تعديل المنظمة',
                [AppResources.VIEW_ORGANIZATION]: 'عرض المنظمة',
                [AppResources.PROVINCE]: 'المقاطعة',
                [AppResources.ADDITIONAL_STREET]: 'شارع إضافي',
                [AppResources.BASIC_INFO]: 'معلومات اساسية',


                [AppResources.BUSINESS_CATEGORY]: 'فئة العمل',
                [AppResources.FIRST_NAME]: 'اسم',
                [AppResources.IDENTITY_TYPE]: 'نوع الهوية',
                [AppResources.IDENTITY_NUMBER]: 'رقم الهوية',
                [AppResources.VAT_REGISTRATION_NUMBER]: 'رقم التسجيل الضريبي',
                [AppResources.VAT_REGISTRATION]: 'التسجيل الضريبي',
                [AppResources.TAX_IDENTIFICATION_NUMBER]: 'رقم التعريف الضريبي',
                [AppResources.CURRENCY]: 'العملة',
                [AppResources.LOGO]: 'الشعار',
                [AppResources.UPLOAD_IMAGE_ERROR]: 'الملف ليس من نوع الصورة',

                [AppResources.INVOICES]: 'الفواتير',
                [AppResources.ADD_INVOICES_BOOK]: 'اضافة دفتر فواتير',
                [AppResources.EDIT_INVOICES_BOOK]: 'تعديل دفتر الفواتير',
                [AppResources.VIEW_INVOICES_BOOK]: 'عرض دفتر الفواتير',
                [AppResources.CREATE_NEW_INVOICES_BOOK]: 'إنشاء دفتر فواتير جديد',

                [AppResources.CREATE_NEW_INVOICES_BOOK]: 'إنشاء دفتر فواتير جديد',
                [AppResources.CREATE_NEW_INVOICES_BOOK]: 'إنشاء دفتر فواتير جديد',
                [AppResources.CREATE_NEW_INVOICES_BOOK]: 'إنشاء دفتر فواتير جديد',
                [AppResources.CREATE_NEW_INVOICES_BOOK]: 'إنشاء دفتر فواتير جديد',
                [AppResources.CREATE_NEW_INVOICES_BOOK]: 'إنشاء دفتر فواتير جديد',

                [AppResources.INVOICING_TYPE]: 'نوع الفواتير',
                [AppResources.ORGANIZATION]: 'المنظمة',
                [AppResources.ORGANIZATION_ID]: 'رقم المنظمة',
                [AppResources.LANGUAGE]: 'اللغة',
                [AppResources.PREFERRED_ADDRESS]: 'العنوان المفضل',
                [AppResources.TAGS]: 'العلامات',
                [AppResources.INVOICE_BOOK_DETAILS]: 'تفاصيل دفتر الفواتير',
                [AppResources.INVOICE_TYPE]: 'نوع الفاتورة',
                [AppResources.ONBOARD]: 'إعداد',
                [AppResources.RENEW]: 'تجديد',
                [AppResources.OTP_VERIFICATION]: 'التحقق من OTP',
                [AppResources.OTP_MSG]: 'يرجى إدخال رمز OTP من فاتورة للمتابعة.',
                [AppResources.OTP_MSG_ERROR]: 'يرجى إدخال رمز OTP من فاتورة للمتابعة.',
                [AppResources.VALIDATE]: 'تحقق',
                [AppResources.OTP]: 'رمز التحقق',

                [AppResources.INVOICE_NUMBER]: 'رقم الفاتورة',
                [AppResources.ISSUE_DATE]: 'تاريخ الإصدار',
                [AppResources.INVOICES_BOOK]: 'دفتر الفواتير',
                [AppResources.AMOUNT]: 'المبلغ',
                [AppResources.DISCOUNT]: 'الخصم',
                [AppResources.TAX]: 'الضريبة',
                [AppResources.TOTAL_AMOUNT]: 'المبلغ الإجمالي',



                [AppResources.INVOICE_DATE_FROM]: 'تاريخ الفاتورة من',
                [AppResources.INVOICE_DATE_TO]: 'تاريخ الفاتورة إلى',
                [AppResources.CLIENT_ID]: 'معرف العميل',
                [AppResources.SERIAL_NUMBER]: 'الرقم التسلسلي',
                [AppResources.SEQUENCE_NUMBER]: 'رقم سري',
                [AppResources.TAX_ID]: 'الرقم الضريبي',
                [AppResources.CLIENT_VAT_NUMBER]: 'رقم ضريبة القيمة المضافة للعميل',
                [AppResources.VIEW_REPORT]: 'عرض التقرير',


                [AppResources.CREATE_NEW_INVOICES_BOOK_ONBOARDING]: 'إنشاء دفتر فواتير جديد',
                [AppResources.ADD_ONBOARDING]: 'إضافة Onboarding',
                [AppResources.EDIT_ONBOARDING]: 'تعديل Onboarding',
                [AppResources.VIEW_ONBOARDING]: 'عرض Onboarding',
                [AppResources.ONBOARDING_PROGRESS]: 'تقدم الإعداد',
                [AppResources.PROGRESS]: 'التقدم',

                [AppResources.PROCEED]: 'تقدم',

                [AppResources.RETRY]: 'إعادة',
                [AppResources.WARNING_LIST]: 'قائمة التحذيرات',
                [AppResources.ERROR_LIST]: 'قائمة الأخطاء',
                [AppResources.INFO_LIST]: 'قائمة المعلومات',
                [AppResources.CREATED]: 'تم إنشاؤها',
                [AppResources.ONBORDING_PENDING]: 'الإعداد قيد الانتظار',
                [AppResources.READY]: 'جاهز',
                [AppResources.NEW]: 'جديد',
                [AppResources.CSR_GENERATION]: 'CSR توليد',
                [AppResources.COMPLIANCE_CSID]: 'امتثال CSID',
                [AppResources.COMPLIANCE_CHECKS]: 'فحص الامتثال',
                [AppResources.PRODUCTION_CSID]: 'إنتاج CSID',
                [AppResources.COMPLETED]: 'مكتمل',
                [AppResources.FAILED]: 'فشلت',
                [AppResources.NOT_STARTED]: 'لم تبدأ',
                [AppResources.FAWATEER_LIST]: 'قائمة الفواتير',
                [AppResources.B2B_B2C_ERROR_MESSAGE]: 'يجب عليك تفعيل واحد على الأقل من B2B أو B2C',
                [AppResources.SIMULATOR_PRODUCTION_ERROR_MESSAGE]: '"يجب عليك تفعيل واحد على الأقل من "تفعيل في المحاكاة" أو " تفعيل في الإنتاج  ',
                [AppResources.IS_DEFAULT]: "إفتراضي",
                [AppResources.ALREADY_HAVE_DEFAULT]: "انت بالفعل تملك عنوان افتراضي",
                [AppResources.MIN_2_MAX_2000]: "يجب أن يكون إدخالك أكثر من حرفين وأقل من 2000 حرف.",
                [AppResources.MIN_1_MAX_1000]: "يجب أن يكون إدخالك أكثر من حرف وأقل من 1000 حرف.",
                [AppResources.MIN_1_MAX_127]: "يجب أن يكون إدخالك بين حرف واحد وأقل من 127 حرف.",
                [AppResources.MIN_0_MAX_127]: "يجب أن يكون إدخالك بين الصفر وأقل من 127 حرف.",
                [AppResources.MIN_2_MAX_100]: "يجب أن يكون إدخالك أكثر من حرفين وأقل من 100 حرف.",
                [AppResources.MIN_2_MAX_30]: "يجب أن يكون إدخالك أكثر من حرفين وأقل من 30 حرف.",
                [AppResources.MIN_2_MAX_20]: "يجب أن يكون إدخالك أكثر من حرفين وأقل من 20 حرف.",
                [AppResources.EQUAL_TO_10]: "يجب أن يكون الإدخال الخاص بك يساوي 10 أرقام.",
                [AppResources.MIN_2_MAX_10]: "يجب أن يكون إدخالك أكثر من حرفين وأقل من 10 حرف.",
                [AppResources.MIN_2_MAX_5]: "يجب أن يكون إدخالك أكثر من حرفين وأقل من 5 حرف.",
                [AppResources.MIN_1_MAX_3]: "هذا الحقل يقبل رمز على الأقل و 3 رموز على الأكث",
                [AppResources.ORDER_NUMBER]: "يقبل هذا الحقل فقط الإدخال الرقمي، بنطاق من 1 إلى 3 أرقام، باستثناء الصفر.",
                [AppResources.VAT_NUMBER_ERROR]: "يجب أن يبدأ الرقم الضريبي بالرقم 3 وينتهي بالرقم 3 ويتكون من 15 رقماً",
                [AppResources.ORGANIZATION_DETAILS]: "تفاصيل المنظمة",
                [AppResources.COMMERCIAL_REGISTRATION_NUMBER]: "رقم السجل التجاري",

                [AppResources.MOMRA]: "ترخيص وزارة الشؤون البلدية والقروية",
                [AppResources.MLSD]: "ترخيص وزارة الموارد البشرية والتنمية الاجتماعية",
                [AppResources.CODE_700]: "Code 700",
                [AppResources.SAGIA]: "ترخيص الهيئة العامة للاستثمار",
                [AppResources.NATIONAL_ID]: "رقم الهوية الوطنية",
                [AppResources.GCC]: "رقم الهوية الخليجية",
                [AppResources.IQAMA]: "رقم الاقامة",
                [AppResources.PASSPORT]: "رقم جواز السفر",
                [AppResources.OTHER_ID]: "أي معرف آخر للمورد",
                [AppResources.START]: "بدء",
                [AppResources.ADDRESS_NAME]: "اسم الموقع",
                [AppResources.SA]: "المملكة العربية السعودية",
                [AppResources.DEBIT_NOTE]: "بيان بالخصم",
                [AppResources.CREDIT_NOTE]: "اشعار دائن",
                [AppResources.TAX_INVOICE]: "فاتورة ضريبية",
                [AppResources.REPORTED]: "تم توثيقها",
                [AppResources.REPORTED_WITH_WARNINGS]: "تم توثيقها مع وجود تحذير",
                [AppResources.CLEARED]: "تم تخليصها",
                [AppResources.CLEARED_WITH_WARNINGS]: "تم تخليصها مع وجود تحذير",

                [AppResources.CSR_GENERATED]: "تم إنشاء المسؤولية الاجتماعية للشركات",
                [AppResources.CSR_GENERATION_FAILED]: "فشل توليد المسؤولية الاجتماعية للشركات",
                [AppResources.COMPLIANCE_CSID_OBTAINED]: "تم الحصول على الامتثال CSID",
                [AppResources.COMPLIANCE_CSID_FAILED]: "فشل الامتثال CSID",
                [AppResources.COMPLIANCE_CHECK_DONE]: "تم التحقق من الامتثال",
                [AppResources.COMPLIANCE_CHECK_FAILED]: "فشل التحقق من الامتثال",
                [AppResources.PRODUCATION_CSID_FAILED]: "فشل إنتاج CSID",
                [AppResources.RENEWAL_PRODUCTION_CSID_FAILED]: "فشل تجديد إنتاج CSID",
                [AppResources.EXPIRED]: "منتهي الصلاحية",
                [AppResources.ONBOARDING_PENDING]: "الإعداد معلق",
                [AppResources.ONBOARDING_FAILED]: "فشل الإعداد",
                [AppResources.PROD_CSID_EXPIRED]: "انتهت صلاحية Prod CSID",
                [AppResources.PROD_CSID_RENEWAL_PENDING]: "تجديد Prod CSID معلق",
                [AppResources.PROD_CSID_RENEWAL_FAILED]: "فشل تجديد Prod CSID",
                [AppResources.IS_10_DIGITS]: "يجب أن يكون إدخالك 10 أرقام",
                [AppResources.IS_5_DIGITS]: "يجب أن يكون إدخالك 5 أرقام",
                [AppResources.IS_4_DIGITS]: "يجب أن يكون إدخالك 4 أرقام",
                [AppResources.TIN]: "رقم التعريف الضريبي",
                [AppResources.ADDRESS_ADDITIONAL_NUMBER]: "يجب أن يكون إدخالك 4 أرقام",
                [AppResources.IDENTITY_ID_NOT_UNIQUE]: "رقم الهوية ليس فريدا",
                [AppResources.PRIMARY]: "رئيسي",
                [AppResources.NOT_PRIMARY]: "فرعي",
                [AppResources.INVALID_INPUT]: "مدخل غير صالح",
                [AppResources.PRIMARY_ADDRESS_REQUIRED]: "يجب عليك إدخال عنوان أساسي",
                [AppResources.METADATA]: "البيانات الوصفية",
                [AppResources.ATTRIBUTE_NAME]: "اسم السمة",
                [AppResources.ATTRIBUTE_DESCRIPTION]: "وصف السمة",
                [AppResources.PRIORITY]: "أولوية",
                [AppResources.VISIBLE]: "ظاهر",
                [AppResources.ORDER_EXIST_ERROR]: "الترتيب المدرج موجود بالفعل في بيانات التعريف الخاصة بك.",
                [AppResources.ROLES_MANAGEMENT]: "إدارة الأدوار",
                [AppResources.LOGGERS_MANAGEMENT]: "إدارة السجل", 
                [AppResources.ADD_ROLE]: "اضافة دور",
                [AppResources.LEVEL]: "المستوى",
                [AppResources.SAVED_SUCCESSFULLY]: "تم الحفظ بنجاح",
                [AppResources.MODULE]: "الوحدة",
                [AppResources.ROLE_INFO]: "معلومات الدور",
                [AppResources.ARABIC_NAME]: 'الاسم بالعربية',
                [AppResources.ROLE_PERMISSIONS]: "أذونات الدور",
                [AppResources.CREATEROLE]: "إنشاء دور",
                [AppResources.EDITROLE]: "تعديل دور",
                [AppResources.VIEW_USER]: "عرض المستخدم",
                [AppResources.ADD_USER_ROLE]: "إضافة دور للمستخدم",
                [AppResources.USERSMANAGEMENT]: "إدارة المستخدمين",
                [AppResources.ROLES]: "الادوار",
                [AppResources.USER]: "المستخدم",
                [AppResources.ADD_USER]: "إضافة مستخدم جديد",
                [AppResources.USERS_MANAGEMENT]: "إدارة المستخدمين",
                [AppResources.ORG_USERS_MANAGEMENT]: "إدارة المستخدمين",
                [AppResources.EDIT_USER]: "تعديل المستخدم",
                [AppResources.UNIVERSAL_ORG_ACCESS]: 'الوصول الشامل للمؤسسة',
                [AppResources.ORGANIZATION_CONTEXT]: 'سياق المؤسسة',
                [AppResources.TENANT_CONTEXT]: 'سياق المجموعة',
                [AppResources.ADD_ROLES]: 'إضافة دور',
                [AppResources.TENANT_LEVEL]: 'مستوى المجموعة',
                [AppResources.ORGANIZATION_LEVEL]: 'مستوى المؤسسة',
                [AppResources.TENANT]: 'المجموعة',
                [AppResources.ORGANIZATIONS]: 'المؤسسات',
                [AppResources.IS_OFF_LINE]: 'هل يكون متصل',
                [AppResources.OFF_LINE]: 'غير متصل',
                [AppResources.ON_LINE]: 'متصل',
                [AppResources.PLACEMENT]: 'تحديد مستوى',
                [AppResources.BELONGS_TO]: 'ينتمي إلى',
                [AppResources.ORDER]: 'الترتيب',
                [AppResources.ERRORS]: 'الاخطاء',
                [AppResources.WARNINGS]: 'التحذيرات',
                [AppResources.PHONE_NUMBER_ERROR]: 'الرجاء إدخال رقم صحيح',
                [AppResources.LOGO_50KB_SIZE_ERROR]: 'يجب ألا يتجاوز حجم ملف صورة الشعار 50 كيلو بايت.',
                [AppResources.LOGO_SIZE_ERROR]: 'يجب ألا يتجاوز حجم ملف صورة الشعار الحد الأقصى.',
                [AppResources.LOGO_DIMENSIONS_ERROR]: 'يجب ألا يتجاوز ارتفاع وعرض صورة الشعار الحد الأقصى للأبعاد.',
                [AppResources.LOGO_320_DIMENSIONS_ERROR]: 'يجب أن يكون ارتفاع وعرض صورة الشعار 320 بكسل في 320 بكسل.',
                [AppResources["403"]]: 'Forbidden',
                [AppResources.USED_ROLE_ERROR]: 'لا يمكنك حذف الدور الذي تم استخدامه.', 
                [AppResources.DUPLICATE_ROLE_ERROR]: 'يوجد دور بنفس الاسم بالفعل. الرجاء اختيار أو إدخال قيمة مختلفة',
                [AppResources.DUPLICATE_USER_ERROR]: 'المستخدم موجود بالفعل',
                [AppResources.USER_ADDED_SUCCESSFULLY]: 'تمت إضافة المستخدم بنجاح',
                [AppResources.MESSAGE_ID]: 'رقم الرسالة',
                [AppResources.MESSAGE]: 'الرسالة',
                [AppResources.LOG_LEVEL]: 'مستوى السجل',
                [AppResources.DIRECTION]: 'اتجاه السجل',
                [AppResources.DURATION]: 'المدة',
                [AppResources.IS_INTERNAL]: 'داخلي',
                [AppResources.REQUEST_HEADERS]: 'معلومات الرئيسية',
                [AppResources.REQUEST_BODY]: 'ما تم ارساله', 
                [AppResources.RESPONSE_BODY]: 'ما تم استلامه', 
                [AppResources.INNER_REQUEST]: 'الطلبات المرتبطة', 
                [AppResources.HOST]: 'المرسل',
                [AppResources.VIEW_LOGGER]: 'عرض السجل',
                [AppResources.TAX_CALCULATION_TOLERANCE]: 'التسامح في حساب الضرائب',
                [AppResources.NUMBER_OPTIONAL]: 'الرجاء إدخال الأرقام فقط.',
                [AppResources.MAX_TAX_CALCULATION_TOLERANCE_GREATER_ERROR]: 'الرجاء إدخال رقم أقل من',
                [AppResources.MIN_TAX_CALCULATION_TOLERANCE_SMALLER_ERROR]: 'الرجاء إدخال رقم أكبر من',
                [AppResources.RESPONSE_ERROR]: 'Response Error',
                [AppResources.VALIDATION_LOG]: 'سجل التحقق من صحة المراسلات',
                [AppResources.SOURCE]: 'المصدر',
                [AppResources.SOURCES]: 'المصادر',
                [AppResources.SOURCE_NAME]: 'اسم المصدر',
                [AppResources.VALUE]: 'القيمة',
                [AppResources.INVOICE_DETAILS]: 'تفاصيل الفاتورة',
        }
};

export default arSa;