import { regexMsgs, regexType } from '../../../../Config/Settings/Constants/Validations';
import { Integer } from '../../../../Config/Settings/Enums';
import { FieldModel } from '../../../../Models';

export class InvoicesBookModel {
    name?: any;
    invoicingType?: any;
    organization?: any;
    language?: any;
    preferredAddress?: any;
    otp?: any;
    tags?: any;
    isOffline?: any;

    public static getFieldsModel(model?: InvoicesBookModel): any {
        if (!model) model = new InvoicesBookModel();
        let fildsObject = FieldModel.buildFromObject(model);

        fildsObject.name.required = true;
        fildsObject.name.validate = true;
        fildsObject.name.regexValue = regexType.MIN_2_MAX_100;
        fildsObject.name.errorMessages = {
          error: regexMsgs.MIN_2_MAX_100,
        };

        fildsObject.invoicingType.required = true;
        fildsObject.invoicingType.validate = true;

        // fildsObject.isOffline.required = true;
        // fildsObject.isOffline.validate = true;

        fildsObject.organization.required = true;
        fildsObject.organization.validate = true;

        fildsObject.language.required = true;
        fildsObject.language.validate = true;

        fildsObject.preferredAddress.required = true;
        fildsObject.preferredAddress.validate = true;

        fildsObject.tags.required = true;
        fildsObject.tags.validate = true;
        fildsObject.tags.value = null;
        fildsObject.tags.regexValue = regexType.MIN_2_MAX_20;
        fildsObject.tags.errorMessages = {
          error: regexMsgs.MIN_2_MAX_20,
        };

        fildsObject.invoicingType.value = null;
        // fildsObject.isOffline.value = false;
        fildsObject.organization.value = null;
        fildsObject.language.value = null;
        fildsObject.preferredAddress.value = null;

        return fildsObject;
    }

    public static searchRequest() {
        return {
            pageIndex: 0,
            pageSize: Integer.Max,
            metaData: ''
        };
    }

    public static toDBModel(model: InvoicesBookModel) {
        var result = FieldModel.getDBModel(model);
        return {
            name: result.name,
            invoicingType: result.invoicingType,
            organization: result.organization,
            language: result.language,
            preferredAddress: result.preferredAddress,
            otp: result.otp,
            tags: result.tags,
        };
    }
}

export class InvoicesBookSearch {
    searchText?: any;

    public static getFieldsModel(model?: InvoicesBookSearch): any {
        if (!model) model = new InvoicesBookSearch();
        let fildsObject = FieldModel.buildFromObject(model);

        return fildsObject;
    }

    public static toDBModel(model: InvoicesBookModel) {
        var result = FieldModel.getDBModel(model);
        return {
            name: result.name,
            invoicingType: result.invoicingType,
            organization: result.organization,
            language: result.language,
            preferredAddress: result.preferredAddress,
            otp: result.otp,
            tags: result.tags,
        };
    }
}

export class MetaDataModel {
    name?: any;
    description?: any;
    order?: any;
    isVisible?: any;
    id?: any;
    isNew: boolean;
    placement: boolean;
    belongsTo?: any;

    public static getFieldsModel(model?: MetaDataModel): any {
        if (!model) {
            model = new MetaDataModel();
            model.isNew = true;
        }else{
            model.isNew = false;
        }
        let fildsObject = FieldModel.buildFromObject(model);

        if (model.isNew) {
            fildsObject.id.value = Date.now();
            fildsObject.isVisible.value = false;
        }else{

            fildsObject.placement.defaultValue = model.placement;
        }

        fildsObject.name.required = true;
        fildsObject.name.validate = true;
        fildsObject.name.regexValue = regexType.MIN_2_MAX_30;
        fildsObject.name.errorMessages = {
          error: regexMsgs.MIN_2_MAX_30,
        };

        fildsObject.description.required = true;
        fildsObject.description.validate = true;
        fildsObject.description.regexValue = regexType.MIN_2_MAX_2000;
        fildsObject.description.errorMessages = {
          error: regexMsgs.MIN_2_MAX_2000,
        };

        fildsObject.order.required = true;
        fildsObject.order.validate = true;
        fildsObject.order.regexValue = regexType.ORDER_NUMBER;
        fildsObject.order.errorMessages = {
          error: regexMsgs.ORDER_NUMBER,
        };

        fildsObject.placement.required = true;
        fildsObject.placement.validate = true;

        fildsObject.belongsTo.required = true;
        fildsObject.belongsTo.validate = true;
        
        return fildsObject;
    }

    public static toDBModel(model: MetaDataModel) {
        var result = FieldModel.getDBModel(model);
        
        return {
            id: result.id,
            name: result.name,
            description: result.description,
            order: result.order,
            isVisible: result.isVisible? true : false,
            isNew: result?.isNew,
            placement: result?.placement?.value || result?.placement,
            belongsTo: result?.belongsTo?.id || result?.belongsTo,
        };
    }
}
