import Backdrop from '@mui/material/Backdrop';
import React from 'react';
import './style.scss';

type Props = {
    isLoading?: boolean | false;
    styles?: any;
};

const FallBack = ({ isLoading = false, styles = {} }: Props) => {
    return (
        <Backdrop open={isLoading} id='styled-backDrop' style={{ zIndex: '9999999999', position: "absolute", backgroundColor: "#9b9b9b75", borderRadius: "4px", ...styles }} >
            <div className="loading">
                <svg width="16px" height="12px">
                    <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                    <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
                </svg>
            </div>
        </Backdrop>
    );
};

export default FallBack;