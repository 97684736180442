import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeFullLoading } from "../../../../Store/Reducers/UI";
import { useParams } from "react-router-dom";
import {
  getInvoicesBookDetails,
  setInvoicesBookDetails,
} from "../../../../Store/Reducers/InvoicesBook";
import { InvoicesBookService } from "../../../../Services/API/InvoicesBook";
import { InvoicesBookOnBoardingModel } from "../../../../Models/system/invoices-book.model";
import { ValidationService } from "../../../../Utils/Validation.services";
import { ValidationModel } from "../../../../Models";
import { OnboardingService } from "../../../../Services/API/Onboarding";
import {
  DIALOG_TYPE,
  MESSAGE_TYPE,
  MessagesService,
} from "../../../../Services/messages.service";
import useTranslation from "../../../../Hooks/Shared/useTranslation";
import { InvoicesBookStatuses, OnboardingStatusCode } from "../../../../Config/Settings/Enums";
import CustomeIconButton, { IconButtonsTypes } from "../../../../Controls/IconButton";
import { InvoicesBookMetaService } from "../../../../Services/API/InvoicesBookMeta";
import { LookupsService } from "../../../../Services/API/Lookups";

const useViewInvoiceBookHook = () => {
  const { T, Resources } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { id } = useParams();
  const [dialog, setDialog] = useState<boolean>(false);
  const [isRenew, setIsRenew] = useState<boolean>(false);
  const invoicesBookDetails = getInvoicesBookDetails(state);
  const [onboardingFormState, setOnboardingFormState] = useState(
    InvoicesBookOnBoardingModel.getFieldsModel()
  );
  const [metaDataSource, setMetaDataSource] = useState<Array<any>>([]);
  const [onBoardable, setOnBoardable] = useState(false);
  const columnsLabels: Array<any> = [
    { id: "name", label: T(Resources.AppResources.NAME), align: "center" },
    { id: "description", label: T(Resources.AppResources.DESCRIPTION), align: "center" },
    { id: "isVisible", label: T(Resources.AppResources.VISIBLE), align: "center" },
    { id: "order", label: T(Resources.AppResources.PRIORITY), align: "center" },
  ];



  const handleOnboardingInputChange = (event: any) => {
    var { value } = event?.target;

    setOnboardingFormState({
      ...onboardingFormState,
      otp: {
        ...onboardingFormState.otp,
        value: value,
      },
    });
  };
  const handelSendOtp = async (isRenew: boolean = false) => {
    try {
      let valid = ValidationService.Valid(onboardingFormState);
      let validationResponse = ValidationModel.setValidations(
        valid,
        onboardingFormState
      );
      setOnboardingFormState(validationResponse);

      if (valid.isValidForm) {

        const request = { otp: onboardingFormState.otp.value };

        if (isRenew)
          await OnboardingService.RenewOnboarding(id, { body: request });
        else await OnboardingService.AddOnboarding(id, { body: request });

        MessagesService.Toast(
          T(Resources.AppResources.CREATED_SUCCESS),
          MESSAGE_TYPE.Success
        );
        setDialog(false);
        setOnboardingFormState(InvoicesBookOnBoardingModel.getFieldsModel());

      }
    } catch (error: any) {
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  };

  const getDataSource = async (id: any) => {
    try {
      const response = await InvoicesBookService.GetInvoicesBookById(id);
      const countryLookupResponse = await LookupsService.GetLookupByValue(response?.preferredAddress?.countryCode);
      fetchMetaDataList();
      if (response) dispatch(setInvoicesBookDetails({ body: { ...response, countryLookup: countryLookupResponse } }));

      setOnBoardable(response?.Status == InvoicesBookStatuses.READY ||
        response?.Status == InvoicesBookStatuses.PROD_CSID_EXPIRED ||
        response?.Status == InvoicesBookStatuses.PROD_CSID_RENEWAL_PENDING ||
        response?.Status == InvoicesBookStatuses.PROD_CSID_RENEWAL_FAILED)

    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  };

  const fetchMetaDataList = async () => {
    let request = {
      pageIndex: 0,
      pageSize: 100
    };
    const response = await InvoicesBookMetaService.SearchInvoicesBook(request, id);

    setMetaDataSource(response.sort(function (a, b) {
      return a.order - b.order
    })
      .map(itm => {
        return {
          id: itm.id,
          name: itm.name,
          description: itm.description,
          isVisible: itm.isVisible ? T(Resources.AppResources.YES) : T(Resources.AppResources.NO),
          order: itm.order,
        }
      }));
  }
  useEffect(() => {
    dispatch(changeFullLoading({ spin: true }));

    if (id !== undefined) {
      getDataSource(id);
    } else {
      dispatch(changeFullLoading({ spin: false }));
    }
  }, []);

  useEffect(() => {
    setOnboardingFormState(InvoicesBookOnBoardingModel.getFieldsModel());
  }, [dialog]);
  return {
    invoicesBookDetails,
    onboardingFormState,
    handleOnboardingInputChange,
    handelSendOtp,
    dialog,
    setDialog,
    isRenew,
    setIsRenew,
    columnsLabels,
    metaDataSource,
    onBoardable
  };
};
export default useViewInvoiceBookHook;
