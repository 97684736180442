import React, { useEffect, useState } from 'react'
import { InvoicesBookSearch } from '../Shared/invoicesBook.model';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchCriteria, setSearchCriteria } from '../../../../Store/Reducers/InvoicesBook';

const useSearchInvoicesBookHook = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const searchCriteria = getSearchCriteria(state)
    const [searchText, setSearchText] = useState("");

    //#region :: Handlers

    const handleInputChange = (value) => {
        setSearchText(value);
    }



    const searchHandler = async () => {
        const searchModel = new InvoicesBookSearch();
        searchModel.searchText = searchText;
        dispatch(setSearchCriteria({ body: searchModel }));
    };

    const resetForm = () => {
        setSearchText("");
        dispatch(setSearchCriteria({ body: {} }));
    };



    //#region :: Search Changes [useEffect]
    useEffect(() => {
        if (!searchCriteria) return;
        setSearchText(searchCriteria.searchText)
    }, [searchCriteria]);
    //#endregion

    return { searchText, searchHandler, handleInputChange, resetForm,
    };
}

export default useSearchInvoicesBookHook;