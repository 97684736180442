import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { LookupCodeLabelDeclaration } from "./index.d";
import { LookupsService } from "../../Services/API/Lookups";
import "./style.scss";

const LookupCodeLabel = ({
  className,
  value,
  withTooltip = false,
  showCode = false,
}: LookupCodeLabelDeclaration) => {
  //#region :: State
  const [model, setModel] = useState<any>();

  //#endregion

  //#region :: Handlers
  const getCodeDescription = async () => {
    try {
      const result = await LookupsService.GetLookupByValue(value);
      setModel(result);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (value) getCodeDescription();
  }, [value]);

  // #endregion

  return (
    <Tooltip
      title={
        withTooltip
          ? `${showCode ? ` (${model?.value}) - ` : ""}${model?.name}`
          : ""
      }
    >
      <label className={className}>
        <>
          {showCode ? ` (${model?.value}) - ` : ""} {model?.name}
        </>
      </label>
    </Tooltip>
  );
};

export default LookupCodeLabel;
