import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const invoicesBook = createSlice({
    name: 'invoicesBook',
    initialState: {
        invoicesBook: [],
        keys: [],
        invoicesBookList: [],
        invoicesBookDetails: {},
        searchCriteria: {
            searchText: "",
        },
        showError: false,
        error: null
    },

    reducers: {
        invoicesBookReceived: (invoicesBook: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                invoicesBook.invoicesBook[item.id] = item;
                invoicesBook.keys = [...invoicesBook.keys, item.id]
            });
            invoicesBook.customerDetails = {};
        },

        setInvoicesBookDetails: (invoicesBook: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            invoicesBook.invoicesBookDetails = data;
        },

        setInvoicesBookList: (invoicesBook: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            invoicesBook.invoicesBookList = data;
        },

        setInvoicesBooks: (invoicesBook, { payload }) => {
            const data = payload.body;
            invoicesBook.invoicesBook = data;
        },

        setSearchCriteria: (invoicesBook: any, { payload }: PayloadAction<any>) => {
            invoicesBook.searchCriteria = payload.body;
        },

        setError: (invoicesBook: any, { payload }: PayloadAction<any>) => {
            invoicesBook.error = payload.response;
            invoicesBook.showError = true;
        },
        deActiveInvoiceBook: (invoicesBook: any, { payload }: PayloadAction<any>) => {
            invoicesBook.invoicesBook[payload.id].isActive = payload.value;
        }
    }
});


export const { invoicesBookReceived, setInvoicesBooks, setInvoicesBookList, setInvoicesBookDetails, setSearchCriteria, setError, deActiveInvoiceBook } = invoicesBook.actions;
export default invoicesBook.reducer;

//#region :: Selectors
export const getInvoicesBookData = (state: any) => {
    return state.invoicesBook.invoicesBook;
};

export const getInvoicesBookDetails = (state: any) => {
    return state.invoicesBook.invoicesBookDetails;
};

export const getInvoicesBookLists = (state: any) => {
    return state.invoicesBook.invoicesBookList;
};

export const getSearchCriteria = (state: any) => {
    return state.invoicesBook.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.invoicesBook.showError;
};

export const getError = (state: any) => {
    return state.invoicesBook.error;
};

export const invoicesBookSelectors = {
    getInvoicesBookData,
    getSearchCriteria,
    getInvoicesBookDetails,
    getInvoicesBookLists,
    getShowError,
    getError,
};
//#endregion
