import { Grid, IconButton, InputBase, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '../../../../../Controls/Modal/Modal';
import Button, { ButtonsTypes } from '../../../../../Controls/Button';
import useTranslation from '../../../../../Hooks/Shared/useTranslation';
import { changeFullLoading } from '../../../../../Store/Reducers/UI';
import { GridSearchIcon } from '@mui/x-data-grid';
import { PaginationModel } from '../../../../../Models';
import RoleTable from './roleTable';
import { clearRulePageList, getRuleList, getRulePageList, setRuleList as setRolesList, setRulePageList } from '../../../../../Store/Reducers/TenantUsersManagements';
import { TenantUsersManagementService } from '../../../../../Services/API/TenantUsersManagement';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './style.scss';
import { EnvLevelContext } from '../../../../../Config/Settings/Enums';
import { RolesMappingService } from '../../../../../Services/API/RolesMapping';

const RulesDialog = ({ userId, open, handleClose }: any) => {
    //#region :: State
    const dispatch = useDispatch();
    const { T, Resources } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(open);
    const state = useSelector(state => state);
    const roleSelectedList = getRuleList(state);
    const RuleList = getRulePageList(state);
    const [mainList, setMainList] = useState<Array<any>>()
    const [userRolseList, setUserRolesList] = useState<Array<any>>([]);
    const [searchText, setSearchText] = useState('');
    const [tabValue, setTabValue] = React.useState('1');
    const handleChangeTabValue = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        setSearchText("");
    };
    const [pagination, setPagination] = useState<PaginationModel>(
        new PaginationModel()
    );

    //#endregion

    const changePageApi = async (pageIndex, search = false) => {
        try {
            dispatch(changeFullLoading({ spin: true }));

            let response: Array<any> = [];
            try {
                if (!search)
                    response = RuleList["page" + pageIndex] ? RuleList["page" + pageIndex] : [];
            } catch {
                response = [];
            }
            if (response.length < 1) {
                response = await getRoleSearch(pageIndex);
                dispatch(setRulePageList({ pageIndex: pageIndex, ruleList: response }));
            }
            else {
                let tempPagObj = {
                    pageSize: pagination?.pageSize || 10,
                    totalCount: pagination.totalCount,
                    totalPages: pagination.totalPages,
                    pageIndex: pageIndex
                }
                setPagination(tempPagObj);
            }
            setMainList(response)
        }
        catch (error) {
            console.error(error);
        }
        finally {
            dispatch(changeFullLoading({ spin: false }));
        }
    }
    const getRoleSearch = async (pageIndex) => {

        const requestModel = {
            name: searchText,
            pageIndex: pageIndex - 1,
            pageSize: pagination.pageSize,
            level: +tabValue
        }
        const response: any = await RolesMappingService.roleSearch(requestModel);

        let tempPagObj = {
            pageSize: pagination?.pageSize || 10,
            totalCount: response?.totalCount,
            totalPages: response?.pagesCount,
            pageIndex: pageIndex
        }
        setPagination(tempPagObj);
        return response.body;
    }

    const getUserById = async (Id: any) => {
        try {
            dispatch(changeFullLoading({ spin: true }));
            const userRolesResponse = await TenantUsersManagementService.getUserRoles(Id);

            if (userRolesResponse?.length) {
                dispatch(setRolesList({ body: userRolesResponse?.map(role => role.id) }));
                setUserRolesList(userRolesResponse);
            }
        }
        catch (error) {
        }
        finally {
            dispatch(changeFullLoading({ spin: false }));
        }
    }


    const handlerCancelDialog = () => {
        handleClose();
    };

    const handleSearch = () => {
        dispatch(clearRulePageList(null));
        changePageApi(1, true);
    }
    //#region :: showDialog Changed [useEffect]

    useEffect(() => {
        if (pagination.pageIndex > 0)
            changePageApi(pagination.pageIndex);
    }, [roleSelectedList]);

    useEffect(() => {
        if (userId)
            getUserById(userId);

        changePageApi(1, true)
        setShowModal(open);
    }, [open, tabValue]);

    useEffect(() => {
        return () => {
            dispatch(clearRulePageList(null));
        }
    }, []);

    // useEffect(() => {
    // }, []);
    //#endregion

    return (
        <ModalComponent
            title={Resources.AppResources.ADD_ROLES}
            open={showModal}
            loading={false}
            divider={false}
            maxWidth={"md"}>
            <Grid container style={{ justifyContent: "center", display: "flex" }} spacing={1} >
                <TabContext value={tabValue}>
                    <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTabValue} aria-label="lab API tabs example" className="tap-div">
                            <Tab sx={{ width: '100%' }} label={T(Resources.AppResources.TENANT_LEVEL)} value={EnvLevelContext.TENANT + ""} />
                            <Tab sx={{ width: '100%' }} label={T(Resources.AppResources.ORGANIZATION_LEVEL)} value={EnvLevelContext.ORGANIZATION + ""} />
                        </TabList>
                    </Grid>
                    <TabPanel value={tabValue} style={{ width: '100%' }}>
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{ justifyContent: "end", display: "flex" }}>
                                <Paper
                                    component="form"
                                    sx={{ display: "flex", alignItems: "center" }}
                                    className={"search-group"}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder={T(Resources.AppResources.SEARCH)}
                                        value={searchText}
                                        size="small"
                                        onChange={(e: any) => { setSearchText(e.target.value) }}
                                        onKeyDown={(e) => { if (e.which === 13) { handleSearch(); e.preventDefault(); } }}
                                    />
                                    <IconButton
                                        type="button"
                                        sx={{ p: "10px" }}
                                        aria-label="search"
                                        onClick={() => handleSearch()}
                                    >
                                        <GridSearchIcon style={{ color: "#1e76b5", fontSize: "18px" }} />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <RoleTable
                                itemsList={mainList || []}
                                pagination={pagination}
                                handleChangePage={(e, page) => changePageApi(page)}
                                userId={userId}
                                userRolesList={userRolseList}
                                level={+tabValue}
                            />
                        </Grid>
                    </TabPanel>
                </TabContext>

            </Grid>
            <Grid className='ResponseButton' container justifyContent={'center'} xs={12}>
                <Button
                    marginLeft={5}
                    marginRight={5}
                    variant='outlined'
                    color='primary'
                    type={ButtonsTypes.CANCEL}
                    label={T(Resources.AppResources.CANCEL)}
                    onClick={() => handlerCancelDialog()}
                />
            </Grid>
        </ModalComponent>
    );

}
export default RulesDialog;