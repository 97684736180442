import styled from 'styled-components';
import { AppConfigService } from '../../Config/Settings/AppConfig';

const Footer = () => {
    return (
        <StyledFooter>
            <p><b>&#169;</b> {new Date().getFullYear()} Xocialive - v{AppConfigService.getConfig().versionNumber}</p>
        </StyledFooter>
    );
};

//#region :: Styled Components
const StyledFooter: any = styled.footer(({ theme }) => ({
    height: '50px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'absolute',
    right: 0,
    left: 0,
    color: theme.status.dark,
    backgroundColor: theme.status.white,
    boxShadow: '0px 0px 2px rgb(50 50 50 / 75%)',
    bottom: '-4rem'
}));
//#endregion

export default Footer;