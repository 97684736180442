import React, { useState } from "react";
import useTranslation from "../../../../Hooks/Shared/useTranslation";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import Accordion from "../../../../Controls/Accordion";
import Button from "../../../../Controls/Button";
import PagesRoute from "../../../../Config/Settings/PagesRoute";
import TextInput from "../../../../Controls/TextInputs";
import CustomDropDown from "../../../../Controls/CustomDropDown";
import useAddInvoicesBookHook from "../hooks/useAddInvoicesBookHook";
import {
  LanguageDDList, LookupTypes,
} from "../../../../Config/Settings/Enums";
import { useParams } from "react-router-dom";
import AutoComplete from "../../../../Components/AutoComplete";
import Checkbox from "../../../../Controls/Checkbox";
import CustomeIconButton, {
  IconButtonsTypes,
} from "../../../../Controls/IconButton";
import TableComp from "../../../../Controls/Table";
import AutoCompleteLookup from "../../../../Components/AutoCompleteLookup";
import AutoCompleteSimple from "../../../../Components/AutoCompleteSimple";
import { MetaDataSource } from "../../../../Config/Settings/Lookups";
import SwitchToggle from "../../../../Components/SwitchToggle";
import SwitchColorToggle from "../../../../Components/SwitchColorToggle";

const Profile = () => {
  const { T, Resources } = useTranslation();
  const {
    formState,
    handleInputChange,
    createInvoicesBook,
    orgAddressesList,
    handleInvoiceTypeChange,
    handleTagsChange,
    handelTagBlur,
    prepareTagsList,
    onEnter,
    isOnboarded,
    metaDataFormState,
    handleMetaDataInputChange,
    handleMetaDataVisibleChange,
    handleSetMetaDataInputChange,
    handelAddMetaData,
    columnsLabels,
    metaDataSource,
    handleSetInputChange,
    handleOfflineChange,
  } = useAddInvoicesBookHook();
  const { id } = useParams();
  var DataSource: any = id;

  
  //#region :: Data List
  const list: Array<any> = [
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: DataSource
        ? T(Resources.AppResources.EDIT_INVOICES_BOOK)
        : T(Resources.AppResources.CREATE_NEW_INVOICES_BOOK),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: (
        <span>
          {DataSource
            ? T(Resources.AppResources.EDIT_INVOICES_BOOK)
            : T(Resources.AppResources.CREATE_NEW_INVOICES_BOOK)}
        </span>
      ),
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          {/* {organizationName ? (
            <Grid item md={4} sm={12} xs={12}>
              <TextInput
                id={"organizationName"}
                name={"organizationName"}
                label={T(Resources.AppResources.ORGANIZATION)}
                value={organizationName}
                isDisabled
              />
            </Grid>
          ):<></>} */}
          
          <Grid container md={12} sm={12} xs={12}>
            <Grid item md={2} sm={12} xs={12} marginLeft={'-15px'}>
              <SwitchColorToggle
                  active={T(Resources.AppResources.ON_LINE)}
                  inactive={T(Resources.AppResources.OFF_LINE)}
                  isActive={!formState.isOffline.value}
                  handleStatusChange={handleOfflineChange}
                  disableAll={isOnboarded || formState?.b2BInvoicing?.value}
              />
            </Grid>
            <Grid item md={4} xs={12} style={{ textAlign: "start" }}>
              <span
                style={{
                  paddingRight: 20,
                  color: !formState.invoicingType.valid ? "#d32f2f" : "unset",
                }}
              >
                {T(Resources.AppResources.INVOICING_TYPE)}*
              </span>
              <FormControlLabel
                disabled={isOnboarded || (!formState.isOffline.value == false || formState.isOffline.value == null)}
                style={{ marginBottom: "3px" }}
                control={
                  <Switch
                    checked={formState?.b2BInvoicing?.value}
                    onChange={(event: any) => {
                      handleInvoiceTypeChange(
                        formState?.b2BInvoicing?.name,
                        event.target.checked
                      );
                    }}
                  />
                }
                label="B2B"
              />
              <FormControlLabel
                disabled={isOnboarded}
                style={{ marginBottom: "3px" }}
                control={
                  <Switch
                    checked={formState?.b2CInvoicing?.value}
                    onChange={(event: any) => {
                      handleInvoiceTypeChange(
                        formState?.b2CInvoicing?.name,
                        event.target.checked
                      );
                    }}
                  />
                }
                label="B2C"
              />
            </Grid>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <TextInput
              id={formState.name.name}
              name={formState.name.name}
              label={T(Resources.AppResources.FIRST_NAME)}
              value={formState.name.value}
              required={formState.name.required}
              error={!formState.name.valid}
              helperText={
                !formState.name.valid
                  ? T(
                      Resources.AppResources[
                        formState.name.errorMessages?.error
                      ]
                    )
                  : formState.name.message
              }
              handleChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomDropDown
              id={formState?.language?.name}
              name={formState?.language?.name}
              label={T(Resources.AppResources.LANGUAGE)}
              value={formState?.language?.value}
              required={formState.language.required}
              error={!formState.language.valid}
              isDisabled={isOnboarded}
              translate={true}
              options={LanguageDDList}
              showLabel={true}
              handleChange={(e: any) => handleInputChange(e)}
            />
            
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomDropDown
              id={formState?.preferredAddress?.name}
              name={formState?.preferredAddress?.name}
              label={T(Resources.AppResources.PREFERRED_ADDRESS)}
              value={formState?.preferredAddress?.value}
              required={formState.preferredAddress?.required}
              error={!formState.preferredAddress.valid}
              translate={true}
              options={orgAddressesList}
              isDisabled={isOnboarded}
              showLabel={true}
              handleChange={(e: any) => handleInputChange(e)}
            />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <AutoComplete
              id={formState.tags.name}
              name={formState.tags.name}
              // defaultValue={formState.tags.value}
              value={prepareTagsList(formState.tags.value) || []}
              autoComplete={true}
              handleChange={handleTagsChange}
              error={!formState.tags.valid}
              options={prepareTagsList(formState.tags.value) || []}
              required={formState.tags.required}
              label={T(Resources.AppResources.TAGS)}
              multiple={true}
              showLabel
              noOptionsText={T(Resources.AppResources.NODATA)}
              onKeyPress={onEnter}
              onBlur={handelTagBlur}
              preventOpenList
            />
          </Grid>
        </Grid>
      ),
    },
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.METADATA),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.METADATA)}</span>,
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          <Grid item md={4} sm={12} xs={12}>
            <TextInput
              id={metaDataFormState.name.name}
              name={metaDataFormState.name.name}
              label={T(Resources.AppResources.ATTRIBUTE_NAME)}
              value={metaDataFormState.name.value}
              required={metaDataFormState.name.required}
              error={!metaDataFormState.name.valid}
              helperText={
                !metaDataFormState.name.valid
                  ? T(
                      Resources.AppResources[
                        metaDataFormState.name.errorMessages?.error
                      ]
                    )
                  : metaDataFormState.name.message
              }
              handleChange={(e) => handleMetaDataInputChange(e)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextInput
              id={metaDataFormState?.description.name}
              name={metaDataFormState?.description.name}
              label={T(Resources.AppResources.ATTRIBUTE_DESCRIPTION)}
              value={metaDataFormState?.description.value}
              required={metaDataFormState?.description.required}
              error={!metaDataFormState?.description.valid}
              helperText={
                !metaDataFormState?.description.valid
                  ? T(
                      Resources.AppResources[
                        metaDataFormState?.description.errorMessages?.error
                      ]
                    )
                  : metaDataFormState?.description.message
              }
              handleChange={(e) => handleMetaDataInputChange(e)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextInput
              id={metaDataFormState?.order.name}
              name={metaDataFormState?.order.name}
              label={T(Resources.AppResources.ORDER)}
              value={metaDataFormState?.order.value}
              required={metaDataFormState?.order.required}
              error={!metaDataFormState?.order.valid}
              helperText={
                !metaDataFormState?.order.valid
                  ? T(
                      Resources.AppResources[
                        metaDataFormState?.order.errorMessages?.error
                      ]
                    )
                  : metaDataFormState?.order.message
              }
              handleChange={(e) => handleMetaDataInputChange(e)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutoCompleteLookup 
                id={metaDataFormState?.placement?.name}
                name={metaDataFormState?.placement?.name}
                selected={metaDataFormState?.placement?.value}
                defaultValue={metaDataFormState?.placement?.defaultValue}
                label={T(Resources.AppResources.PLACEMENT)}
                handleInputChange={handleSetMetaDataInputChange}
                lookupType={LookupTypes.MetaDataPlacement}
                error={!metaDataFormState.placement.valid}
                showLabel
            /> 
          </Grid>
          <Grid item md={4} xs={12}>
            <AutoCompleteSimple 
                id={metaDataFormState?.belongsTo?.name}
                name={metaDataFormState?.belongsTo?.name}
                selected={metaDataFormState?.belongsTo?.value}
                label={T(Resources.AppResources.BELONGS_TO)}
                handleInputChange={handleSetMetaDataInputChange}
                error={!metaDataFormState.belongsTo.valid}
                options={MetaDataSource}
                showLabel
            /> 
            
          </Grid>
          <Grid item md={4} xs={12}>
            <Grid md={12} container justifyContent={"space-between"}>
              <span>
                <Checkbox
                  id={metaDataFormState.isVisible.name}
                  name={metaDataFormState.isVisible.name}
                  label={T(Resources.AppResources.VISIBLE)}
                  checked={metaDataFormState.isVisible.value}
                  handleChange={(e: any) => handleMetaDataVisibleChange(e)}
                />
              </span>

              <CustomeIconButton
                id="add"
                ariaLabel="add"
                type={IconButtonsTypes.ADD}
                onClick={handelAddMetaData}
              />
            </Grid>
          </Grid>

          <Grid paddingTop={2} md={12} sm={12} xs={12}>
            <TableComp
              dataSource={metaDataSource}
              tableColumns={columnsLabels}
              showPagination={false}
            />
          </Grid>
        </Grid>
      ),
    },
  ];
  //#endregion

  return (
    <Grid container spacing={1}>
      <Accordion content={list} />
      <Grid container xs={12} marginTop={2} gap={"10px"}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => createInvoicesBook()}
          label={
            DataSource
              ? T(Resources.AppResources.SAVE)
              : T(Resources.AppResources.CREATE)
          }
        />
        {!DataSource && (
          <Button
            variant="contained"
            onClick={() => createInvoicesBook(true)}
            color="primary"
            label={T(Resources.AppResources.CREATE_AND_NEW)}
            // onClick={() => handleSave(true)}
          />
        )}
        <Button
          variant="outlined"
          color="primary"
          link={true}
          to={PagesRoute.PAGES.INVOICES_BOOK_MANAGEMENT}
          label={T(Resources.AppResources.CANCEL)}
        />
      </Grid>
    </Grid>
  );
};
export default Profile;
