import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class UsersManagementService {



    public static GetById = async (id: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.INVOICES.GET_BY_ID + id);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };
    
    public static  CreateAdUsers = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.ORG_USERS.ADD_USER,{body:request},true,true);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.handleError(error);
            WebServiceClient.getError(error);
        }
    }
    
    public static UpdateStatus = async (id: any, requestData: any) => {
        try {
            var response = await WebServiceClient.put(APIConstants.ORG_USERS.ACTIVATE.replace("{id}", id), {body: requestData}, true, true);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };


}
