import './style.scss';
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ImgBase64Declarations } from './ImgBase64Declarations';
import useTranslation from '../Hooks/Shared/useTranslation';
import { convertBase64 } from '../Utils/File';

const FileBase64 = ({ 
    label= undefined, 
    handleChange, 
    urlImg, 
    error, 
    reset = false, 
    resetCallBack = undefined, 
    maxSize = undefined, 
    maxSizeErrorMessage = undefined,
    imgWidth= undefined,
    imgHeight= undefined,
    maxDimensionErrorMessage= undefined,
  }: ImgBase64Declarations) => {
    const { T, Resources } = useTranslation();
    const [baseImage, setBaseImage] = useState<any>(null);
    const [urlcurrentImg, setUrlcurrentImg] = useState<any>(urlImg);
    const [massageError, SetMassageError] = useState<boolean>(false);
    const [customMassageError, SetCustomMassageError] = useState<string>('');
    const uploadImage = async (e : any) => {
        
        const file = e.target.files[0];
        const fileType = file.type;
        
        if( imgHeight && imgWidth){
            const checkDimensionResult = await handleCheckImageDimensions(file);
            if(!checkDimensionResult){
                imageResetHandler();
                return;
            }else{
                SetCustomMassageError("")
            }
        }

        if(maxSize && file.size >= (maxSize * 1024)){
            SetCustomMassageError(maxSizeErrorMessage || T(Resources.AppResources.LOGO_SIZE_ERROR))
            imageResetHandler();
            return;
        }else{
            SetCustomMassageError("")
        }
        
        if (fileType == "image/png" || fileType == "image/jpeg" || fileType == "image/jpg" || fileType == "image/svg+xml"|| fileType == "image/gif") {

            const base64: any = await convertBase64(file);
            setBaseImage(base64);
            handleChange(base64, file.name)
            SetMassageError(false)
        } else {
            SetMassageError(true)
            setBaseImage(null);
        }
    };

    const handleCheckImageDimensions = async (selectedFile: any) => {
        let isValid = true;
    
       
        if (selectedFile) {
            try {
                const imageSrc = await readFileAsync(selectedFile) as string;
                const { width, height } = await getImageDimensionsAsync(imageSrc);
    
                if ((imgHeight && imgWidth) && (width > imgWidth || height > imgHeight)) {
                    SetCustomMassageError(maxDimensionErrorMessage || T(Resources.AppResources.LOGO_DIMENSIONS_ERROR));
                    isValid = false;
                } else {
                    isValid = true;
                }
            } catch (error) {
                console.error('Error reading or processing image:', error);
                // Handle the error appropriately
                isValid = false;
            }
        }
    
        return isValid;
    };

    const readFileAsync = async (file: any): Promise<string | ArrayBuffer | null> => {
        return new Promise<string | ArrayBuffer | null>((resolve) => {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                e.target?.result && resolve(e.target?.result);
            };
            reader.readAsDataURL(file);
        });
    };

    const getImageDimensionsAsync = async (imageSrc: string): Promise<{ width: number; height: number }> => {
        return new Promise<{ width: number; height: number }>((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.src = imageSrc;
        });
    };

    const imageResetHandler = () => {
        setBaseImage(null);
        setUrlcurrentImg(null);
        handleChange(null);
        resetCallBack && resetCallBack();
        
        document.getElementById("contained-button-file").value = null;
    }

    useEffect(() => {
        setUrlcurrentImg(urlImg)
    }, [urlImg])

    useEffect(() => {
        if(reset){
            imageResetHandler();
        }
    }, [reset]);
    return (
        <Grid className='file-base-container'>
            <div style={{display: 'flex'}}>
                <input hidden
                    accept="image/png, image/gif, image/jpeg"
                    id="contained-button-file"
                    multiple
                    type="file"
                    size={maxSize}
                    onChange={(e) => {
                        uploadImage(e);
                    }}
                />
                {!baseImage && !urlcurrentImg &&
                    <>
                        {label && <span className='logo-label'>{label}</span>}
                        <label className='file-button' htmlFor="contained-button-file"  style={{  display:'flex',  border:`${error ? '1px solid red':'none'}`}} >
                            <AddPhotoAlternateIcon />
                        </label>
                    </>
                }
            </div>
            {baseImage && <div className={"zoom-menu-open"}>
                <img src={baseImage} />
                <label className='reset-button' onClick={() => imageResetHandler()}>
                    <HighlightOffIcon />
                </label>
            </div>
            }
            {massageError &&
                <label className='massage-error'>
                    {T(Resources.AppResources.UPLOAD_IMAGE_ERROR)}
                </label>
            }
            {customMassageError && 
                <label className='massage-error-mui-label'>
                    {customMassageError}
                    
                </label>}
            {!baseImage && urlcurrentImg && <div className={"zoom-menu-open"} >
                <img src={urlcurrentImg} />
                <label className='reset-button' onClick={() => imageResetHandler()}>
                    <HighlightOffIcon />
                </label>
            </div>
            }
        </Grid>
    )
}
export default FileBase64