import '../Style/controls-styles.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import propTypes from 'prop-types';
import React, { useState } from 'react';

import { AccordionDeclarations } from './declarations';

const Accordion = ({ content, openOne }: AccordionDeclarations) => {

    //#region :: State && Handlers
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
    };

    const customeProps = (obj: any) => {
        return openOne ? {
            expanded: expanded === obj.ariaControls,
            onChange: handleChange(obj.ariaControls)
        } : {};
    };
    //#endregion

    return (
        content && content?.map((obj: any) => {
            let props = obj.expanded ? { expanded: obj.expanded } : { expanded: obj.expanded };

            return (
                obj.showSection === true && <Grid key={obj.id} item xs={obj.grid} className='styled-accordion-main-grid'>
                    <MuiAccordion className='styled-mui-accordion' {...customeProps(obj)} defaultExpanded={obj.defaultExpanded !== undefined ? obj.defaultExpanded : true} {...props}>
                        <div className='accordion-styled-border-header'>
                            <AccordionSummary
                                id={obj.id}
                                aria-controls={obj.ariaControls}
                                className="accordion-styled-summary"
                                expandIcon={obj.showExpandIcon !== undefined ? obj.showExpandIcon ? <ExpandMoreIcon /> : null : <ExpandMoreIcon />}>
                                {obj.summary}
                                {/* <label className='accordion-styled-summary-title'>{obj.title}</label> */}
                            </AccordionSummary>
                        </div>
                        <AccordionDetails style={{ height: '100% !important', minHeight: '100% !important' }}>
                            {obj.details}
                        </AccordionDetails>
                    </MuiAccordion>
                </Grid>
            )
        })
    );
};

//#region :: Validation PropTypes
Accordion.propTypes = {
    content: propTypes.array,
    openOne: propTypes.any
};
//#endregion

export default Accordion;