import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class RolesMappingService {
    public static GetById = async (id?: any) => {
        try {
           
            const result = await WebServiceClient.get(`${APIConstants.ROLES.GET}${id}`, true,false)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }
    public static CreateRole = async (request: any) => {
        try {
           
            const result = await WebServiceClient.post(APIConstants.ROLES.POST, {body:request}, undefined, true,false)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }
    public static Update = async (request: any, id: any) => {
        try {
           
            const result = await WebServiceClient.put(`${APIConstants.ROLES.PUT}${id}`,{body:request}, true,false)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }
    
    public static roleSearch = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.ROLES.SEARCH, {body:request},undefined, true, false);
            return response.data;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
    
    public static deleteRole = async (id: number) => {
        try {
            const response = await WebServiceClient.delete(`${APIConstants.ROLES.DELETE}${id}`, true, false);
            return response.data;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
}
