import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteOrganization from "../../../../../Components/AutoCompleteOrganization";
import Checkbox from "../../../../../Controls/Checkbox";
import useTranslation from "../../../../../Hooks/Shared/useTranslation";
import Locales from "../../../../../Services/Internationalization/Locales";
import { getUILanguage } from "../../../../../Store/Reducers/UI";
import {
  getRuleList,
  setRuleList,
} from "../../../../../Store/Reducers/TenantUsersManagements";
import { TenantUsersManagementService } from "../../../../../Services/API/TenantUsersManagement";
import { EnvLevelContext, EnvLevelContextText } from "../../../../../Config/Settings/Enums";

interface Props {
  index: number;
  item: any;
  userId: any;
  userRolesList: any;
  level: number;
}

const RoleTableRow = ({ index, item, userId, userRolesList, level }: Props) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const roleSelectedList = getRuleList(state);
  const currentLanguage = getUILanguage(state);
  const [checkVal, setCheckVal] = useState<boolean>(item?.isChecked);
  const [organizationsValues, setOrganizationsValues] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion

  //#region Handlers
  const handleRole = async (value: any, fId: any) => {
    try {
      setIsLoading(true)
      setCheckVal(value);

      if (value) {
        //send the Add request here
        await TenantUsersManagementService.addRole(
          userId,
          item?.id,
          level == EnvLevelContext.ORGANIZATION ? prepareOrganizationsList() : null
        );
      } else {
        //send the Remove request here
        await TenantUsersManagementService.removeRole(userId, item?.id);
      }

      let localList: Array<any> = [...roleSelectedList];
      if (value) {
        localList.push(fId.toString());
        dispatch(setRuleList({ body: localList }));
      } else {
        localList = localList.filter((id) => id != fId.toString());
        dispatch(setRuleList({ body: localList }));
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  };

  const handleSelectFacility = async (value: any) => {
    try {
      setIsLoading(true)
      if (isChecked && !(value?.length == 0 && organizationsValues?.length == 0) )

        await TenantUsersManagementService.updateRole(
          userId,
          item?.id,
          value
        );
      setOrganizationsValues(value);

    } catch (ex) { }
    finally {
      setIsLoading(false)
    }
  };

  const prepareOrganizationsList = () => {
    let tmpFacilList = organizationsValues.map((facil) => facil.id);

    if (tmpFacilList.some((facil) => facil.id == null)) {
      return [];
    } else return tmpFacilList;
  };

  const setDefaultUserFacility = () => {
    const role = userRolesList?.find((role) => role.id == item?.id);
    if (!role || role.universalOrgAccess == true) setOrganizationsValues([])
    else
      setOrganizationsValues([...role?.organizationsAccess])
  };
  //#endregion

  const isChecked = useMemo(() => {
    return checkVal || roleSelectedList?.includes(item?.id)
  }, [checkVal, roleSelectedList, level, item]);

  useEffect(() => {
    setDefaultUserFacility();
  }, [userRolesList, level, item]);
  //#endregion

  return (
    <tr>
      <td className="order-items-td">
        <Checkbox
          // className="on-set-check"
          id={`check_${item?.id}`}
          name={`check_${item?.id}`}
          single
          checked={isChecked}
          handleChange={async (e: any) => {
            e.stopPropagation();
            await handleRole(e.target.checked, item?.id);
          }}
        />
      </td>
      <td>{item?.id}</td>
      <td>
        {currentLanguage == Locales.ARABIC ? item.arabicName : item.englishName}
      </td>
      <td>
        {T(Resources.AppResources[EnvLevelContextText[item?.level]]) || "_"}
      </td>
     {level == EnvLevelContext.ORGANIZATION &&  <td>
        <AutoCompleteOrganization
          id={`organizations-${item?.id}`}
          name={`organizations-${item?.id}`}
          selected={organizationsValues}
          handleChange={handleSelectFacility}
          label={T(Resources.AppResources.ORGANIZATIONS)}
          handleInputChange={handleSelectFacility}
          multiple
          showLabel
          isChecked={isChecked}
          disabled={!isChecked || isLoading}
          withGlobalAccess
          userId={userId}
          style={{
            maxHeight: "100px",
            overflow: "auto",
            maxWidth: "255px",
          }}
        />
      </td>}
    </tr>
  );
};

export default RoleTableRow;
