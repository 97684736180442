import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class TenantUsersManagementService {
    public static SearchOrganization = async (request: any, userId: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.USERS.USER_ORGANIZATIONS.replace("{Id}", userId),{body:request},undefined,true,false);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static updateRole = async ( id: any, roleId: any, orgsList: any) => {
        try {
            var response = await WebServiceClient.patch(APIConstants.USERS.UPDATE_ROLE.replace("{Id}", id).replace("{RoleId}", roleId), {body:orgsList}, true, false);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static removeRole= async (id: any, roleId: any) => {
        try {
            var response = await WebServiceClient.delete(APIConstants.USERS.REMOVE_ROLE.replace("{Id}", id).replace("{RoleId}", roleId), true, false);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static addRole = async ( id: any, roleId: any, orgsList: any) => {
        try {
            var response = await WebServiceClient.patch(APIConstants.USERS.ADD_ROLE.replace("{Id}", id).replace("{RoleId}", roleId), {body: orgsList}, true, false);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
    
    public static UpdateStatus = async (id: any, requestData: any) => {
        try {
            var response = await WebServiceClient.put(APIConstants.USERS.ACTIVATE.replace("{id}", id), {body: requestData}, true, false);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

    public static Update = async (id: any, request:any) => {
        try {
            const response = await WebServiceClient.put(APIConstants.USERS.PUT + id, {body: request}, true, false);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

    public static GetById = async (id:any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.USERS.GET.replace("{id}", id),true,false);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

    public static  SearchAdUsers = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.USERS.AD_SEARCH,{body:request},undefined,true,false);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
    
    public static  CreateAdUsers = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.USERS.ADD_USER,{body:request},undefined,true,false);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.handleError(error);
            WebServiceClient.getError(error);
        }
    }


    
    public static getUserRoles = async (id: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.USERS.USER_Roles.replace("{Id}",id),true,false);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };


    public static getUserPermissions = async (id: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.USERS.USER_Roles.replace("{Id}",id),true,false);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

}
