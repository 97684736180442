import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MuiCheckbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Label from '../Label';

export interface CheckBoxProps {
    single?: boolean;
    options?: any;
    id?: any;
    name?: any;
    label?: any;
    value?: any;
    checked?: boolean;
    disabled?: boolean;
    handleChange?: any;
    inputProps?: any;
    groupName?: any;
    indeterminate?: any;
    size?: any;
    fontSize?: any;
    color?: any;
    outLinecolor?: any;
};

const Checkbox = ({ single = false, options = undefined, id, name, label = '', value, checked, disabled = false, handleChange = undefined,
    inputProps, groupName, indeterminate, size = 'small', fontSize = 'small', color = 'primary', outLinecolor }: CheckBoxProps) => {

    const checkboxref: any = useRef();

    //#region :: Props
    const props = indeterminate !== undefined ? {
        indeterminate
    } : {};
    //#endregion

    //#region :: useEffect
    useEffect(() => {
        // debugger
        console.log(checked);
        
    }, [checked]);
    //#endregion
    
    return (
        !single
            ? <>
                <Label text={groupName} />
                <FormGroup row>
                    {options === undefined ?
                        <StyledFormControlLabel
                            label={<Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontSize: fontSize, fontWeight: 'bolder' }}>
                                {label}
                            </Typography>}
                            control={
                                <MuiCheckbox
                                    {...props}
                                    id={id}
                                    name={name}
                                    value={value}
                                    checked={checked}
                                    disabled={disabled}
                                    color={color}
                                    size={size}
                                    onChange={handleChange}
                                    inputProps={inputProps}
                                    icon={<CheckBoxOutlineBlankIcon color={outLinecolor} fontSize={fontSize} />}
                                    checkedIcon={<CheckBoxIcon color={disabled ? 'default' : color} fontSize={fontSize} />} />
                            } />
                        :
                        <>
                            {options.map((item: any) => (
                                <FormControlLabel
                                    key={item.key}
                                    label={<StyledTypography variant="body2" color="textSecondary">
                                        {label}
                                    </StyledTypography>}
                                    control={
                                        <MuiCheckbox
                                            {...props}
                                            id={item.id}
                                            name={item.name}
                                            value={item.value}
                                            checked={item.checked}
                                            disabled={item.disabled}
                                            color={color}
                                            size={size}
                                            onChange={item.handleChange}
                                            inputProps={inputProps}
                                            icon={<CheckBoxOutlineBlankIcon color={outLinecolor} fontSize={fontSize} />}
                                            checkedIcon={<CheckBoxIcon color={item.disabled ? 'default' : color} fontSize={fontSize} />} />
                                    } />
                            ))}
                        </>}
                </FormGroup>
            </>
            : <MuiCheckbox
                id={id}
                name={name}
                size={size}
                color={color}
                checked={checked}
                // ref={checkboxref}
                disabled={disabled}
                onChange={(e, code) => {handleChange && handleChange(e, code)}} />
    );
};

//#region :: Styled Components
const StyledFormControlLabel: any = styled(FormControlLabel)`
    margin-left: 0 !important;
    margin-right: 0 !important;
`;

const StyledTypography: any = styled(Typography)`
    font-size: 12px;
`;
//#endregion

//#region :: Validation PropTypes
Checkbox.propTypes = {
    id: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    fontSize: propTypes.string,
    size: propTypes.string,
    color: propTypes.string,
    outLinecolor: propTypes.string,
    groupName: propTypes.string,
    single: propTypes.bool,
    checked: propTypes.bool,
    disabled: propTypes.bool,
    indeterminate: propTypes.bool,
    options: propTypes.array,
    inputProps: propTypes.object,
    handleChange: propTypes.func
};
//#endregion

export default Checkbox;