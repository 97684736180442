import Grid from '@mui/material/Grid';
import React, { } from 'react';
import styled from 'styled-components';
import Card from '../../../Controls/Card';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import Button from '../../../Controls/Button'; 
import useAppNavigate from '../../../Hooks/Shared/useAppNavigate';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { getFieldValue } from '../../../Utils/StringUtils';
import useViewloggerHook from './hooks/useViewloggerHook';
import './styles.scss' 
import { AccordionDetails, AccordionSummary } from '@mui/material'; 
import MuiAccordion from '@mui/material/Accordion';
import LogReuestDetails from './LogReuestDetails';


 
const ViewLogger = () => {
    const { T, Resources } = useTranslation();
    const { dataSource } = useViewloggerHook()
    const navigate = useAppNavigate();
    //#region :: State
    console.log(dataSource);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card>

                    <LogReuestDetails dataSource={dataSource} />

                    <Grid item marginTop={2}>
                        <Button
                            variant='outlined'
                            color='primary'
                            label={T(Resources.AppResources.BACK)}
                            onClick={() => navigate.push(PagesRoute.PAGES.LOGGERMANAGEMENT || '')}
                        />
                    </Grid>
                </Card>
                {
                    dataSource?.innerRequest && dataSource.innerRequest.length > 0 &&
                    <Grid item xs={12}>
                        <Card>

                            <Grid item>
                                <StyledSubParagraph>{T(Resources.AppResources.INNER_REQUEST)}</StyledSubParagraph>
                            </Grid>
                            {
                                dataSource?.innerRequest && dataSource?.innerRequest?.map((obj: any) => {
                                    return (

                                        <Grid key={obj.id} item xs={obj.grid} className='styled-accordion-main-grid'>

                                            <Grid item>
                                                <MuiAccordion className='styled-mui-accordion' defaultExpanded={false}  >
                                                    <div className='accordion-styled-border-header'>
                                                        <AccordionSummary

                                                            id={obj.id}
                                                            aria-controls={obj.ariaControls}
                                                            className="accordion-styled-summary" style={{ direction: "ltr" }}
                                                            expandIcon={true}>
                                                            <span>{obj && getFieldValue(obj.logLevel)} :</span>
                                                            <span> {obj && getFieldValue(obj.message)}</span>
                                                        </AccordionSummary>
                                                    </div>
                                                    <AccordionDetails style={{ height: '100% !important', minHeight: '100% !important' }}>

                                                        <LogReuestDetails dataSource={obj} />

                                                    </AccordionDetails>
                                                </MuiAccordion>  </Grid>
                                        </Grid>

                                    )
                                })
                            }
                        </Card>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

//#region :: Styled Components
const StyledSubParagraph: any = styled.span(() => ({
    fontWeight: 'bold!important',
    fontSize: 'medium!important'
}));
 
//#endregion

export default ViewLogger;