import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class ReportsService {

    public static SearchReports = async (request: any) => {
        try {
            var response = await WebServiceClient.post(APIConstants.INVOICES.REPORT, request, undefined, true, true);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };
    public static UsersReports = async (request: any) => {
        try {
            var response = await WebServiceClient.post(APIConstants.USERS.SEARCH, request, undefined, true, true);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };
    public static OrganizationUsersReports = async (request: any) => {
        try {
            var response = await WebServiceClient.post(APIConstants.ORG_USERS.SEARCH, request, undefined, true, true);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };
    public static GetQrById = async (id: any) => {
        try {
            var response = await WebServiceClient.get(APIConstants.INVOICES.GET_QR_BASE64.replace("{invoiceId}", id));
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

    public static GetPdfFile = async (invoiceId) => {
        try {
            var response = await WebServiceClient.get(APIConstants.INVOICES.GET_PDF_BLOB.replace('{invoiceId}', invoiceId), true, true, true);
            return response
        } catch (error) {
            WebServiceClient.getError(error);
        }
    }


    public static GetReportsById = async (id: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.INVOICES.GET_BY_ID + id);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

}
