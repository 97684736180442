import MuiAutocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { GridSearchIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useTranslation from "../../Hooks/Shared/useTranslation";
import { getUILanguage } from "../../Store/Reducers/UI";
import { AutoCompleteModel } from "./AutoCompleteModel";
import { AutoCompleteDeclaration } from "./indexProp";
import { makeStyles } from "@material-ui/styles";


const AutoComplete = ({
  name,
  value,
  disabled,
  error,
  required,
  label,
  options,
  handleChange,
  handleClear,
  filterSelectedOptions = false,
  multiple = false,
  size = "small",
  autoComplete,
  noOptionsText = "asd",
  limitTags = -1,
  handlerSearch,
  filterOptions,
  renderOption = undefined,
  onKeyPress,
  isLoading = false,
  showSearchIcon = false,
  onBlur,
  showLabel = false,
  preventOpenList = false
}: AutoCompleteDeclaration) => {
  //#region :: State
  let _callBackFunction: any;
  const { T, Resources } = useTranslation();
  const state = useSelector((state) => state);
  const currentLanguage = getUILanguage(state);
  const [dataOptions, setOptions] = useState<any>([]);
  const [elementProps, setElementProps] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<any>(multiple ? [] : "");
  const [validate, setValidate] = useState<any>(new AutoCompleteModel());
  const [searchInputProps, setSearchInputProps] = useState<any>(null);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator': {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  const preventOpenProp = preventOpenList ?  {open:false, classes:{ root: classes.root }} : {};
  //#endregion

  //#region :: Handlers
  const handlerSearchLocaly = (searchCriteria: any) => {
    if (_callBackFunction) clearTimeout(_callBackFunction);
    _callBackFunction = setTimeout(() => {
      handlerSearch && handlerSearch(searchCriteria);
      clearTimeout(_callBackFunction);
      _callBackFunction = null;
    }, 500);
  };

  const changeHandler = (value: any) => {
    if (value == null) {
      handlerSearchLocaly(null);
      setSelectedValue(null);
      handleChange && handleChange(null);
      handleClear && handleClear();
    }
    handleChange && handleChange(value);
    if (multiple) {
      let prev = [...selectedValue];
      setSelectedValue(prev);
    }
  };

  const handlerSetData = (options: any) => {
    let newOption = options?.map((item: any) => {
      return {
        ...item,
        label: T(item.label),
      };
    });

    if (newOption != undefined && newOption?.length) setOptions(newOption);
    else setOptions([]);
  };

  useEffect(() => {
    handlerSetData(options);
  }, [options]);

  useEffect(() => {
    if (multiple) {
      if (value && dataOptions) {
        let data = dataOptions.filter((item: any) => value.some(x => x.id == item.id));
        setSelectedValue(data);
      }
    } else {
      if (!value || !dataOptions || !dataOptions.length) {
        setSelectedValue("");
        return;
      }
      let data = dataOptions.filter((item: any) => item.id == value);
      setSelectedValue(data[0]);
    }
  }, [value, dataOptions]);

  useEffect(() => {
    setValidate({
      disable: disabled,
      error: error,
      required: required,
    });
  }, [error, disabled, required]);

  useEffect(() => {
    if (!renderOption) return;

    setElementProps({ renderOption });
  }, [renderOption]);

  useEffect(() => {
    if (showSearchIcon)
      setSearchInputProps({
        startAdornment: (
          <InputAdornment position="start">
            <GridSearchIcon fontSize="small" />
          </InputAdornment>
        ),
        disableUnderline: true,
      });
  }, [showSearchIcon]);
  //#endregion

  return (
    <>
      <MuiAutocomplete
        {...elementProps}
        
        autoHighlight
        size={size}
        multiple={multiple}
        filterSelectedOptions={filterSelectedOptions}
        id={name}
        disabled={validate.disable}
        filterOptions={filterOptions}
        onBlur={onBlur}
        autoComplete={autoComplete}
        value={selectedValue && selectedValue}
        defaultValue={selectedValue && selectedValue}
        options={
          dataOptions != undefined && dataOptions.length && dataOptions
            ? dataOptions
            : []
        }
        noOptionsText={noOptionsText ? noOptionsText : T(Resources.AppResources.NODATA)}
        onChange={(event: any, newValue: any) => changeHandler(newValue)}
        limitTags={limitTags}
        onKeyDown={(e) => {
          onKeyPress && onKeyPress(e);
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            onChange={(e) => handlerSearchLocaly(e.target.value)}
            label={label}
            id={name}
            autoComplete={autoComplete == false ? "off" : "on"}
            name={name}
            required={validate.required}
            error={validate.error}
            disabled={validate.disable}
            placeholder={label}
            InputLabelProps={{
              shrink: showLabel,
              style: {
                fontSize: "0.9rem",
                transform:
                  showLabel && currentLanguage === "en-us"
                    ? "translate(14px, -9px) scale(0.75)"
                    : showLabel
                      ? "translate(-14px, -9px) scale(0.75)"
                      : "none",
              },
            }}
            InputProps={{
              ...params.InputProps,
              ...searchInputProps,
            }}
            inputProps={{
              ...params.inputProps,
              style: { fontSize: "0.7rem" },
              autoComplete: "new-password",
            }}
          />
        )}
        {...preventOpenProp}
      />
      {isLoading === true ? (
        <div className="NormalDD DDList-loader"></div>
      ) : null}
    </>
  );
};

export default AutoComplete;
