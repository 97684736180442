import React, { useEffect, useRef, useState } from "react";
import useTranslation from "../../../../Hooks/Shared/useTranslation";
import { useDispatch } from "react-redux";
import { Addresses, OrganizationModel } from "../Shared/organization.model";
import { changeFullLoading } from "../../../../Store/Reducers/UI";
import { ValidationService } from "../../../../Utils/Validation.services";
import { PaginationModel, ValidationModel } from "../../../../Models";
import { useParams } from "react-router";
import { OrganizationService } from "../../../../Services/API/OrganizationManagement";
import {
  MESSAGE_TYPE,
  MessagesService,
} from "../../../../Services/messages.service";
import PagesRoute from "../../../../Config/Settings/PagesRoute";
import { OrganizationDbModel } from "../Shared/organization.model";
import { LookupsService } from "../../../../Services/API/Lookups";
import {
  PaginationEnums,
} from "../../../../Config/Settings/Enums";
import { AddressesService } from "../../../../Services/API/Addresses";
import {
  IdentityTypesValidation,
  regexType,
} from "../../../../Config/Settings/Constants/Validations";
import LookupCodeLabel from "../../../../Components/LookupCodeLabel";
import { GlobalService } from "../../../../Services/global.service";
import { useTenant } from "../../../../MultiTenant/tenantContext";
import CustomeIconButton, { IconButtonsTypes } from "../../../../Controls/IconButton";
import RadioButton from "../../../../Controls/Radiobutton";

const useAddOrganizationHook = () => {
  const { T, Resources } = useTranslation();
  const { id } = useParams();
  const [addressesSource, setAddressesSource] = useState<Array<any>>([]);
  const [addressesOrgList, setAddressesOrgList] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(
    OrganizationModel.getFieldsModel()
  );
  const [addressesFormState, setAddressesFormState] = useState(
    Addresses.getAddFieldsModel()
  );
  const [resetImage, setResetImage] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationModel>(
    new PaginationModel()
  );
  const { currentTenant } = useTenant() || {};
  const [lastUpdatedAddress, setLastUpdatedAddress] = useState<any>(null);
  const [defaultAddressValue, setDefaultAddressValue] = useState<any>(null);
  
  const columnsLabels: Array<any> = [
    {
      id: "name",
      label: T(Resources.AppResources.ADDRESS_NAME),
      align: "center",
    },
    {
      id: "country",
      label: T(Resources.AppResources.COUNTRY),
      align: "center",
    },
    {
      id: "province",
      label: T(Resources.AppResources.PROVINCE),
      align: "center",
    },
    { id: "city", label: T(Resources.AppResources.CITY), align: "center" },
    {
      id: "district",
      label: T(Resources.AppResources.DISTRICT),
      align: "center",
    },
    {
      id: "street",
      label: T(Resources.AppResources.STREET_NAME),
      align: "center",
    },
    {
      id: "additionalStreet",
      label: T(Resources.AppResources.ADDITIONAL_STREET),
      align: "center",
    },
    {
      id: "buildingNumber",
      label: T(Resources.AppResources.BUILDING_NUMBER),
      align: "center",
    },
    {
      id: "postalCode",
      label: T(Resources.AppResources.POSTAL_CODE),
      align: "center",
    },
    {
      id: "additionalNumber",
      label: T(Resources.AppResources.ADDITIONAL_NUMBER),
      align: "center",
    },
    {
      id: "isDefaultAddress",
      label: T(Resources.AppResources.IS_DEFAULT),
      align: "center",
    },
    {
      id: "actions",
      label: T(Resources.AppResources.ACTIONS),
      align: "center",
    }
  ];

  //#region :: Handlers

  const handleSetAsDefault = async (addressId: string) =>  {
    setDefaultAddressValue(addressId);
    if(id)
      await AddressesService.SetDefaulAddress(addressId);
  }

  const setIdentityNumberValidation = (value: any) => {
    let tempRegexValue = regexType.MIN_2_MAX_20;

    if (IdentityTypesValidation[value?.value])
      tempRegexValue = IdentityTypesValidation[value?.value]?.regex;

    setFormState({
      ...formState,
      identityType: {
        ...formState["identityType"],
        value: value,
      },
      identityNumber: {
        ...formState["identityNumber"],
        regexValue: tempRegexValue,
      },
    });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    handleSetInputChange(name, value);
  };
  const handleSetInputChange = (name: any, value: any) => {
    setFormState({
      ...formState,
      [name]: {
        ...formState[name],
        value: value,
      },
    });
  };

  const handleSetFileChange = (name: any, data: any) => {
    setFormState({
      ...formState,
      logoFileName: {
        ...formState["logoFileName"],
        value: name,
      },
      logoData: {
        ...formState["logoData"],
        value: data,
      },
    });
  };

  const handleAddressInputChange = (event: any) => {
    const { name, value } = event.target;
    handleSetAddressInputChange(name, value);
  };
  const handleSetAddressInputChange = (name: any, value: any) => {
    setAddressesFormState({
      ...addressesFormState,
      [name]: {
        ...addressesFormState[name],
        value: value,
      },
    });
  };
  const createOrganization = async (createAndNew: boolean = false) => {
    let valid = ValidationService.Valid(formState);
    let validationResponse = ValidationModel.setValidations(valid, formState);

    const maxTaxCalculationTolerance = GlobalService?.TenantServices?.maxTaxCalculationTolerance;
    const minTaxCalculationTolerance = GlobalService?.TenantServices?.minTaxCalculationTolerance;

    if(formState.taxCalculationTolerance.value < minTaxCalculationTolerance ){
      
      setFormState({...validationResponse, taxCalculationTolerance:{ ...validationResponse.taxCalculationTolerance,valid: false, errorMessages: { error: "MIN_TAX_CALCULATION_TOLERANCE_SMALLER_ERROR", boundary: minTaxCalculationTolerance}}});
        return;
    }
    else if(formState.taxCalculationTolerance.value > maxTaxCalculationTolerance ){
      setFormState({...validationResponse, taxCalculationTolerance:{ ...validationResponse.taxCalculationTolerance,valid: false, errorMessages: { error: "MAX_TAX_CALCULATION_TOLERANCE_GREATER_ERROR", boundary: maxTaxCalculationTolerance}}});
        return;
    }
    else if(isNaN(formState.taxCalculationTolerance.value)){
      setFormState({...validationResponse, taxCalculationTolerance:{ ...validationResponse.taxCalculationTolerance,valid: false, errorMessages: { error: "NUMBER_OPTIONAL", boundary: ""}}});
        return;
    }
    else if(!isNaN(parseInt(formState.taxCalculationTolerance.value))){
      setFormState({...validationResponse, taxCalculationTolerance:{ ...validationResponse.taxCalculationTolerance,valid: true, message:"", errorMessages: {}}});
    }
    else
    setFormState(validationResponse);

    if (!id) {
      if (!addressesOrgList.some((itm) => itm.isDefaultAddress)) {
        MessagesService.Toast(
          T(Resources.AppResources.PRIMARY_ADDRESS_REQUIRED),
          MESSAGE_TYPE.Error
        );
        return;
      }
    }

    if (valid.isValidForm) {
      dispatch(changeFullLoading({ spin: true }));
      let request = OrganizationModel.toDBModel(formState);

      try {
        if (id) {
          request.id = id;
          await OrganizationService.UpdateOrganization(id, request);
          MessagesService.Toast(
            T(Resources.AppResources.SAVED_SUCCESSFULLY),
            MESSAGE_TYPE.Success
          );
          // debugger
          //   navigate.push(PagesRoute.PAGES.ORGANIZATION_MANAGEMENT);
          setTimeout(() => {
            GlobalService.RefetchOrgs = true;
            window.location.href = `/${currentTenant?.id}${PagesRoute.PAGES.ORGANIZATION_MANAGEMENT}`;
          }, 500);
        } else {
          await OrganizationService.AddOrganization({ body: request });
          MessagesService.Toast(
            T(Resources.AppResources.CREATED_SUCCESS),
            MESSAGE_TYPE.Success
          );
          if (createAndNew) {
            setFormState(OrganizationModel.getFieldsModel());
            setResetImage(true);
            setAddressesFormState(Addresses.getAddFieldsModel());
            setAddressesOrgList([]);
          } else {
            // navigate.push(PagesRoute.PAGES.ORGANIZATION_MANAGEMENT);
            GlobalService.RefetchOrgs = true;
            window.location.href = `/${currentTenant?.id}${PagesRoute.PAGES.ORGANIZATION_MANAGEMENT}`;
          }
        }
      } catch (error: any) {
        //console.log(error);
      } finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    } else {
      // MessagesService.Toast(T(Resources.AppResources.PLEASE_FILL_THE), MESSAGE_TYPE.Error);
      //console.log("plesae fill")
    }
  };

  const handelFetchData = async () => {
    try {
      dispatch(changeFullLoading({ spin: true }));
      let response: OrganizationDbModel;
      let countryLookupResponse: any;
      let identityTypeLookupResponse: any;
      let currencyLookupResponse: any;
      if (id) {
        response = await OrganizationService.GetOrganizationsById(id);

        //TODO handel populate lookups data in different way
        countryLookupResponse = await LookupsService.GetLookupByValue(response?.organizationInfo?.businessCategory);
        currencyLookupResponse = await LookupsService.GetLookupByValue(response?.organizationInfo?.currencyCode);
        identityTypeLookupResponse = await LookupsService.GetLookupByValue(response?.organizationInfo.identityInfo.identityType.code + "");

        response.organizationInfo.businessCategory = countryLookupResponse;
        response.organizationInfo.currencyCode = currencyLookupResponse;
        response.organizationInfo.identityInfo.identityType.code = identityTypeLookupResponse;

        const formData = OrganizationDbModel.mapToFormModel(response);
        //console.log("newForm",formState);

        setFormState(formData);
      }
    } catch (error) {
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  };

  const getOrganizationsAddresses = async () => {
    try {
      let request = {
        pageIndex: 0,
        pageSize: PaginationEnums.SMALL_PAGE_SIZE,
        organizationId: id,
        //searchText: "string"
      };
      let response: any;

      response = await AddressesService.SearchAddressesByOrganization(request);

      const defaultAddress = response?.data?.body.find(x => x.isDefaultAddress);
      // debugger;
      defaultAddress && setDefaultAddressValue(defaultAddress?.id)

      setAddressesOrgList(
        response?.data?.body.map((x) => {
          return {
            ...Addresses.prepareAddressListRow(x),
            country: <LookupCodeLabel value={x?.addressInfo?.countryCode} withTooltip />,
          };
        })
      );

      let tempPagination = new PaginationModel();
      tempPagination.pageIndex = response?.data?.pageIndex;
      tempPagination.pageSize = response?.data?.pageSize;
      tempPagination.totalCount = response?.data?.totalCount;
      tempPagination.totalPages = response?.data?.pagesCount;

      setPagination(tempPagination);
    } catch (error) {
    } finally {
    }
  };

  const changePageApi = async (pageIndex) => {
    setPagination({
      pageSize: pagination?.pageSize,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
      pageIndex: pageIndex,
    });

    let request = {
      pageIndex: pageIndex,
      pageSize: PaginationEnums.SMALL_PAGE_SIZE,
      organizationId: id,
      //searchText: "string"
    };
    let response: any;

    response = await AddressesService.SearchAddressesByOrganization(request);
    setAddressesOrgList(response?.data?.body.map((x) => {
      return {
        ...Addresses.prepareAddressListRow(x),
        country: <LookupCodeLabel value={x?.addressInfo?.countryCode} withTooltip />,
      };
    })
    );
  };

  // const getIdentityTypeDataSource = async (searchText?: string) => {
  //   try {
  //     const response = await LookupsService.GetLookupSearch(
  //       LookupTypes.IdentityType,
  //       null,
  //       searchText
  //     );

  //     let items: Array<any> = [];
  //     if (response?.length > 0) {
  //       response?.map((item: any) => {
  //         const obj = {
  //           id: item?.id,
  //           label:
  //             T(Resources.AppResources[item?.name]) ||
  //             item?.name ||
  //             item?.value,
  //           value: item?.value,
  //         };
  //         items.push(obj);
  //       });
  //     }
  //     return items;
  //   } catch (error) {}
  // };

  // const getCountriesDataSource = async (searchText?: string) => {
  //   try {
  //     const response = await LookupsService.GetLookupSearch(
  //       LookupTypes.CountryCode,
  //       null,
  //       searchText
  //     );

  //     let items: Array<any> = [];
  //     if (response?.length > 0) {
  //       response?.map((item: any) => {
  //         const obj = {
  //           id: item?.id,
  //           label: item?.name,
  //           value: item?.value,
  //         };
  //         items.push(obj);
  //       });
  //     }
  //     return items;
  //   } catch (error) {}
  // };

  const handleAddAddressRow = async () => {
    try {
      let valid = ValidationService.Valid(addressesFormState);
      let validationResponse = ValidationModel.setValidations(
        valid,
        addressesFormState
      );

      setAddressesFormState(validationResponse);
      if (valid.isValidForm) {

        if (lastUpdatedAddress)
          setLastUpdatedAddress(null);

        if (
          addressesFormState.isDefaultAddress.value &&
          addressesOrgList
            .map((itm) => {
              itm.id = +itm.id;
              return itm;
            })
            .some((itm) => itm.isDefaultAddress)
        ) {
          MessagesService.Toast(
            T(Resources.AppResources.ALREADY_HAVE_DEFAULT),
            MESSAGE_TYPE.Error
          );
          return;
        }
        const isTheFirstAddress = addressesOrgList.length == 0;


        const newAddress = Addresses.toDBModel(addressesFormState, isTheFirstAddress);
        
        if(isTheFirstAddress) 
          setDefaultAddressValue(newAddress.id);
        
        if (newAddress.countryCode.value)
          newAddress.countryCode = newAddress.countryCode.value;

        if (id) {
          
          newAddress.organizationId = id;
          if(newAddress.isNew === true){
            const response = await AddressesService.AddAddress(newAddress);
            newAddress.id = response?.data?.body.id;
          }
          else 
            await AddressesService.EditAddress(newAddress, newAddress.id);

          MessagesService.Toast(
            T(Resources.AppResources.SAVED_SUCCESSFULLY),
            MESSAGE_TYPE.Success
          );
          setPagination({ ...pagination, totalCount: pagination.totalCount + 1 })
        }

        let newAddressesList = prepareNewAddressesList(newAddress);

        if(id) {
          newAddressesList = newAddressesList.slice(0, 5);
        }

        setAddressesOrgList(newAddressesList);
        handleSetInputChange("Addresses", newAddressesList);

        setAddressesFormState(Addresses.getAddFieldsModel());
      }
    } catch (error) {
    } finally {
    }
  };

  const prepareNewAddressesList = (newAddress: any) => {
    if (addressesOrgList.length) {
      if (addressesOrgList.some((itm) => itm.isDefaultAddress)) {
        return [
          addressesOrgList[0],
          Addresses.prepareFormAddressListRow(newAddress),
          ...addressesOrgList.slice(1),
        ];
      } else {
        return [
          Addresses.prepareFormAddressListRow(newAddress),
          ...addressesOrgList,
        ];
      }
    } else {
      return [Addresses.prepareFormAddressListRow(newAddress)];
    }
  };

  const handleDeleteAddressRow = async (addressId: any) => {
    setAddressesOrgList(addressesOrgList.filter(x => x.id != addressId));

    if(id)
      await AddressesService.DeleteAddress(addressId);
  };

  const handleEditAddressRow = (id: any) => {
    const tempToEditAdd = addressesOrgList.find(x => x.id == id)

    let newAddressesList = addressesOrgList.filter(x => x.id != id);
    if (lastUpdatedAddress) {
      newAddressesList = [newAddressesList[0], lastUpdatedAddress, ...newAddressesList.slice(1)]
    }

    setLastUpdatedAddress(tempToEditAdd);
    setAddressesFormState(Addresses.getEditFieldsModel(tempToEditAdd));

    setAddressesOrgList(newAddressesList)
  };

  useEffect(() => {
    if (id) {
      handelFetchData();
      getOrganizationsAddresses();
    }
  }, [id]);

  useEffect(() => {
    if (addressesOrgList.length)
      setAddressesSource(
        addressesOrgList.map((rowData: Addresses) => {
          
          return {
            id: rowData.id,
            province: rowData.province,
            city: rowData.city,
            district: rowData.district,
            street: rowData.street,
            additionalStreet: rowData.additionalStreet,
            buildingNumber: rowData.buildingNumber,
            postalCode: rowData.postalCode,
            additionalNumber: rowData.additionalNumber,
            isDefaultAddress: 
            <RadioButton 
              id={`radio-${rowData.id}`}
              // value={rowData.id}
              checked={defaultAddressValue == rowData.id}
              handleChange={()=> handleSetAsDefault(rowData.id)}
              isGrouped={false}
            />
              ,
            name: rowData?.name,
            country:
              T(
                Resources.AppResources[
                rowData?.country?.value || rowData?.country
                ]
              ) ||
              rowData?.country?.label ||
              rowData?.country,
            countryCode: rowData.countryCode,
            actions:
              (
                <>
                  <CustomeIconButton
                    id='edit'
                    ariaLabel='edit'
                    type={IconButtonsTypes.EDIT}
                    onClick={(e) => handleEditAddressRow(rowData?.id)}
                  />
                  <CustomeIconButton
                    id='delete'
                    ariaLabel='delete'
                    type={IconButtonsTypes.DELETE}
                    onClick={(e) => handleDeleteAddressRow(rowData?.id)}
                  />
                </>
              )
          };
        })
      );
    else setAddressesSource([]);
  }, [addressesOrgList, defaultAddressValue]);
  return {
    formState,
    addressesFormState,
    handleSetInputChange,
    handleInputChange,
    createOrganization,
    handleSetFileChange,
    columnsLabels,
    addressesSource,
    handleAddAddressRow,
    handleAddressInputChange,
    handleSetAddressInputChange,
    resetImage,
    setResetImage,
    pagination,
    changePageApi,
    // getCountriesDataSource,
    // getIdentityTypeDataSource,
    setIdentityNumberValidation,
    defaultAddressValue ,
  };
};

export default useAddOrganizationHook;
