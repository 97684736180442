import DefaultSearch from '../../Controls/Search/DefaultSearch';
import TextInput from '../../Controls/TextInputs';
import { Grid } from '@mui/material';
import Card from '../../Controls/Card';
import useTranslation from '../../Hooks/Shared/useTranslation';
import useSearchOnboardingHook from './hooks/useSearchOnboardingHook';

const Search = () => {
    const { T, Resources } = useTranslation();
    const { searchForm, searchHandler, handleInputChange, resetForm, handleCPressEnter} = useSearchOnboardingHook();

    return (
        <Card>
            <DefaultSearch
                searchHandler={searchHandler}
                resetHandler={resetForm}
                showAdvance={false}>
                <Grid item md={11} xs={12}>
                    <TextInput
                        id={searchForm.searchText.name}
                        name={searchForm.searchText.name}
                        label={T(Resources.AppResources.SEARCH)}
                        value={searchForm.searchText.value}
                        maxLength={20}
                        onKeyPress={handleCPressEnter}
                        handleChange={(e: any) => handleInputChange(e)} />
                </Grid>
            </DefaultSearch>
        </Card>
    );
}

export default Search