import { useIntl } from "react-intl";
import { AppResources } from "../../Services/Internationalization/Resources";

const useTranslation = () => {
  const intl = useIntl();

  const T = (id: string, value = {}) => {
  try {
    return intl.formatMessage(
      { id, description: id, defaultMessage: id },
      (value = { ...value })
    );
  } catch (error) {
    return id;
  }
};

return {
  T,
  Resources: {
    AppResources,
  },
};
};

export default useTranslation;
