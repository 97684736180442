import React, { useEffect, useState } from 'react'
import { OrganizationSearch } from '../Shared/organization.model';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchCriteria, setSearchCriteria } from '../../../../Store/Reducers/OrganizationManagement';

const useSearchOrganizationHook = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const searchCriteria = getSearchCriteria(state)
    const [searchText, setSearchText] = useState("");

    //#region :: Handlers

    const handleInputChange = (value: any) => {
        setSearchText(value);
    };


    const searchHandler = async () => {
        const searchModel = new OrganizationSearch();
        searchModel.searchText = searchText;
        dispatch(setSearchCriteria({ body: searchModel }));
    };


    const resetForm = () => {
        setSearchText("");
        dispatch(setSearchCriteria({ body: {} }));
    };


    //#region :: Search Changes [useEffect]
    useEffect(() => {
        if (!searchCriteria) return;
        setSearchText(searchCriteria.searchText)
    }, [searchCriteria]);
    //#endregion

    return {
        searchText, searchHandler, handleInputChange, resetForm
    };
}

export default useSearchOrganizationHook;