import { format } from 'date-fns';

const Months = {
    JAN: 'app.january',
    FEB: 'app.february',
    MAR: 'app.march',
    APR: 'app.april',
    MAY: 'app.may',
    JUN: 'app.june',
    JUL: 'app.july',
    AUG: 'app.august',
    SEP: 'app.september',
    OCT: 'app.october',
    NOV: 'app.november',
    DEC: 'app.december'
}

const MonthsArray = [
    'app.january',
    'app.february',
    'app.march',
    'app.april',
    'app.may',
    'app.june',
    'app.july',
    'app.august',
    'app.september',
    'app.october',
    'app.november',
    'app.december'
]

const FormatDate = (dateValue: any, formatDate = "dd/MM/yyyy") => {
    if (dateValue && dateValue.length) {
        const newDate = new Date(dateValue);
        const convertedDate = format(newDate, formatDate);

        return convertedDate;
    }
    else {
        return dateValue;
    }
};

const GetEDTDateFromDateTime = (value: Date | string) => {
    if (!value) return "";
    var currentdate: any = new Date();

    if (typeof value === 'string' || value instanceof String) {
        currentdate = new Date(value);
    } else {
        currentdate = value;
    }

    let startDateStr = currentdate.getFullYear();
    startDateStr += '-' + (currentdate.getMonth() + 1);
    startDateStr += '-' + currentdate.getDate() + " EDT";



    currentdate = new Date(startDateStr.replace(/-/g, '\/'));
    return currentdate;

}

const FormatDateToHijri = (date: any, formatDate = "yyy-MM-dd") => {
    if (date !== undefined) {
        const newDate = new Date(date);
        const convertedDate = format(newDate, formatDate);
        return convertedDate;
    }
    else {
        return date;
    }
};

const FormatDateTime = (dateTime: any) => {
    if (dateTime !== undefined) {
        const newDateTime = new Date(dateTime);
        const convertedDateTime = format(newDateTime, 'dd/MM/yyyy hh:mm:ss a');

        return convertedDateTime;
    }
    else {
        return dateTime;
    }
};
const TodayDate = () => {
    let today: any = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    return today;
};

const GetMonth = (month: any) => {
    switch (month) {
        case 1:
            return Months.JAN;
        case 2:
            return Months.FEB;
        case 3:
            return Months.MAR;
        case 4:
            return Months.APR;
        case 5:
            return Months.MAY;
        case 6:
            return Months.JUN;
        case 7:
            return Months.JUL;
        case 8:
            return Months.AUG;
        case 9:
            return Months.SEP;
        case 10:
            return Months.OCT;
        case 11:
            return Months.NOV;
        case 12:
            return Months.DEC;
        default:
            return month;
    }
};
const SplitMonthDate = (date: any) => {
    const array = date.split("-", 2);
    const month = GetMonth(parseInt(array[1]));

    return month;
};

const TimeZone = () => {
    return new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" }).split(",");
};

const getAge = (dateString: string) => {
    if (!dateString) return "ــــ";
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};


const getAgeString = (date: Date) => {

    let today = new Date();
    let dateInput = new Date(date);
    let dateDiff = (<any>today) - (<any>dateInput);
    let allDaysValue = (dateDiff) / (1000 * 60 * 60 * 24);
    let totalYears = Math.floor(allDaysValue / 365);
    allDaysValue %= 365;
    let totalMonths = Math.floor(allDaysValue / 30.4167);
    allDaysValue %= 30.4167;
    let totalDays = Math.floor(allDaysValue);
    let result = "";
    if (totalYears > 0) result += totalYears + " Yr ";
    if (totalMonths > 0) result += totalMonths + " M ";
    if (totalDays > 0) result += totalDays + " day ";

    return result;
}

const checkIsExpired = (date: Date) => {
    let today = new Date();
    let dateInput = new Date(date);
    today.setHours(0, 0, 0, 0);
    dateInput.setHours(0, 0, 0, 0);
    if (dateInput < today) {
        return true
    } else {
        return false
    }
}

const DateSort = (dateA: any, dateB: any) => {
    let c: any = new Date(dateA);
    let d: any = new Date(dateB);
    return c - d;
};

function getLastNMonths(n: any) {
    const months: string[] = [];
    const currentDate : any = new Date();


    for (let i = n - 1; i >= 0; i--) {
        let monthIndex: any = currentDate.getMonth() - i;
        let year: any = currentDate.getFullYear();
        if (monthIndex < 0) {
            monthIndex = 12 + monthIndex;
            year--;
        }
        // months.push(Months[monthIndex]);
    }

    return months;
}

export { FormatDate, FormatDateToHijri, FormatDateTime, TodayDate, SplitMonthDate, TimeZone, getAge, GetEDTDateFromDateTime, getAgeString, checkIsExpired, DateSort, getLastNMonths, MonthsArray };