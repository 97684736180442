import React, { useEffect, useState } from 'react'
import { OnboardingSearch } from '../Shared/onboarding.model';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchCriteria, setSearchCriteria } from '../../../Store/Reducers/Onboarding';

const useSearchOnboardingHook = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const searchCriteria = getSearchCriteria(state)
    const [searchForm, setSearchForm] = useState(OnboardingSearch.getFieldsModel());

    //#region :: Handlers

    const handleInputChange = (event: any) => {
        var { name, value } = event.target;
        handleChangeInput(name, value);
    };

    const handleChangeInput = (name: any, value: any) => {
        setSearchForm({
            ...searchForm,
            [name]: {
                ...searchForm[name],
                value: value
            }
        });
    };

    const searchHandler = async () => {
        const request = OnboardingSearch.toDBModel(searchForm);
        dispatch(setSearchCriteria({ body: request }));

    };

    const resetForm = () => {
        setSearchForm(OnboardingSearch.getFieldsModel());
        dispatch(setSearchCriteria({ body: {} }));
    };
    
    const handleCPressEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler()
        }
    }

    //#region :: Search Changes [useEffect]
    useEffect(() => {
        if (!searchCriteria) return;
        setSearchForm({
            ...searchForm,
            searchText: {
                ...searchForm.searchText,
                value: searchCriteria.searchText
            },
        });
    }, [searchCriteria]);
    //#endregion


    return {
        searchForm, searchHandler, handleInputChange, resetForm, handleCPressEnter
    };
}

export default useSearchOnboardingHook;