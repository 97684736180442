
export class ValidationService {
    private static dontValidate(name?: any) {
        let dateObject = {
            valid: true,
            name: '',
            message: ''
        };

        dateObject.valid = true;
        dateObject.name = name;
        dateObject.message = '';

        return dateObject;
    };


    private static checkInputRegex(value?: any, name?: any, regexValue?: any, message?: any) {
        const validRegex = regexValue.test(value);
        let dateObject = {
            valid: true,
            name: '',
            message: ''
        };

        if (validRegex) {
            dateObject.valid = true;
            dateObject.name = name;
            dateObject.message = '';
        }
        else {
            dateObject.valid = false;
            dateObject.name = name;
            dateObject.message = message;
        }

        return dateObject;
    };

    private static requiredHandler(value?: any, name?: any, message?: any) {
        let dateObject = {
            valid: true,
            name: '',
            message: ''
        };
        if (
            value === '' ||
            value === undefined ||
            value == null ||
            value.length === 0
        ) {
            dateObject.valid = false;
            dateObject.name = name;
            dateObject.message = message;
        } else {
            dateObject.valid = true;
            dateObject.name = name;
            dateObject.message = '';
        }

        return dateObject;
    };



    public static Valid(formInputs?: any) {
        let responseObject: any = {}
        let isValidForm = true;
        let keyss: Array<any> = [];
        let myForm = { ...formInputs }
        Object.keys(formInputs).map((key) => {
            keyss.push(key);
        });
        keyss.map((item) => {
            let input = { ...myForm[item] };
            let name = input.name;
            if (input.isField == false) {
                let object = { ...input }
                delete object.isField;
                let data = this.Valid(object);
                responseObject[item] = data; // To check if you need only data or data.responseObject
            }
            if (!input.validate) {
                let dontValidateResponse = this.dontValidate(name);
                responseObject[name] = dontValidateResponse;
            }  
            if (input.regexValue !== "" && input.regexValue !== undefined && input.value !== "" && input.value !== undefined)// && input.value !== "0"
            {
                let regexResponse = this.checkInputRegex(input.value, input.name, input.regexValue, input.errorMessages.error);
                responseObject[input.name] = regexResponse;
                if (regexResponse.valid === false) {
                    isValidForm = false;
                }
            }
            else if (input.required) {
                let requiredResponse = this.requiredHandler(input.value, input.name, input.errorMessages.requiredMsg);
                responseObject[input.name] = requiredResponse;
                if (requiredResponse.valid === false) {
                    isValidForm = false;
                }
            }
            else {
                let dontValidateResponse = this.dontValidate(name);
                responseObject[name] = dontValidateResponse;
            }
        });


        let responseFormValidation: any = {
            responseObject: responseObject,
            isValidForm: isValidForm
        }

        return responseFormValidation;
    };

}