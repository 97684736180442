import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { getUILanguage } from '../../../../Store/Reducers/UI';
import { EnvLevelContextText } from '../../../../Config/Settings/Enums';
import { PaginationModel } from '../../../../Models';
import PagesRoute from '../../../../Config/Settings/PagesRoute';
import { setRoleMappingDetails } from '../../../../Store/Reducers/RolesManagement';
import Locales from '../../../../Services/Internationalization/Locales';
import { PermissionScopes, PermissionsService } from '../../../../Services/permissions.service';
import CustomeIconButton, { IconButtonsTypes } from '../../../../Controls/IconButton';
import UseRoleMappingSearch from '../../../../Hooks/useRoleMappingSearchHook';
import { Link } from 'react-router-dom';
import { RolesMappingService } from '../../../../Services/API/RolesMapping';
import { MESSAGE_TYPE, MessagesService } from '../../../../Services/messages.service';

const useAddRoleMappingHook = () => {
    //#region :: State
    const { T, Resources } = useTranslation();
    const state = useSelector(state => state);
    const currentLanguage = getUILanguage(state);
    const columns = [
        { id: "name", label: T(Resources.AppResources.NAME), align: "center" },
        { id: "level", label: T(Resources.AppResources.LEVEL), align: "center" },
            { id: "actions", label: T(Resources.AppResources.ACTIONS), align: "center" }
    ];

    const dispatch = useDispatch();
    const { roleMapping, currentPage, pagination, totalPages, pageSize, changeCurrentPage, resetPage } = UseRoleMappingSearch()
    const [innerPagination, setPagination] = useState<PaginationModel>(new PaginationModel());
    const [pageData, setPageData] = useState<Array<any>>([]);
    //#endregion

    const handleDelete = async (id: number) => {
        try {
            
            const response = await RolesMappingService.deleteRole(id);
            resetPage();
            MessagesService.Toast(T(Resources.AppResources.DELETED_SUCCESS), MESSAGE_TYPE.Success);
        } catch (error) {
            if(error.data.status.code == "B00200"){
                MessagesService.Toast(T(Resources.AppResources.USED_ROLE_ERROR), MESSAGE_TYPE.Error);
            }
        }
        
    }

    const mapData = () => {
        setPageData(roleMapping.map((rol: any) => {

            return {
                name: currentLanguage == Locales.ARABIC ? rol.arabicName : rol.englishName,
                level: T(Resources.AppResources[EnvLevelContextText[rol.level]]),
                actions: (
                    <div>
                        {PermissionsService.hasPermission(PermissionScopes.EDIT_ROLE) && <CustomeIconButton
                            id='edit'
                            aria-label={T(Resources.AppResources.EDIT)}
                            type={IconButtonsTypes.EDIT}
                            component={Link}
                            to={PagesRoute.PAGES.EDITROLE + rol.id} />}
                        {PermissionsService.hasPermission(PermissionScopes.DELETE_ROLE) && <CustomeIconButton
                            id='delete'
                            aria-label={T(Resources.AppResources.DELETE)}
                            type={IconButtonsTypes.DELETE}
                            onClick={() => handleDelete(rol.id)} />}
                    </div>
                )
            }
        }));
        dispatch(setRoleMappingDetails({ body: roleMapping }));
        setPagination({
            pageIndex: currentPage,
            pageSize: pageSize,
            totalCount: pagination.defaultContext && pagination.defaultContext.totalCount,
            totalPages: totalPages
        });
    };


    //#region :: [useEffect]
    useEffect(() => {
        if (!roleMapping) return
        if (roleMapping && Array.isArray(roleMapping))
            mapData();
    }, [roleMapping, currentPage]);
    //#endregion

    return {
        pageData,
        columns,
        changeCurrentPage,
        innerPagination,
    }
}

export default useAddRoleMappingHook