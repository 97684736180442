import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { PaginationModel } from '../../../../Models';
import useInvoicesBookSearch from '../../../../Hooks/useInvoicesBookSearch';
import CustomeIconButton, { IconButtonsTypes } from '../../../../Controls/IconButton';
import PagesRoute from '../../../../Config/Settings/PagesRoute';
import { Grid } from '@mui/material';
import SwitchToggle from '../../../../Components/SwitchToggle';
import { InvoicesBookService } from '../../../../Services/API/InvoicesBook';
import useAppNavigate from '../../../../Hooks/Shared/useAppNavigate';
import { LanguagesValues } from '../../../../Config/Settings/Lookups';
import CBadge from '../../../../Components/Badg/badge';
import { useDispatch } from 'react-redux';
import { deActiveInvoiceBook } from '../../../../Store/Reducers/InvoicesBook';
import { PermissionScopes, PermissionsService } from '../../../../Services/permissions.service';

const useInvoicesBookListHook = () => {
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const invoicesBookSearch = useInvoicesBookSearch();
    const appNavigate = useAppNavigate();
    const [invoicesBookSource, setOrganizationSource] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<PaginationModel>(new PaginationModel());
    const cardLabels: Array<any> = [
        { id: 'img' },
        { id: 'name', label: T(Resources.AppResources.NAME), },
        { id: 'organization', label: T(Resources.AppResources.ORGANIZATION) },
        { id: 'language', label: T(Resources.AppResources.LANGUAGE) },
        { id: 'status', label: T(Resources.AppResources.STATUS) },
        { id: 'tags'},
        { id: 'action'},
    ];

 
    const handelChangeStatus = async (id: any, isActive: boolean) => {
        await InvoicesBookService.ActivateInvoiceBook(id, isActive);
        dispatch(deActiveInvoiceBook({id, isActive}));
    }

    //#region :: Organization Data [useEffect]

    useEffect(() => {
        if (!invoicesBookSearch?.invoicesBook) return;

        setOrganizationSource(invoicesBookSearch?.invoicesBook?.map((item: any) => {
            const { id, organizationId, invoicesBookInfo, status,organizationName,  isActive, organizationLogoData, tags } = item;
            

            return {
                id,
                img: <Grid style={{ display: 'flex', justifyContent: "end", marginLeft: '20px' }}><img style={{ width: "50px" }} src={organizationLogoData} /></Grid>,
                name: invoicesBookInfo?.name,
                organization: organizationName,
                language: T(Resources.AppResources[LanguagesValues[invoicesBookInfo?.language?.code]]),
                status: T(Resources.AppResources[status.description]),
                tags: 
                <Grid style={{ display: 'flex', justifyContent: 'flex-start', gap: '1%'}}>
                    {tags?.split(',').map(x => {return(<CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={x}>{x}</CBadge>)})}
                </Grid>,
                _action: (

                    <Grid container style={{ display: 'flex'}} >
                        {PermissionsService.hasPermission(PermissionScopes.CHANGE_INVOICES_BOOK_STATUS_ORGANIZATION) && <Grid item xs={4} style={{ display: 'flex' ,justifyContent:"start"}} spacing={1}>
                            <SwitchToggle
                                id={id}
                                active={T(Resources.AppResources.ACTIVE)}
                                inactive={T(Resources.AppResources.IN_ACTIVE)}
                                isActive={isActive}
                                handleStatusChange={()=>{handelChangeStatus(id, !isActive)}}
                            />
                        </Grid>}
                        <Grid item xs={8}  style={{ display: 'flex',justifyContent:"end"}} >
                            
                           { PermissionsService.hasPermission(PermissionScopes.EDIT_INVOICES_BOOK_ORGANIZATION) &&<CustomeIconButton
                                id='edit'
                                aria-label='edit'
                                type={IconButtonsTypes.EDIT}
                                onClick={() => appNavigate.push(PagesRoute.PAGES.EDIT_INVOICES_BOOK + id)}
                                />}
                            {PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_BOOK_DETAILS_ORGANIZATION) &&<CustomeIconButton
                                id='view'
                                aria-label='view'
                                type={IconButtonsTypes.VIEW}
                                onClick={() => appNavigate.push(PagesRoute.PAGES.VIEW_INVOICES_BOOK + id)} />}
                        </Grid>
                    </Grid>
                ),
                get action() {
                    return this._action;
                },
                set action(value) {
                    this._action = value;
                },
            };
        }));

        setPagination({

            //Prodaction
            pageIndex: invoicesBookSearch.currentPage ,
            pageSize: invoicesBookSearch.pageSize,
            totalCount: invoicesBookSearch.pagination.defaultContext !== undefined && invoicesBookSearch.pagination.defaultContext.totalCount,
            totalPages: invoicesBookSearch.totalPages

        });
    }, [invoicesBookSearch.invoicesBook]);
    //#endregion

    return {
        cardLabels, pagination, invoicesBookSource, changeCurrentPage:  invoicesBookSearch.changeCurrentPage
    }
}

export default useInvoicesBookListHook