import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class PermissionsServices {
    public static GetAllLevel = async (type: any, parentId?: any, searchText?:any) => {
        try {
           
            const result = await WebServiceClient.get(`${APIConstants.PERMISSIONS.GET}?Level=${type}&parentId=${parentId || ''}&SearchText=${searchText || ''}`, true,false)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }


    public static GetCurrentUserPermissions = async (includeOrganization = false) => {
        try {
           
            const result = await WebServiceClient.get(`${APIConstants.PERMISSIONS.CURRENT_USER}`, true,includeOrganization)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }
}
