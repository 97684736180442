import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export interface CardProps {
    children: any;
    height?: any;
    spacing?: any;
    margintop?: any;
    flex?: any;
    borderRadius?: any;
};

const Card = ({ children, height, spacing, margintop = 10, flex = false, borderRadius }: CardProps) => {

    return (
        <StyledCard spacing={spacing} borderradius={borderRadius} height={height} margintop={margintop}>
            <StyledCardContent flexdisplay={+flex}>
                {children}
            </StyledCardContent>
        </StyledCard>
    );
};

//#region :: Styled Component
const StyledCard: any = styled(MuiCard)`
margin: ${({ spacing }: any) => spacing && spacing + 'px'};
margin-top: ${({ margintop }: any) => margintop && margintop + 'px'};
width: 100%!important;
height: ${({ height }: any) => height && height + '%!important'};
border-radius: ${({ borderradius }: any) => borderradius && borderradius + 'px!important'};
`;

const StyledCardContent: any = styled(CardContent)`
    &.MuiCardContent-root:last-child {
        padding-bottom: 16px;
        display: ${({ flexdisplay }: any) => flexdisplay && 'flex'};
    }
`;
//#endregion

//#region :: Validation PropTypes
Card.propTypes = {
    children: propTypes.any,
    spacing: propTypes.number
};
//#endregion

export default Card;