import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class LookupsService {
    public static GetLookup = async (type: any, parentId?: any) => {
        try {
           
            const result = await WebServiceClient.get(`${APIConstants.LOOKUPS.GET}?type=${type}&parentId=${parentId || ''}`, true,false)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }
    public static GetLookupByValue = async (value: any) => {
        try {
           
            const result = await WebServiceClient.get(`${APIConstants.LOOKUPS.GET}/${value}`, true,false)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }
    public static GetLookupSearch = async (type: any, parentId?: any, searchText?: any) => {
        try {
            const request = {
                pageIndex: 0,
                pageSize: 10,
                LookupType:type,
                parentId,
                searchText
            }
            const result = await WebServiceClient.post(APIConstants.LOOKUPS.POST, {body:request}, undefined, true,false)
            return result?.data?.body;
        }
        catch (error: any) {
            throw error;
        }
    }
}
