import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersManagementSelectors, setError, usersManagementReceived } from '../Store/Reducers/UsersManagements';
import { pageRequested, PaginationSelectors, PaginationModules } from '../Store/Reducers/Pagination';
import Constants from '../Config/Settings/API';
const useUsersManagementSearch = () => {

    //#region :: State

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [usersManagement, setUsersManagement] = useState<Array<any>>([]);
    const _usersManagement = usersManagementSelectors.getUsersManagementsBookData(state);
    const searchCriteria = usersManagementSelectors.getSearchCriteria(state);

    const currentPage = PaginationSelectors.getCurrentPage(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT);
    const pageSize = PaginationSelectors.getPageSize(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT);
    const totalPages = PaginationSelectors.getTotalPages(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT);
    const pagination = PaginationSelectors.getPaginationModule(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT);

    const lastPageIndex = PaginationSelectors.getLastPageIndex(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT);
    const hasMore = PaginationSelectors.hasMore(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT) || false;
    const isPageFetching = PaginationSelectors.isPageFetching(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT);
    const pageKeys = PaginationSelectors.getPageKeys(state, PaginationModules.ORGANIZATION_USERS_MANAGEMENT, currentPage);

    const requestPage = (actionType: any, pageIndex: any, reset: boolean = false) => {

        //Prodaction
        if (isPageFetching) return;

        //Prodaction
        dispatch(pageRequested({
        url: Constants.ORG_USERS.SEARCH,
            data: { body: { ...searchCriteria, pageIndex: pageIndex, pageSize: pageSize } },
            method: 'POST',
            onSuccess: actionType,
            onError: setError.type,
            args: {
                resetPages: reset,
                source: 'body',
                id: 'id',
                module: PaginationModules.ORGANIZATION_USERS_MANAGEMENT,
                page: pageIndex
            }
        }));
    };

    const changeCurrentPage = (page: any) => {
        requestPage(usersManagementReceived.type, page);
    };

    const refetch = () => {
        requestPage(usersManagementReceived.type, currentPage, true);
    }
    
    useEffect(() => {
        if (!searchCriteria) return;
        requestPage(usersManagementReceived.type, 0, true);
    }, [searchCriteria]);

    //Prodaction
    useEffect(() => {
        if (pageKeys && pageKeys.keys) {
            let items: Array<any> = [];

            pageKeys.keys.map((key: any) => {
                items.push(_usersManagement[key]);
            });
            setUsersManagement(items);
        }
    }, [pageKeys.keys]);

    useEffect(() => {
        setUsersManagement(_usersManagement);
    }, [_usersManagement]);

    return { usersManagement, hasMore, isPageFetching, lastPageIndex, currentPage, pagination, totalPages, pageSize, changeCurrentPage, refetch };
};

export default useUsersManagementSearch;