import React, { useEffect, Suspense } from "react";
import { Provider as StoreProvider, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MuiThemeProvider from "@mui/material/styles/ThemeProvider";
import createMuiTheme from "@mui/material/styles/createTheme";
import configureStore from "../Store/ConfigureStore";
import LocaleProvider from "../Services/Internationalization/LocaleProvider";
import { MuiThemeVariables } from "../Config/Theme/MuiThemeVariables";
import {
  getUIDirection,
  getUILanguage,
  getUIThemeMode,
  getFullLoading,
} from "../Store/Reducers/UI";
import Fallback from "../Controls/Fallbacks/SpinFallback";
import FullPageFallback from "../Controls/Fallbacks/FullPage";
import { LanguagesShortName } from "../Services/Internationalization/Locales";
import "../assets/styles/style.scss";
import RoutConfig from "./RouteConfig";
import { AuthProvider } from "../MultiTenant/authContext";
import { useTenant } from "../MultiTenant/tenantContext";
import { GlobalService } from "../Services/global.service";
import { OrganizationService } from "../Services/API/OrganizationManagement";
import { EnvMode } from "../Config/Settings/Enums";
import { getAuth } from "../Store/Reducers/Auth";

//#region :: Configs
const store = configureStore();

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});
//#endregion

const ConfiguredApp = () => {
  //#region :: State
  const state = useSelector((state) => state);
  const currentLanguage = getUILanguage(state);
  const direction = getUIDirection(state);
  const themeMode = getUIThemeMode(state);
  const fullLoading = getFullLoading(state);
  //#endregion

  //#region :: Create Mui Theme
  const themeMui = React.useMemo(() => {
    return createMuiTheme({
      ...MuiThemeVariables,
      direction: direction,
      palette: {
        ...MuiThemeVariables.palette,
        mode: themeMode,
      },
    });
  }, [direction, themeMode]);

  //#endregion

  //#region :: [Effect] Change layout direction
  useEffect(() => {
   
    
    const html: any = document.querySelector("html");
    html.setAttribute("dir", direction);

    html.classList.remove(LanguagesShortName.ARABIC);
    html.classList.remove(LanguagesShortName.ENGLISH);

    if (direction === "rtl") {
      html.classList.add(LanguagesShortName.ARABIC);
      html.setAttribute("lang", LanguagesShortName.ARABIC);
    } else {
      html.classList.add(LanguagesShortName.ENGLISH);
      html.setAttribute("lang", LanguagesShortName.ENGLISH);
    }

  }, [direction]);
  //#endregion

  return (
    <LocaleProvider locale={currentLanguage}>
      <CacheProvider value={direction === "rtl" ? cacheRtl : cacheLtr}>
        <MuiThemeProvider theme={themeMui}>
          <ThemeProvider theme={themeMui}>
            <Suspense fallback={<Fallback />}>
              <FullPageFallback loading={fullLoading} />
              <RoutConfig />
            </Suspense>
          </ThemeProvider>
        </MuiThemeProvider>
      </CacheProvider>
    </LocaleProvider>
  );
};

const App = (props: any) => {
  const { currentTenant } = useTenant() || {};
  return (
    <Router basename={currentTenant?.clientBaseRoute}>
      <StoreProvider store={store}>
        <AuthProvider>
          <ConfiguredApp />
        </AuthProvider>
      </StoreProvider>
    </Router>
  );
};

export default App;
