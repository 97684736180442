import axios from 'axios';
import { StorageService, STORAGE_KEY } from '../Services/storage.service';

import config from './multitenantConfig';
import { GlobalService } from '../Services/global.service';
import { EnvMode } from '../Config/Settings/Enums';

const DEFAULT_TENANT_ID = 'master';
const ERROR_TENANT_UNAVAILABLE = 'Tenant unavailable';

const getTenantInfo = async (tenantId: string) => {
  try {  
    // debugger
    const envMode = GlobalService.EnvMode;     
    const  response = await axios({
      url: config.Routes.TenantInfo(tenantId),
      method: 'get',
      headers: {
        env: envMode || EnvMode.SIM
        }, 
      withCredentials: true
      })
      const envModes = response.data.body.singleEnvironment;
      if (envModes) {
        GlobalService.EnvMode = envModes;
        GlobalService.CurrentActiveOrganization = null;
        GlobalService.AppUserAuthorizationInfo = null;
        // GlobalService.AppUser = null;
        GlobalService.OrgsList = null;
        // GlobalService.IsTenantContext = false;
        GlobalService.RefetchOrgs = true;
      }
    if (response.status === 200)
      return response.data;

    if (response.status === 404)
      return null;

    throw new Error(ERROR_TENANT_UNAVAILABLE);
  }
  catch (error) { throw error; }
};

const getDefaultTenant = async () => {
  const tenantInfo = await getTenantInfo(DEFAULT_TENANT_ID);

  if (tenantInfo == null) throw new Error(ERROR_TENANT_UNAVAILABLE);

  return tenantInfo;
};

const getTenant = async () => {
  const path = window.location.pathname;
  var tenantInfo: any = null;
  var clientBaseRoute = `/${DEFAULT_TENANT_ID}`;

  if (path === '/')
    window.location.pathname = clientBaseRoute;

  const tenantId = path.split('/')[1];

  if (tenantId.toLowerCase() === DEFAULT_TENANT_ID)
    return { ...(await getDefaultTenant())?.body, clientBaseRoute };

  tenantInfo = await getTenantInfo(tenantId);
  clientBaseRoute = (tenantInfo && `/${tenantId}`) || clientBaseRoute;

  if (tenantInfo === null)
    tenantInfo = await getDefaultTenant();

  if (tenantInfo === null) throw new Error(ERROR_TENANT_UNAVAILABLE);

  return { ...tenantInfo?.body, clientBaseRoute };
};

export { getTenant, DEFAULT_TENANT_ID };