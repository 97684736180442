import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { useDispatch } from 'react-redux';
import { changeFullLoading } from '../../../../Store/Reducers/UI';
import React , { useEffect, useState } from 'react';
import { PaginationModel } from '../../../../Models';
import { PaginationEnums } from '../../../../Config/Settings/Enums';
import { TenantUsersManagementService } from '../../../../Services/API/TenantUsersManagement';
import CustomeIconButton, { IconButtonsTypes } from '../../../../Controls/IconButton';
import { MESSAGE_TYPE, MessagesService } from '../../../../Services/messages.service';

const useAddTenantUserHook = () => {
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();

    const [adUsersTableData, setAdUsersList] = useState<Array<any>>([]);
    const columns = [
        { id: "userPrincipalName", label: T(Resources.AppResources.USERNAME), align: "center" },
        { id: "name", label: T(Resources.AppResources.NAME), align: "center" },
        { id: "emailAddress", label: T(Resources.AppResources.EMAIL), align: "center" },
        { id: "actions", label: T(Resources.AppResources.ACTIONS), align: "center" }
    ];

    const [pagination, setPagination] = useState<PaginationModel>(
        new PaginationModel()
    );
    // const navigate = useAppNavigate();

    //#endregion

    //#region Handlers


    const getAdUsers = async (nameSearchText: any = undefined, emailSearchText: any = undefined,userNameSearchText: any = undefined) => {
        try {
            let request = {
                pageIndex: 0,
                pageSize: PaginationEnums.SMALL_PAGE_SIZE,
                name: nameSearchText, 
                userName: userNameSearchText,
                email: emailSearchText
            };
            let response: any;
            
            response = await TenantUsersManagementService.SearchAdUsers(request);
            const tmpList = response?.data?.body.map((x) => prepareUsersListRow(x));
            setAdUsersList(tmpList);

            let tempPagination = new PaginationModel();
            tempPagination.pageIndex = response?.data?.pageIndex;
            tempPagination.pageSize = response?.data?.pageSize;
            tempPagination.totalCount = response?.data?.totalCount;
            tempPagination.totalPages = response?.data?.pagesCount;

            setPagination(tempPagination);
        } catch (error) {
        } finally {
        }
    };

    const changeCurrentPage = async (pageIndex) => {
        setPagination({
            pageSize: pagination?.pageSize,
            totalCount: pagination?.totalCount,
            totalPages: pagination?.totalPages,
            pageIndex: pageIndex,
        });

        let request = {
            pageIndex: pageIndex,
            pageSize: PaginationEnums.SMALL_PAGE_SIZE,
            //searchText: "string"
        };
        let response: any;

        response = await TenantUsersManagementService.SearchAdUsers(request);
        setAdUsersList(response?.data?.body.map((itm) => {
            return prepareUsersListRow(itm)
        })
        );
    };

    const handleAddAdUser = async (request: any) => {
        try {
            dispatch(changeFullLoading({ spin: true }));
            const adUser = {
               adGuid :request.guid,
               userName :request.userPrincipalName,
               name :request.name,
               email :request.emailAddress
            };
            const response = await TenantUsersManagementService.CreateAdUsers(adUser);

            if(response?.id){
                MessagesService.Toast(T(Resources.AppResources.USER_ADDED_SUCCESSFULLY), MESSAGE_TYPE.Success);
            }
        } catch (error) {
            if(error?.data?.status?.code == "B00004")
                MessagesService.Toast(T(Resources.AppResources.DUPLICATE_USER_ERROR), MESSAGE_TYPE.Error);
                
        } finally {

            dispatch(changeFullLoading({ spin: false }));
        }
        // 
    }

    const prepareUsersListRow = (itm) => {

        return {
            userPrincipalName: itm.userPrincipalName,
            name: itm.name,
            emailAddress: itm.emailAddress,
            actions: (
                <CustomeIconButton
                    id='add'
                    aria-label='add'
                    type={IconButtonsTypes.ADD}
                    onClick={() => { handleAddAdUser(itm); }} />
            )
        }
    }
    //#endregion


    //#region :: UseEffect
    useEffect(() => {
        
        getAdUsers()
    }, [])

    //#endregion 

    return {
        adUsersTableData, columns, pagination, changeCurrentPage, getAdUsers
    }
}

export default useAddTenantUserHook