
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App/App';
import { TenantProvider } from './MultiTenant/tenantContext';


// const root = createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
ReactDOM.render(
  <TenantProvider>
    <App />
  </TenantProvider>
    
  ,
  document.getElementById('root')
);