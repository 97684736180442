import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OnboardingSearch } from '../../../Pages/Onboarding/Shared/onboarding.model';


const onboarding = createSlice({
    name: 'onboarding',
    initialState: {
        onboarding: [],
        keys: [],
        onboardingLists: {},
        onboardingDetails: {},
        searchCriteria: OnboardingSearch.toDBModel(OnboardingSearch.getFieldsModel()),
        showError: false,
        error: null
    },

    reducers: {
        onboardingReceived: (onboarding: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                onboarding.onboarding[item.id] = item;
                onboarding.keys = [...onboarding.keys, item.id]
            });
            onboarding.customerDetails = {};
        },

        setOnboardingsDetails: (onboarding: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            onboarding.onboardingDetails = data;
        },


        setOnboardingsList: (onboarding: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            onboarding.onboardingLists = data;
        },

        setOnboardings: (onboarding, { payload }) => {
            onboarding.onboarding = payload;
        },

        setSearchCriteria: (onboarding: any, { payload }: PayloadAction<any>) => {
            onboarding.searchCriteria = payload.body;
        },

        setError: (onboarding: any, { payload }: PayloadAction<any>) => {
            onboarding.error = payload.response;
            onboarding.showError = true;
        }
    }
});


export const { onboardingReceived, setOnboardings, setOnboardingsList, setOnboardingsDetails, setSearchCriteria, setError } = onboarding.actions;
export default onboarding.reducer;

//#region :: Selectors
export const getOnboardingsData = (state: any) => {
    return state.onboarding.onboarding;
};

export const getOnboardingsDetails = (state: any) => {
    return state.onboarding.onboardingDetails;
};

export const getOnboardingLists = (state: any) => {
    return state.onboarding.onboardingLists;
};

export const getSearchCriteria = (state: any) => {
    return state.onboarding.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.onboarding.showError;
};

export const getError = (state: any) => {
    return state.onboarding.error;
};

export const onboardingSelectors = {
    getOnboardingsData,
    getSearchCriteria,
    getOnboardingsDetails,
    getOnboardingLists,
    getShowError,
    getError,
};
//#endregion
