import Grid from '@mui/material/Grid';
import React from 'react';

import ModalComponent from '../../../../../Controls/Modal/Modal';
import useTranslation from '../../../../../Hooks/Shared/useTranslation';
import TableComp from '../../../../../Controls/Table';
import useAddTenantUserHook from '../../hooks/useAddTenantUserHook';
import AdUsersSearch from './adUsersSearch';


const AdUsersDialog = ({ id = null, open = false, handleClose }) => {

    const { T, Resources } = useTranslation();
    const tenantUsersManagementHook = useAddTenantUserHook();


    return (
        <ModalComponent
            title={T(Resources.AppResources.USER)}
            open={open}
            handleClose={handleClose}
            loading={false}
            maxWidth={'md'}
            divider={false}>
            <Grid container spacing={2}>
                <Grid item xs={12} marginBottom={'10px'}>
                    <AdUsersSearch searchHandler={tenantUsersManagementHook.getAdUsers} />
                </Grid>
                
                <Grid paddingTop={2} md={12} sm={12} xs={12} paddingLeft={2}>
                    <TableComp
                        dataSource={tenantUsersManagementHook.adUsersTableData}
                        tableColumns={tenantUsersManagementHook.columns}
                        changePageApi={tenantUsersManagementHook.changeCurrentPage}
                        pagination={tenantUsersManagementHook.pagination} />
                </Grid>
            </Grid>
        </ModalComponent>
    );
};

export default AdUsersDialog;