import Grid from '@mui/material/Grid';
import React, {  } from 'react';
import styled from 'styled-components';
import Card from '../../Controls/Card';
import PagesRoute from '../../Config/Settings/PagesRoute';
import Button from '../../Controls/Button';
import useAppNavigate from '../../Hooks/Shared/useAppNavigate';
import useTranslation from '../../Hooks/Shared/useTranslation';
import { getFieldValue } from '../../Utils/StringUtils';
import useViewTenantUserHook from './hooks/useViewUserHook';
import './styles.scss'
import CBadge from '../../Components/Badg/badge';


const ViewOrgUser = () => {
    const { T, Resources } = useTranslation();
    const {dataSource}=useViewTenantUserHook()
    const navigate = useAppNavigate();
    //#region :: State
   console.log(dataSource);
   
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={3}>
                            <Grid item>
                                <StyledSubParagraph>{T(Resources.AppResources.USERNAME)}</StyledSubParagraph>
                            </Grid>
                            <Grid item>
                                <StyledDetails>{dataSource && getFieldValue(dataSource.userName)}</StyledDetails>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Grid item>
                                <StyledSubParagraph>{T(Resources.AppResources.NAME)}</StyledSubParagraph>
                            </Grid>
                            <Grid item>
                                <StyledDetails>{dataSource && getFieldValue(dataSource?.name)}</StyledDetails>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Grid item>
                                <StyledSubParagraph>{T(Resources.AppResources.EMAIL)}</StyledSubParagraph>
                            </Grid>
                            <Grid item>
                                <StyledDetails>{dataSource && getFieldValue(dataSource.email)}</StyledDetails>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Grid item>
                                <StyledSubParagraph>{T(Resources.AppResources.MOBILE_NUMBER)}</StyledSubParagraph>
                            </Grid>
                            <Grid item>
                                <StyledDetails>{dataSource && getFieldValue(dataSource.mobileNumber)}</StyledDetails>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Grid item>
                                <StyledSubParagraph>{T(Resources.AppResources.STATUS)}</StyledSubParagraph>
                            </Grid>
                            <Grid item>
                                <StyledDetails>
                                    {dataSource && dataSource.isActive
                                        ? T(Resources.AppResources.ACTIVE)
                                        : T(Resources.AppResources.IN_ACTIVE)}
                                </StyledDetails>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item marginTop={2}>
                        <Button
                            variant='outlined'
                            color='primary'
                            label={T(Resources.AppResources.BACK)}
                            onClick={() => navigate.push(PagesRoute.PAGES.ORGUSERSMANAGEMENT || '')}
                        />
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};

//#region :: Styled Components
const StyledSubParagraph: any = styled.span(() => ({
    fontWeight: 'bold!important',
    fontSize: 'medium!important'
}));

const StyledDetails: any = styled.p(({ theme }: any) => ({
    fontSize: '0.8rem',
    padding: '0px',
    marginTop: '0px',
    color: theme.palette.primary.darkGrayish
}));
//#endregion

export default ViewOrgUser;