import { Grid } from '@mui/material'
import React from 'react'
import useTranslation from '../../../Hooks/Shared/useTranslation';
import ReactJson from 'react-json-view';

const ResponseErrorDialog = ({ data }: any) => {
    
    return (
        <Grid container paddingY={2} spacing={1} xs={12}>
            <Grid item md={12} sm={12} xs={12}>
                <GetJsonFieldValue jtext={data} />
            </Grid>
        </Grid>
    )
}

export default ResponseErrorDialog


const containerStyle = {
    borderRadius: '3px',
    backgroundColor: 'rgb(240, 240, 240)',
  };
  
  const GetJsonFieldValue = ({jtext}) => {
    
    try {
      const jsonData = JSON.parse(jtext);
  
  
      if (typeof jsonData === 'object' && jsonData !== null) {
        return (
          <div dir="ltr"><ReactJson  src={jsonData} collapsed={false} collapseStringsAfterLength={100} style={containerStyle} /> </div>
        );
      } else {
        return <span>{jtext}</span>;
      }
    } catch (error) {
      return <span>{jtext}</span>;
    }
  }