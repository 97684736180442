import React from "react";
import TextInput from "../../Controls/TextInputs";
import { Grid } from "@mui/material";
import Card from "../../Controls/Card";
import useTranslation from "../../Hooks/Shared/useTranslation";
import DefaultSearch from "../../Controls/Search/DefaultSearch";
import useSearchReportsHook from "./hooks/useSearchReportsHook";
import CustomDropDown from "../../Controls/CustomDropDown";
import AdvancedSearch from "../../Controls/Search/AdvancedSearch";
import DateInput from "../../Controls/DateInput";
import { InvoiceStatuses, StatusDDList, TaxDDList } from "../../Config/Settings/Lookups";
import AutoCompleteSearchable from "../../Components/AutoCompleteSearchable";

const Search = () => {
  const { T, Resources } = useTranslation();
  const {
    searchForm,
    searchHandler,
    handleChangeInput,
    handleInputChange,
    resetForm,
    isAdvancedSearch,
    toggleAdvancedSearch,
    getInvoicesBooksDataSource,
    handleCPressEnter,
  } = useSearchReportsHook();

  return (
    <Card>
      <DefaultSearch
        searchHandler={searchHandler}
        resetHandler={resetForm}
        toggledvancedSearch={toggleAdvancedSearch}
        isAdvancedSearch={isAdvancedSearch}
        showAdvance={true}
      >
        <Grid item md={2.9} xs={12}>
          <AutoCompleteSearchable
            id={searchForm?.invoicesBook?.name}
            name={searchForm?.invoicesBook?.name}
            selected={searchForm?.invoicesBook?.value}
            label={T(Resources.AppResources.INVOICES_BOOK)}
            handleChange={handleChangeInput}
            error={!searchForm.invoicesBook.valid}
            showLabel
            getDataSource={getInvoicesBooksDataSource}
          />
        </Grid>
        <Grid item md={2.9} xs={12}>
          <TextInput
            id={searchForm.invoiceNumber.name}
            name={searchForm.invoiceNumber.name}
            label={T(Resources.AppResources.INVOICE_NUMBER)}
            value={searchForm.invoiceNumber.value}
            maxLength={20}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)}
          />
        </Grid>

        <Grid item md={2.9} xs={12}>
          <DateInput
            value={searchForm?.invoiceDateFrom?.value}
            minDate={new Date("1900-01-01")}
            id={searchForm?.invoiceDateFrom?.name}
            name={searchForm?.invoiceDateFrom?.name}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            size="small"
            isHijri={false}
            label={T(Resources.AppResources.INVOICE_DATE_FROM)}
            handleChange={(value: any) => {
              handleChangeInput(searchForm?.invoiceDateFrom.name, value);
            }}
          />
        </Grid>
        <Grid item md={2.9} xs={12}>
          <DateInput
            value={searchForm?.invoiceDateTo?.value}
            minDate={new Date("1900-01-01")}
            id={searchForm?.invoiceDateTo?.name}
            name={searchForm?.invoiceDateTo?.name}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            size="small"
            isHijri={false}
            label={T(Resources.AppResources.INVOICE_DATE_TO)}
            handleChange={(value: any) => {
              handleChangeInput(searchForm?.invoiceDateTo.name, value);
            }}
            // handleChange={(value: any) => {
            //     setSearchForm({
            //         ...searchForm,
            //         invoiceDateTo: {
            //             ...searchForm.invoiceDateTo,
            //             value: value
            //         }
            //     })
            // }}
          />
        </Grid>
      </DefaultSearch>
      {isAdvancedSearch && (
        <AdvancedSearch
          searchHandler={searchHandler}
          resetHandler={resetForm}
          toggledvancedSearch={toggleAdvancedSearch}
        >
          <Grid item md={2.9} xs={12}>
            <TextInput
              id={searchForm.serialNumber.name}
              name={searchForm.serialNumber.name}
              label={T(Resources.AppResources.SERIAL_NUMBER)}
              value={searchForm.serialNumber.value}
              maxLength={20}
              handleChange={(e: any) => handleInputChange(e)}
            />
          </Grid>
          <Grid item md={2.9} xs={12}>
            <TextInput
              id={searchForm.sequenceNumber.name}
              name={searchForm.sequenceNumber.name}
              label={T(Resources.AppResources.SEQUENCE_NUMBER)}
              value={searchForm.sequenceNumber.value}
              maxLength={20}
              handleChange={(e: any) => handleInputChange(e)}
            />
          </Grid>
          <Grid item md={2.9} xs={12}>
            <CustomDropDown
              id={searchForm?.status?.name}
              name={searchForm?.status?.name}
              label={T(Resources.AppResources.STATUS)}
              value={searchForm?.status?.value}
              options={InvoiceStatuses}
              translate={true}
              showLabel={true}
              handleChange={(e) => handleInputChange(e)} />
          </Grid>
          {/* <Grid item md={2.9} xs={12}>
            <CustomDropDown
              id={searchForm?.clientVatNumber?.name}
              name={searchForm?.clientVatNumber?.name}
              label={T(Resources.AppResources.CLIENT_VAT_NUMBER)}
              value={searchForm?.clientVatNumber?.value}
              translate={true}
              options={TaxDDList}
              handleChange={(e: any) => handleInputChange(e)}
            />
          </Grid> */}
        </AdvancedSearch>
      )}
    </Card>
  );
};

export default Search;
