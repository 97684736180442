import DefaultSearch from '../../../Controls/Search/DefaultSearch';
import TextInput from '../../../Controls/TextInputs';
import { Grid } from '@mui/material';
import Card from '../../../Controls/Card';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import useSearchInvoicesBookHook from './hooks/useSearchInvoicesBookHook';

const Search = () => {
    const { T, Resources } = useTranslation();
    const { searchText, searchHandler, handleInputChange, resetForm } = useSearchInvoicesBookHook();

    const handleCPressEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler()
        }
    }

    return (
        <Card>
            <DefaultSearch
                searchHandler={searchHandler}
                resetHandler={resetForm}
                showAdvance={false}>
                <Grid item md={12} xs={12}>
                    <TextInput
                        id={'searchText'}
                        name={"searchText"}
                        label={T(Resources.AppResources.SEARCH)}
                        value={searchText}
                        maxLength={20}
                        onKeyPress={handleCPressEnter}
                        handleChange={(e: any) => handleInputChange(e.target.value)} />
                </Grid>
            </DefaultSearch>
        </Card>
    );
}

export default Search