import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class ConnectorDbService {

    public static InvalidResponseReports = async (request: any) => {
        try {
            var response = await WebServiceClient.post(APIConstants.CONNECTOR_DB.SEARCH, request, undefined, true, true);
            return response;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

    public static getInvoice = async (id, sourceId) => {
        try {
            const response = await WebServiceClient.get(`${APIConstants.CONNECTOR_DB.Get.replace("{Id}", id)}?sourceId=${sourceId}`,true,true);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

}
