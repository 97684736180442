import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class TenantsService {

    public static getConnectors = async (id) => {
        try {
            const response = await WebServiceClient.get(`${APIConstants.TENANT.CONNECTORS}/${id}`,true,false);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

}
