import Grid from '@mui/material/Grid';
import React, {  } from 'react';

import Card from '../../../Controls/Card';
import TableComp from '../../../Controls/Table';
import Button, { ButtonsTypes } from '../../../Controls/Button';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import Search from './search';
import { useNavigate } from 'react-router-dom'; 
import useloggerSearchHook from './hooks/useloggerSearchHook'; 
import { PermissionScopes, PermissionsService } from '../../../Services/permissions.service';

const LoggerManagement = () => {

  //#region :: State
  const { T, Resources } = useTranslation();
   const {useloggersHook, loggersTableData, columns, pagination } = useloggerSearchHook();
  //#endregion
  
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Search />
        </Grid>
        <Card> 
          {
            <TableComp
                      dataSource={loggersTableData}
                      tableColumns={columns}
                      changePageApi={useloggersHook.changeCurrentPage}
                      pagination={pagination} />  
             }
        </Card>  
      </Grid>
    </Grid>
  );
};
export default LoggerManagement;