import React from 'react'
import Card from '../../../Controls/Card'
import Profile from './Shared/profile'

const AddInvoicesBook = () => {
    return (
        <>
            <Card>
                <Profile />
            </Card>
        </>
    )
}

export default AddInvoicesBook