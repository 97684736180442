import React, { Fragment } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import LocalesResources from "./LocaleResources";

import Locales from './Locales';

//Provider:
const LocaleProvider = ({ children, locale = Locales.ENGLISH }: any) => (
    <IntlProvider
        locale={locale}
        textComponent={Fragment}
        messages={LocalesResources[locale]}
        onError={(error: any) => { }}>
        {children}
    </IntlProvider>
);
export default LocaleProvider;

//Translate:
export const Format = (id: any, value = {}) => (
    <FormattedMessage id={id} values={{ ...value }} />
);
