import { IDrawerSubItem } from '../Config/Settings/PagesRoute';
import { GlobalService } from './global.service';

export class PermissionsService {
  private static _user: any;
  private static activeFacility: any;

  private static get NonFacilityContext() {
    return [PermissionServices.PROVIDER, PermissionServices.FACILITIES];
  }

  public static get CurrentUser() {
    let result: any = null;
    if (this._user) {
      result = this._user;
    } else {
      this._user = GlobalService.AppUser;
      result = this._user;
    }

    return result;
  }

  public static hasAnyPermission(actions: Array<PermissionScopes>): boolean {
    // return true;
    for (let i = 0; i < actions.length; i++) {
      const element = actions[i];
      let hasPermission = this.hasPermission(element)
      if (hasPermission) return true
      continue;
    }

    return false
  }

  public static hasPermission(action: PermissionScopes): boolean {
    if (GlobalService.AppUser?.isSystemManager)
      return true;

    const permissions = GlobalService.AppUserAuthorizationInfo;
    if (permissions && permissions.length) {
      let isHavePermission = permissions.some(x => x.id == action)
      return isHavePermission
    }
    return false;
  }

  public static filterPerPermissions(menuList: Array<IDrawerSubItem>): Array<IDrawerSubItem> {
    let tempMenueList: Array<IDrawerSubItem> = [];

    for (const menuItem of menuList) {
      if (GlobalService.AppUser?.isSystemManager) {
        tempMenueList.push(menuItem);
        continue;
      }

      const permissionsList = menuItem.PERMISSIONS.split(',');
      const permissions = GlobalService.AppUserAuthorizationInfo;
      for (const permissionItem of permissionsList) {
        const action: PermissionScopes = PermissionScopes[permissionItem];
        if (permissions && permissions.length) {
          let isHavePermission = permissions.some(x => x.id == action)
          if (isHavePermission){
            tempMenueList.push(menuItem);
            break;
          }
        }
      }
    }
    return tempMenueList;
  }

  public static hasPermissions(Service: PermissionServices, Action: PermissionScopes): boolean {
    return true;
    // Warning don't return true here !!!!!!!!!!!!
    if (!this.CurrentUser) return false;
    const permissionsList: Array<any> = this.CurrentUser.roles;
    if (!Array.isArray(permissionsList)) return false;

    return false;
  }
}
//#endregion

export enum PermissionServices {
  PROVIDER = 'Provider',
  PATIENT = 'Patient',
  ELIGIBILITY = 'Eligibility',
  DICTIONARIES = 'Dictionaries',
  BENIFIT = 'benifit',
  POLICIES = 'policies',
  PLANS = 'plans',
  PAYERS = 'payers',
  PROVIDER_TPA = 'providerTPA',
  CONTRACTS = 'contracts',
  DICTIONARIES_ITEMS = 'dictionaries_item',
  DICTIONARIES_DIAGNOSIS = 'dictionaries_diagnosis',
  TERMINOLOGIES = 'terminologies',
  CODINGTYPE = 'codingtype',
  GS_TPA = 'GS_TPA',
  GS_HIC = 'GS_HIC',
  GS_HCP = 'GS_HCP',
  FACILITIES = 'facilities',
  PRACTITIONERS = 'practitioners',
  DEPARTMENT = 'department',
  SM_ITEMS = 'itemServiceManagment',
  SM_SERVICECATALOG = 'serviceCatalog',
  SM_PRICELISTS = 'priceLists',
  SM_MASTERPRICELIST = 'masterPriceList',
  SM_SERVICECATEGORY = 'serviceCategory',
  SM_SERVICEGROUP = 'serviceGroup',
  TASKS = 'tasks',
  BILLINGMANAGMENT = 'billingManagement',
  CLAIMMANAGEMENT = 'claimManagement',
  USERS_MANAGEMENT = 'usersManagement',
  ENCOUNTERS = 'encounters',
  AUTHORIZATION = 'authorization',
  PAYMENT = 'payments',
  ROLES_MAPPING = 'rolesMapping',
  TENANT_USERS_MANAGEMENT = 'tenantUsersManagement'

}

export enum PermissionScopes {

  SEND_INVOICES = 117,
  VIEW_INVOICES_LIST = 118,
  VIEW_INVOICES_DETAILS = 119,
  VIEW_INVOICE_QR_CODE = 120,
  DOWNLOAD_INVOICE = 121,
  SEARCH_FOR_AN_INVOICE = 122,


  VIEW_INVOICES_BOOKS_LIST_ORGANIZATION = 235,
  ADD_INVOICES_BOOK_ORGANIZATION = 236,
  EDIT_INVOICES_BOOK_ORGANIZATION = 237,
  VIEW_INVOICES_BOOK_DETAILS_ORGANIZATION = 238,
  CHANGE_INVOICES_BOOK_STATUS_ORGANIZATION = 239,
  SEARCH_INVOICES_BOOKS_ORGANIZATION = 240,

  VIEW_ONBOARDING__LIST = 313,
  ADD_ONBOARDING = 314,
  VIEW_ONBOARDING__DETAILS = 315,
  SEARCH_ONBOARDING = 316,

  VIEW_ORGANIZATIONS_LIST = 401,
  ADD_ORGANIZATION = 402,
  EDIT_ORGANIZATION = 403,
  VIEW_ORGANIZATION_DETAILS = 404,
  CHANGE_ORGANIZATION_STATUS = 405,
  SEARCH_ORGANIZATION = 406,

  VIEW_ROLES_LIST = 523,
  ADD_NEW_ROLE = 524,
  EDIT_ROLE = 525,
  SEARCH_ROLE = 526,
  SEARCH_ROLE_PERMISSIONS = 527,
  DELETE_ROLE = 528,

  VIEW_USERS_LIST_TENANT = 628,
  ADD_NEW_USER_TENANT = 629,
  EDIT_USER_TENANT = 630,
  VIEW_USER_DETAILS_TENANT = 631,
  CHANGE_USER_STATUS_TENANT = 632,
  SEARCH_USERS_TENANT = 633,
  EXPORS_USERS_TENANT = 634,
  VIEW_USERS_LIST_ORGANIZATION = 641,
  ADD_NEW_USER_ORGANIZATION = 642,
  EDIT_USER_ORGANIZATION = 643,
  VIEW_USER_DETAILS_ORGANIZATION = 644,
  CHANGE_USER_STATUS_ORGANIZATION = 645,
  SEARCH_USERS_ORGANIZATION = 646,
  EXPORS_USERS_ORGANIZATION = 647,
  ADD_ROLE_TO_USER_TENANT = 648,
  ADD_ROLE_TO_USER_ORGANIZATION = 649,

  SEARCH_LOGGER_LIST = 701, 
  VIEW_LOGGER_DETAILS = 702,
  EDIT_LOGGER = 703,


  CREATE = 'Create',
  STATUS_CHANGE = 'Deactivate',
  UPDATE = 'Update',
  LIST = 'List',
  VIEW = 'View'
}