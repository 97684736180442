import { regexMsgs, regexType } from '../../../../Config/Settings/Constants/Validations';
import { FieldModel } from '../../../../Models';

export class UserPasswordModel {
  id: string;
  password: string;
  confirmPassword: string;

  public static getFieldsModel(model?: UserPasswordModel): any {
    if (!model) model = new UserPasswordModel();
    let fildsObject = FieldModel.buildFromObject(model);
    fildsObject.password.required = true;
    fildsObject.password.validate = true;
    fildsObject.confirmPassword.required = true;
    fildsObject.confirmPassword.validate = true;
    return fildsObject;
  }
}

export class UserModel {
  practitionerId: string;
  username: string;
  name: string;
  email: string;
  mobileNumber: string;
  status: string;
  organizations: any;

  public static getFieldsModel(model?: UserModel): any {
    if (!model) model = new UserModel();
    let fildsObject = FieldModel.buildFromObject(model);
    // fildsObject.name.required = true;
    // fildsObject.name.validate = true;
    // fildsObject.username.validate = true;
    // fildsObject.username.required = true;
    // fildsObject.email.required = true;
    // fildsObject.email.validate = true;
    // fildsObject.email.regexValue = regexType.EMAIL;
    // fildsObject.email.errorMessages = { error: regexMsgs.INVALID_EMAIL };
    
    fildsObject.mobileNumber.required = true;
    fildsObject.mobileNumber.validate = true;
    fildsObject.mobileNumber.regexValue = regexType.RCM_PHONE_NUMBER_GLOBAL;
    fildsObject.mobileNumber.errorMessages = {
      error: regexMsgs.PHONE_NUMBER_ERROR, // TODO
    };

    // fildsObject.organization.required = true;
    // fildsObject.organization.validate = true;
    fildsObject.organizations.value = false;

    return fildsObject;
  }
  
  public static toDBModel(model: UserModel) {
    var result = FieldModel.getDBModel(model);
      
    return {
      mobileNumber: result?.mobileNumber,
      organizations: result?.organizations?.length ? result?.organizations?.map(x => x.id) : null,
    };
  }
}

export class PasswordModel {
  password: string;
  confirmPassword: string;
  public static getFieldsModel(model?: PasswordModel): any {
    if (!model) model = new PasswordModel();
    let fildsObject = FieldModel.buildFromObject(model);
    //@TODO update fileds to set reuqired falg based on busnees;
    fildsObject.password.required = true;
    fildsObject.password.validate = true;
    fildsObject.confirmPassword.required = true;
    fildsObject.confirmPassword.validate = true;
    return fildsObject;
  }
}


