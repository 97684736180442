import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class InvoicesBookService {

    public static SearchInvoicesBook = async () => {

        try {
            const response = await WebServiceClient.post(APIConstants.INVOICES_BOOK.SEARCH_INVOICES_BOOK, request);
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static AddInvoicesBook = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.INVOICES_BOOK.ADD_INVOICES_BOOK, request);
            return response;
        }
        catch (error: any) {
            WebServiceClient.handleError(error);
            WebServiceClient.getError(error);
            //console.log({ error });
        }
    }

    public static UpdateInvoicesBook = async (id: any, data: any) => {
        try {
            let object = {
                body: {
                    ...data
                }
            }
            const result = await WebServiceClient.put(APIConstants.INVOICES_BOOK.GET + id, object)
            return result;
        }
        catch (error: any) {
            throw error;
        }
    }

    public static GetInvoicesBookById = async (id: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.INVOICES_BOOK.GET + id);
            return response.data.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

    public static ActivateInvoiceBook = async (id: any, value) => {
        try {
            const response = await WebServiceClient.patch(APIConstants.INVOICES_BOOK.ACTIVATE.replace('{id}', id), {body: value});;
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static GetInvoicesBooksList = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.INVOICES_BOOK.LIST, {body: request});
            return response?.data?.body;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

}
