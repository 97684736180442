
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const logger = createSlice({
    name: 'logger',
    initialState: {
        logger: [],
        keys: [],
        loggerDetails: {}, 
        loggerList: [],     
        searchCriteria: {
            searchText: ''
        },
        isSearching: true,
        showError: false,
        error: null
    },
    reducers: {
        loggerReceived: (logger: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                logger.logger[item.id] = item;
                logger.keys = [...logger.keys, item.id]
            });

            logger.loggerDetails = null;
        },  
        setLoggersDetails: (logger: any, { payload }: PayloadAction<any>) => {
            const currentUser = payload.body;
            logger.loggerDetails = currentUser;
        },
        
        setLoggersList: (logger: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            logger.loggerLists = data;
        },

      
        setSearchCriteria: (logger: any, { payload }: PayloadAction<any>) => { 
            logger.searchCriteria = payload.body;
        },

        setError: (logger: any, { payload }: PayloadAction<any>) => {
            logger.error = payload.response;
            logger.showError = true;
        }
    }
});

export const { loggerReceived,   setLoggersList, setLoggersDetails, setSearchCriteria, setError } = logger.actions;
export default logger.reducer;

//#region :: Selectors
export const getLoggerData = (state: any) => {
    return state.logger.logger;
};

export const getLoggerDetails = (state: any) => {
    return state.logger.loggerDetails;
};

export const getLoggerLists = (state: any) => {
    return state.logger.loggerLists;
};

export const getSearchCriteria = (state: any) => {
    return state.logger.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.logger.showError;
};

export const getError = (state: any) => {
    return state.logger.error;
};

export const loggerSelectors = {
    getLoggerData,
    getSearchCriteria,
    getLoggerDetails,
    getLoggerLists,
    getShowError,
    getError,
};
//#endregion