import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { useNavigate } from 'react-router-dom';  
import {  PaginationModel } from '../../../../Models';
import CustomeIconButton, { IconButtonsTypes } from '../../../../Controls/IconButton';
import PagesRoute from '../../../../Config/Settings/PagesRoute'; 
import { PermissionScopes,  PermissionsService } from '../../../../Services/permissions.service';
import useLoggersSearch from '../../../../Hooks/useLoggersSearch'; 

const useLoggerSearchHook = () => {
  
    const { T, Resources } = useTranslation(); 
   
    const useloggersHook = useLoggersSearch();
    const [loggersTableData, setLoggersTableData] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<PaginationModel>(new PaginationModel());  
    const navigate = useNavigate();
   
  
    const columns = [
      { id: "messageId", label: T(Resources.AppResources.MESSAGE_ID), align: "center" },
      { id: "message", label: T(Resources.AppResources.MESSAGE), align: "center" },
      { id: "duration", label: T(Resources.AppResources.DURATION), align: "center" },
      ...(PermissionsService.hasPermission(PermissionScopes.VIEW_LOGGER_DETAILS) ?
      [{ id: "actions", label: T(Resources.AppResources.ACTIONS), align: "center" }] : [])
    ];
 
  
    const handleDelete = async (id: number) => {
        //TODO 
  }

  const mapData = (dataSource: any) => {
  
    dataSource && dataSource.logger && setLoggersTableData(dataSource?.logger.map((item: any) => {
      return {
        id: item.id,
        messageId: item.messageId,
        message: item?.message, 
        duration: item.duration,
        isInternal: item.duration,

        actions: (
          <div>
           
           {PermissionsService.hasPermission(PermissionScopes.VIEW_LOGGER_DETAILS) && <CustomeIconButton
            id="sync"
            aria-label={T(Resources.AppResources.VIEW_USER)}
            type={IconButtonsTypes.VIEW}
            onClick={() => navigate(PagesRoute.PAGES.VIEW_LOGGER+ item.id)} />
            }

              {/* {PermissionsService.hasPermission(PermissionScopes.EDIT_LOGGER) && <CustomeIconButton
                  id='delete'
                  aria-label={T(Resources.AppResources.DELETE)}
                  type={IconButtonsTypes.DELETE}
                  onClick={() => handleDelete(item.id)} />} */}
          </div>
      )


      }
    }));

    setPagination({
      pageIndex: dataSource.currentPage,
      pageSize: dataSource.pageSize,
      totalCount: dataSource.pagination.defaultContext && dataSource.pagination.defaultContext.totalCount,
      totalPages: dataSource.totalPages
    });
  };

 
  //#region :: [useEffect]
  useEffect(() => {
 
    if (!useloggersHook) return;
 
    mapData(useloggersHook);
  }, [useloggersHook.logger]);
  //#endregion
 
    return { useloggersHook,  loggersTableData, columns, pagination }
}

export default useLoggerSearchHook