import React from "react";
import { useNavigate } from "react-router";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router";
import { Box, LinearProgress, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Card from "../../Controls/Card";
import Accordion from "../../Controls/Accordion";
import PagesRoute from "../../Config/Settings/PagesRoute";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { getFieldValue } from "../../Utils/StringUtils";
import Button from "../../Controls/Button";
import { OnboardingModel } from "./Shared/onboarding.model";
import useViewOnboardingHook from "./hooks/useViewOnboardingHook";
import ModalComponent from "../../Controls/Modal/Modal";
import OtpDialog from "./Shared/otpDialog";
import {
  ONBOARDING_STAGE,
  OnboardingStatusModel,
} from "../../Models/system/onboarding-status.model";
import "./style.scss";
import CBadge from "../../Components/Badg/badge";

const ViewOnboarding = () => {
  const { T, Resources } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    onboardingDetail,
    onboardingsDetails,
    handelOnboardingProgress,
    dialog,
    handleOpenDialog,
    handleCloseDialog,
    formState,
    handleInputChange,
    createOnboarding,
    isRenew, 
    setIsRenew
  } = useViewOnboardingHook();

  console.log(onboardingDetail);
  
  //#region :: Data List
  const list: Array<any> = [
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.INVOICE_BOOK_DETAILS),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.INVOICE_BOOK_DETAILS)}</span>,
      details: (
        <Grid container paddingTop={1} xs={12}>
          <Grid container xs={12}>
            <Grid container xs={6} md={9}>
              <Grid container xs={12} md={12}>
                <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.NAME)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                      {getFieldValue(onboardingsDetails?.invoiceBookName)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.INVOICE_TYPE)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                      <Grid style={{ display: 'flex', justifyContent: 'flex-start', gap: '1%'}}>
                        {onboardingsDetails?.invoiceType?.split(',').map(x => {return(<CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={x}>{x}</CBadge>)})}
                    </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.ORGANIZATION)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                      {getFieldValue(onboardingsDetails?.organizationName)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} md={12}>
                <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.LANGUAGE)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                      {getFieldValue(
                        T(Resources.AppResources[onboardingsDetails?.language])
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.STATUS)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                      {getFieldValue(T(Resources.AppResources[onboardingsDetails?.invoicesBookStatus?.description]))}
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.PREFERRED_ADDRESS)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                      {getFieldValue(onboardingsDetails?.preferredAddress)}
                    </Grid>
                  </Grid>
                </Grid> */}

                <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.TAGS)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                        <Grid style={{ display: 'flex', justifyContent: 'flex-start', gap: '1%'}}>
                        {onboardingsDetails?.tags?.split(',').map(x => {return(<CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={x}>{x}</CBadge>)})}
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} md={3}>
              <Grid>
                <Grid className="title">
                  {T(Resources.AppResources.PROGRESS)}
                </Grid>
              </Grid>
              <Grid>
                <Grid className="sub-title">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={OnboardingModel.onboardingStatusPercent(
                          onboardingsDetails?.onboardingStatus?.code
                        )}
                      />
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${OnboardingModel.onboardingStatusPercent(
                      onboardingsDetails?.onboardingStatus?.code
                    )}%`}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.ADDRESS),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.ADDRESS)}</span>,
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          <Grid item xs={12}>
            <Grid paddingTop={1} container spacing={1}>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.ADDRESS_NAME)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.name)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.COUNTRY)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.countryCode)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.PROVINCE)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.province)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.CITY)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.city)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid paddingTop={1} container spacing={1}>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.DISTRICT)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.district)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.STREET_NAME)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.street)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.ADDITIONAL_STREET)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.additionalStreet)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.BUILDING_NUMBER)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.buildingNumber)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid paddingTop={1} container spacing={1}>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.POSTAL_CODE)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.postalCode)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.ADDITIONAL_NUMBER)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.additionalNumber)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.IS_DEFAULT)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(onboardingDetail?.preferredAddress?.isDefault? T(Resources.AppResources.PRIMARY) : T(Resources.AppResources.NOT_PRIMARY))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.ONBOARDING_PROGRESS),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.ONBOARDING_PROGRESS)}</span>,
      details: (
        <Grid container paddingTop={3} spacing={1} xs={12}>
          <Grid item xs={12}>
            <Grid className="container-box" container>
              <Grid position="relative" className="border-top-section" md={4}>
                <label className="style-label">
                  {T(Resources.AppResources.INVOICES_BOOK)}
                </label>
                <Grid className="icon-1">
                  {/* <CheckCircleIcon color="success" /> */}
                  {handelOnboardingProgress(
                    OnboardingStatusModel.getStageStatus(
                      onboardingDetail,
                      ONBOARDING_STAGE.CREATED
                    )
                  )}
                  <label>{T(Resources.AppResources.CREATED)}</label>
                </Grid>
              </Grid>
              <Grid className="border-all-section" md={8}>
                <Grid className="icon-2">
                  {/* <HourglassTopIcon color='warning' /> */}
                  {handelOnboardingProgress(
                    OnboardingStatusModel.getStageStatus(
                      onboardingDetail,
                      ONBOARDING_STAGE.ONBOARDING_PENDING
                    )
                  )}
                  <label>{T(Resources.AppResources.ONBORDING_PENDING)}</label>
                </Grid>
                <Grid className="icon-3">
                  {/* <DoNotDisturbOnIcon color='error' /> */}
                  {handelOnboardingProgress(
                    OnboardingStatusModel.getStageStatus(
                      onboardingDetail,
                      ONBOARDING_STAGE.READY
                    )
                  )}
                  <label>{T(Resources.AppResources.READY)}</label>
                  {/* {T(Resources.AppResources.INVOICES_BOOK)} */}
                </Grid>
                <Grid className="container-icon">
                  <Grid className="icon-4">
                    {/* <CheckCircleIcon color="success" /> */}
                    {/* {handelOnboardingProgress("3")} */}
                    {handelOnboardingProgress(
                      OnboardingStatusModel.getStageStatus(
                        onboardingDetail,
                        ONBOARDING_STAGE.NEW
                      )
                    )}
                    <label>{T(Resources.AppResources.NEW)}</label>
                  </Grid>
                  <Grid className="icon-5">
                    {/* <CheckCircleIcon color="success" /> */}
                    {handelOnboardingProgress(
                      OnboardingStatusModel.getStageStatus(
                        onboardingDetail,
                        ONBOARDING_STAGE.CSR_GENERATION
                      )
                    )}

                    <label>{T(Resources.AppResources.CSR_GENERATION)}</label>
                  </Grid>
                  <Grid className="icon-6">
                    {handelOnboardingProgress(
                      OnboardingStatusModel.getStageStatus(
                        onboardingDetail,
                        ONBOARDING_STAGE.COMPLIANCE_CSID
                      )
                    )}

                    <label>{T(Resources.AppResources.COMPLIANCE_CSID)}</label>
                  </Grid>
                  <Grid className="icon-7">
                    {/* <HighlightOffIcon color='error' /> */}
                    {handelOnboardingProgress(
                      OnboardingStatusModel.getStageStatus(
                        onboardingDetail,
                        ONBOARDING_STAGE.COMPLIANCE_CHECK
                      )
                    )}

                    <label>{T(Resources.AppResources.COMPLIANCE_CHECKS)}</label>
                  </Grid>
                  <Grid className="icon-8">
                    {/* <DoNotDisturbOnIcon color='error' /> */}
                    {handelOnboardingProgress(
                      OnboardingStatusModel.getStageStatus(
                        onboardingDetail,
                        ONBOARDING_STAGE.PRODUCTION_CSID
                      )
                    )}

                    <label>{T(Resources.AppResources.PRODUCTION_CSID)}</label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "35%",
                marginTop: "35px",
              }}
            >
              <Grid className="icon-label">
                <CheckCircleIcon color="success" />
                <label>{T(Resources.AppResources.COMPLETED)}</label>
              </Grid>
              <Grid className="icon-label">
                <HourglassTopIcon color="warning" />
                <label>{T(Resources.AppResources.PENDING)}</label>
              </Grid>
              <Grid className="icon-label">
                <HighlightOffIcon color="error" />
                <label>{T(Resources.AppResources.FAILED)}</label>
              </Grid>
              <Grid className="icon-label">
                <DoNotDisturbOnIcon color="error" />
                <label>{T(Resources.AppResources.NOT_STARTED)}</label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.ERROR_LIST),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.ERROR_LIST)}</span>,
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {onboardingDetail?.details?.Message}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
  ];
  //#endregion

  return (
    <Grid className="onboarding" container spacing={1}>
      <Card>
        <Grid item xs={12}>
          <Accordion content={list} />
        </Grid>

        <Grid item xs={12} marginTop={2}>
            
          <Button
            variant="outlined"
            color="primary"
            label={T(Resources.AppResources.BACK)}
            onClick={() => navigate(PagesRoute.PAGES.ONBOARDING)}
          />

            <Button
              marginLeft={10}
              marginRight={10}
              disabled={!onboardingsDetails?.canBeRenewed}
              variant="contained"
              color="warning"
              label={T(Resources.AppResources.RENEW)}
              onClick={() => {handleOpenDialog(); setIsRenew(true);}}
            />

          <Button
            marginLeft={10}
            marginRight={10}
            variant="contained"
            color="primary"
            label={T(Resources.AppResources.RETRY)}
            onClick={() => {handleOpenDialog(); setIsRenew(false);}}
          />

        </Grid>
      </Card>

      <Grid>
        <ModalComponent
          maxWidth="sm"
          handleClose={() => handleCloseDialog()}
          title={T(Resources.AppResources.OTP_VERIFICATION)}
          open={dialog}
        >
          <OtpDialog
            handleCloseDialog={handleCloseDialog}
            handleInputChange={handleInputChange}
            formState={formState}
            hadnleAddOnboard={() => createOnboarding(isRenew)}
          />
        </ModalComponent>
      </Grid>
    </Grid>
  );
};

export default ViewOnboarding;
