import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RadioButton = ({ options = undefined, id, name, label, checked, disabled = false, handleChange, checkColor, textWeight,
    groupName, formLabel, size, color, isGrouped = true, value,  }: any) => {

    return (
        <>
            {isGrouped ?
                options === undefined
                    ? <StyledRadioGroup flexDirection='column'
                        onChange={handleChange}
                        defaultValue={options == undefined ? '' : options.defaultValue}
                        name={groupName}>
                        <StyledRadio
                            checkColor={checkColor}
                            textweight={textWeight}
                            id={id}
                            name={name}
                            label={label}
                            color={color}
                            size={size}
                            checked={checked}
                            disabled={disabled}
                            onChange={handleChange}
                            control={<Radio size={size} color={color} />} />
                    </StyledRadioGroup>
                    : <FormControl>
                        <StyledFormLabel>{formLabel}</StyledFormLabel>
                        <StyledRadioGroup
                            defaultValue={options == undefined ? '' : options.defaultValue}
                            flexDirection={options.styles.flexDirection}
                            onChange={handleChange}
                            name={groupName}>
                            {options && options ?
                                options.list.map((option: any) => (
                                    <StyledRadio
                                        checkColor={options.styles.checkColor}
                                        textweight={options.styles.textWeight}
                                        key={option.key}
                                        id={option.id}
                                        name={option.name}
                                        label={option.label}
                                        value={option.value}
                                        checked={option.checked}
                                        disabled={option.disabled}
                                        onChange={option.handleChange}
                                        control={<Radio size={size} color={color} />} />
                                ))
                                : null}
                        </StyledRadioGroup>
                    </FormControl>
                :

                <Radio
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    size={size}
                    color={color}
                />
            }
        </>
    )
};

//#region :: Styled Component
const StyledFormLabel: any = styled(FormLabel)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3)
}));

const StyledRadio: any = styled(FormControlLabel)`
    & .MuiFormControlLabel-label{
        font-weight: ${({ textweight }: any) => textweight && textweight}
    }
    & .MuiRadio-colorPrimary.Mui-checked {
        color: ${({ checkColor }: any) => checkColor && checkColor};
    }
`;

const StyledRadioGroup: any = styled(RadioGroup)`
    flex-direction: ${({ flexDirection }: any) => flexDirection && `${flexDirection}!important`};
`;
//#endregion

//#region :: Validation PropTypes
RadioButton.propTypes = {
    id: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    groupName: propTypes.string,
    defaultValue: propTypes.string,
    formLabel: propTypes.string,
    fontSize: propTypes.string,
    size: propTypes.string,
    color: propTypes.string,
    checkColor: propTypes.string,
    textWeight: propTypes.string,
    checked: propTypes.bool,
    disabled: propTypes.bool,
    options: propTypes.array,
    handleChange: propTypes.func,
    isGrouped: propTypes.bool,
};
//#endregion

export default RadioButton;