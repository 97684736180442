import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Card from '../../../Controls/Card';
import DefaultSearch from '../../../Controls/Search/DefaultSearch';
import TextInput from '../../../Controls/TextInputs';
import useTranslation from '../../../Hooks/Shared/useTranslation'; 
import { LoggerManagementSearchModel } from './Shared/logger.model';

import { getSearchCriteria, setSearchCriteria } from '../../../Store/Reducers/LoggersManagement';

const Search = () => {
 
  
  const { T, Resources } = useTranslation(); 
  
  //#region :: State 
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const searchCriteria = getSearchCriteria(state);
  const [searchForm, setSearchForm] = useState(LoggerManagementSearchModel.getFieldsModel());
  //#endregion

  //#region :: Handlers
  const searchHandler = () => {
    const request = LoggerManagementSearchModel.toDBModel(searchForm);

    dispatch(setSearchCriteria({ body: request }));
  };

  const handleInputChange = (event: any) => {
    var { name, value } = event.target;
    handleInputChangeValue(name, value);
  };

  const handleInputChangeValue = (name: any, value: any) => {
    setSearchForm({
      ...searchForm,
      [name]: {
        ...searchForm[name],
        value: value
      }
    });
  };

  const resetForm = () => {
    setSearchForm(LoggerManagementSearchModel.getFieldsModel());
    dispatch(setSearchCriteria({ body: {} }))
  };

 
  const handleCPressEnter = (e) => {
      if (e.key === "Enter") {
          searchHandler()
      }
  }
  //#endregion

  //#region :: [useEffect]
  useEffect(() => {
    if (!searchCriteria) return;
    setSearchForm({
      ...searchForm,
    });
  }, [searchCriteria]);

  return (
    <Card>
      <DefaultSearch
        searchHandler={searchHandler}
        resetHandler={resetForm}
        showAdvance={false}>  
        <Grid item md={12} xs={12}>
        <TextInput
            id={searchForm?.searchText?.name}
            name={searchForm?.searchText?.name}
            label={T(Resources.AppResources.MESSAGE)}
            value={searchForm?.searchText?.value}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)} />
       </Grid>
      </DefaultSearch>
    </Card>
  );
};

export default Search;
 