import APIConstants from "../../../Config/Settings/API";
import { OrganizationModel } from "../../../Pages/SystemSetup/OrganizationManagement/Shared/organization.model";
// import { OrganizationModel } from "../../../Models/system/orgnization.model";
import { WebServiceClient } from "../WebServiceClient";

export class OrganizationService {

    public static SearchOrganization = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.ORGANIZATION.SEARCH_ORGANIZATION, {body:request}, true, false);
            return response;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
    public static listOrganization = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.ORGANIZATION.LIST, {body:request}, undefined, true, false);
            return response?.data?.body;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
    public static AddOrganization = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.ORGANIZATION.ADD_ORGANIZATION , request, true, false);;
            return response;
        }
        catch (error: any) {
            WebServiceClient.handleError(error);
            WebServiceClient.getError(error);
        }
    }
    public static UpdateOrganization = async (id: any, data: OrganizationModel) => {
        try {
            let object = {
                body: {
                    ...data
                }
            }
            const result = await WebServiceClient.put(APIConstants.ORGANIZATION.PUT + id, object, true, false);
            return result;
        }
        catch (error: any) {
            throw error;
        }
    }

    public static GetOrganizationsById = async (id: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.ORGANIZATION.GET_BY_ID + id, true, false);
            return response.data.body
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };

    public static ActivateOrganization = async (id: any, value) => {
        try {
            const response = await WebServiceClient.patch(APIConstants.ORGANIZATION.ACTIVATE.replace('{id}', id), {body: value}, true, false);;
            return true
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
}
