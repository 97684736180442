import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface CostomReactCheckBoxProps {
  single?: boolean;
  options?: any;
  id?: any;
  name?: any;
  label?: any;
  value?: any;
  checked?: boolean;
  disabled?: boolean;
  handleChange?: any;
  inputProps?: any;
  groupName?: any;
  indeterminate?: any;
  size?: any;
  fontSize?: any;
  color?: any;
  outLinecolor?: any;
}

const CostomReactCheckBox = ({
  single = false,
  options = undefined,
  id,
  name,
  label = "",
  value,
  checked,
  disabled = false,
  handleChange = undefined,
  inputProps,
  groupName,
  indeterminate,
  size = "small",
  fontSize = "small",
  color = "primary",
  outLinecolor,
}: CostomReactCheckBoxProps) => {



  //#region :: useEffect
  useEffect(() => {
    // debugger
    console.log(checked);
  }, [checked]);
  //#endregion

  return (
    <CheckBoxContainer>
      <CheckBox
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={handleChange}
      />
      {checked ? (
        <svg
          style={{ maxWidth: 20, fill: "#1e76b5" }}
          className="MuiSvgIcon-root MuiSvgIcon-colorPrimary MuiSvgIcon-fontSizeSmall muiltr-mwci7l-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="CheckBoxIcon"
        >
          <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        </svg>
      ) : (
        <svg
          style={{ maxWidth: 20 }}
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall muiltr-ptiqhd-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="CheckBoxOutlineBlankIcon"
        >
          <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
        </svg>
      )}
    </CheckBoxContainer>
  );
};

//#region :: Styled Components


const CheckBoxContainer: any = styled.div`
    position: relative;
    width: 43px;
`;

const CheckBox: any = styled.input`
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 1;
`;

//#endregion

//#region :: Validation PropTypes
CostomReactCheckBox.propTypes = {
  id: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  value: propTypes.string,
  fontSize: propTypes.string,
  size: propTypes.string,
  color: propTypes.string,
  outLinecolor: propTypes.string,
  groupName: propTypes.string,
  single: propTypes.bool,
  checked: propTypes.bool,
  disabled: propTypes.bool,
  indeterminate: propTypes.bool,
  options: propTypes.array,
  inputProps: propTypes.object,
  handleChange: propTypes.func,
};
//#endregion

export default CostomReactCheckBox;
