import styled from 'styled-components';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

const StyledDialogTitle: any = styled(MuiDialogTitle)`
    &.MuiDialogTitle-root{
        margin: 0;
        padding: 16px 16px 0px 16px;
    }
`;

const StyledDialogTitleClose: any = styled(IconButton)`
    &.MuiButtonBase-root {
        position: absolute;
        left: ${({ theme }) => theme && theme.spacing(1)};
        top: ${({ theme }) => theme && theme.spacing(1)};
        color: ${({ theme }) => theme && theme.palette.grey[500]};
    }
`;

const StyledDialogContent: any = styled(MuiDialogContent)`
    &.MuiDialogContent-root {
        padding: 8px;
        font-size: 15px;
    }
`;

const StyledDialogChildren: any = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    font-weight: bold;
    white-space: pre-line;
`;

const DialogChildren: any = styled.div`
    padding-right: 10px;
    padding-left: 10px;
    font-weight: bold;
`;

const StyledDialogActions: any = styled(MuiDialogActions)`
    &.MuiDialogActions-root {
        margin: 0;
        padding: ${({ theme }: any) => theme && theme.spacing(1)};
    }
    margin-right: 10;
`;

export { StyledDialogTitle, StyledDialogTitleClose, StyledDialogContent, StyledDialogActions, StyledDialogChildren, DialogChildren };