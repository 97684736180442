import React, { useState } from 'react'
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingModel } from '../Shared/onboarding.model';
import { ValidationService } from '../../../Utils/Validation.services';
import { ValidationModel } from '../../../Models';
import { changeFullLoading } from '../../../Store/Reducers/UI';
import { OnboardingService } from '../../../Services/API/Onboarding';
import { useParams } from 'react-router';
import { getOnboardingLists } from '../../../Store/Reducers/Onboarding';
import { DIALOG_TYPE, MESSAGE_TYPE, MessagesService } from '../../../Services/messages.service';
import { useNavigate } from 'react-router-dom';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import { OnboardingStatusCode } from '../../../Config/Settings/Enums';
import { InvoicesBookService } from '../../../Services/API/InvoicesBook';

const useAddOnboardingHook = () => {
    const { T, Resources } = useTranslation();
    const { Id } = useParams();
    const state = useSelector((state: any) => state);
    const onboardingLists = getOnboardingLists(state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formState, setFormState] = useState(OnboardingModel.getFieldsModel());

    //#region :: Handlers
    const handleInputChange = (event: any) => {
        var { name, value } = event.target;
        handleSetInputChange(name, value)

    };
    const handleSetInputChange = (name: any, value: any) => {
        setFormState({
            ...formState,
            [name]: {
                ...formState[name],
                value: value
            },
        });
    };

    const createOnboarding = async () => {

        let valid = ValidationService.Valid(formState);
        let validationResponse = ValidationModel.setValidations(valid, formState)
        setFormState(validationResponse)

        if (valid.isValidForm) {
            dispatch(changeFullLoading({ spin: true }));
            // let onboarding: Array<any> = [...onboardingLists];
            let request = OnboardingModel.addBoardingDBModel(formState);
            let response;
            try {
                response = await OnboardingService.AddOnboarding(validationResponse.invoiceBook.value.id, { body: request });

                MessagesService.Toast(T(Resources.AppResources.CREATED_SUCCESS), MESSAGE_TYPE.Success);
                navigate(PagesRoute.PAGES.VIEW_ONBOARDING + validationResponse.invoiceBook.value.id)
            }
            catch (error: any) {
            }
            finally {
                dispatch(changeFullLoading({ spin: false }));
            }
        }
        else {
            // MessagesService.Toast(T(Resources.AppResources.PLEASE_FILL_THE), MESSAGE_TYPE.Error);
            //console.log("plesae fill")
        }
    };


    const getInvoicesBooksDataSource = async (searchText?: string) => {
        try {
            const request = {
                searchText,
                pageIndex: 0,
                pageSize: 10
            }
            const response = await InvoicesBookService.GetInvoicesBooksList(request);

            let items: Array<any> = [];
            if (response?.length > 0) {
                response?.map((item: any) => {
                    const obj = {
                        id: item?.id,
                        label: item?.value,
                    };
                    items.push(obj);
                });
            }
            return items;

        } catch (error) {
        }
    };
    return {
        formState, handleSetInputChange, handleInputChange, createOnboarding, getInvoicesBooksDataSource
    }
}

export default useAddOnboardingHook