import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CardList from '../../../Components/CardList'
import useOrganizationListHook from './hooks/useOrganizationListHook'
import Card from '../../../Controls/Card';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import Button, { ButtonsTypes } from '../../../Controls/Button';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import Search from './search';
import { PermissionScopes, PermissionsService } from '../../../Services/permissions.service';

const OrganizationManagement = () => {
  const navigate = useNavigate();
  const { T, Resources } = useTranslation();
  const { cardLabels, organizationSource, pagination, changeCurrentPage } = useOrganizationListHook();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Search />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardList
              ListData={organizationSource}
              labels={cardLabels}
              pagination={pagination}
              changePageApi={changeCurrentPage} 
            />

          </Card>
        </Grid>

        {PermissionsService.hasPermission(PermissionScopes.ADD_ORGANIZATION) && <Button
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => navigate(PagesRoute.PAGES.ADD_ORGANIZATION)}
          // buttonsAction={ButtonActions}
          type={ButtonsTypes.FLOATIONG} />}
      </Grid>
    </>
  )
}

export default OrganizationManagement