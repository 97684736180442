import React, {  } from "react";
import Grid from "@mui/material/Grid";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import TextInput from "../../../Controls/TextInputs";
import MobilePhone from "../../../Controls/MobilePhone";
import Button from "../../../Controls/Button";
import PagesRoute from "../../../Config/Settings/PagesRoute";
import { useNavigate } from "react-router-dom";
import AutoCompleteSearchable from "../../../Components/AutoCompleteSearchable";
import useEditUserHook from "../hooks/useEditUserHook";

const Profile = () => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const { DataSource, addForm, handleInputChange, handlerSetData, saveDataHandler, getOrganizationDataSource } = useEditUserHook();

  const navigate = useNavigate();
  //#endregion


  return (
    <Grid item className="custom-accordion" container spacing={1}>
      <Grid spacing={1} container xs={12}>
        <Grid item md={3} sm={6} xs={12}>
          <TextInput
            id={addForm.name.name}
            name={addForm.name.name}
            label={T(Resources.AppResources.ARABIC_NAME)}
            value={addForm.name.value}
            required={addForm.name.required}
            helperText={addForm.name.message}
            error={!addForm.name.valid}
            maxLength={100}
            isDisabled
            handleChange={(e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextInput
            id={addForm.username.name}
            name={addForm.username.name}
            label={T(Resources.AppResources.USERNAME)}
            value={addForm.username.value}
            required={addForm.username.required}
            helperText={addForm.username.message}
            error={!addForm.username.valid}
            isDisabled
            handleChange={(e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextInput
            id={addForm.email.name}
            name={addForm.email.name}
            label={T(Resources.AppResources.EMAIL)}
            value={addForm.email.value}
            required={addForm.email.required}
            helperText={addForm.email.message}
            error={!addForm.email.valid}
            isDisabled
            handleChange={(e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextInput
            id={addForm.status.name}
            name={addForm.status.name}
            label={T(Resources.AppResources.STATUS)}
            value={addForm.status.value}
            required={addForm.status.required}
            helperText={addForm.status.message}
            error={!addForm.status.valid}
            isDisabled
            handleChange={(e) => handleInputChange(e)}
          />
        </Grid>
      </Grid>
      <Grid spacing={1} container xs={12} paddingTop={2}>
        <Grid item md={3} sm={6} xs={12}>
          <MobilePhone
            positionFixed={true}
            label={T(Resources.AppResources.MOBILE_NUMBER)}
            value={addForm.mobileNumber.value}
            handleOnChange={(e) => handlerSetData(addForm.mobileNumber.name, e)}
            valid={addForm.mobileNumber.valid}
          />
        </Grid>
        {/* <Grid item md={3} sm={6} xs={12}>
          <AutoCompleteSearchable
            id={addForm?.organizations?.name}
            name={addForm?.organizations?.name}
            selected={addForm?.organizations?.value}
            label={T(Resources.AppResources.ORGANIZATION)}
            handleChange={handlerSetData}
            error={!addForm.organizations.valid}
            showLabel
            getDataSource={getOrganizationDataSource}
            multiple
            filterSelectedOptions
          />
        </Grid> */}
        <Grid item xs={12} marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            label={T(Resources.AppResources.SAVE)}
            onClick={() => saveDataHandler()}
          />
          <Button
            marginLeft={10}
            marginRight={10}
            variant="outlined"
            color="primary"
            label={T(Resources.AppResources.CANCEL)}
            onClick={() => navigate(PagesRoute.PAGES.ORGUSERSMANAGEMENT)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
