import { useEffect, useState } from "react";
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { useParams } from "react-router-dom";
import { LoggerService } from "../../../../Services/API/Logger";
import {  PaginationModel } from '../../../../Models';
  
const useViewloggerHook = () => {
  const { id } = useParams();
  const [dataSource, setDataSource] = useState<any>();    
  const { T, Resources } = useTranslation(); 
   
  //#endregion

  //#region :: Initial [useEffect]
  const getDataSource = async () => {
      try { 
          let response = await LoggerService.GetById(id);
          setDataSource(response);   
      } catch (error) {

      }
  }

  useEffect(() => { 
      getDataSource()
  }, [id]);

 
  return {
    dataSource 
  };
};

export default useViewloggerHook;
