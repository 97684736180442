import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button, { ButtonsTypes } from '../Button';
import { theme as MainThemeVariables } from '../../Config/Theme/ThemeVariables';

export interface DefaultSearchProps {
    children?: any;
    toggledvancedSearch?: any;
    isAdvancedSearch?: any;
    showAdvance?: any;
    searchHandler?: any;
    resetHandler?: any;
    style?: any;
    md?: any;
    onKeyPress?: any;
};

const DefaultSearch = ({ children, toggledvancedSearch, isAdvancedSearch = false, showAdvance = true, searchHandler,
    resetHandler, style, md = 1, onKeyPress }: DefaultSearchProps) => {

    const screenSize = useMediaQuery((theme: any) => theme.breakpoints.up('MobileScreen'));

    const keyPressHandler = (e: any) => {
        if (e.key.toLowerCase() === "enter") {
            searchHandler && searchHandler(e);
        }
    };

    return (
        <Grid container>
            <StyledGrid container spacing={1} item md={isAdvancedSearch && isAdvancedSearch ? 12 : 11} isadvancedsearch={+isAdvancedSearch}>
                {children}
            </StyledGrid>
            {!isAdvancedSearch &&
                <StyledGridCardActions
                    item
                    md={md}
                    xs={12}
                    display='flex'
                    marginBottom='3px'
                    justifyContent={screenSize ? 'center' : 'end'}
                    paddingTop={screenSize ? null : '0.5rem'}
                    paddingRight={screenSize ? null : '1rem'}
                    onKeyPress={keyPressHandler}
                >
                    <StyledCardActions style={style}>
                        <Button
                            bgColor={MainThemeVariables['green-color']}
                            customeColor={MainThemeVariables['white-color']}
                            variant='contained'
                            size='medium'
                            type={ButtonsTypes.SEARCH}
                            onClick={() => searchHandler !== undefined ? searchHandler() : null}
                            width={35} />
                        <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            type={ButtonsTypes.RESET}
                            onClick={() => resetHandler !== undefined ? resetHandler() : null} />
                        {showAdvance && <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            type={ButtonsTypes.ADD}
                            onClick={() => toggledvancedSearch !== undefined ? toggledvancedSearch() : null} />}
                    </StyledCardActions>
                </StyledGridCardActions>}
        </Grid>
    )
};

//#region :: Styled Components
const StyledGrid: any = styled(Grid)`
    padding: 0 !important;
    margin: 0;
    padding-left : ${({ isadvancedsearch }: any) => isadvancedsearch && isadvancedsearch ? '0px' : '15px!important'};
`;

const StyledCardActions: any = styled(CardActions)`
    padding: 0 !important;
    & .MuiButton-containedPrimary{
        width: 30px !important;
        min-width: auto !important;
        margin: 0;
    }
    & .MuiButton-startIcon{
        margin: 0 !important;
    }
`;

const StyledGridCardActions: any = styled(Grid)`
   display: ${({ display }: any) => display};
   background: ${({ background }: any) => background};
   justify-content: ${({ justifyContent }: any) => justifyContent};
   margin-bottom:  ${({ marginBottom }: any) => marginBottom};
   padding-top : ${({ paddingTop }: any) => paddingTop};
   padding-right: ${({ paddingRight }: any) => paddingRight}; 
`;
//#endregion

//#region :: Validation PropTypes
DefaultSearch.propTypes = {
    toggledvancedSearch: propTypes.func,
    isAdvancedSearch: propTypes.bool,
    children: propTypes.any
};
//#endregion

export default DefaultSearch;