import React, { useContext, useEffect, useState } from 'react';

import FallBack from '../Components/Fallbacks/ComponentFallBack/FallBack';
import TenentNotAvailable from '../Components/TenentNotAvailable';
import { GlobalService } from '../Services/global.service';
import config from './multitenantConfig';
import { getTenant } from './tenantResolver';
import { getCookie } from '../Utils/Cookies';
import CookiesConstants from '../Config/Settings/Cookies';
import { OrganizationService } from '../Services/API/OrganizationManagement';
import { TenantUsersManagementService } from '../Services/API/TenantUsersManagement';

export const TenantContext = React.createContext<any | undefined>(undefined);
export const useTenant = () => useContext(TenantContext);

export const TenantProvider = ({ children }: any) => {

    const [currentTenant, setCurrentTenant]: any = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const isLoggedIn = getCookie(CookiesConstants.AUTH.IS_LOGGED_IN);

    const loadTenant = async () => {
        // 
        setIsLoading(true);
        const current_organization = GlobalService.CurrentActiveOrganization;

        try {
            const tenant = await getTenant();
            GlobalService.TenantUrl = `${config.BaseUrl}/${tenant?.id}`;
            GlobalService.TenantServices = tenant;

            //TODO this line to get all Orgs for the current tenant using the last selected env-mode, we need to move this function to a different place to be confirmed with the first SOLID principle "single responsibility"
            if (isLoggedIn && GlobalService.AppUser?.id && (!(current_organization && Object.keys(current_organization).length) || GlobalService.RefetchOrgs)) {
                const request = {
                    pageIndex: 0,
                    pageSize: 100,
                    searchText: null,
                };
                
                const result = await TenantUsersManagementService.SearchOrganization(request, GlobalService.AppUser?.id);
                const response = result?.data.body;
                let tmpOrg = response?.length > 0 ? response[0] : null;

                if(current_organization && current_organization?.id && response.length ){
                    let selectedOrg = response.find(org => org.id == current_organization.id);

                    if(selectedOrg)
                        tmpOrg = selectedOrg;
                }

                GlobalService.OrgsList = response;
                GlobalService.RefetchOrgs = false;
              
                if (!!tmpOrg)
                    GlobalService.CurrentActiveOrganization = {
                        id: tmpOrg?.id,
                        name: tmpOrg?.value,
                    };

            }
            setCurrentTenant(tenant);
        }
        catch (error) {
            setCurrentTenant(null);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadTenant();
    }, []);

    const getEndPoint = (serviceName: string, actionName: string, params: any = null) => {
        if (currentTenant == null) return "";

        var service = currentTenant.services.find((x: any) => x.api.toLowerCase() === serviceName.toLowerCase());
        var action = service.endpoints.find((x: any) => x.action.toLowerCase() === actionName.toLowerCase());

        var url = `${config.BaseUrl}/${action.route}`;
        if (params)
            params.forEach((param: any) => {
                url = url.replace(`{${param.key}}`, param.value);
            });
        return { ...action, url };
    };

    return (
        <>
            <FallBack isLoading={isLoading} />
            {!isLoading && !currentTenant && <TenentNotAvailable />}
            {
                currentTenant && <TenantContext.Provider value={{ currentTenant, getEndPoint }}>
                    {children}
                </TenantContext.Provider>
            }
        </>
    );
};


