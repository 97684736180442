import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Card from '../../Controls/Card'
import Accordion from '../../Controls/Accordion'
import PagesRoute from '../../Config/Settings/PagesRoute'
import useTranslation from '../../Hooks/Shared/useTranslation'
import { useNavigate, useParams } from 'react-router'
import { getFieldValue } from '../../Utils/StringUtils';
import Button from '../../Controls/Button';
import "./style.scss"
import { ReportsService } from '../../Services/API/Reports';
import { getReportsDetails, setReportsDetails } from '../../Store/Reducers/Reports';
import { useDispatch, useSelector } from 'react-redux';
import { changeFullLoading } from '../../Store/Reducers/UI';

const ViewReports = () => {
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const reportsDetails = getReportsDetails(state);
    const { id } = useParams();
    const navigate = useNavigate();

    const getDataSource = async (reportId: any) => {
        try {
            const response = await ReportsService.GetReportsById(reportId);
            if (response)
                dispatch(setReportsDetails({ body: response }));
        }
        catch (error: any) {
            console.error(error);
        }
        finally {
            dispatch(changeFullLoading({ spin: false }));
        }
    };

    useEffect(() => {
        dispatch(changeFullLoading({ spin: true }));

        if (id !== undefined) {
            getDataSource(id);
        }
        else {
            dispatch(changeFullLoading({ spin: false }));
        }
    }, []);


    useEffect(() => () => {
        dispatch(setReportsDetails({ body: null }));
    }, []);
    //#endregion

    //#region :: Data List
    const list: Array<any> = [
        {
            id: 'panel1a-header',
            ariaControls: 'p1-content',
            title: T(Resources.AppResources.INVOICE_BOOK_DETAILS),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.INVOICE_BOOK_DETAILS)}</span>),
            details: (
                <Grid container paddingTop={1} spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.INVOICE_NUMBER)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.invoiceNumber)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.INVOICE_TYPE)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.invoiceType)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.ISSUE_DATE)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.issueDate)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.ORGANIZATION)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.organization)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.INVOICES_BOOK)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.invoicesBook)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.CURRENCY)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.currency)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.AMOUNT)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.amount)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.DISCOUNT)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.discount)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.TAX)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.tax)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.TOTAL_AMOUNT)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(reportsDetails?.totalAmount)}</Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid >
                </Grid>
            )
        },

    ];
    //#endregion


    return (
        <Grid className='onboarding' container spacing={1}>
            <Card>
                <Grid item xs={12}>
                    <Accordion content={list} />
                </Grid>

                <Grid item xs={12} marginTop={2}>
                    <Button
                        variant='outlined'
                        color='primary'
                        label={T(Resources.AppResources.BACK)}
                        onClick={() => navigate(PagesRoute.PAGES.REPORTS)} />
                </Grid>
            </Card>
        </Grid>
    )
}

export default ViewReports