import { Grid, IconButton, InputBase, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '../../../../Controls/Modal/Modal';
import Button, { ButtonsTypes } from '../../../../Controls/Button';
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { changeFullLoading, getUILanguage } from '../../../../Store/Reducers/UI';
import { GridSearchIcon } from '@mui/x-data-grid';
import { PaginationModel } from '../../../../Models';
import { clearRulePageList, getRuleList, getRulePageList, setRuleList, setRulePageList } from '../../../../Store/Reducers/UsersManagements';
import { TenantUsersManagementService } from '../../../../Services/API/TenantUsersManagement';
import './style.scss';
import { EnvLevelContext } from '../../../../Config/Settings/Enums';
import { RolesMappingService } from '../../../../Services/API/RolesMapping';
import Locales from '../../../../Services/Internationalization/Locales';
import Checkbox from '../../../../Controls/Checkbox';
import TableComp from '../../../../Controls/Table';
import { GlobalService } from '../../../../Services/global.service';


const OrgRulesDialog = ({ userId, open, handleClose }: any) => {
    //#region :: State
    const dispatch = useDispatch();
    const { T, Resources } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(open);
    const [dialogList, setDialogList] = useState<Array<any>>([]);
    const state = useSelector((state) => state);
    const roleSelectedList = getRuleList(state);
    const RuleList = getRulePageList(state);
    const curentLang = getUILanguage(state);
    const [mainList, setMainList] = useState<Array<any>>();
    const [userRolseList, setUserRolseList] = useState<Array<any>>([]);
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState<PaginationModel>(
      new PaginationModel()
    );
    const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
    const organization = GlobalService.CurrentActiveOrganization;
    const columns = [
      { id: "action", label: "#", align: "center", width: "13%" },
      {
        id: "id",
        label: T(Resources.AppResources.ID),
        align: "center",
        width: "30%",
      },
      { id: "name", label: T(Resources.AppResources.NAME), align: "left" },
    ];
    //#endregion
    const handleChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      fId: any
    ) => {
      try {
          dispatch(changeFullLoading({ spin: true }));
          const val = event.target.checked;
          let localList: Array<any> = [...roleSelectedList];
          if (val) {
          //send the Add request here
          await TenantUsersManagementService.addRole(userId, fId, [organization?.id]);
          } else {
          //send the Remove request here
          await TenantUsersManagementService.removeRole(userId, fId);
          }
          if (val) {
          localList.push(fId);
          dispatch(setRuleList({ body: localList }));
          } else {
          localList = localList.filter((id) => id != fId);
          dispatch(setRuleList({ body: localList }));
          }
      } catch (error) {
          
      } finally{
        dispatch(changeFullLoading({ spin: false }));
      }
    };
    const changePageApi = async (pageIndex, search = false) => {
      try {
        dispatch(changeFullLoading({ spin: true }));
  
        let response: Array<any> = [];
        try {
          if (!search)
            response = RuleList["page" + pageIndex]
              ? RuleList["page" + pageIndex]
              : [];
        } catch {
          response = [];
        }
        if (response.length < 1) {
          response = await getRuleSearch(pageIndex);
          
          dispatch(setRulePageList({ pageIndex: pageIndex, ruleList: response }));
        } else {
          let tempPagObj = {
            pageSize: pagination?.pageSize || 10,
            totalCount: pagination.totalCount,
            totalPages: pagination.totalPages,
            pageIndex: pageIndex,
          };
          setPagination(tempPagObj);
        }
        mapedRule(response);
        setMainList(response);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    };

    const getRuleSearch = async (pageIndex) => {
      const requestModel = {
        name: searchText,
        pageIndex: pageIndex - 1,
        pageSize: pagination.pageSize,
        level: EnvLevelContext.ORGANIZATION
      };
      const response = await RolesMappingService.roleSearch(requestModel);
      let tempPagObj = {
        pageSize: pagination?.pageSize || 10,
        totalCount: response.totalCount,
        totalPages: response.totalPages,
        pageIndex: pageIndex,
      };
      setPagination(tempPagObj);
      return response.body;
    };

    const mapedRule = async (ruleList) => {
      try {
        dispatch(changeFullLoading({ spin: true }));
        let localList: Array<any> = [];
        // debugger
        ruleList?.map((row: any, index: number) => {
          let isChecked = roleSelectedList?.includes(+row?.id);
          const obj = {
            action: (
            //   <Checkbox
            //     name={row.englishName}
            //     checked={isChecked}
            //     onChange={(e: any) => handleChange(e, row.id)}
            //     inputProps={{ "aria-label": "controlled" }}
            //     disabled={
            //       userRolseList && userRolseList.some((rol) => rol.id == row.id)
            //         ? userRolseList.find((rol) => rol.id == row.id)
            //             .universalOrgAccess
            //           ? true
            //           : false
            //         : false
            //     }
            //   />
                <Checkbox
                    // className="on-set-check"
                    id={`check_${row?.id}`}
                    name={`check_${row?.id}`}
                    single
                    checked={isChecked}
                    handleChange={async (e: any) => {
                        e.stopPropagation();
                        // await handleRole(e.target.checked, row?.id);
                        handleChange(e, row.id)
                    }}
                    disabled={
                        userRolseList && userRolseList.some((rol) => rol.id == row.id)
                        ? userRolseList.find((rol) => rol.id == row.id)
                            .universalOrgAccess
                            ? true
                            : false
                        : false
                    }
                />
            ),
            id: ++index,
            name:
              curentLang == Locales.ARABIC ? row?.arabicName : row?.englishName,
          };
          localList.push(obj);
        });
        setDialogList(localList);
      } catch (error) {
      } finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    };
    const getUserbyId = async (Id: any) => {
      try {
        dispatch(changeFullLoading({ spin: true }));
        const userRolesResponse: any = await TenantUsersManagementService.getUserRoles(Id);
        if (Array.isArray(userRolesResponse) && userRolesResponse.length)
          dispatch(
            setRuleList({ body: userRolesResponse?.map((rol) => rol.id) })
          );
        setUserRolseList(userRolesResponse);
      } catch (error) {
      } finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    };
  
    const handlerCancelDialog = () => {
      setShowCancelDialog(!showCancelDialog);
    };
    const handlerCancelConframe = () => {
      handlerCancelDialog();
      handleClose();
    };
    const handleSearch = () => {
      dispatch(clearRulePageList(null));
      changePageApi(1, true);
    };
    
    //#region :: showDialog Changed [useEffect]
  
    useEffect(() => {
      mapedRule(mainList);
    }, [mainList, userRolseList, roleSelectedList]);
  
    useEffect(() => {
      if (pagination.pageIndex > 0) changePageApi(pagination.pageIndex);
    }, [roleSelectedList]);
  
    useEffect(() => {
      if (userId) getUserbyId(userId);
      changePageApi(1);
      setShowModal(open);
    }, [open]);
  
    useEffect(() => {
      return () => {
        dispatch(clearRulePageList(null));
      };
    }, []);
    //#endregion
  
    return (
      <ModalComponent
        title={Resources.AppResources.ROLES}
        open={showModal}
        loading={false}
        divider={false}
      >
        <Grid
          container
          style={{ justifyContent: "center", display: "flex" }}
          spacing={1}
        >
          <Grid item xs={11} style={{ justifyContent: "end", display: "flex" }}>
            <Paper
              component="form"
              sx={{ display: "flex", alignItems: "center" }}
              className={"search-group"}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={T(Resources.AppResources.SEARCH)}
                value={searchText}
                size="small"
                onChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.which === 13) {
                    handleSearch();
                    e.preventDefault();
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => handleSearch()}
              >
                <GridSearchIcon style={{ color: "#1e76b5", fontSize: "18px" }} />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={11}>
            <TableComp
              dataSource={dialogList}
              tableColumns={columns}
              pagination={pagination}
              changePageApi={changePageApi}
            />
          </Grid>
        </Grid>
        <Grid className="ResponseButton" marginTop={2} item xs={12}>
  
          <Button
            marginLeft={5}
            marginRight={5}
            variant="outlined"
            color="primary"
            type={ButtonsTypes.CANCEL}
            label={T(Resources.AppResources.CANCEL)}
            onClick={() => handlerCancelConframe()}
          />
        </Grid>
        {/* <ConfirmationDialog
          showDialog={showCancelDialog}
          handleConfirm={handlerCancelConframe}
          handleClose={handlerCancelDialog}
        /> */}
      </ModalComponent>
    );
  };
  export default OrgRulesDialog;
  