import { FieldModel } from '../../../../Models';

export class LoggerManagementSearchModel {
  searchText?: any;


  public static getFieldsModel(model?: LoggerManagementSearchModel): any {
    model = new LoggerManagementSearchModel();
    let fildsObject = FieldModel.buildFromObject(model);
    fildsObject.searchText.value = "";
    return fildsObject;
  }

  public static toDBModel(searchForm: LoggerManagementSearchModel) {
     
    let searchText = searchForm.searchText?.value;

    return {
      searchText:  searchText,
    };
  }
}