import React, { useEffect } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import Accordion from '../../../../Controls/Accordion';
import Button from '../../../../Controls/Button';
import PagesRoute from '../../../../Config/Settings/PagesRoute';
import TextInput from '../../../../Controls/TextInputs';
import FileBase64 from '../../../../FileBase64';
import useAddOrganizationHook from '../hooks/useAddOrganizationHook';
import AutoCompleteLookup from '../../../../Components/AutoCompleteLookup';
import { LookupTypes } from '../../../../Config/Settings/Enums';
import { useParams } from 'react-router-dom';
import CustomeIconButton, { IconButtonsTypes } from '../../../../Controls/IconButton';
import TableComp from '../../../../Controls/Table';
import { PermissionScopes, PermissionsService } from '../../../../Services/permissions.service';
import RadioGroup from '@mui/material/RadioGroup';


const Profile = () => {
    const { T, Resources } = useTranslation();
    const {  id } = useParams();
    const { 
        formState, 
        addressesFormState, 
        handleInputChange, 
        createOrganization, 
        handleSetFileChange, 
        columnsLabels, 
        addressesSource, 
        handleAddAddressRow,
        handleAddressInputChange,
        handleSetAddressInputChange,
        resetImage,
        setResetImage,
        pagination,
        changePageApi,
        setIdentityNumberValidation,
        handleSetInputChange,
        defaultAddressValue
    } = useAddOrganizationHook();
    var DataSource: any =  id;

    
    //#region :: Data List
    const list: Array<any> = [
        {
            id: 'panel1a-header',
            ariaControls: 'p1-content',
            title: T(Resources.AppResources.BASIC_INFO),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.BASIC_INFO)}</span>),
            details: (
                <Grid container paddingTop={1} spacing={1} xs={12}>
                    <Grid item md={3} xs={12}>
                        <AutoCompleteLookup 
                            id={formState?.businessCategory?.name}
                            name={formState?.businessCategory?.name}
                            selected={formState?.businessCategory?.value}
                            label={T(Resources.AppResources.BUSINESS_CATEGORY)}
                            handleInputChange={handleSetInputChange}
                            lookupType={LookupTypes.BusinessCategory}
                            error={!formState.businessCategory.valid}
                            showLabel
                        /> 
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={formState.name.name}
                            name={formState.name.name}
                            label={T(Resources.AppResources.NAME)}
                            value={formState.name.value}
                            required={formState.name.required}
                            helperText={!formState.name.valid ? T(Resources.AppResources[formState.name.errorMessages?.error]) : formState.name.message}
                            error={!formState.name.valid}
                            handleChange={(e) => handleInputChange(e)} />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={formState.tin.name}
                            name={formState.tin.name}
                            label={T(Resources.AppResources.TIN)}
                            value={formState.tin.value}
                            required={formState.tin.required}
                            helperText={!formState.tin.valid ? T(Resources.AppResources[formState.tin.errorMessages?.error]) : formState.tin.message}
                            error={!formState.tin.valid}
                            isDisabled={DataSource}
                            handleChange={(e) => handleInputChange(e)} />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        
                        <AutoCompleteLookup 
                            id={formState?.identityType?.name}
                            name={formState?.identityType?.name}
                            selected={formState?.identityType?.value}
                            defaultValue={formState?.identityType?.defaultValue}
                            label={T(Resources.AppResources.IDENTITY_TYPE)}
                            handleInputChange={(name, value) => {handleSetInputChange(name, value); setIdentityNumberValidation(value);}}
                            lookupType={LookupTypes.IdentityType}
                            error={!formState.identityType.valid}
                            showLabel
                        /> 
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={formState.identityNumber.name}
                            name={formState.identityNumber.name}
                            label={T(Resources.AppResources.IDENTITY_NUMBER)}
                            value={formState.identityNumber.value}
                            required={formState.identityNumber.required}
                            helperText={!formState.identityNumber.valid ? T(Resources.AppResources[formState.identityNumber.errorMessages?.error]) : formState.identityNumber.message}
                            error={!formState.identityNumber.valid}
                            handleChange={(e) => handleInputChange(e)} />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={formState.vatRegistrationNumber.name}
                            name={formState.vatRegistrationNumber.name}
                            label={T(Resources.AppResources.VAT_REGISTRATION_NUMBER)}
                            value={formState.vatRegistrationNumber.value}
                            required={formState.vatRegistrationNumber.required}
                            helperText={!formState.vatRegistrationNumber.valid ? T(Resources.AppResources[formState.vatRegistrationNumber.errorMessages?.error]) : formState.vatRegistrationNumber.message}
                            error={!formState.vatRegistrationNumber.valid}
                            isDisabled={DataSource}
                            handleChange={(e) => handleInputChange(e)} />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <AutoCompleteLookup 
                            id={formState?.currencyCode?.name}
                            name={formState?.currencyCode?.name}
                            selected={formState?.currencyCode?.value}
                            defaultValue={formState?.currencyCode?.defaultValue}
                            label={T(Resources.AppResources.CURRENCY)}
                            handleInputChange={handleSetInputChange}
                            lookupType={LookupTypes.Currency}
                            error={!formState.currencyCode.valid}
                            showLabel
                        /> 
                    </Grid>
                    <Grid item marginTop={1} md={12} sm={12} xs={12}>
                        <FileBase64
                            label={`${T(Resources.AppResources.LOGO)}*`}
                            urlImg={formState.logoData.value}
                            error={!formState.logoData.valid}
                            handleChange={(data: any, name:any) => {
                                handleSetFileChange(name, data);
                            }}
                            reset={resetImage}
                            maxSize={50}//50 KB
                            maxSizeErrorMessage={T(Resources.AppResources.LOGO_50KB_SIZE_ERROR)}
                            resetCallBack={() => setResetImage(false)}
                            imgHeight={320}
                            imgWidth={320}
                            maxDimensionErrorMessage={T(Resources.AppResources.LOGO_320_DIMENSIONS_ERROR)}
                        />
                    </Grid>
                </Grid>
            )
        },
        {
            id: 'panel2a-header',
            ariaControls: 'p2-content',
            title: T(Resources.AppResources.ADDRESS),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.ADDRESS)}</span>),
            details: (
                <Grid container paddingTop={1} spacing={1} xs={12}>
                    <Grid item md={3} xs={12}>
                        <TextInput
                        id={addressesFormState.name.name}
                        name={addressesFormState.name.name}
                        label={T(Resources.AppResources.ADDRESS_NAME)}
                        value={addressesFormState.name.value}
                        required={addressesFormState.name.required}
                        helperText={addressesFormState.name.message}
                        error={!addressesFormState.name.valid}
                        handleChange={(e) => handleAddressInputChange(e)} 
                        />
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <AutoCompleteLookup 
                            id={addressesFormState?.countryCode?.name}
                            name={addressesFormState?.countryCode?.name}
                            selected={addressesFormState?.countryCode?.value}
                            defaultValue={addressesFormState?.countryCode?.defaultValue}
                            label={T(Resources.AppResources.COUNTRY)}
                            handleInputChange={handleSetAddressInputChange}
                            lookupType={LookupTypes.CountryCode}
                            error={!addressesFormState.countryCode.valid}
                            showLabel
                        /> 
                    </Grid>
                     <Grid item md={3} xs={12}>
                        <TextInput
                            id={addressesFormState.province.name}
                            name={addressesFormState.province.name}
                            label={T(Resources.AppResources.PROVINCE)}
                            value={addressesFormState.province.value}
                            required={addressesFormState.province.required}
                            helperText={!addressesFormState.province.valid ? T(Resources.AppResources[addressesFormState.province.errorMessages?.error]) : addressesFormState.province.message}
                            // helperText={addressesFormState.province.message}
                            error={!addressesFormState.province.valid}
                            handleChange={(e) => handleAddressInputChange(e)} 
                            />
                    </Grid>
                   <Grid item md={3} xs={12}>
                        <TextInput
                            id={addressesFormState.city.name}
                            name={addressesFormState.city.name}
                            label={T(Resources.AppResources.CITY)}
                            value={addressesFormState.city.value}
                            required={addressesFormState.city.required}
                            helperText={!addressesFormState.city.valid ? T(Resources.AppResources[addressesFormState.city.errorMessages?.error]) : addressesFormState.city.message}
                            error={!addressesFormState.city.valid}
                            handleChange={(e) => handleAddressInputChange(e)} />
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <TextInput
                            id={addressesFormState.district.name}
                            name={addressesFormState.district.name}
                            label={T(Resources.AppResources.DISTRICT)}
                            value={addressesFormState.district.value}
                            required={addressesFormState.district.required}
                            helperText={!addressesFormState.district.valid ? T(Resources.AppResources[addressesFormState.district.errorMessages?.error]) : addressesFormState.district.message}
                            error={!addressesFormState.district.valid}
                            handleChange={(e) => handleAddressInputChange(e)} />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={addressesFormState.street.name}
                            name={addressesFormState.street.name}
                            label={T(Resources.AppResources.STREET_NAME)}
                            value={addressesFormState.street.value}
                            required={addressesFormState.street.required}
                            helperText={!addressesFormState.street.valid ? T(Resources.AppResources[addressesFormState.street.errorMessages?.error]) : addressesFormState.street.message}
                            error={!addressesFormState.street.valid}
                            handleChange={(e) => handleAddressInputChange(e)} />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={addressesFormState.additionalStreet.name}
                            name={addressesFormState.additionalStreet.name}
                            label={T(Resources.AppResources.ADDITIONAL_STREET)}
                            value={addressesFormState.additionalStreet.value}
                            required={addressesFormState.additionalStreet.required}
                            helperText={!addressesFormState.additionalStreet.valid ? T(Resources.AppResources[addressesFormState.additionalStreet.errorMessages?.error]) : addressesFormState.additionalStreet.message}
                            error={!addressesFormState.additionalStreet.valid}
                            handleChange={(e) => handleAddressInputChange(e)} />
                    </Grid>

                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={addressesFormState.buildingNumber.name}
                            name={addressesFormState.buildingNumber.name}
                            label={T(Resources.AppResources.BUILDING_NUMBER)}
                            value={addressesFormState.buildingNumber.value}
                            required={addressesFormState.buildingNumber.required}
                            helperText={!addressesFormState.buildingNumber.valid ? T(Resources.AppResources[addressesFormState.buildingNumber.errorMessages?.error]) : addressesFormState.buildingNumber.message}
                            error={!addressesFormState.buildingNumber.valid}
                            handleChange={(e) => handleAddressInputChange(e)} />
                    </Grid>

                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={addressesFormState.postalCode.name}
                            name={addressesFormState.postalCode.name}
                            label={T(Resources.AppResources.POSTAL_CODE)}
                            value={addressesFormState.postalCode.value}
                            required={addressesFormState.postalCode.required}
                            helperText={!addressesFormState.postalCode.valid ? T(Resources.AppResources[addressesFormState.postalCode.errorMessages?.error]) : addressesFormState.postalCode.message}
                            error={!addressesFormState.postalCode.valid}
                            handleChange={(e) => handleAddressInputChange(e)} />
                    </Grid>

                    <Grid item md={3} sm={12} xs={12}>
                        <TextInput
                            id={addressesFormState.additionalNumber.name}
                            name={addressesFormState.additionalNumber.name}
                            label={T(Resources.AppResources.ADDITIONAL_NUMBER)}
                            value={addressesFormState.additionalNumber.value}
                            required={addressesFormState.additionalNumber.required}
                            helperText={!addressesFormState.additionalNumber.valid ? T(Resources.AppResources[addressesFormState.additionalNumber.errorMessages?.error]) : addressesFormState.additionalNumber.message}
                            error={!addressesFormState.additionalNumber.valid}
                            handleChange={(e) => handleAddressInputChange(e)} />
                    </Grid>
                    <Grid container justifyContent={'end'} md={6} sm={12} xs={12}>
                        <CustomeIconButton
                            color={!formState.Addresses.valid  ? 'error' : ''}
                            className={'add-icon'}
                            id='add'
                            aria-label='add'
                            type={IconButtonsTypes.ADD}
                            onClick={handleAddAddressRow}
                        />
                    </Grid>
                    <Grid paddingTop={2} md={12} sm={12} xs={12}>
                        <RadioGroup defaultValue={defaultAddressValue}>
                            <TableComp
                                dataSource={addressesSource}
                                tableColumns={columnsLabels}
                                showPagination={(id != null)}
                                changePageApi={changePageApi}
                                pagination={pagination}
                            />
                        </RadioGroup>
                    </Grid>

                </Grid>
            )
        },
        {
            id: 'panel2a-header',
            ariaControls: 'p2-content',
            title: T(Resources.AppResources.CONFIGURATIONS),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.CONFIGURATIONS)}</span>),
            details: (
                <Grid container paddingTop={1} spacing={1} xs={12}>
                    <Grid item md={3} xs={12}>
                        <TextInput
                        id={formState.taxCalculationTolerance.name}
                        name={formState.taxCalculationTolerance.name}
                        label={T(Resources.AppResources.TAX_CALCULATION_TOLERANCE)}
                        value={formState.taxCalculationTolerance.value}
                        required={formState.taxCalculationTolerance.required}
                        helperText={!formState.taxCalculationTolerance.valid ?`${T(Resources.AppResources[formState.taxCalculationTolerance.errorMessages?.error])} ${formState.taxCalculationTolerance.errorMessages?.boundary}`: formState.taxCalculationTolerance.message}
                        error={!formState.taxCalculationTolerance.valid}
                        handleChange={(e) => handleInputChange(e)} 
                        />
                    </Grid>
                </Grid>
            )
        }
    ];
    //#endregion
    return (
        <Grid container spacing={1}>
            <Accordion content={list} />
            <Grid item xs={12} marginTop={2}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => createOrganization()}
                    label={DataSource ? T(Resources.AppResources.SAVE) : T(Resources.AppResources.CREATE)} />
                {!DataSource && <Button
                    marginRight={10}
                    marginLeft={10}
                    variant='contained'
                    color='primary'
                    label={T(Resources.AppResources.CREATE_AND_NEW)}
                    onClick={() => createOrganization(true)}
                />}
                {PermissionsService.hasPermission(PermissionScopes.SEARCH_ORGANIZATION) && <Button
                    marginRight={DataSource ? 10 : 0}
                    marginLeft={DataSource ? 10 : 0}
                    variant='outlined'
                    color='primary'
                    link={true}
                    to={PagesRoute.PAGES.ORGANIZATION_MANAGEMENT}
                    label={T(Resources.AppResources.CANCEL)}
                />}
            </Grid>
        </Grid>
    )
}
export default Profile;