import React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useTranslation from '../../Hooks/Shared/useTranslation';
import LandingPageImg from '../../assets/images/LandingPageImg.png';

const Home = () => {
    //#region :: State
    const { T, Resources } = useTranslation();
    const screenSize = null;
    const MidScreenSize = null;
    const TabScreen = null;
    const Xlarge = null;
    //#endregion

    return (
        <>
            <StyledHeroGrid item xs={12}>
                <GridContainer istabscreen={TabScreen}>
                    <Grid item xs={12} lg={6} sm={9} md={11}>
                        <Box p={3}>

                        </Box>
                    </Grid>
                    <GridImg isscreensize={MidScreenSize} islargesize={Xlarge} style={{ minWidth: !screenSize && 'auto' }} item xs={12} sm={1} lg={6} md={3}>
                        <Img src={LandingPageImg} alt="new" />
                    </GridImg>
                </GridContainer>
            </StyledHeroGrid >
        </>
    );
};

//#region :: Styled Components
const StyledHeroGrid: any = styled(Grid)`
    background: linear-gradient(to right,#2e49a2 0%,#1b1b4d 100%);
    height: fit-content;
    color: #fff;
    min-width: fit-content;
    overflow: hidden;
    margin: -15px -32px 0 -15px !important;
    padding: 0 12px 0 0;
    margin : ${({ theme }: any) => theme.direction === 'rtl' ? '-15px -32px 0 -15px!important' : '-15px !important'};
`;

const GridContainer: any = styled(Grid)`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

const GridImg: any = styled(Grid)`
    display: flex;
    justify-content: center;
    min-width: ${({ theme, isscreensize, islargesize }: any) => theme.direction === 'rtl' && islargesize ? '200vh' : isscreensize ? '110vh' : '50%'};
`;

const StyledTitle: any = styled.h1`
    color: #fff;
    font-size: 45px;
    font-weight: 300;
    line-height: 1.2;
`;

const Img: any = styled.img`
 max-width: 100%;
 width: 70%;
 height: auto;
`;




//#endregion

export default Home;