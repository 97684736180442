import React , { lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CookiesConstants from '../../Config/Settings/Cookies';
import Layout from '../../Layout/Master';
import { GlobalService } from '../../Services/global.service';
import { getCookie } from '../../Utils/Cookies';
import Home from '../../Pages/Home';
import UnauthorizedError from '../../Pages/Errors/UnauthorizedError';
import ForbiddenError from '../../Pages/Errors/ForbiddenError';
import ServiceUnavailable from '../../Pages/Errors/ServiceUnavailable';
import NotFound from '../../Pages/Errors/NotFound';
import PagesRoute from '../../Config/Settings/PagesRoute';
import { redirect } from 'react-router-dom';
import { isPlainObject } from '@reduxjs/toolkit';
import OrganizationManagement from '../../Pages/SystemSetup/OrganizationManagement';
import InvoicesBookManagement from '../../Pages/SystemSetup/InvoicesBookManagement';
import Onboarding from '../../Pages/Onboarding';
import AddOnboarding from '../../Pages/Onboarding/addOnboarding';
import ViewOnboarding from '../../Pages/Onboarding/viewOnboarding';
import Report from '../../Pages/Reports';
import ViewReports from '../../Pages/Reports/viewReports';
import RolesManagements from '../../Pages/TenantContext/RoleMapping';
import CreateRole from '../../Pages/TenantContext/RoleMapping/createRole';
import AddOrganization from '../../Pages/SystemSetup/OrganizationManagement/addOrganization';
import EditOrganization from '../../Pages/SystemSetup/OrganizationManagement/editOrganization';
import ViewOrganization from '../../Pages/SystemSetup/OrganizationManagement/viewOrganization';
import AddInvoicesBook from '../../Pages/SystemSetup/InvoicesBookManagement/addInvoicesBook';
import EditInvoicesBook from '../../Pages/SystemSetup/InvoicesBookManagement/editInvoicesBook';
import ViewInvoicesBook from '../../Pages/SystemSetup/InvoicesBookManagement/viewInvoicesBook';
import TenantUsersManagement from '../../Pages/TenantContext/TenantUsersManagement';
import ViewUser from '../../Pages/TenantContext/TenantUsersManagement/viewUser';
import EditUser from '../../Pages/TenantContext/TenantUsersManagement/editUser';
import Fawateer from '../../Pages/Fawateer';
import FawateerList from '../../Pages/Fawateer/fawateerList';
import { PermissionScopes, PermissionsService } from '../../Services/permissions.service';
import UsersManagement from '../../Pages/UsersManagement';
import EditOrgUser from '../../Pages/UsersManagement/editUser';
import ViewOrgUser from '../../Pages/UsersManagement/viewUser';

import LoggerManagement from '../../Pages/TenantContext/logger';
import ViewLogger from '../../Pages/TenantContext/logger/ViewLogger';
import ResponseError from '../../Pages/ResponseError';

//#region :: Lazy Imports



//#endregion

const Admin = () => {

  const navigate = useNavigate();
  const { pathname } = useLocation();


  useEffect(() => {
    if (!pathname || pathname == '/' || pathname == '') navigate('/x4Fatoora')

  }, [pathname])
  return (
    <Routes>
      <Route path={`/401`} element={<UnauthorizedError />} />
      <Route path={`/403`} element={<ForbiddenError />} />
      <Route path={`/503`} element={<ServiceUnavailable />} />
      <Route path="*" element={<NotFound />} />
      <Route path={`x4Fatoora`} element={<Home />} />

      {(PermissionsService.hasPermission(PermissionScopes.SEARCH_ORGANIZATION) )
      && <Route path={`/organization_management`} element={<OrganizationManagement />} />}

      {PermissionsService.hasPermission(PermissionScopes.ADD_ORGANIZATION) &&
      <Route path={`/organization_management/add_organization`} element={<AddOrganization />} />}

      {PermissionsService.hasPermission(PermissionScopes.EDIT_ORGANIZATION)&&
      <Route path={`/organization_management/edit_organization/:id`} element={<EditOrganization />} />}

      {PermissionsService.hasPermission(PermissionScopes.VIEW_ORGANIZATION_DETAILS) && 
      <Route path={`/organization_management/view_organization/:id`} element={<ViewOrganization />} />}


      {(PermissionsService.hasPermission(PermissionScopes.SEARCH_INVOICES_BOOKS_ORGANIZATION)) &&
      <Route path={`/invoices_book_management`} element={<InvoicesBookManagement />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.ADD_INVOICES_BOOK_ORGANIZATION) &&
      <Route path={`/invoices_book_management/add_invoices_book`} element={<AddInvoicesBook />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.EDIT_INVOICES_BOOK_ORGANIZATION) &&
      <Route path={`/invoices_book_management/edit_invoices_book/:id`} element={<EditInvoicesBook />} />}

      {PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_BOOK_DETAILS_ORGANIZATION )&&
      <Route path={`/invoices_book_management/view_invoices_book/:id`} element={<ViewInvoicesBook />} />}
      
      
      {(PermissionsService.hasPermission(PermissionScopes.SEARCH_ONBOARDING) ) &&
      <Route path={`/onboarding`} element={<Onboarding />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.ADD_ONBOARDING) &&
      <Route path={`/onboarding/add_onboarding`} element={<AddOnboarding />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.VIEW_ONBOARDING__DETAILS) &&
      <Route path={`/onboarding/view_onboarding/:id`} element={<ViewOnboarding />} />}


      {PermissionsService.hasPermission(PermissionScopes.SEARCH_FOR_AN_INVOICE) &&
      <Route path={`/invoices`} element={<Report />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_DETAILS) &&
      <Route path={`/invoices/view_invoice/:id`} element={<ViewReports />} />}


      {PermissionsService.hasPermission(PermissionScopes.SEARCH_ROLE) &&
      <Route path={`/roles_management`} element={<RolesManagements />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.ADD_NEW_ROLE) &&
      <Route path={`/roles_management/createRole`} element={<CreateRole />} />}

      {PermissionsService.hasPermission(PermissionScopes.EDIT_ROLE) &&
      <Route path={`/roles_management/editRole/:id`} element={<CreateRole />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.SEARCH_USERS_TENANT) &&
      <Route path={`/users_management`} element={<TenantUsersManagement />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.VIEW_USER_DETAILS_TENANT) &&
      <Route path={`/users_management/view_user/:id`} element={<ViewUser />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.EDIT_USER_TENANT) &&
      <Route path={`/users_management/edit_user/:id`} element={<EditUser />} />}
      
      
      {(PermissionsService.hasPermission(PermissionScopes.VIEW_USERS_LIST_ORGANIZATION) ||
      PermissionsService.hasPermission(PermissionScopes.SEARCH_USERS_ORGANIZATION)) &&
      <Route path={`/org_users_management`} element={<UsersManagement />} />}

      {PermissionsService.hasPermission(PermissionScopes.VIEW_USER_DETAILS_ORGANIZATION) &&
      <Route path={`/org_users_management/view_user/:id`} element={<ViewOrgUser />} />}
      
      {PermissionsService.hasPermission(PermissionScopes.EDIT_USER_ORGANIZATION) &&
      <Route path={`/org_users_management/edit_user/:id`} element={<EditOrgUser />} />}

      {(PermissionsService.hasPermission(PermissionScopes.SEARCH_LOGGER_LIST) )
      && <Route path={`/loggers_management`} element={<LoggerManagement/>} />}

      {PermissionsService.hasPermission(PermissionScopes.VIEW_LOGGER_DETAILS) && 
      <Route path={`/loggers_management/view_logger/:id`} element={<ViewLogger />} />}
 
      <Route path={`/validation_log`} element={<ResponseError />}/>
    </Routes>
  );
};

export default Layout(Admin);