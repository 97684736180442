import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MuiButton from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import SpeedDial from '../../Controls/SpeedDial';
import { theme as MainThemeVariables } from '../../Config/Theme/ThemeVariables';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export const ButtonsTypes = {
    SAVE: 'save',
    SEARCH: 'search',
    RESET: 'reset',
    SEND: 'send',
    CANCEL: 'cancel',
    BACK: 'back',
    LINK: 'link',
    EXPORT: 'export',
    ADD: 'add',
    SKIP: 'skip',
    SHRINK: 'shrink',
    PRINT: 'print',
    DETAILS: 'details',
    EMPTY: 'empty',
    FLOATIONG: 'floating',
    REMOVE: 'remove',
    NEXT: 'next',
    PREVIOUS: 'previous',
    COPY: 'copy',
};

export interface ButtonProps {
    buttonsAction?: any;
    link?: boolean;
    label?: any;
    variant?: string;
    height?: any;
    width?: any;
    fontSize?: any;
    startIcon?: any;
    component?: any;
    to?: any;
    customeColor?: any;
    color?: any;
    bgColor?: any;
    style?: any;
    disabled?: any;
    onClick?: any;
    size?: any;
    margin?: any;
    marginTop?: any;
    marginRight?: any;
    marginLeft?: any;
    marginBottom?: any;
    type?: any;
    children?: any;
};

const Button = ({ buttonsAction, link = false, label, variant, height, width = 'auto', fontSize, startIcon, component, to, customeColor, color, bgColor, style,
    disabled = false, onClick, size = 'small', margin, marginTop, marginRight, marginLeft, marginBottom, type = '', children }: ButtonProps) => {

    const props = link === true ?
        {
            to: to,
            component: Link
        }
        : {};

    const buttons = (type: any) => {
        switch (type) {
            case ButtonsTypes.SEARCH:
                return <StyledButton
                    aria-label='Search'
                    style={{ ...style, fontSize: fontSize }}
                    variant={variant}
                    disabled={disabled}
                    customecolor={customeColor}
                    color={color}
                    bgcolor={bgColor}
                    margin={margin}
                    size={size}
                    width={width}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<SearchIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.DETAILS:
                return <StyledButtonDetails
                    {...props}
                    aria-label='Details'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    bgcolor={bgColor}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    width={width}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<MoreHorizIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButtonDetails>;
            case ButtonsTypes.RESET:
                return <StyledButton
                    aria-label='Reset'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    bgcolor={bgColor}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<RotateLeftIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.REMOVE:
                return <StyledButton
                    aria-label='remove'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<RemoveIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.SEND:
                return <StyledButton
                    aria-label='Send'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={startIcon}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.EXPORT:
                return <StyledButton
                    aria-label='Export'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    bgcolor={MainThemeVariables['export-color']}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<SaveAltIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.SAVE:
                return <StyledButton
                    aria-label='Save'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<SaveIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.ADD:
                return <StyledButton
                    {...props}
                    aria-label='Add'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    height={height}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.PRINT:
                return <StyledButton
                    aria-label='Print'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    bgcolor={bgColor}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    width={width}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<PrintIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.SHRINK:
                return <StyledButton
                    {...props}
                    aria-label='Shrink'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    customecolor={customeColor}
                    color={color}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    startIcon={<RemoveCircleOutlineIcon />}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
            case ButtonsTypes.EMPTY:
                return <StyledButton
                    {...props}
                    aria-label='Shrink'
                    style={{ fontSize: fontSize }}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    onClick={onClick}>
                    {children}
                </StyledButton>;
            case ButtonsTypes.NEXT:
                return (

                    <StyledButton
                        {...props}
                        aria-label='Shrink'
                        style={{ fontSize: fontSize }}
                        disabled={disabled}
                        margin={margin}
                        size={size}
                        margintop={marginTop}
                        marginright={marginRight}
                        marginleft={marginLeft}
                        marginbottom={marginBottom}
                        onClick={onClick}>
                        {children}
                        <ArrowForwardIosRoundedIcon />
                    </StyledButton>);
            case ButtonsTypes.PREVIOUS:
                return <StyledButton
                    {...props}
                    aria-label='Shrink'
                    style={{ fontSize: fontSize }}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    onClick={onClick}>
                    <ArrowBackIosRoundedIcon />
                    {children}
                </StyledButton>;
            case ButtonsTypes.COPY:
                return <StyledButton
                    {...props}
                    className="copy-week"
                    aria-label='Shrink'
                    style={{ fontSize: fontSize }}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    onClick={onClick}>
                    <ContentCopyIcon />
                    {children}
                </StyledButton>;
            case ButtonsTypes.FLOATIONG:
                return <StyledFloatingBtn>
                    <SpeedDial actions={buttonsAction && buttonsAction} onClick={onClick} />
                </StyledFloatingBtn>;
            default:
                return <StyledButton
                    {...props}
                    aria-label='Button'
                    style={{ fontSize: fontSize }}
                    variant={variant}
                    color={color}
                    customecolor={customeColor}
                    bgcolor={bgColor}
                    disabled={disabled}
                    margin={margin}
                    size={size}
                    margintop={marginTop}
                    marginright={marginRight}
                    marginleft={marginLeft}
                    marginbottom={marginBottom}
                    height={height}
                    onClick={onClick}>
                    {label}
                </StyledButton>;
        }
    };

    return (buttons(type))
};

//#region :: Styled Component
const StyledButton: any = styled(MuiButton)`
margin: ${({ margin }: any) => margin && margin + 'px!important'};
margin-top: ${({ margintop }: any) => margintop && margintop + 'px!important'};
margin-right: ${({ marginright }: any) => marginright && marginright + 'px!important'};
margin-left: ${({ marginleft }: any) => marginleft && marginleft + 'px!important'};
margin-bottom: ${({ marginbottom }: any) => marginbottom && marginbottom + 'px!important'};
background-color: ${({ bgcolor }: any) => bgcolor && bgcolor + '!important'};
color: ${({ customecolor }: any) => customecolor && customecolor + '!important'};
width: ${({ width }: any) => width && width + 'px!important'};
height: ${({ height }: any) => height && height + 'px!important'};
min-width: auto!important;
`;

const StyledButtonDetails: any = styled(MuiButton)`
    margin-right: 0px !important;
    margin-left: 0px !important;
    min-width: auto !important;
    padding: 0px !important;
    margin: 0px !important;
    display: flex !important;
    justify-content: end !important;
    & .MuiButton-startIcon {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
`;

const StyledFloatingBtn: any = styled.div`
    z-index: 10;
    // width: 56px;
    // height: 56px;
    font-size: 18px;
    & .MuiFab-root {
        // position: 'fixed !important;
        // bottom:'30px;
        // right:'30px;
    }
    & .MuiSpeedDial-root {
        position: fixed !important;
        bottom: 30px;
        left:30px;
        /* [theme.direction === 'rtl' ? 'left' : 'right']: 30px; */
    }
`;
//#endregion

//#region :: Validation PropTypes
Button.propTypes = {
    label: propTypes.string,
    variant: propTypes.string,
    color: propTypes.string,
    margin: propTypes.number,
    marginBottom: propTypes.number,
    disabled: propTypes.bool,
    onClick: propTypes.func
};
//#endregion

export default Button;