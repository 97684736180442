//import axios from 'axios';
import * as actions from '../Actions/API';
import { changeFullLoading } from '../Reducers/UI';
import { axios } from '../../Services/API/WebServiceClient';
import APIConstants from '../../Config/Settings/API';
import { GlobalService } from '../../Services/global.service';
import { MESSAGE_TYPE, MessagesService } from '../../Services/messages.service';
import { pagesReseted } from '../Reducers/Pagination';

let requestsCancelations: Array<any> = [];   


const API = ({ dispatch }: any) => (next: any) => async (action: any) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    next(action);

    const { url, method, data, onSuccess, onError, args } = action.payload;
    dispatch(changeFullLoading({ spin: true }));

    const currentTenant = GlobalService.TenantServices;       
    const current_organization = GlobalService.CurrentActiveOrganization?.id;      
    const envMode = GlobalService.EnvMode;    
    try {
        //Cancel Repetitive Requests
        let currentUrlRequests = requestsCancelations.filter(x => x.url === url);
        if (currentUrlRequests) {
            currentUrlRequests.map(request => request.cancelRequest());
            requestsCancelations = requestsCancelations.filter(x => x.url !== url);
        }

        
        const headers =  {
            "TenantId": currentTenant?.id,
            "env": envMode
        }

        if(current_organization)
        {
            headers['OrganizationId'] = current_organization;
        }
        
        const response = await axios.request({
            baseURL: APIConstants.BASE_URL,
            headers:headers,
            url,
            method,
            data,
            cancelToken: new axios.CancelToken(c => requestsCancelations.push({ url, cancelRequest: c })),
            withCredentials: true});

        requestsCancelations = requestsCancelations.filter(x => x.url !== url);

        //General:
        dispatch(actions.apiCallSuccess());

        //Specifc:
        if (onSuccess) {
            dispatch(changeFullLoading({ spin: false }));
            dispatch({ type: onSuccess, payload: response.data, args });
        }
   
    }
    catch (error) {
        if (axios.isCancel(error)) {
            return;
        }
        //console.log('CALL_API_ERROR', error);
        //General:
        dispatch(actions.apiCallFailed());

        //Specifc:
        if (onError) {   
            dispatch(changeFullLoading({ spin: false }));
            dispatch({ type: onError, payload: error, args });
        }
    }
};

export default API;