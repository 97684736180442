import Grid from '@material-ui/core/Grid';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import Button from '../../../Controls/Button';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import '../style.css';
import { Link } from 'react-router-dom';

const NotFound = () => {

    const { T, Resources } = useTranslation();

    return (
        <Grid container item xs={12} spacing={1}>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>{T(Resources.AppResources.OOPS)}</h1>
                        <h2 style={{ fontFamily: 'Cairo' }}>{T(Resources.AppResources.MSG404)}</h2>
                    </div>
                    <Button
                        style={{ fontFamily: 'Cairo' }}
                        variant="contained"
                        link={true}
                        component={Link}
                        to={PagesRoute.PAGES.HOME}
                        label={T(Resources.AppResources.HOME_PAGE)} />
                </div>
            </div>
        </Grid>
    );
};

export default NotFound;