import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Auth from '../Routes/Auth';
import Internal from '../Routes/Internal';
import { getAuth } from '../Store/Reducers/Auth';

const RoutConfig = () => {
    const state = useSelector(state => state);
    const navigate = useNavigate();
    const auth = getAuth(state);
    const [path, setPath] = useState(window.location.pathname);

    //#region :: [Effect] Mount Window pathF
    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setPath(window.location.pathname);
        }

        // return () =>  unmounted = true;
    }, []);
    //#endregion

    return (
        <Routes>
            {!auth?.isLoggedIn ?
                <Route path={`*`} element={<Auth />} /> : <Route path={`*`} element={<Internal />} />}

        </Routes>
    )
};
export default RoutConfig;