import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import "react-phone-input-2/lib/material.css";
import PhoneInputDeclarations from "./index.d";
import "./styles.scss";

const MobilePhone = ({ error = null, value, positionFixed = false, label, onKeyPress, placeHolder, handleOnChange, required = false, valid = true }: PhoneInputDeclarations) => {
    const [currentValue, setCurrentValue] = useState<any>(value);
    useEffect(() => {
        setCurrentValue(value)
    }, [value])

    return (
        <>
            <div className={error ? "phone-input error" : "phone-input"}>


                <PhoneInput
                    dropdownClass={positionFixed ? 'country-list-position_fixed ' : 'country-list'}
                    specialLabel={label}
                    onEnterKeyPress={onKeyPress}
                    isValid={valid}
                    autocompleteSearch={true}
                    inputClass="style-phone-input"
                    placeholder="+96**********"
                    country={"sa"}
                    value={currentValue?.toString()}
                    onChange={(e) => {
                        handleOnChange("+" + e)
                    }}
                    enableSearch={true}
                    enableClickOutside={true}
                    masks={{
                        jo: '..-...-....',
                        sa: '..-...-....'
                    }}
                />

            </div>
            <span>            {error && <p style={{ fontSize: '13px', color: "red" }}>{error}</p>}
            </span>
        </>
    );
}

export default MobilePhone;