import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class AddressesService {

    public static ListAddressesByOrganization = async () => {
        try {
            const response = await WebServiceClient.get(APIConstants.ADDRESSES.LIST);
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
  
    public static SearchAddressesByOrganization = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.ADDRESSES.SEARCH, {body: request}, undefined, true, false);
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
  
    public static AddAddress = async (request: any) => {
        try {
            const response = await WebServiceClient.post(APIConstants.ADDRESSES.POST, {body: request}, undefined, true, false);
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
  
    public static EditAddress = async (request: any, id:any) => {
        try {
            const response = await WebServiceClient.put(APIConstants.ADDRESSES.PUT.replace("{id}", id), {body: request}, true, false);
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
  
    public static DeleteAddress = async ( id:any) => {
        try {
            const response = await WebServiceClient.delete(APIConstants.ADDRESSES.DELETE.replace("{id}", id), true, false);
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
  
    public static SetDefaulAddress = async ( id:any) => {
        try {
            const response = await WebServiceClient.patch(APIConstants.ADDRESSES.SET_DEFAULT.replace("{id}", id), true, false);
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }
}
