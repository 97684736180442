import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import propTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';
import useTranslation from '../../Hooks/Shared/useTranslation';
import { EmptyTable } from '../Icons/Icons';

export interface TableCompProps {
    dataSource?: any;
    treeTable?: boolean;
    tableColumns?: any;
    pagination?: any;
    changePageApi?: any;
    hasModal?: any;
    height?: any;
    paginationGrid?: any;
    paddingTableData?: any;
    fontFamily?: any;
    showPagination?: boolean;
    showHeader?: boolean;
    showTooltip?: boolean;


};

export interface RowProps {
    treeTable?: boolean;
    row?: any;
    tableColumns?: any;
    fontFamily?: any;
    getFieldValue?: any;
    paddingTableData?: any;
    showHeader?: boolean;
    showTooltip?: boolean;

};

function Row({ treeTable, row, tableColumns, fontFamily, getFieldValue, paddingTableData, showHeader,showTooltip = true  }: RowProps) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow style={{ backgroundColor: row?.error ? '#ff8a8a' : 'inherit' }}>
                {treeTable
                    ? row.children && row.children.length > 0
                        ? <StyledTableData width={5}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </StyledTableData>
                        : <StyledTableData width={5}>
                            <IconButton
                                style={{ visibility: 'hidden' }}
                                aria-label="expand row"
                                size="small">
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        </StyledTableData>
                    : null}
                {tableColumns && tableColumns.map((column: any) => {
                    const value = row[column.id];
                    return (
                        typeof (value) != 'object'
                            ? showTooltip ?<Tooltip key={column.id} title={column && getFieldValue(value)} placement='top'>
                                <StyledTableData
                                    key={column.id}
                                    align={column.align}
                                    width={160}
                                    style={{ padding: paddingTableData, fontFamily: fontFamily }}>
                                    {getFieldValue(value)}
                                </StyledTableData>
                            </Tooltip>
                            :<StyledTableData
                                key={column.id}
                                align={column.align}
                                width={160}
                                style={{ padding: paddingTableData, fontFamily: fontFamily }}>
                                {getFieldValue(value)}
                            </StyledTableData>
                            : <StyledTableData
                                key={column.id}
                                align={column.align}
                                width={60}
                                style={{ padding: paddingTableData, fontFamily: fontFamily }}>
                                {getFieldValue(value)}
                            </StyledTableData>
                    );
                })}
            </TableRow>

            {treeTable &&
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <StyledTableData colSpan={tableColumns.length + tableColumns.length}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <StyledDivBorder showHeader={showHeader} open={open}>
                                <TableComp
                                    treeTable={true}
                                    dataSource={row.children}
                                    showHeader={false}
                                    tableColumns={tableColumns}
                                    showPagination={false} />
                            </StyledDivBorder>
                        </Collapse>
                    </StyledTableData>
                </TableRow>
            }
        </>
    );
};
//Note For 0 Based Pagination changePageApi-1,pagination.pageIndex + 1
const TableComp = ({ dataSource = [], treeTable = false, tableColumns, pagination, changePageApi, hasModal, height, paginationGrid = true,
    paddingTableData = 0, fontFamily = 'cairo', showPagination = true, showHeader = true , showTooltip = true }: TableCompProps) => {

    const { T, Resources } = useTranslation();

    //#region :: Handlers
    const handleChangePage = (event: any, newPage: any) => {
        changePageApi(newPage - 1);
    };

    const getFieldValue = (field: any) => {
        if (!field) return 'ـــــ';

        if (typeof (field) !== 'string') return field;

        if (!field.includes('^')) return T(field);

        var fieldParts = field.split('^');

        var result = fieldParts.map(part => part).reduce((a, b) => T(a) + ' ' + ((b && T(b)) || ''));

        return result;
    };
    //#endregion
    return (
        <Box
            display='flex'
            flexWrap='wrap'
            alignContent='flex-start'
            className='heading'>
            <StyledContainerGrid item xs={12} row='true'>
                <TableContainerStyle>
                    <Table aria-label='sticky table' size='small'>
                        {showHeader && <TableHead>
                            <TableRow>
                                {tableColumns && treeTable && <StyledTableRow />}
                                {tableColumns !== undefined && tableColumns.map((column: any, index: number) => (
                                    showTooltip ? 
                                    <Tooltip key={column.id} title={column && column.label} placement='top'>
                                        <StyledTableRow
                                            size='small'
                                            key={column && column.id}
                                            align={column && column.alignHeader !== undefined ? column.alignHeader : 'center'}
                                            style={{ fontFamily: fontFamily }}
                                            onClick={(e) => column && column.handler&& column.handler(e)}>
                                            {column && column.label} {column && column.icon}
                                        </StyledTableRow>
                                    </Tooltip>
                                    : 
                                    <StyledTableRow
                                        size='small'
                                        key={column && column.id}
                                        align={column && column.alignHeader !== undefined ? column.alignHeader : 'center'}
                                        style={{ fontFamily: fontFamily }}
                                        onClick={(e) => column && column.handler&& column.handler(e)}>
                                        {column && column.label} {column && column.icon}
                                    </StyledTableRow>
                                ))}
                            </TableRow>
                        </TableHead>}
                        <TableBody style={{ height: height }}>
                            {dataSource !== undefined && dataSource.length > 0
                                ? dataSource.map((row: any) => {
                                    return (
                                        <Row
                                            key={row.Id}
                                            row={row}
                                            treeTable={treeTable}
                                            showHeader={showHeader}
                                            tableColumns={tableColumns}
                                            fontFamily={fontFamily}
                                            paddingTableData={paddingTableData}
                                            getFieldValue={getFieldValue} 
                                            showTooltip={showTooltip}/>
                                    );
                                })
                                : <TableRow>
                                    <StyledTableData colSpan={tableColumns?.length}>
                                        <StyledCenterDiv>
                                            <EmptyTable />
                                            <StyledEmptyIcon>{T(Resources.AppResources.NODATA)}</StyledEmptyIcon>
                                        </StyledCenterDiv>
                                    </StyledTableData>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainerStyle>
                {dataSource !== undefined && showPagination && dataSource.length > 0
                    ? paginationGrid && <PaginationGrid>
                        <span>{T(Resources.AppResources.TOTAL_RESULTS)} {pagination.totalCount}</span>
                        <PaginationStyle
                            page={(pagination.pageIndex + 1)}
                            count={pagination.totalPages}
                            onChange={(e: any, page: any) => handleChangePage(e, page)}
                            showFirstButton={true}
                            showLastButton={true}
                            size='small'
                            variant='text'
                            shape='rounded' />
                    </PaginationGrid>
                    : null}
            </StyledContainerGrid>
            {hasModal && hasModal}
        </Box>
    );
};

//#region :: Styled Components
const StyledDivBorder: any = styled.div`
  border-right: thin solid #d6d6d6;
  border-bottom: ${({ showHeader, open }: any) => (showHeader && open) ? 'thin solid #d6d6d6' : 'none'};
  position: relative;
  margin-inline-start:20px;
  
/* &:after {
  padding:0;
  margin:0;
  content: "";
  width:99%;
  height:1.1px;
  background-color:white;
  position: absolute;
  left:0;
  top:-1px;
  } */

  &:before {
  padding:0;
  margin:0;
  content: "";
  width:99%;
  height:1px;
  background-color:white;
  position: absolute;
  left:0;
  bottom:-1px;
  }
`;

const StyledTableRow: any = styled(TableCell)`
    background-color: ${({ theme }: any) => theme && theme.palette.primary.main};
    width: ${({ width }: any) => width && width + 'px'};

    &.MuiTableCell-alignCenter{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2px;
    }
    cursor: pointer;
`;

const StyledTableData: any = styled(TableCell)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 1px 0px !important;
    max-width: 160px;
    width: ${({ width }: any) => width && width + 'px'};
`;

const StyledContainerGrid: any = styled(Grid)`
`;

const StyledCenterDiv: any = styled.div`
    text-align: center;
`;

const StyledEmptyIcon: any = styled.div`
    color: ${({ theme }: any) => theme && theme.status.lightGray};
`;

const PaginationGrid: any = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 0.8rem;
`;

const PaginationStyle: any = styled(Pagination)`
    display: flex;
    justify-content: center;
`;

const TableContainerStyle: any = styled(TableContainer)`
    overflow: initial;
    overflow-y: hidden !important;
    &::-webkit-scrollbar {
        height: .4rem;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }: any) => theme && theme.palette.primary.silver};
    }
`;
//#endregion

//#region :: Validation PropTypes
TableComp.propTypes = {
    dataSource: propTypes.any,
    tableColumns: propTypes.array,
    pagination: propTypes.object,
    changePageApi: propTypes.func,
    hasModal: propTypes.object
};
//#endregion

export default TableComp;