import {
  regexMsgs,
  regexType,
} from "../../../../Config/Settings/Constants/Validations";
import { Integer } from "../../../../Config/Settings/Enums";
import { FieldModel } from "../../../../Models";

export class OrganizationModel {
  businessCategory?: any;
  name?: any;
  tin?: any;
  identityType?: any;
  identityNumber?: any;
  vatRegistrationNumber?: any;
  taxIdentificationNumber?: any;
  currency?: any;
  logo?: any;
  code?: any;
  logoData?: any;
  logoFileName?: any;
  currencyCode?: any;
  Addresses?: Array<Addresses>;
  taxCalculationTolerance?: any;

  public static getFieldsModel(model?: OrganizationModel): any {
    if (!model) model = new OrganizationModel();
    let fildsObject = FieldModel.buildFromObject(model);


    fildsObject.name.required = true;
    fildsObject.name.validate = true;
    fildsObject.name.regexValue = regexType.MIN_2_MAX_100;
    fildsObject.name.errorMessages = {
      error: regexMsgs.MIN_2_MAX_100,
    };

    fildsObject.tin.required = true;
    fildsObject.tin.validate = true;
    fildsObject.tin.regexValue = regexType.IS_10_DIGITS;
    fildsObject.tin.errorMessages = {
      error: regexMsgs.EQUAL_TO_10,
    };

    fildsObject.currencyCode.required = true;
    fildsObject.currencyCode.validate = true;
    fildsObject.currencyCode.defaultValue = "SAR";
    fildsObject.currencyCode.value = "SAR";

    fildsObject.Addresses.required = true;
    fildsObject.Addresses.validate = true;
    fildsObject.Addresses.value = null;

    fildsObject.businessCategory.required = true;
    fildsObject.businessCategory.validate = true;


    fildsObject.identityType.required = true;
    fildsObject.identityType.validate = true;
    fildsObject.identityType.value = "";

    fildsObject.identityNumber.required = true;
    fildsObject.identityNumber.validate = true;
    fildsObject.identityNumber.regexValue = regexType.MIN_2_MAX_20;
    fildsObject.identityNumber.errorMessages = {
      error: regexMsgs.INVALID_INPUT,
    };

    fildsObject.vatRegistrationNumber.required = true;
    fildsObject.vatRegistrationNumber.validate = true;
    fildsObject.vatRegistrationNumber.regexValue = regexType.VAT_REG_NUMBER;
    fildsObject.vatRegistrationNumber.errorMessages = {
      error: regexMsgs.VAT_NUMBER_ERROR,
    };

    fildsObject.logoData.required = true;
    fildsObject.logoData.validate = true;

    fildsObject.logoFileName.required = true;
    fildsObject.logoFileName.validate = true;


    fildsObject.taxCalculationTolerance.value = 0;

    return fildsObject;
  }

  public static searchRequest() {
    return {
      pageIndex: 0,
      pageSize: Integer.Max,
      metaData: "",
    };
  }

  public static toDBModel(model: OrganizationModel) {
    var result = FieldModel.getDBModel(model);

    let identity = {
      identityType: {
        code: +result?.identityType?.value || +result.identityType || null,
      },
      identityNumber: result.identityNumber,
    };

    result.Addresses.map(itm => {
      if (!itm.additionalNumber) itm.additionalNumber = null;
      itm.id = itm.isNew ? 0 : +itm.id;
      itm.isDefault = itm.isDefault !== true ? false : true;
      itm.isDefaultAddress = itm.isDefaultAddress !== true ? false : true;

      return itm;
    });


    // request.identityType = request?.identityType?.value;

    result = {
      ...result,
      businessCategory:
        result?.businessCategory?.value || result?.businessCategory?.label,
      identityInfo: identity,
      configuration: {
        taxCalculationTolerance: result.taxCalculationTolerance ? result.taxCalculationTolerance : null
      }
    };


    if (result?.currencyCode?.value || result?.currencyCode?.label)
      result.currencyCode = result?.currencyCode?.value || result?.currencyCode?.label;

    return result;
  }
}

export class OrganizationSearch {
  searchText?: any;

  public static getFieldsModel(model?: OrganizationSearch): any {
    if (!model) model = new OrganizationSearch();
    let fildsObject = FieldModel.buildFromObject(model);

    return fildsObject;
  }

  public static toDBModel(model: any) {
    let searchText = model.searchText.value;

    return {
      searchText,
    };
  }
}

export class OrganizationDbModel {
  organizationInfo: OrganizationInfo;
  status: OrgStatus;
  id: number;
  createdOn: string;
  createdBy: string;
  lastUpdatedOn: any;
  lastUpdatedBy: any;
  isActive: any;

  public static mapToFormModel(dbModel: OrganizationDbModel): any {
    const fildsObject = OrganizationModel.getFieldsModel();

    fildsObject.name.value = dbModel.organizationInfo?.name;
    fildsObject.vatRegistrationNumber.value =
      dbModel.organizationInfo?.vatRegistrationNumber;
    fildsObject.tin.value = dbModel.organizationInfo?.tin;
    fildsObject.businessCategory.value =
      dbModel.organizationInfo?.businessCategory;
    fildsObject.currencyCode.value = dbModel.organizationInfo?.currencyCode;
    fildsObject.identityNumber.value =
      dbModel.organizationInfo.identityInfo?.identityNumber;
    fildsObject.identityType.value =
      dbModel.organizationInfo.identityInfo.identityType?.code;
    fildsObject.code.value =
      dbModel.organizationInfo.identityInfo.identityType?.code;
    fildsObject.logoData.value = dbModel.organizationInfo?.logoData;
    fildsObject.logoFileName.value = dbModel.organizationInfo?.logoFileName;
    fildsObject.taxCalculationTolerance.value = dbModel.organizationInfo.configuration?.taxCalculationTolerance;
    fildsObject.Addresses.value = dbModel.organizationInfo.addresses;

    return fildsObject;
  }

  public static mapToListModel(dbModel: OrganizationDbModel): any {
    const model = new OrganizationListModel();

    model.id = dbModel.id;
    model.identityInfo = dbModel.organizationInfo.identityInfo;
    model.name = dbModel.organizationInfo?.name;
    model.vatRegistrationNumber =
      dbModel.organizationInfo?.vatRegistrationNumber;
    model.tin = dbModel.organizationInfo?.tin;
    model.businessCategory = dbModel.organizationInfo?.businessCategory;
    model.currencyCode = dbModel.organizationInfo?.currencyCode;
    model.Addresses = dbModel.organizationInfo.addresses;
    model.img = dbModel?.organizationInfo?.logoData;
    model.isActive = dbModel?.isActive;

    return model;
  }
}
export interface OrganizationInfo {
  identityInfo: IdentityInfo;
  name: string;
  vatRegistrationNumber: string;
  tin: string;
  businessCategory: string;
  currencyCode: string;
  addresses: Array<Addresses>;
  logoFileName: string;
  logoData: string;
  configuration: Configuration;
}
export interface IdentityInfo {
  identityType: IdentityType;
  identityNumber: string;
}

export interface IdentityType {
  description: string;
  code: number;
  source: string;
}

export interface OrgStatus {
  description: string;
  code: number;
  source: string;
}

export interface Configuration {
  taxCalculationTolerance: string;
}

export class Addresses {
  id: string;
  street: string;
  name: string;
  additionalStreet: string;
  buildingNumber: string;
  additionalNumber: any;
  city: string;
  postalCode: string;
  province: string;
  district: string;
  countryCode: any;
  country: any;
  isDefaultAddress: boolean;
  isActive: boolean;
  isNew: boolean;

  public static getAddFieldsModel(model?: Addresses): any {
    if (!model) {
      model = new Addresses();
      model.isNew = true;
    } else {
      if (model.id && model.id.toString().length != 13)
        model.isNew = false;
    }
    let fildsObject = FieldModel.buildFromObject(model);

    if (model.isNew) {
      fildsObject.id.value = Date.now();
      fildsObject.additionalNumber.value = "";
      fildsObject.isDefaultAddress.value = null;
      fildsObject.countryCode.defaultValue = "SA";
      fildsObject.countryCode.value = "SA";
    }
    else {
      fildsObject.countryCode.defaultValue = model.countryCode;
    }

    return this.setAddressFormValidations(fildsObject);
  }

  public static getEditFieldsModel(model?: Addresses): any {
    if (!model) {
      model = new Addresses();
      model.isNew = true;
    } else {
      if (model.id && model.id.toString().length != 13)
        model.isNew = false;
    }
    let fildsObject = FieldModel.buildFromObject(model);

    if (!model.isNew)
      fildsObject.countryCode.defaultValue = model.countryCode;


    return this.setAddressFormValidations(fildsObject);
  }

  public static setAddressFormValidations(fildsObject: any) {

    fildsObject.countryCode.required = true;
    fildsObject.countryCode.validate = true;


    fildsObject.name.required = true;
    fildsObject.name.validate = true;

    // fildsObject.province.required = true;
    // fildsObject.province.validate = true;
    fildsObject.province.regexValue = regexType.MIN_0_MAX_127;
    fildsObject.province.errorMessages = {
      error: regexMsgs.MIN_0_MAX_127,
    };

    fildsObject.city.required = true;
    fildsObject.city.validate = true;
    fildsObject.city.regexValue = regexType.MIN_1_MAX_127;
    fildsObject.city.errorMessages = {
      error: regexMsgs.MIN_1_MAX_127,
    };


    fildsObject.district.required = true;
    fildsObject.district.validate = true;
    fildsObject.district.regexValue = regexType.MIN_1_MAX_127;
    fildsObject.district.errorMessages = {
      error: regexMsgs.MIN_1_MAX_127,
    };

    fildsObject.street.required = true;
    fildsObject.street.validate = true;
    fildsObject.street.regexValue = regexType.MIN_1_MAX_1000;
    fildsObject.street.errorMessages = {
      error: regexMsgs.MIN_1_MAX_1000,
    };

    fildsObject.buildingNumber.required = true;
    fildsObject.buildingNumber.validate = true;
    fildsObject.buildingNumber.regexValue = regexType.IS_4_DIGITS;
    fildsObject.buildingNumber.errorMessages = {
      error: regexMsgs.IS_4_DIGITS,
    };

    fildsObject.postalCode.required = true;
    fildsObject.postalCode.validate = true;
    fildsObject.postalCode.regexValue = regexType.IS_5_DIGITS;
    fildsObject.postalCode.errorMessages = {
      error: regexMsgs.IS_5_DIGITS,
    }

    fildsObject.additionalStreet.validate = true;
    fildsObject.additionalStreet.regexValue = regexType.MIN_1_MAX_127;
    fildsObject.additionalStreet.errorMessages = {
      error: regexMsgs.MIN_1_MAX_127,
    };

    fildsObject.additionalNumber.regexValue = regexType.IS_4_DIGITS;
    fildsObject.additionalNumber.errorMessages = {
      error: regexMsgs.IS_4_DIGITS,
    }

    return fildsObject;
  }

  public static toDBModel(model: Addresses, isTheFirstAddress: boolean) {
    var result = FieldModel.getDBModel(model);

    result.country = (model?.countryCode?.value?.name && model?.countryCode?.value?.name != model?.country?.value) ?
      model?.countryCode?.value?.name || model?.countryCode?.value : model?.country?.value;

    result.countryCode = model?.countryCode?.value?.value
    result.isDefaultAddress = isTheFirstAddress ? true : result.isDefaultAddress === true ? true : false;
    return result;
  }

  public static prepareAddressListRow = (rowData: AddressSearchResult) => {
    return {
      id: rowData.id,
      name: rowData?.addressInfo.name,
      country: rowData?.addressInfo?.countryCode,
      countryCode: rowData?.addressInfo?.countryCode,
      province: rowData?.addressInfo.province,
      city: rowData?.addressInfo.city,
      district: rowData?.addressInfo.district,
      street: rowData?.addressInfo.street,
      additionalStreet: rowData?.addressInfo.additionalStreet,
      buildingNumber: rowData?.addressInfo.buildingNumber,
      postalCode: rowData?.addressInfo.postalCode,
      additionalNumber: rowData?.addressInfo.additionalNumber,
      isDefaultAddress: rowData.isDefaultAddress,

    }
  }
  public static prepareFormAddressListRow = (rowData: Addresses) => {
    return {
      province: rowData.province,
      city: rowData.city,
      district: rowData.district,
      street: rowData.street,
      additionalStreet: rowData.additionalStreet,
      buildingNumber: rowData.buildingNumber,
      postalCode: rowData.postalCode,
      additionalNumber: rowData.additionalNumber,
      isDefaultAddress: rowData.isDefaultAddress,
      isDefault: rowData.isDefaultAddress,
      isNew: rowData.isNew,
      id: rowData.id,
      name: rowData?.name,
      country: rowData?.country?.name || rowData?.country,
      countryCode: rowData.countryCode,
    }
  }
}

export class OrganizationListModel {
  id: number;
  identityInfo: IdentityInfo;
  name: string;
  vatRegistrationNumber: string;
  tin: string;
  businessCategory: string;
  currencyCode: string;
  Addresses: Array<Addresses>;
  img: string;
  isActive: boolean;
}

export interface AddressSearchResult {
  addressInfo: AddressInfo
  isDefaultAddress: boolean
  organizationId: number
  organizationName: any
  organizationLogoFileName: any
  organizationLogoData: any
  id: number
  createdOn: string
  createdBy: string
  lastUpdatedOn: any
  lastUpdatedBy: any
  isActive: boolean
}

export interface AddressInfo {
  name: string
  street: string
  additionalStreet: string
  buildingNumber: string
  additionalNumber: any
  city: string
  postalCode: string
  province: string
  district: string
  countryCode: string | any
}