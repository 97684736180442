const AppResources = {
  FAWATEER: "app.fawateer",
  GREETING: "app.greeting",
  ACTIVITY_STATUS: "app.activity_status",
  X4FATOORA: "app.x4fatoora",


  //Start view Historical

  STEP_ID: "app.step_id",
  TRANSACTION_CODE: "app.transaction_code",
  TRANSACTION_START_DATE: "app.transaction_start_date",
  TRANSACTION_END_DATE: "app.transaction_end_date",
  //End view Historical

  //Start view Qualification

  QUALIFICATION_ID: "app.qualification_id",
  QUALIFICATION_CODE: "app.qualification_code",
  MAJOR_CODE: "app.major_code",
  UNIVERSITY_CODE: "app.university_code",
  QUALIFICATION_STATUS: "app.qualification_status",

  //Start view Qualification
  //Start view NetPay

  NET_PAY: "app.net_pay",
  PAID_DATE: "app.qualification_code",
  GREGORIAN_DATE: "app.gregorian_date",
  ELEMENTS: "app.elements",

  //Start view NetPay
  //Start view Jobs

  JOB_POSITION_CODE: "app.job_position_code",
  JOB_CLASS_CODE: "app.job_class_code",
  POSITION_STATUS: "app.position_status",
  START_DATE: "app.start_date",
  END_DATE: "app.end_date",

  VACATION_ID: "app.vacation_id",

  VACATION_CODE: "app.vacation_code",

  //Start view jobs
  //Start view Jobs

  APPRAISAL_ID: "app.appraisal_id",
  APPRAISAL_TYPE_CODE: "app.appraisal_type_code",
  RESULT: "app.result",
  RATING_CODE: "app.rating_code",

  //Start view jobs

  DATE_TO: "app.date_To",
  TRANSACTION_TYPE: "app.transaction_Type",
  INSURANCE_COMPANY: "app.insurance_Company",
  PURPOSE: "app.purpose",
  STATUS: "app.status",
  BRANCH: "app.branch",
  TPA: "app.TPA",
  PAYSLIP: "app.payslip",
  ABSENCE: "app.absence",
  ACTIONS: "app.actions",
  REPORTS: "app.reports",
  VIEW_REPORT: "app.view_report",
  REQUESTS: "app.requests",
  REQUESTREPORT: "app.request_Report",
  MOBILE_NUMBER: "app.mobile_Number",
  SYSTEM_SETUP: "app.system_Setup",
  SEARCH: "app.search",
  RESET: "app.reset",
  DASHBOARD: "app.dashBoard",
  BENEFICIARY: "app.beneficiary",
  CREATEBENEFICIARY: "app.createBeneficiary",
  EDITBENEFICIARY: "app.editBeneficiary",
  FILTER: "app.filter",
  BLANKPAGE: "app.blankPage",
  CLAIMS: "app.claims",
  ELIGIBILITY: "app.eligibilityy",
  HOME: "app.home",
  PAYMENT: "app.payment",
  PREAUTHORIZATION: "app.preAuthorization",
  SETTINGS: "app.settings",
  PROFILE: "app.profile",
  THEME: "app.theme",
  MY_ACCOUNT: "app.my_account",
  USER_IMAGE: "app.user_Image",
  DOWNLOAD: "app.download",
  UPLOAD: "app.upload",
  CANCEL: "app.cancel",
  DATE_VALIDATION: "app.date_Validation",
  SELECT: "app.select",
  NODATA: "app.nodata",
  DATE: "app.date",
  NAME: "app.name",
  ["403"]: "app.forbidden",
  DOCUMENTID: "app.document_Id",
  FILEID: "app.file_id",
  CONTACTNO: "app.contact_no",
  DATEOFBIRTH: "app.date_of_birth",
  INSURANCEPLAN: "app.insurance_plan",
  ELIGIBILITYSTATUS: "app.eligibility_status",
  LOCAION: "app.location",
  IS_MANGER: "app.is_manger",
  IS_SYSTEM_ADMIN: "app.is_system_admin",
  MANAGER: "app.manager",
  POSITION: "app.position",
  TOTAL_HOURS: "app.total_hours",
  TOTAL_HOURS_8_Ratio: "app.total_hour_8_Ratio",
  HOURS_Ratio: "app.hours_ratio",
  TOTAL: "app.total",
  // PERIOD:'app.period',
  TOTAL_RESULTS: "app.total_results",
  MEMBER_CARD_ID: "app.member_Card_ID",
  ELIGIBILITY_ID: "app.eligibility_ID",
  INSURANCE_PLAN: "app.insurance_Plan",
  REQUEST_NEW_ELIGIBILITY: "app.request_New_Eligibility",
  BENEFICIARY_ID: "app.beneficiary_ID",
  ARABIC_LANGUAGE: "app.arabic_language",
  ENGLISH_LANGUAGE: "app.english_language",
  CHECK_ELIGIBILITY: "app.check_eligibility",
  FROM: "app.from",
  TO: "app.to",
  OK: "app.ok",
  CLEAR: "app.clear",
  INSURER: "app.insurer",
  SERVICE_DATE: "app.service_date",
  ADD_NEW_BENEFICIARY: "app.add_new_beneficiary",
  AGE: "app.age",

  // Start View Beneficiary
  PERSONAL_INFORMATION: "app.personal_information",
  ENEFICIARY_FILE_ID: "app.beneficiary_file_id",
  BENEFICIARY_NAME: "app.beneficiary_name",
  GENERATE: "app.generate",
  DATE_OF_BIRTH: "app.date_of_birth",
  BLOOD_GROUP: "app.blood_group",
  NATIONALITY: "app.nationality",
  RESIDENT_TYPE: "app.resident_type",
  DOCUMENT_TYPE: "app.document_type",
  DOCUMENT_ID: "app.document_id",
  PATIENT_PHONE_NUMBER: "app.patient_phone_number",
  EMERGENCY_PHONE_NUMBER: "app.emergency_phone_number",
  EMERGENCY_CONTACT_RELATION: "app.emergency_contact_relation",
  EMAIL: "app.email",
  MARTIAL_STATUS: "app.martial_status",
  PREFERRED_LANGUAGE: "app.preferred_language",
  ADDRESS: "app.address",
  BASIC_INFO: "app.basic_info",
  PROVINCE: "app.province",
  ADDITIONAL_STREET: "app.additional_street",
  MEMBERSHIP_NO: "app.membership_no",
  PPOLICY_NO: "app.Ppolicy_no",
  GROUP_NAME: "app.group_name",
  APPROVAL_LIMIT: "app.approval_limit",
  NETWORK_NO: "app.network_no",
  TERM: "app.term",
  CO_PAY: "app.co_pay",
  NOTES: "app.Notes",
  APPROVALS: "app.approvals",
  VIEWBENEFICIARY: "app.beneficiary_details",
  WEEK_ONE: "app.week_one",
  WEEK_TWO: "app.week_two",
  WEEK_THREE: "app.week_three",
  WEEK_FOUR: "app.week_four",
  WEEK_FIVE: "app.week_five",

  // End View Beneficiary

  // Start View Eligibility
  ELIGIBILITY_DETAILS: "app.eligibility_details",
  CONTACT_NUMBER: "app.contact_number",
  COVARAGE: "app.coverage",
  CLASS: "app.class",
  NETWORK: "app.network",
  DEPENDENT_RELATIONSHIP: "app.dependent_relationship",
  ROOM_TYPE: "app.room_type",
  PERIOD: "app.period",
  MAX_ALLOWED: "app. max_allowed",
  DEPARTMENT_NAME: "app.department_name",
  DEPARTMENT_ROLE: "app.department_role",
  TRANSACTION_DATE: "app.transaction_date",
  RESPONSE_TIME: "app.response_time",
  DOWNLOAD_TIME: "app.download_time",
  ELIGIBILITY_PURPOSE: "app.eligibility_purpose",
  INCLUSIONS: "app.inclusions",
  UNIT: "app.unit",
  LIMIT: "app.limit",
  MAX_OUT_OF_POCKET: "app.max_out_of_pocket",
  EXCEPTIONS: "app.Exceptions",
  EXCLUSIONS: "app. Exclusions",

  // End View Eligibility

  HIJRI: "app.hijri",
  SAVE: "app.save",
  VIEWELIGIBILITY: "app.viewEligibility",

  //Items Page
  ITEMS: "app.items",
  ITEM: "app.item",
  CHAPTER: "app.chapter",
  BLOCK: "app.block",
  ALIAS_CODE: "app.alias_code",
  ALIAS: "app.alias",
  CATEGORY: "app.category",
  CREATED_DATE: "app.created_date",
  SHORT_DESCRIPTION: "app.short_description",
  ITEM_CODE: "app.item_code",
  ADD_NEW_ITEM: "app.add_new_item",
  ADDITEM: "app.add_item",
  VIEWITEM: "app.view_item",
  EXPORT: "app.export",

  CODE: "app.code",
  LONG_DESCRIPTION: "app.long_description",
  OWNER: "app.owner",
  ADD_CATEGORY: "app.add_category",
  ADD_CHAPTER: "app.add_chapter",
  ADD_BLOCK: "app.add_block",
  ID: "app.id",
  BACK: "app.back",
  EDIT: "app.edit",
  DELETE: "app.delete",
  YES: "app.yes",
  NO: "app.no",
  DIAGNOSIS: "app.diagnosis",
  ADD_NEW_DIAGNOSIS: "app.add_new_diagnosis",
  PARENT: "app.parent",
  ADDDIAGNOSIS: "app.add_diagnosis",
  VERSION: "app.version",
  YEAR: "app.year",
  SECONDARY_ASSOCIATIONS: "app.secondary_associations",
  ASSOCIATION: "app.association",
  EDITITEM: "app.edit_item",
  GENDER: "app.gender",
  JOINING_DATE: "app.join_date",
  HIRE_DATE: "app.hire_date",
  TERMINATE_DATE: "app.terminate_date",

  // Start Practitioners

  PRACTITIONERS: "app.practitioners",
  DESCRIPTION: "app.description",
  ADD_NEW_PARTITIONER: "app.add_new_partitioner",
  LICENSE: "app.license",
  ENGLISH_NAME: "app.english_name",
  SPECIALTY: "app.Specialty",
  ROLE: "app.role",
  ACTION_FROM: "app.active_from",
  ACTION_TO: "app.active_to",
  CREATEPRACTITIONERS: "app.create_practitioners",
  CREATE_EMP: "app.create_emp",
  EDIT_EMP: "app.edit_emp",
  EDITPRACTITIONERS: "app.edit_practitioners",
  PARTITIONER_CODE: "app.partitioner_code",
  PRACTITIONER_SPECIALTY: "app.practitioner_specialty",
  PRACTITIONER_ROLE: "app.practitioner_role",
  VIEWPRACTITIONERS: "app.view_practitioners ",
  ADD: "app.add",
  TERMINOLOGY: "app.terminology",
  ADDTERMINOLOGY: "app.add_terminology",
  EDITTERMINOLOGY: "app.edit_terminology",
  VIEWTERMINOLOGY: "app.view_terminology",
  ADD_NEW_TERMINOLOGY: "app.add_new_terminology",
  CODE_SYSTEM: "app.code_system",
  VALUE_SET: "app.value_set",

  // Start TypesManagement
  SERVICES_MANAGEMENT: "app.servicesManagement",
  TYPESMANAGEMENT: "app.typesManagement",
  CODE_SYSTEM_URL: "app.codeSystemUrl",
  ADD_NEW_TYPE: "app.addNewType",
  CREATETYPESMANAGEMENT: "app.create_Types_Management",
  EDITTYPESMANAGEMENT: "app.edit_Types_Management",
  VIEWTYPESMANAGEMENT: "app.types_Management_details",

  PAYERS: "app.payers",
  PAYER: "app.payer",
  ADDPAYER: "app.add_payer",
  EDITPAYER: "app.edit_payer",
  VIEWPAYER: "app.view_payer",
  ADD_NEW_PAYER: "app.add_new_payer",
  NAME_AR: "app.name_ar",
  LastName: "LastName",
  NAME_EN: "app.name_en",
  UNIFIED_ID: "app.unified_id",
  VAT_NUMBER: "app.vat_number",
  COUNTRY: "app.country",
  CITY: "app.city",
  CONTACT_NAME: "app.contact_name",
  PHONE_NUMBER: "app.phone_number",
  BUILDING_NUMBER: "app.building_number",

  STREET_NAME: "app.street_name",
  DISTRICT: "app.district",
  POSTAL_CODE: "app.postal_code",
  ADDITIONAL_NUMBER: "app.additional_number",
  CREATE: "app.create",
  CREATE_AND_NEW: "app.create_and_new",
  EXT: "app.ext",

  // Start PROVIDER_GROUPS
  PROVIDER: "app.provider",
  PROVIDERGROUPS: "app.provider_groups",
  ADDNEWGROUP: "app.add_new_group",
  CREATEPROVIDERGROUPS: "app.create_provider_groups",
  EDITPROVIDERGROUPS: "app.edit_provider_groups",
  VIEWPROVIDERGROUPS: "app.provider_groups_details",

  // Start Facilities
  FACILITIES: "app.facilities",
  HOSPITALCODE: "app.hospitalCode",

  LICENSE_NO: "app.license_no",
  LICENSE_PERIOD: "app.license_period",
  LICENSE_START: "app.license_start",
  LICENSE_END: "app.license_end",
  CHHI_ID: "app.chhi_id",
  ADDFACILITIES: "app.add_facilities",
  EDITFACILITIES: "app.edit_facilities",
  VIEWFACILITIES: "app.view_facilities",

  SERVICECATEGORIES: "app.service_Categories",
  VIEWSERVICECATEGORIES: "app.view_service_Categories",
  EDITSERVICECATEGORIES: "app.edit_service_Categories",
  ADDSERVICECATEGORIES: "app.add_service_Categories",
  ADD_NEW_SERVICE_CATEGORIE: "app.add_new_service_Categorie",

  SERVICESUBGROUPS: "app.service_sub_groups",
  VIEWSERVICESUBGROUPS: "app.view_service_sub_groups",
  EDITSERVICESUBGROUPS: "app.edit_service_sub_groups",
  ADDSERVICESUBGROUPS: "app.add_service_sub_groups",
  ADD_NEW_SERVICE_SUB_GROUPS: "app.add_new_service_sub_groups",
  SERVICE_GROUP: "app.service_group",

  SERVICECATALOG: "app.service_catalog",
  ADDSERVICECATALOG: "app.add_service_catalog",
  EDITSERVICECATALOG: "app.edit_service_catalog",
  VIEWSERVICECATALOG: "app.view_service_catalog",
  CATALOG_NAME: "app.catalog_name",
  ADD_NEW_SERVICE_CATALOG: "app.add_new_service_catalog",

  SERVICEITEMS: "app.service_items",
  ADDSERVICEITEMS: "app.add_service_items",
  EDITSERVICEITEMS: "app.edit_service_items",
  VIEWSERVICEITEMS: "app.view_service_items",
  SERVICEMANAGEMENTS: "app.service_items",

  SERVICE: "app.service",
  SERVICE_SUB_GROUP: "app.service_sub_group",
  SERVICE_CATEGORY: "app.service_category",

  PRICELISTS: "app.price_lists",
  ADDPRICELISTS: "app.add_price_lists",
  EDITPRICELISTS: "app.edit_price_lists",
  VIEWPRICELISTS: "app.view_price_lists",

  MALE: "app.male",
  FEMALE: "app.female",
  ADD_NEW_PRICE_LISTS: "app.add_new_price_lists",

  SELECTED_FILES: "app.selected_files",
  DRAG_AND_DROP_A_FILE_HERE_OR_CLICK: "app.drag_and_drop_file",
  UNITPRICE: "app.unit_price",
  VAT_CATEGORY: "app.vat_category",
  NON_STANDARD_CODE: "app.non_standard_code",
  PACKAGE: "app.package",
  // Start ServiceGroups

  SERVICEGROUPS: "app.service_Groups",
  ADD__NEW_SERVICE_GROUPS: "app.add_new_service_groups",
  ADDSERVICEGROUPS: "app.add_servicegroups",
  EDITSERVICEGROUPS: "app.edit_servicegroups",
  VIEWSERVICEGROUPS: "app.view_servicegroups",
  LOGIN: "app.login",
  USERNAME: "app.username",
  PASSWORD: "app.password",

  // Start PriceLists
  ITEM_TYPE: "app.item_type",
  ITEM_CATEGORY: "app.item_category",
  ITEM_CHAPTER: "app.item_chapter",
  ITEM_BLOCK: "app.item_block",

  PRE_AUTHORIZATION_ID: "app.pre_authorization_id",
  ADD_NEW_PREAUTHORIZATION: "app.add_new_pre_authorization",
  ADDPREAUTHORIZATION: "app.add_pre_authorization",
  EDITPREAUTHORIZATION: "app.edit_pre_authorization",
  VIEWPREAUTHORIZATION: "app.view_pre_authorization",

  PRE_AUTHORIZATION_INFO: "app.pre_authorization_info",
  DATE_ORDERED: "app.date_ordered",
  TYPE: "app.type",
  SUBTYPE: "app.subtype",
  SEQUENCE: "app.sequence",
  CODE_DESCRIPTION: "app.code_description",
  ON_ADMISSION: "app.on_admissino",

  VIEWDIAGNOSIS: "app.view_diagnosis",
  EDITDIAGNOSIS: "app.edit_diagnosis",

  DOWNLOAD_SAMPLE: "app.download_sample",

  VIEWPAYMENT: "app.view_Payment",

  POLICYLISTS: "app.policies_lists",
  PLANS: "app.plans",
  INSURANCEPOLICIES: "app.insurancePolicies",
  POLICIES: "app.policies",
  ADDPOLICIESLISTS: "app.add_policy_lists",
  EDITPOLICIESLISTS: "app.edit_policy_lists",
  VIEWPOLICIESLISTS: "app.view_policy_lists",
  ENCOUNTERS: "app.encounters",
  ENCOUNTER: "app.encounter",

  ENCOUNTERS_CLAIMS: "app.encounters-claims",

  POLICYMANAGEMENT: "app.PolicyManagement",

  ADDCLAIM: "app.addClaim",
  EDITCLAIM: "app.editClaim",
  VIEWCLAIM: "app.viewClaim",

  // Start Reports
  REPORT: "app.Report",
  COLLECTIONREPORT: "app.collectionReport",
  AGINGREPORT: "app.agingReport",
  DICTIONARIES: "app.dictionaries",
  BENEFITS: "app.benefits",
  ADDBENEFIT: "app.add_benefit",
  EDITBENEFIT: "app.edit_benefit",
  VIEWBENEFIT: "app.view_benefit",
  ADD_NEW_BENEFIT: "app.add_new_benefit",

  PATIENT: "app.patient",
  CREATEPATIENT: "app.createPatient",
  EDITPATIENT: "app.editPatient",
  PATIENT_ID: "app.patient_ID",
  ADD_NEW_PATIENT: "app.add_new_patient",
  VIEWPATIENT: "app.view_patient",
  SUMMARY: "app.dashboard_summary",
  REJECTION: "app.dashboard_rejection",
  REJECTED: "app.rejected",
  PENDING: "app.pending",
  SUBMITTED: "app.submitted",
  SUBMITTE: "app.submitte",
  FOLLOWUPS: "app.followUps",
  ERROR: "app.error",
  SOME_THING_WENT_WRONG: "app.something_went_wrong_message",
  error: "app.close",
  CHANGE_STATUS_CONFIRMATION_MSG: "app.change_status_confirmation_msg",
  CONFIRMATION: "app.confirmation",
  CLOSE: "app.close",
  EMPLOYEESPROCEDURES: "app.employees_procedures",
  VIEWEMPLOYEEPROCEDURE: "app.view_employees_procedures",
  JOBSPROCEDURES: "app.jobs_procedures",
  SALARYRALLIES: "app.salary_rallies",
  VIEWSALARYRALLIES: "app.view_salary_rallies",

  VACATIONS: "app.vacations",
  VIEWVACATIONS: "app.view_vacations",

  QUALIFICATIONS: "app.qualifications",
  VIEWQUALIFICATIONS: "app.view_qualifications",
  QUARTRY: "app.quarter",
  MONTHLY: "app.monthly",
  YEARLY: "app.yearly",
  WEEKLY: "app.weekly",
  REVIEWS: "app.reviews",
  VIEWREVIEWS: "app.view_reviews",

  PERSONALINFORMATION: "app.personal_information",
  JOBDATA: "app.job_data",
  VACATIONDATA: "app.vacation_data",
  PERFORMANCEDATA: "app.performance_data",
  RATINGDATA: "app.rating_data",
  SALARYDATA: "app.salary_data",
  LOCATIONDATA: "app.location_data",

  EMPLOYEESREPORTS: "app.employees_reports",
  PERFORMANCEREPORTS: "app.performance_reports",
  REQUESTS_MANAGEMENT: "app.requests_management",
  CATEGORIES_MATCHING_MANAGEMENT: "app.categories_matching_management",
  REPORTS_MANAGEMENT: "app.reports_management",
  RESEND: "app.resend",
  COMMITMENT_RATE: "app.commitment_rate",
  PROCEDURES_TYPES: "app.procedures_types",
  EMPLOYEE_TYPE: "app.employee_type",
  SYSTEM_PROPERTIES_TITLE: "app.systemPropertiesTitle",
  TITLE: "app.title",
  MATCHING_DATA: "app.matching_data",
  VIEWJOBPROCEDURE: "app.viewJobProcedure",
  AR_FIRSTNAME: "app.ar_firstname",
  AR_SECONDNAME: "app.ar_secondname",
  AR_thirdAME: "app.ar_thirdame",
  AR_FourthNAME: "app.ar_fourthname",
  EN_FIRSTNAME: "app.en_firstname",
  EN_SECONDNAME: "app.en_secondname",
  EN_thirdAME: "app.ar_thirdame",
  EN_FourthNAME: "app.en_fourthname",
  //Start Eltiza
  CONFIGURATIONS: "app.configurations",
  ORGANIZATION_STRUCTURE: "app.organization_structure",
  REVENUE_STREAMS: "app.revenue_streams",
  PROJECTS: "app.projects",
  PROJECT_NUMBER: "app.project",
  CHARGE_CODES: "app.charge_codes",
  ADD_NEW_CHARGE_CODES: "app.add_new_charge_codes",
  MATERIAL_STATUS: "app.material_status",
  ADD_CHARGE_CODE_CONF: "app.add_charge_code_conf",
  SINGLE: "app.single",
  MARRIED: "app.married",
  DIVORCED: "app.divorced",
  WIDOWER: "app.widower",
  UNSELECT: "app.unselect",
  // Start Organization Structure
  DEPARTMENT: "app.department",
  EMPLOYEE_DEPARTMENT: "app.employee_department",
  UNITS: "app.units",
  SECTION: "app.section",
  ACTIVE: "status.active",
  IN_ACTIVE: "status.inActive",
  EMPLOYEES: "app.employees",
  EXAMPLE: "app.examples",
  EMPLOYEE_TIME_SHEET: "app.employee_time_sheet",
  APPROVAL: "app.approval",
  DETAILED_REPORT: "app.detailed_report",
  AGREE: "app.agree",
  DISAGREE: "app.disagree",
  WUTHDRAWAL: "app.wuthdrawal",
  TODAY: "app.today",
  NEXT: "app.next",
  PREVIOUS: "app.previous",
  UPDATE: "app.update",
  SUMMARY_REPORT: "app.summary_report",
  GENERAL_REPORT: "app.general_report",
  COST_REPORT: "app.cost_report",
  COPY_WEEK: "app.copy_week",
  DELETED_DEILY_WORK_ROW: "app.deleted_deily_work_row",
  DRAFT: "app.draft",
  NO_ACTIONS: "app.no_actions",
  APPROVED: "app.approved",
  APPROVE: "app.approve",
  CUSTOMER: "app.customer",
  STARTDATE: "app.start_date",
  ENDDATE: "app.end_date",
  CREATED_SUCCESS: "app.created_success",
  DELETED_SUCCESS: "app.deleted_success",
  UPDATED_SUCCESS: "app.updated_success",
  B2B_B2C_ERROR_MESSAGE: "app.b2b_b2c_error_message",
  SIMULATOR_PRODUCTION_ERROR_MESSAGE: "app.simulator_production_error_message",
  DELETE_CONFIRMATION_MESSAGE: "app.delete_confirmation_message",
  LOGOUT: "app.logout",
  OOPS: "app.oops",
  HOME_PAGE: "app.home_page",
  MSG404: "app.msg_404",
  SOMETHING_WENT_WRONG_MESSAGE: "app.something_went_wrong_message",
  ADMINISTRATIVE: "app.administrative",
  BILLABLE: "app.billable",
  DEVELOPMENT: "app.development",
  OTHERS: "app.others",
  CHARGECODE_ALREDY_EXIST: "app.chargecode_alredy_exist",
  REJECT_REASON: "app.reject_reason",
  CHANGE_PASSWORD: "app.change_password",
  OLD_PASSWORD: "app.old_password",
  NEW_PASSWORD: "app.new_password",
  CONFIRM_PASSWORD: "app.confirm_password", //confirm password,
  FORGET_PASSWORD: "app.forget_password", //forget password
  WELCOME_LOGIN: "app.welcome_login",
  SEND: "app.send",
  RESET_PASSWORD: "app.reset_password",
  RESET_PASSWORD_MESSAGE: "app.reset_password_message",
  HOURS_VALIDATIONS_MESSAGE: "app.hours_validations_message",
  NUMBER: "app.number",
  DIRECTREPORTEES: "app.directreportees",
  UNLOCK: "app.unlock",
  CONFIGURATION_HOUR: "app.configuration_hour",
  HOURS: "app.hours",

  /////////////////////////////// Start X4Fatoora

  ORGANIZATION_MANAGEMENT: "app.organization_management",
  INVOICES_BOOK_MANAGEMENT: "app.invoices_book_management",
  ONBOARDING: "app.onboarding",
  SIMULATION: "app.production",
  PRODUCTION: "app.simulation",

  ADD_ORGANIZATION: "app.add_organization",
  EDIT_ORGANIZATION: "app.edit_organization",
  VIEW_ORGANIZATION: "app.view_organization",

  BUSINESS_CATEGORY: "app.businessCategory",
  FIRST_NAME: "app.firstName",
  IDENTITY_TYPE: "app.identityType",
  IDENTITY_NUMBER: "app.identityNumber",
  VAT_REGISTRATION_NUMBER: "app.vatRegistrationNumber",
  VAT_REGISTRATION: "app.vatRegistratio",
  TAX_IDENTIFICATION_NUMBER: "app.taxIdentificationNumber",
  CURRENCY: "app.currency",
  LOGO: "app.logo",
  UPLOAD_IMAGE_ERROR: 'app.upload_image_error',

  INVOICES: "app.invoices",
  ADD_INVOICES_BOOK: "app.add_invoices_book",
  EDIT_INVOICES_BOOK: "app.edit_invoices_book",
  VIEW_INVOICES_BOOK: "app.view_invoices_book",
  CREATE_NEW_INVOICES_BOOK: "app.create_new_invoices_book",

  INVOICING_TYPE: "app.invoicing_type",
  ORGANIZATION: "app.organization",
  ORGANIZATION_ID: "app.organization_id",
  LANGUAGE: "app.language",
  PREFERRED_ADDRESS: "app.preferred_address",
  TAGS: "app.tags",
  INVOICE_BOOK_DETAILS: "app.invoice_book_details",
  INVOICE_TYPE: "app.invoice_type",
  ONBOARD: "app.onboard",
  RENEW: "app.renew",
  OTP: "app.otp",
  OTP_VERIFICATION: "app.otp_verification",
  OTP_MSG: "app.otp_msg",
  OTP_MSG_ERROR: "app.otp_msg_error",
  VALIDATE: "app.validate",

  INVOICE_NUMBER: "app.invoice_number",
  ISSUE_DATE: 'app.issue_date',
  INVOICES_BOOK: "app.invoices_book",
  AMOUNT: "app.amount",
  DISCOUNT: "app.discount",
  TAX: "app.tax",
  TOTAL_AMOUNT: "app.total_amount",

  INVOICE_DATE_FROM: "app.invoice_date_from",
  INVOICE_DATE_TO: "app.invoice_date_to",
  CLIENT_ID: "app.client_id",
  SERIAL_NUMBER: "app.serial_number",
  SEQUENCE_NUMBER: "app.sequence_number",
  TAX_ID: "app.tax_id",
  CLIENT_VAT_NUMBER: "app.client_vat_number",
  CREATE_NEW_INVOICES_BOOK_ONBOARDING: "app.create_new_invoices_book_onboarding",
  ADD_ONBOARDING: "app.add_onboarding",
  EDIT_ONBOARDING: "app.edit_onboarding",
  VIEW_ONBOARDING: "app.view_onboarding",
  ONBOARDING_PROGRESS: "app.onboarding_progress",
  PROGRESS: "app.progress",
  PROCEED: "app.proceed",
  fawateer: "app.fawateer",
  RETRY: "app.retry",
  WARNING_LIST: "app.warning_list",
  ERROR_LIST: "app.error_list",
  INFO_LIST: "app.info_list",
  CREATED: "app.created",
  ONBORDING_PENDING: "app.onbording_pending",
  READY: "app.ready",
  NEW: "app.new",
  CSR_GENERATION: "app.csr_generation",
  COMPLIANCE_CSID: "app.compliance_csid",
  COMPLIANCE_CHECKS: "app.compliance_checks",
  PRODUCTION_CSID: "app.production_csid",
  COMPLETED: "app.completed",
  FAILED: "app.failed",
  NOT_STARTED: "app.not_started",
  FAWATEER_LIST: "app.fawateer_list",
  IS_DEFAULT: "app.is_default",
  ALREADY_HAVE_DEFAULT: "app.already_have_default",
  MIN_2_MAX_2000: "app.min_2_max_2000",
  MIN_2_MAX_100: "app.min_2_max_100",
  MIN_2_MAX_30: "app.min_2_max_30",
  MIN_2_MAX_20: "app.min_2_max_20",
  MIN_2_MAX_10: "app.min_2_max_10",
  MIN_2_MAX_5: "app.min_2_max_5",
  MIN_1_MAX_3: "app.min_1_max_3",
  VAT_NUMBER_ERROR: "app.vat_number_error",
  EQUAL_TO_10: "app.equal_to_10",
  ORDER_NUMBER: "app.order_number",
  ORGANIZATION_DETAILS: "app.organization_details",
  COMMERCIAL_REGISTRATION_NUMBER: "app.commercial_registration_number",
  CRN: "app.commercial_registration_number",
  MOMRAH: "app.momra",
  MOMRA: "app.momra",
  MOM: "app.momra",
  MLSD: "app.mlsd",
  MHRSD: "app.mlsd",
  MLS: "app.mlsd",
  CODE_700: "app.code_700",
  "700": "app.code_700",
  SAGIA: "app.sagia",
  MISA: "app.sagia",
  SAG: "app.sagia",
  NATIONAL_ID: "app.national_id",
  GCC: "app.gcc",
  IQAMA: "app.iqama",
  PASSPORT: "app.passport",
  OTHER_ID: "app.other_id",
  OTH: "app.other_id",
  START: "app.start",
  ADDRESS_NAME: "app.address_name",
  SA: "country.sa",
  AR: "app.arabic_language",
  EN: "app.english_language",
  DEBIT_NOTE: "app.debit_note",
  CREDIT_NOTE: "app.credit_note",
  TAX_INVOICE: "app.tax_invoice",
  REPORTED: "app.reported",
  REPORTED_WITH_WARNINGS: "app.reported_with_warnings",
  CLEARED: "app.cleared",
  CLEARED_WITH_WARNINGS: "app.cleared_with_warnings",
  CSR_GENERATED: "app.csr_generated",
  CSR_GENERATION_FAILED: "app.csr_generation_failed",
  COMPLIANCE_CSID_OBTAINED: "app.compliance_csid_obtained",
  COMPLIANCE_CSID_FAILED: "app.compliance_csid_failed",
  COMPLIANCE_CHECK_DONE: "app.compliance_check_done",
  COMPLIANCE_CHECK_FAILED: "app.compliance_check_failed",
  PRODUCATION_CSID_FAILED: "app.producation_csid_failed",
  RENEWAL_PRODUCTION_CSID_FAILED: "app.renewal_production_csid_failed",
  EXPIRED: "app.expired",

  ONBOARDING_PENDING: "app.onboarding_pending",
  ONBOARDING_FAILED: "app.onboarding_failed",
  PROD_CSID_EXPIRED: "app.prod_csid_expired",
  PROD_CSID_RENEWAL_PENDING: "app.prod_csid_renewal_pending",
  PROD_CSID_RENEWAL_FAILED: "app.prod_csid_renewal_failed",
  TIN: "app.tin",
  ADDRESS_ADDITIONAL_NUMBER: "app.address_additional_number",
  IDENTITY_ID_NOT_UNIQUE: "app.identity_id_not_unique",
  PRIMARY: "app.primary",
  NOT_PRIMARY: "app.not_primary",
  INVALID_INPUT: "app.invalid_input",
  PRIMARY_ADDRESS_REQUIRED: "app.primary_address_required",
  METADATA: "app.metadata",
  ATTRIBUTE_NAME: "app.attribute_name",
  ATTRIBUTE_DESCRIPTION: "app.attribute_description",
  PRIORITY: "app.proiority",
  VISIBLE: "app.visible",
  ORDER_EXIST_ERROR: "app.order_exist_error",
  ROLES_MANAGEMENT: "app.roles_management", 
  LOGGERS_MANAGEMENT:"app.loggers_management", 
  ADD_ROLE: "app.ADD_ROLE",
  LEVEL: "app.level",
  SAVED_SUCCESSFULLY: "app.SAVED_SUCCESSFULLY",
  MODULE: "app.module",
  ROLE_INFO: "app.role_info",
  ARABIC_NAME: "app.arabic_name",
  ROLE_PERMISSIONS: "app.role_permissions",
  CREATEROLE: "app.createRole",
  EDITROLE: "app.editRole",
  VIEW_USER: "app.view_user",
  ADD_USER_ROLE: "app.add_user_role",
  USERSMANAGEMENT: "app.usersmanagement",
  ROLES: "app.roles",
  ADD_ROLES: "app.add_roles",
  USER: "app.user",
  ADD_USER: "app.add_user",
  USERS_MANAGEMENT: "app.users_management",
  ORG_USERS_MANAGEMENT: "app.org_users_management",
  EDIT_USER: "app.edit_user",
  UNIVERSAL_ORG_ACCESS: "app.universal_org_access",
  TENANT_CONTEXT: 'app.tenant_context',
  ORGANIZATION_CONTEXT: 'app.organization_context',
  TENANT_LEVEL: 'app.tenant_level',
  ORGANIZATION_LEVEL: 'app.organization_level',
  TENANT: 'app.tenant',
  ORGANIZATIONS: 'app.organizations',
  IS_OFF_LINE: "app.is_Off_line",
  OFF_LINE: "app.off_line",
  ON_LINE: "app.on_line",
  PLACEMENT: "app.placement",
  MIN_0_MAX_127: "app.MIN_0_MAX_127",
  MIN_1_MAX_127: "app.MIN_1_MAX_127",
  MIN_1_MAX_1000: "app.MIN_1_MAX_1000",
  IS_10_DIGITS: "app.is_10_digits",
  IS_5_DIGITS: "app.is_5_digits",
  IS_4_DIGITS: "app.is_4_digits",
  BELONGS_TO: "app.belongs_to",
  ORDER: "app.order",
  ERRORS: "app.errors",
  WARNINGS: "app.warnings",
  PHONE_NUMBER_ERROR: "app.phone_number_error",
  LOGO_50KB_SIZE_ERROR: "app.logo_50kb_size_error",
  LOGO_SIZE_ERROR: "app.logo_size_error",
  LOGO_DIMENSIONS_ERROR: "app.logo_dimensions_error",
  LOGO_320_DIMENSIONS_ERROR: "app.logo_320_dimensions_error",
  USED_ROLE_ERROR: "app.used_role_error",
 
  MESSAGE_ID: "app.messageId",
  MESSAGE: "app.message",
  LOG_LEVEL: "app.logLevel",
  DIRECTION: "app.direction",
  DURATION: "app.duration", 
  IS_INTERNAL: "app.isInternal", 

  REQUEST_HEADERS: "app.headers",
  REQUEST_BODY:  "app.requestBody",
  RESPONSE_BODY:  "app.responseBody",  
  INNER_REQUEST:  "app.innerRequest",  
  HOST:"app.host", 
  VIEW_LOGGER: "app.view_logger",
  DUPLICATE_ROLE_ERROR: "app.duplicate_role_error",
  DUPLICATE_USER_ERROR: "app.duplicate_user_error",
  USER_ADDED_SUCCESSFULLY: "app.user_added_successfully",
  TAX_CALCULATION_TOLERANCE: "app.tax_calculation_tolerance",
  NUMBER_OPTIONAL: "app.number_optional",
  MAX_TAX_CALCULATION_TOLERANCE_GREATER_ERROR:"app.max_tax_calculation_tolerance_greater_error",
  MIN_TAX_CALCULATION_TOLERANCE_SMALLER_ERROR:"app.min_tax_calculation_tolerance_smaller_error",
  RESPONSE_ERROR: "app.response_error",
  VALIDATION_LOG: "app.validation_log",
  SOURCE: "app.source",
  SOURCES: "app.sources",
  SOURCE_NAME: "app.source_name",
  VALUE: "app.value",
  INVOICE_DETAILS: "app.invoice_details"
};

const Resources = {
  ...AppResources,
};

export default Resources;

export { AppResources };
