import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import Button from '../Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import CircularSpinner from '../../Controls/Fallbacks/CircularSpinner';
import { StyledDialogTitle, StyledDialogTitleClose, StyledDialogContent, StyledDialogActions, StyledDialogChildren, DialogChildren }
    from './styles';
import { SuccessIcon, InfoIcon, WarningIcon, ErrorIcon } from '../Icons/Icons';
import useTranslation from '../../Hooks/Shared/useTranslation';

export const IconTypes = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

export interface ModalProps {
    children?: any,
    title?: any;
    open?: any;
    path?: any;
    loading?: any;
    divider?: any;
    actions?: any;
    handleClose?: any;
    maxWidth?: any;
    status?: any;
};

const Modal = ({ children, title = '', open, path, loading, divider = true, actions, handleClose, maxWidth, status }: ModalProps) => {

    const { T } = useTranslation();

    const SweetAlert = (status: any) => {
        switch (status) {
            case IconTypes.SUCCESS:
                return <SuccessIcon
                    style={{ display: 'flex', width: '100%' }}
                    width={100}
                    height={79} />
            case IconTypes.INFO:
                return <InfoIcon
                    style={{ display: 'flex', width: '100%' }}
                    width={100}
                    height={79} />
            case IconTypes.WARNING:
                return <WarningIcon
                    style={{ display: 'flex', width: '100%' }}
                    width={100}
                    height={79} />
            case IconTypes.ERROR:
                return <ErrorIcon
                    style={{ display: 'flex', width: '100%' }}
                    width={100}
                    height={79} />
            default:
                return null;
        }
    }

    //#region :: useEffect
    useEffect(() => {
    }, [open]);
    //#endregion

    return (
        <Dialog
            maxWidth={maxWidth}
            open={open}
            fullWidth={true}
            onClose={handleClose}>

            <DialogTitle onClose={handleClose}>
                {title && T(title)}
            </DialogTitle>

            <StyledDialogContent
                dividers={divider}
                className={loading ? 'text-center' : ''}>
                {loading
                    ? <CircularSpinner />
                    : <>
                        {SweetAlert(status)}
                        {status !== undefined
                            ? <StyledDialogChildren>{children}</StyledDialogChildren>
                            : <DialogChildren>{children}</DialogChildren>}
                    </>}
            </StyledDialogContent>

            {/* Render Modal Actions */}
            {!loading &&
                actions &&
                actions.length &&
                <StyledDialogActions>
                    {actions.map((item: any) => {
                        return (
                            <Button
                                type={item.type}
                                key={item.id}
                                variant={item.variant}
                                onClick={item.action}
                                customeColor={item.customeColor}
                                color={item.color}
                                bgColor={item.bgColor}
                                label={item.title && T(item.title)} />
                        );
                    })}
                </StyledDialogActions>
            }
        </Dialog>
    );
};

//#region :: Dialog Title Component
export interface DialogTitleProps {
    children: any;
    onClose?: any;
};

const DialogTitle = ({ children, onClose, ...other }: DialogTitleProps) => {

    return (
        <StyledDialogTitle
            {...other}>
            <Typography style={{ marginBottom: '15px', textAlign: 'center', fontWeight: 'bolder', fontSize: '1.5rem' }}>
                {children}
            </Typography>
            {onClose
                ? (<StyledDialogTitleClose
                    aria-label='close'
                    onClick={onClose}>
                    <CloseIcon />
                </StyledDialogTitleClose>)
                : null}
        </StyledDialogTitle>
    )
};
//#endregion

//#region :: Validation PropTypes
Modal.propTypes = {
    children: propTypes.any,
    title: propTypes.string,
    open: propTypes.bool,
    onClose: propTypes.func,
    loading: propTypes.bool,
    divider: propTypes.bool,
    actions: propTypes.array
};
//#endregion

export default Modal;