import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ForbiddenErrorImg } from '../../../Controls/Icons/Icons';
import '../style.css';

const ForbiddenError = () => {
    
    return (
        <Grid item xs={12} spacing={1}>
            <div className="unauthorized-Error">
                <ForbiddenErrorImg />
                <div className="unauthorized-Error-button">
                    <Box p={1}>
                        <h1 style={{ fontFamily: 'cairo' }}>المستخدم غير فعال, الرجاء التواصل مع المدير المسؤول</h1>
                    </Box>
                </div>
            </div>
        </Grid>
    );
};

export default ForbiddenError;