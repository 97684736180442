import XLSX from 'xlsx';
import invoice from '../assets/files/Invoice.pdf';
import { saveAs } from 'file-saver';


//JSON.stringify(content, null, 4);
const Download = (content: any) => {
    const blob = new Blob([content]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    var anchor = document.createElement('a');
    anchor.setAttribute('href', fileDownloadUrl);
    anchor.setAttribute('download', 'fileName.json');
    anchor.click();
    URL.revokeObjectURL(fileDownloadUrl);
};




function downloadPDF() {
    const fileUrl = invoice; // Replace with your PDF file URL

    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
        if (xhr.status === 200) {
            const blob = new Blob([xhr.response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'your-filename.pdf'; // Replace with the desired filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }
    };

    xhr.send();
}

function downloadPDFFromBlob(blobText: string, fileName: any) {
        const blob = new Blob([blobText], { type: 'application/pdf' });

        const reader = new FileReader();

        reader.onloadend = function () {
            // The result will be the base64 representation of the Blob
            const base64String = reader.result.split(',')[1];
        
            // Create a Blob from the base64 string
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const fileBlob = new Blob([byteArray], { type: 'application/pdf' });
        
            // Create a link element
            const link = document.createElement('a');
        
            // Set the download attribute and create a URL for the Blob
            link.download = 'your-file-name.pdf'; // Replace with the desired file name
            link.href = window.URL.createObjectURL(fileBlob);
        
            // Append the link to the document and trigger a click
            document.body.appendChild(link);
            link.click();
        
            // Remove the link from the document
            document.body.removeChild(link);
        };
        

        reader.readAsDataURL(blob);
        //console.log(reader);
        
            // FileSaver.saveAs(blob, "invoice.pdf");
            
        // saveAs(blob, 'your-file-name.pdf');
        // const url = window.URL.createObjectURL(blob);

        // const a = document.createElement('a');
        // a.href = url;
        // a.download = fileName; // Replace with the desired filename
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
        // window.URL.revokeObjectURL(url);

}

const downloadFile = (blobText: string, fileName) => {
    const blob = new Blob([blobText])
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

const handlerDownloadExcelFromBlob = (blob: Blob, fileName: any) => {
    try {
        const fileDownloadUrl = URL.createObjectURL(blob);
        var anchor = document.createElement('a');
        anchor.setAttribute('href', fileDownloadUrl);
        anchor.setAttribute('download', fileName);
        anchor.click();
        URL.revokeObjectURL(fileDownloadUrl); 
    } catch (error) {
        console.error(error)
    }

}

function downloadPdf(pdfData) {
    // Assume that your PDF data is stored in a variable named 'pdfData'
    // For example, you may receive it from an API response

    // Convert the base64 PDF data to a Uint8Array
    const uint8Array = new Uint8Array(atob(pdfData).split('').map(char => char.charCodeAt(0)));

    // Create a Blob
    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and create a URL for the Blob
    link.download = 'your-file-name.pdf'; // Replace with the desired file name
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document and trigger a click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
}


const DownloadExcel = (tableData: Array<any> = [], fileName = 'Excel', sheetName = `${fileName}-Sheet`) => {
    const newData = tableData.map(row => {
        delete row.tableData;
        delete row.action;
        return row;
    });

    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
    //Buffer

    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

function ConvertJsonToExcel(rawData: Array<any>, fileName: string = "result", columnNames: any = null) {
    try {
        if (!rawData || !Array.isArray(rawData) || !rawData.length) return;

        if (!columnNames || !Array.isArray(columnNames) || !columnNames.length) {
            columnNames = Object.keys(rawData[0]);
        }

        const ws = XLSX.utils.json_to_sheet(rawData, { header: columnNames });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'RCM Sheet');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataView = new DataView(excelBuffer);
        const blob = new Blob([dataView], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);

    }
    catch (err) {
        throw err;
    }
}

const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export { Download, DownloadExcel, ConvertJsonToExcel, convertBase64 , downloadPDF , handlerDownloadExcelFromBlob, downloadPDFFromBlob };
