export const PaginationEnums = {
  PAGE_SIZE: 10,
  SMALL_PAGE_SIZE: 5,
  ORGANIZATION_PAGE_SIZE: 6,
  INVOICES_BOOK_PAGE_SIZE: 9,
  ONBOARDING_PAGE_SIZE: 9,
};

export const Integer = {
  Max: 2147483647,
};

export const StatusTypes = {
  ACTIVE: 1,
  IN_ACTIVE: 2,
};

export const EnvMode = {
  SIM: "sim",
  PROD: "prod",
};
export const Invoice_Book_Status = {
  READY: "READY",
  EXPAIR: "EXPAIR",
  DRAFT: "DRAFT",
};

export const LanguageDDList = [
  { label: "app.arabic_language", id: 1 },
  { label: "app.english_language", id: 2 },
];
export const StatusDDL = [
  { label: "status.inActive", id: 2 },
  { label: "status.active", id: 1 },
];

export const EnvoiceList = [
  { label: "First", id: 1 },
  { label: "Second", id: 2 },
  { label: "Third", id: 3 },
  { label: "Fourth", id: 4 },
  { label: "Fifth", id: 5 },
  { label: "Sixth", id: 6 },
  { label: "Seventh", id: 7 },
  { label: "Eighth", id: 8 },
  { label: "Ninth", id: 9 },
  { label: "Tenth", id: 10 },
  { label: "Eleventh", id: 11 },
  { label: "Twelfth", id: 12 },
  { label: "Thirteenth", id: 13 },
  { label: "Fourteenth", id: 14 },
  { label: "Fifteenth", id: 15 },
  { label: "Sixteenth", id: 16 },
  { label: "Seventeenth", id: 17 },
  { label: "Eighteenth", id: 18 },
  { label: "Nineteenth", id: 19 },
  { label: "Twentieth", id: 20 },
];

// export const IdentityTypes = {
//     NATIONAL_IDENTITY: 401,
//     IQAMA_IDENTITY: 402,
//     COMMERCIAL_REGISTRATION_NUMBER: 403,
//     UNIFIED_ORGANIZATION_NUMBER: 404,
//     CITIZENS_OF_GULF_COUNTRIES: 405,
//     PASSPORT: 406,
//     BORDER_NUMBER: 407,
//     FAMILY_CARD_NUMBER: 408,
//     C700: 409
// };

export enum ORGANIZATION_NUMBER {
  Departments = 1,
  Units = 2,
  Sections = 3,
  Revenue_Streams = 4,
  Sub_Revenue_Streams = 5,
  Projects = 6,
  Project_Main_Activities = 7,
}

export const PersonalInformationSubCategories = {
  TYPE: 1,
  NATIONALITY: 2,
  RELIGION: 3,
  EMPLOYEE_STATUS: 4,
  BLOOD_TYPE: 5,
  MARTIAL_STATUS: 6,
  HEALTH_STATUS: 7,
};

export const UserType = {
  SYSTEM_ADMIN: "System Admin",
  SYSTEM_ADMIN_MANAGER: "System Admin and Manager",
  MANAGER: "Manager",
  EMPLOYEE: "Employee",
};

export const MAIN_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const OnboardingProgress = {
  COMPLETED: "1",
  PENDING: "2",
  FAILED: "3",
  NOT_STARTED: "4",
};

export const OnboardingStatusCode = {
  Conflict: 409,
  Success: 201,
};

export const StatusCode = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export enum EnvLevelContext {
  TENANT = 1,
  ORGANIZATION = 2,
}

export const EnvLevelContextText = {
  [EnvLevelContext.TENANT]: "TENANT",
  [EnvLevelContext.ORGANIZATION]: "ORGANIZATION",
};

export enum LookupTypes {
  GFS = 100,
  ExecutiveManagements = 200,
  Managements = 300,
  IdentityType = 400,
  UserTypes = 500,
  Gender = 600,
  BillStatus = 700,
  RefundStatus = 800,
  BusinessCategory = 900,
  CountryCode = 1000,
  Province = 1100,
  City = 1200,
  District = 1300,
  Currency = 1400,
  MetaDataPlacement = 1500,
}

export enum IdentityTypes {
  // COMMERCIAL_REGISTRATION_NUMBER = 2,//CRN
  // MOMRA = 3,//MOM
  // MLSD = 4,//MLS
  // CODE_700 = 5,//700
  // SAGIA = 6,//SAG
  // OTHER_ID = 11 //OTH
  TAX_IDENTIFICATION_NUMBER = 1,
  COMMERCIAL_REGISTRATION_NUMBER = 2,
  MOMRAH = 3,
  MHRSD = 4,
  CODE_700 = 5,
  MISA = 6,
  NATIONAL_ID = 7,
  GCC = 8,
  IQAMA = 9,
  PASSPORT = 10,
  OTHER_ID = 11,
}

export enum InvoicesBookStatuses {
  CREATED = 1,
  ONBOARDING_PENDING = 2,
  ONBOARDING_FAILED = 3,
  READY = 4,
  PROD_CSID_EXPIRED = 5,
  PROD_CSID_RENEWAL_PENDING = 6,
  PROD_CSID_RENEWAL_FAILED = 7,
}

export enum OnboardingStatuses {
  NEW = 1,
  CSR_GENERATED = 2,
  CSR_GENERATION_FAILED = 3,
  COMPLIANCE_CSID_OBTAINED = 4,
  COMPLIANCE_CSID_FAILED = 5,
  COMPLIANCE_CHECK_DONE = 6,
  COMPLIANCE_CHECK_FAILED = 7,
  COMPLETED = 8,
  PRODUCATION_CSID_FAILED = 9,
  RENEWAL_PRODUCTION_CSID_FAILED = 11,
  EXPIRED = 100,
}

export enum InvoiceTypes {
  TAX_INVOICE = 388,
  DEBIT_NOTE = 383,
  CREDIT_NOTE = 381,
}

export enum InvoiceStatuses {
  PENDING = 1,
  REPORTED = 2,
  REPORTED_WITH_WARNINGS = 201,
  CLEARED = 3,
  CLEARED_WITH_WARNINGS = 301,
  FAILED = 4,
  REJECTED = 5,
}

export const OrganizationStatus = {
  ACTIVE: 1,
  IN_ACTIVE: 2,
};

export const MetaDataSource = [
  { label: "app.invoices", id: 1 },
  { label: "app.invoices_book", id: 2 },
];
export enum MetaDataSourceEnum {
  INVOICES = 1,
  INVOICES_BOOK = 2,
}

export const MetaDataSourceEnumText = {
  [EnvLevelContext.TENANT]: "app.invoices",
  [EnvLevelContext.ORGANIZATION]: "app.invoices_book",
};

export const InvoicesJobStatuses = [
  { label: "NEW", id: "NEW" },
  { label: "PENDING", id: "PENDING" },
  { label: "INVALID", id: "INVALID" },
  { label: "SUBMITTED", id: "SUBMITTED" },
  { label: "CONSUMED", id: "CONSUMED" },
];

export enum InvoiceTransactionTypes {
  STANDARD = 1,
  SIMPLIFIED = 2,
}

