import React, { useEffect, useState } from "react";
import AutoComplete from "../AutoComplete";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { ICompleteSelectDeclarations } from './ICompleteSelectDeclarations';

const AutoCompleteSimple = ({
  name,
  label,
  error,
  selected,
  handleInputChange,
  showLabel = false,
  disabled = false,
  translate = true,
  options
}: ICompleteSelectDeclarations) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const [inputValue, setInputValue] = useState<any>("");
  const [dropDownItems, setDropDownItems] = useState<any>([]);
  const [searchText, setSearchText] = useState("");


  //#endregion

  //#region :: Handlers
  const inputChange = async (newInputLable: any) => {
    setInputValue(newInputLable);
  };

  const handleChangeInput =async (newInputId: any) => {
    
    if (newInputId) {
      const select = options.find((item) => item.id == newInputId.id);
      inputChange( newInputId?.id);
      if(select){
        handleInputChange(name, select);
      }else{
        let response: any;

        handleInputChange(name, {id: response?.id, label: response?.value} );
      }

    } else {
      handleInputChange(name, newInputId);
      inputChange( newInputId?.id);
    }
  };

  const handelSearch = () => {
    setDropDownItems(options.filter(itm => (itm?.label.includes(searchText) ))) //|| (T(Resources.AppResources[itm?.label])).includes(searchText)
  };

  // const prepareOptionsList = (optionsList = options) => {
  //   setDropDownItems(optionsList.map(itm => {return{ id: itm?.id, label: itm.label}}))
  // };

  //#endregion

  //#region :: UseEffect
  useEffect(() => {
    handelSearch();
  }, [searchText]);


  useEffect(() => {
    let selectedItem:any;
    selectedItem = dropDownItems.find(itm => itm.id == selected?.id);

    setInputValue(selectedItem?.id);
  }, [selected]);

  useEffect(() => {
    setDropDownItems(options);
  }, [options]);
  //#endregion

  return (
    <AutoComplete
      id={name}
      name={name}
      defaultValue={inputValue}
      value={inputValue}
      autoComplete={false}
      showLabel={showLabel}
      disabled={disabled}
      filterSelectedOptions={false}
      handlerSearch={(SearchText) => setSearchText(SearchText ?? "")}
      handleChange={handleChangeInput}
      error={error}
      options={dropDownItems}
      required={true}
      label={(translate && label) ? T(label) : label}
      multiple={false}
      noOptionsText={T(Resources.AppResources.NODATA)}
      filterOptions={(x) => x}
      isLoading={false}
    />
  );
};

export default AutoCompleteSimple;
