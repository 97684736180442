import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import ReactJson from "react-json-view";
import { ConnectorDbService } from "../../../Services/API/ConnectorDb";
import { useSelector } from "react-redux";
import { getSearchCriteria } from "../../../Store/Reducers/ConnectorDbManagement";
import Resources from "../../../Services/Internationalization/Resources";
import { getFieldValue } from "../../../Utils/StringUtils";
import { FormatDate } from "../../../Utils/DateUtils";
import CBadge from "../../../Components/Badg/badge";
import {
  InvoiceTransactionTypes,
  InvoiceTypes,
} from "../../../Config/Settings/Enums";
import TableComp from "../../../Controls/Table";
import CustomeIconButton, { IconButtonsTypes } from "../../../Controls/IconButton";
import { DIALOG_TYPE, MessagesService } from "../../../Services/messages.service";
import ModalComponent from "../../../Controls/Modal/Modal";

const ResponseDetilasDialog = ({ data, invId }: any) => {
  const { T, Resources } = useTranslation();
  const state = useSelector((state) => state);
  const searchCriteria = getSearchCriteria(state);
  const [invDetails, setInvDetails] = useState<any>();
  const [jsonResponse, setJsonResponse] = useState<any>();
  const [errorsListDialog, setErrorsListDialog] = useState(false);
  const [errorsList, setErrorsList] = useState<Array<string>>();

  const legalMandatoryTotalsColumns = [
    {
      id: "name",
      label: T(Resources.AppResources.NAME),
      align: "center",
    },
    {
      id: "value",
      label: T(Resources.AppResources.VALUE),
      align: "center",
    },
  ];

  const taxSubTotalColumns = [
    {
      id: "taxCategory",
      label: "Tax Category",
      align: "center",
    },
    {
      id: "taxableAmount",
      label: "Taxable Amount",
      align: "center",
    },
    {
      id: "taxAmount",
      label: "Tax Amount",
      align: "center",
    },
    {
      id: "actions",
      label: T(Resources.AppResources.ACTIONS),
      align: "center",
    },
  ];

  const lineItemsColumns = [
    {
      id: "id",
      label: T(Resources.AppResources.CODE),
      align: "center",
    },
    {
      id: "name",
      label: T(Resources.AppResources.NAME),
      align: "center",
    },
    {
      id: "priceAmount",
      label: "Unit Price",
      align: "center",
    },
    {
      id: "quantity",
      label: "QTY",
      align: "center",
    },
    {
      id: "discountAmount",
      label: "Discount",
      align: "center",
    },
    {
      id: "compSher",
      label: "Comp. Share",
      align: "center",
    },
    {
      id: "lineExtensionAmount",
      label: "Net Price",
      align: "center",
    },
    {
      id: "taxCategory",
      label: "Tax%",
      align: "center",
    },
    {
      id: "taxAmount",
      label: "Tax",
      align: "center",
    },
    {
      id: "roundingAmount",
      label: "Rounding Amount",
      align: "center",
    },
    {
      id: "actions",
      label: T(Resources.AppResources.ACTIONS),
      align: "center",
    },
  ];

  //
  const handelGetData = async () => {
    try {
      const result = await ConnectorDbService.getInvoice(
        invId,
        searchCriteria?.sourceId
      );
      setInvDetails(result);
    } catch (error) {}
  };

  const handelParseResponse = async () => {
    try {
      const tempJsonResponse = JSON.parse(data);
      // console.log(tempJsonResponse);
      setJsonResponse(tempJsonResponse);
      
    } catch (error) {}
  };

  const getLegalMandetories = () => {
    const tempList: Array<any> = [];
    console.log(jsonResponse);

    tempList.push({
      value: invDetails?.legalMandatoryTotals?.taxInclusiveAmount?.toString(),
      name: "Tax Inclusive Amount",
      error: jsonResponse?.body?.hasOwnProperty("Body.LegalMandatoryTotals.TaxInclusiveAmount"),
    });
    tempList.push({
      value: invDetails?.legalMandatoryTotals?.taxExclusiveAmount?.toString(),
      name: "Tax Exclusive Amount",
      error: jsonResponse?.body?.hasOwnProperty("Body.LegalMandatoryTotals.TaxExclusiveAmount"),
    });
    tempList.push({
      value: invDetails?.legalMandatoryTotals?.lineExtensionAmount?.toString(),
      name: "Line Extension Amount",
      error: jsonResponse?.body?.hasOwnProperty("Body.LegalMandatoryTotals.LineExtensionAmount"),
    });
    tempList.push({
      value: invDetails?.legalMandatoryTotals?.prepaidAmount?.toString(),
      name: "Prepaid Amount",
      error: jsonResponse?.body?.hasOwnProperty("Body.LegalMandatoryTotals.PrepaidAmount"),
    });
    tempList.push({
      value: invDetails?.legalMandatoryTotals?.discountTotalAmount?.toString(),
      name: "Discount Total Amount",
      error: jsonResponse?.body?.hasOwnProperty("Body.LegalMandatoryTotals.DiscountTotalAmount"),
    });
    tempList.push({
      value: invDetails?.legalMandatoryTotals?.amountDue?.toString(),
      name: "Amount Due",
      error: jsonResponse?.body?.hasOwnProperty("Body.LegalMandatoryTotals.AmountDue"),
    });

    return tempList;
  };

  const getLineItems = () => {
    let tempList: Array<any> = [];

    if (invDetails?.lineItems && invDetails.lineItems.length)
        tempList = invDetails?.lineItems.map((inv, index) => {

        let error = data.includes(`Body.LineItems[${index}]`);
        
        return {
          id: inv.id,
          name: inv.itemInfo?.name,
          priceAmount: inv.priceInfo?.priceAmount?.toString(),
          lineExtensionAmount: inv.lineExtensionAmount?.toString(),
          taxCategory: inv.itemInfo?.taxCategoryInfo?.taxCategory?.percentage?.toString(),
          discountAmount: inv.discountInfo?.amount?.toString(),
          compSher: inv.priceInfo?.discountInfo?.amount?.toString(),
          taxAmount: inv.taxTotal?.taxAmount?.toString(),
          roundingAmount: inv.taxTotal?.roundingAmount?.toString(),
          quantity: `${inv.quantityInfo.value?.toString()}${
            inv.quantityInfo.unitOfMeasure ?? ""
          }`,
          actions: <>
              <CustomeIconButton
                id="sync"
                aria-label={T(Resources.AppResources.DESCRIPTION)}
                type={IconButtonsTypes.VIEW}
                onClick={() => {
                  // showInvoiceWarnings(errors)
                  showErrorsListDialog(`Body.LineItems[${index}]`)
                }}
              />
          </>,
          error,
        };
      });

    return tempList;
  };

  const getTaxSubtotal = () => {
    let tempList: Array<any> = [];

    if (
      invDetails?.taxTotalInfo?.taxSubtotal &&
      invDetails?.taxTotalInfo?.taxSubtotal.length
    )
      tempList = invDetails?.taxTotalInfo?.taxSubtotal.map((inv,index) => {
        let error = data.includes(`Body.TaxTotalInfo.TaxSubtotal[${index}]`);
        return {
          taxCategory: inv?.taxCategoryInfo?.taxCategory?.description,
          taxableAmount: inv?.taxableAmount?.toString(),
          taxAmount: inv?.taxAmount?.toString(),
          actions: <>
              <CustomeIconButton
                id="sync"
                aria-label={T(Resources.AppResources.DESCRIPTION)}
                type={IconButtonsTypes.VIEW}
                onClick={() => {
                  // showInvoiceWarnings(errors)
                  showErrorsListDialog(`Body.TaxTotalInfo.TaxSubtotal[${index}]`)
                }}
              />
          </>,
          error
        };
      });
    return tempList;
  };

  const showInvoiceWarnings = (errors: Array<any>) => {
    // const list = ["err1", "err2", "err3", "err4", "err5"]
    MessagesService.Dialog(
      T(Resources.AppResources.WARNINGS),
      errors.map(err => `- ${err}`).join('<br/>'),
      DIALOG_TYPE.Alert,
      T(Resources.AppResources.OK),
      undefined,
      undefined
    );
  };
  const showErrorsListDialog = (micro) => {
    
    let errors:Array<string> = [];
    for (let property in jsonResponse?.body) {
      if ( property.includes(micro)) {
        let propName = property.replace(`${micro}.`,'')
        errors.push(`${camelCaseToTitleCase(propName.replaceAll('.', ' => '))} : ${jsonResponse?.body[property].join(',')}`)
      }
    }
    setErrorsList(errors);
    setErrorsListDialog(true)
  };

  function camelCaseToTitleCase(camelCaseStr) {
    // Insert a space before each uppercase letter and capitalize the first letter of each word
    let titleCaseStr = camelCaseStr
        .replace(/([A-Z])/g, ' $1')  // Insert space before uppercase letters
        .replace(/^./, function(str){ return str.toUpperCase(); }) // Capitalize the first letter
        .trim(); // Remove any leading/trailing whitespace

    return titleCaseStr.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
  //

  useEffect(() => {
    handelParseResponse();
    handelGetData();
  }, []);

  console.log(invDetails);

  return (
    <Grid container paddingY={2} spacing={1} xs={12}>
      <Grid container md={12} sm={12} xs={12} padding={1}>
        <Grid item xs={6} md={3}>
          <Grid>
            <Grid className="title">
              {T(Resources.AppResources.INVOICE_NUMBER)}
            </Grid>
          </Grid>
          <Grid>
            <Grid className="sub-title">
              {getFieldValue(invDetails?.invoiceReferenceNumber)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid>
            <Grid className="title">
              {T(Resources.AppResources.ISSUE_DATE)}
            </Grid>
          </Grid>
          <Grid>
            <Grid className="sub-title">
              {FormatDate(invDetails?.issueDate, "dd-MM-yyyy")}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid>
            <Grid className="title">
              {T(Resources.AppResources.TRANSACTION_TYPE)}
            </Grid>
          </Grid>
          <Grid>
            <Grid className="sub-title">
              {invDetails?.invoiceTransactionType ==
              InvoiceTransactionTypes.STANDARD ? (
                <CBadge
                  bgColor={"whitesmoke"}
                  expectedWith="fit-content"
                  id={"B2B"}
                >
                  B2B
                </CBadge>
              ) : (
                <CBadge
                  bgColor={"whitesmoke"}
                  expectedWith="fit-content"
                  id={"B2C"}
                >
                  B2C
                </CBadge>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Grid>
            <Grid className="title">
              {T(Resources.AppResources.INVOICE_TYPE)}
            </Grid>
          </Grid>
          <Grid>
            <Grid className="sub-title">
              {invDetails?.invoiceType?.code == InvoiceTypes.TAX_INVOICE ? (
                getFieldValue(T(Resources.AppResources.TAX_INVOICE))
              ) : (
                <></>
              )}
              {invDetails?.invoiceType?.code == InvoiceTypes.DEBIT_NOTE ? (
                getFieldValue(T(Resources.AppResources.DEBIT_NOTE))
              ) : (
                <></>
              )}
              {invDetails?.invoiceType?.code == InvoiceTypes.CREDIT_NOTE ? (
                getFieldValue(T(Resources.AppResources.CREDIT_NOTE))
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid  md={12} sm={12} xs={12} padding={1}>
        <Typography variant="h6">Lines Items</Typography>
        {/* lineItems table */}
        <TableComp
          dataSource={getLineItems()}
          tableColumns={lineItemsColumns}
          showPagination={false}
          showTooltip={false}
        />
      </Grid>
      <Grid container md={12} sm={12} xs={12} padding={1}>
        <Grid md={6} sm={6} xs={12}>
          <Typography variant="h6">Totals</Typography>
          <Grid container md={12} sm={12} xs={12} >
            <Grid md={11.5} sm={11.5} xs={11.5} >
            {/* LegalMandetories table */}
              <TableComp
                dataSource={getLegalMandetories()}
                tableColumns={legalMandatoryTotalsColumns}
                showPagination={false}
                showTooltip={false}
              />
            </Grid>
            <Grid container justifyContent={'center'} alignContent={'flex-start'} md={0.5} sm={0.5} xs={0.5}>
              <CustomeIconButton
                    id="sync"
                    aria-label={T(Resources.AppResources.DESCRIPTION)}
                    type={IconButtonsTypes.VIEW}
                    onClick={() => {
                      // showInvoiceWarnings(errors)
                      showErrorsListDialog("Body.LegalMandatoryTotals")
                    }}
                  />
            </Grid>
          </Grid>
        </Grid>
        <Grid md={6} sm={6} xs={12}>
          <Typography variant="h6">Tax Sub Totals</Typography>
          {/* taxSubTotal table */}
          <TableComp
            dataSource={getTaxSubtotal()}
            tableColumns={taxSubTotalColumns}
            showPagination={false}
            showTooltip={false}
          />
        </Grid>
        
      </Grid>
      <ModalComponent
          maxWidth="sm"
          handleClose={() => setErrorsListDialog(false)}
          title={T(Resources.AppResources.ERROR_LIST)}
          open={errorsListDialog}
        >
          {errorsList?.map(err => <>
          <p>{`- ${err}`}</p>
          </>)}
        </ModalComponent>
    </Grid>
  );
};

export default ResponseDetilasDialog;
