import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { organizationSelectors, setError, organizationReceived } from '../Store/Reducers/OrganizationManagement';
import { pageRequested, PaginationSelectors, PaginationModules } from '../Store/Reducers/Pagination';
import Constants from '../Config/Settings/API';
import { OrganizationDbModel } from '../Pages/SystemSetup/OrganizationManagement/Shared/organization.model';
const useOrganizationSearch = () => {

    //#region :: State

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [organization, setOrganization] = useState<Array<any>>([]);
    const _organization = organizationSelectors.getOrganizationData(state);
    const searchCriteria = organizationSelectors.getSearchCriteria(state);

    const currentPage = PaginationSelectors.getCurrentPage(state, PaginationModules.ORGANIZATION);
    const pageSize = PaginationSelectors.getPageSize(state, PaginationModules.ORGANIZATION);
    const totalPages = PaginationSelectors.getTotalPages(state, PaginationModules.ORGANIZATION);
    const pagination = PaginationSelectors.getPaginationModule(state, PaginationModules.ORGANIZATION);

    const lastPageIndex = PaginationSelectors.getLastPageIndex(state, PaginationModules.ORGANIZATION);
    const hasMore = PaginationSelectors.hasMore(state, PaginationModules.ORGANIZATION) || false;
    const isPageFetching = PaginationSelectors.isPageFetching(state, PaginationModules.ORGANIZATION);
    const pageKeys = PaginationSelectors.getPageKeys(state, PaginationModules.ORGANIZATION, currentPage);

    const requestPage = (actionType: any, pageIndex: any, reset: boolean = false) => {

        if (isPageFetching) return;

       
        dispatch(pageRequested({
            url: Constants.ORGANIZATION.SEARCH_ORGANIZATION,
            data: { body: { ...searchCriteria, pageIndex: pageIndex, pageSize: pageSize } },
            method: 'POST',
            onSuccess: actionType,
            onError: setError.type,
            args: {
                resetPages: reset,
                source: 'body',
                id: 'id',
                module: PaginationModules.ORGANIZATION,
                page: pageIndex
            }
        }));
    };

    const changeCurrentPage = (page: any) => {
        requestPage(organizationReceived.type, page);
    };

    useEffect(() => {
        if (!searchCriteria) return;
        requestPage(organizationReceived.type, 0, true);
    }, [searchCriteria]);

    
    useEffect(() => {
        if (pageKeys && pageKeys.keys) {
            let items: Array<any> = [];

            pageKeys.keys.map((key: any) => {
                items.push(_organization[key]);
            });
            setOrganization(items.map(itm => OrganizationDbModel.mapToListModel(itm)));
        }
    }, [pageKeys.keys]);

    useEffect(() => {

        let items:Array<any> = [];
        
        if(_organization.length > pageSize)
            items = _organization.filter(x =>x)
                    .sort((x,y) => y.id - x.id)
                    .slice((currentPage * pageSize),((currentPage+1)* pageSize))
                    .map(itm => OrganizationDbModel.mapToListModel(itm))
        else
            items = _organization.map(itm => OrganizationDbModel.mapToListModel(itm))
        setOrganization(items);
    }, [_organization]);

    return { organization, hasMore, isPageFetching, lastPageIndex, currentPage, pagination, totalPages, pageSize, changeCurrentPage };
};

export default useOrganizationSearch;