import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button, { ButtonsTypes } from '../Button';
import { theme as MainThemeVariables } from '../../Config/Theme/ThemeVariables';

export interface AdvancedSearchProps {
  children?: any;
  toggledvancedSearch?: any;
  searchHandler?: any;
  resetHandler?: any;
};

const AdvancedSearch = ({ children, toggledvancedSearch, searchHandler, resetHandler }: AdvancedSearchProps) => {

  const screenSize = useMediaQuery((theme: any) => theme.breakpoints.up('MobileScreen'));

  return (
    <GridContainer item md={12} marginTop='0.5rem' minWidth={screenSize ? '100%' : '100%'}>
      <Grid container spacing={1}>
        {children}
      </Grid>
      <StyledGrid item md={12} xs={12}>
        <StyledCardActions>
          <Button
            bgColor={MainThemeVariables['green-color']}
            customeColor={MainThemeVariables['white-color']}
            variant='contained'
            width={35}
            type={ButtonsTypes.SEARCH}
            onClick={() => searchHandler !== undefined ? searchHandler() : null} />
          <Button
            variant='contained'
            color='primary'
            type={ButtonsTypes.RESET}
            onClick={() => resetHandler !== undefined ? resetHandler() : null} />
          <Button
            variant='contained'
            color='primary'
            type={ButtonsTypes.SHRINK}
            onClick={() => toggledvancedSearch !== undefined ? toggledvancedSearch() : null} />
        </StyledCardActions>
      </StyledGrid>
    </GridContainer>
  )
};

//#region :: Styled Components
const StyledCardActions: any = styled(CardActions)`
  padding: 8px 0 0 0 !important;

  & .MuiButton-containedPrimary{
      width: 30px !important;
    min-width: auto!important;
  margin: 0;
  }
  & .MuiButton-startIcon {
    margin: 0 !important;
  }
`;

const GridContainer: any = styled(Grid)`
  margin-top: ${({ marginTop }: any) => marginTop && marginTop};
  min-width: ${({ minWidth }: any) => minWidth && minWidth};
`;

const StyledGrid: any = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
  width: 100%;
`;
//#endregion

//#region :: Validation PropTypes
AdvancedSearch.propTypes = {
  toggledvancedSearch: propTypes.func,
  children: propTypes.any
};
//#endregion

export default AdvancedSearch;