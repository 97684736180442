import * as apiActions from "../Actions/API";
import { PaginationSelectors, pageChanged, pageError, pageInitialized, pageReFetch, pageRequested, pageRetrieved, pageSaved, pagesReseted } from '../Reducers/Pagination';

const Pagination = ({ dispatch, getState }: any) => (next: any) => async (action: any) => {

    next(action);

    switch (action.type) {
        case pageRequested.type:
            return onPageRequested({ dispatch, getState }, action);
        case pageRetrieved.type:
            return onPageRetrieved({ dispatch, getState }, action);
        case pageError.type:
            return onPageError({ dispatch, getState }, action);
        default:
            return;
    }
};

const onPageRequested = ({ dispatch, getState }: any, action: any) => {

    const { url, method, data, onSuccess, onError, args } = action.payload;

    if (args.resetPages) {
        dispatch(pagesReseted({ module: args.module, contextId: args.contextId }));
    }

    if (PaginationSelectors.isPageExists(getState(), args.module, args.contextId, args.page)) {
        dispatch(pageChanged({ module: args.module, contextId: args.contextId, page: args.page }));
        return;
    }

    dispatch(pageInitialized({ module: args.module, contextId: args.contextId, page: args.page, resetPages: args.resetPages }));
    dispatch(apiActions.apiCallBegan({ url, method, data, onSuccess: pageRetrieved.type, onError: pageError.type, args: { ...args, actionSuccess: onSuccess, actionError: onError } }));
};

const onPageRetrieved = ({ dispatch }: any, action: any) => {
    const { payload, args } = action;

    if (args.actionSuccess) {
        dispatch({ type: args.actionSuccess, payload, args });
    }
    dispatch(pageSaved({
        page: args.page,
        contextId: args.contextId,
        data: payload[args.source].map((m: any) => m[args.id]),
        totalCount: payload.totalCount,
        module: args.module
    }));
};

const onPageError = ({ dispatch }: any, action: any) => {
    //console.log('PAGE_ERROR', action);
    const { payload, args } = action; 
    
    dispatch(pageReFetch({ page: args.page, module: args.module, contextId: args.contextId }));

    if (args.actionError)
        dispatch({ type: args.actionError, payload, args });
};

export default Pagination;