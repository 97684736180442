import useTranslation from "../../../../Hooks/Shared/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { changeFullLoading } from "../../../../Store/Reducers/UI";
import { useEffect, useState } from "react";
import { TenantUsersManagementService } from "../../../../Services/API/TenantUsersManagement";
import { useNavigate, useParams } from "react-router-dom";
import {
  getUserDetails,
  setUserDetails,
} from "../../../../Store/Reducers/TenantUsersManagements";
import { UserModel } from "../Shared/User.models";
import { ValidationService } from "../../../../Utils/Validation.services";
import { FieldModel, ValidationModel } from "../../../../Models";
import PagesRoute from "../../../../Config/Settings/PagesRoute";
import { OrganizationService } from "../../../../Services/API/OrganizationManagement";
import { MESSAGE_TYPE, MessagesService } from "../../../../Services/messages.service";

const useEditTenantUserHook = () => {
  const { T, Resources } = useTranslation();
  const { id } = useParams();

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const DataSource = getUserDetails(state);
  const [addForm, setAddForm] = useState(UserModel.getFieldsModel());
  const navigate = useNavigate();
  // const navigate = useAppNavigate();

  //#endregion

  //#region :: Handlers

  const handleInputChange = (event: any) => {
    var { name, value } = event.target;
    handlerSetData(name, value);
  };

  const handlerSetData = (name: any, value: any) => {
    setAddForm({
      ...addForm,
      [name]: {
        ...addForm[name],
        value: value,
      },
    });
  };

  const saveDataHandler = async () => {
    let valid = ValidationService.Valid(addForm);
    let validationResponse = ValidationModel.setValidations(valid, addForm);

    setAddForm(validationResponse);
    if (valid.isValidForm) {
      const request = UserModel.toDBModel(addForm);


      dispatch(changeFullLoading({ spin: true }));
      try {

        await TenantUsersManagementService.Update(
          DataSource?.id,
          request
        );


        navigate(PagesRoute.PAGES.USERSMANAGEMENT);
        MessagesService.Toast(T(Resources.AppResources.SAVED_SUCCESSFULLY), MESSAGE_TYPE.Success);

      } catch (error: any) {
        console.error(error);
      } finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    }
  };

  const getOrganizationDataSource = async (searchText: any = undefined) => {
    const request = {
      pageIndex: 0,
      pageSize: 100,
      searchText: searchText,
    };
    const orgsListData = await OrganizationService.listOrganization(request);

    return orgsListData.map(itm => {
      return {
        id: itm?.id,
        label: itm?.value
      }
    });
  };

  //#endregion

  //#region :: get Data Source
  const getDataSource = async () => {
    dispatch(changeFullLoading({ spin: true }));
    try {
      const response = await TenantUsersManagementService.GetById(id);
      dispatch(setUserDetails({ body: response }));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  };
  //#endregion
  //#region :: [useEffect]
  useEffect(() => {
    if (DataSource)
      setAddForm({
        ...addForm,
        status: {
          ...addForm.status,
          value: DataSource?.isActive ? T(Resources.AppResources.ACTIVE) : T(Resources.AppResources.IN_ACTIVE),
        },
        name: {
          ...addForm.name,
          value: DataSource?.name,
        },
        email: {
          ...addForm.email,
          value: DataSource?.email,
        },
        mobileNumber: {
          ...addForm.mobileNumber,
          value: DataSource?.mobileNumber,
        },
        username: {
          ...addForm.username,
          value: DataSource?.userName,
        },
        organizations: {
          ...addForm.organizations,
          value: DataSource?.organizations.map(o => { return { id: o.id, label: o?.value }; }),
        },
      });
  }, [DataSource]);

  useEffect(() => {
    getDataSource();
  }, []);
  useEffect(
    () => () => {
      dispatch(setUserDetails({ body: null }));
    },
    []
  );
  //#endregion/
  return {
    DataSource,
    addForm,
    handleInputChange,
    handlerSetData,
    saveDataHandler,
    getOrganizationDataSource,
  };
};

export default useEditTenantUserHook;
