import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationSearch } from '../../../Pages/SystemSetup/OrganizationManagement/Shared/organization.model';

const organization = createSlice({
    name: 'organization',
    initialState: {
        organization: [],
        keys: [],
        organizationList: [],
        organizationDetails: {},
        searchCriteria: OrganizationSearch.toDBModel(OrganizationSearch.getFieldsModel()),
        showError: false,
        error: null
    },

    reducers: {
        organizationReceived: (organization: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                organization.organization[item.id] = item;
                organization.keys = [...organization.keys, item.id]
            });
            organization.customerDetails = {};
        },

        setOrganizationsDetails: (organization: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            organization.organizationDetails = data;
        },

        setInvoicesBookList: (organization: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            organization.organizationList = data;
        },

        setOrganizations: (organization, { payload }) => {
            const data = payload.body;
            organization.organization = data;
        },

        setSearchCriteria: (organization: any, { payload }: PayloadAction<any>) => {
            organization.searchCriteria = payload.body;
        },

        setError: (organization: any, { payload }: PayloadAction<any>) => {
            organization.error = payload.response;
            organization.showError = true;
        },
        deActiveOrg: (organization:any, {payload}: PayloadAction<any>) => {
            organization.organization[payload.id].isActive = payload.value;
        }
    }
});


export const { organizationReceived, setOrganizations, setInvoicesBookList, setSearchCriteria, setOrganizationsDetails, setError, deActiveOrg } = organization.actions;
export default organization.reducer;

//#region :: Selectors
export const getOrganizationData = (state: any) => {
    return state.organization.organization;
};


export const getOrganizationsDetails = (state: any) => {
    return state.organization.organizationDetails;
};

export const getOrganizationLists = (state: any) => {
    return state.organization.organizationList;
};

export const getSearchCriteria = (state: any) => {
    return state.organization.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.organization.showError;
};

export const getError = (state: any) => {
    return state.organization.error;
};

export const organizationSelectors = {
    getOrganizationData,
    getSearchCriteria,
    getOrganizationsDetails,
    getOrganizationLists,
    getShowError,
    getError,
};
//#endregion
