import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectorDbSelectors, setError, connectorDbReceived } from '../Store/Reducers/ConnectorDbManagement';
import { pageRequested, PaginationSelectors, PaginationModules } from '../Store/Reducers/Pagination';
import Constants from '../Config/Settings/API';


const useConnectorDbSearch = () => {
  
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [connectorDb, setConnectorDb] = useState<Array<any>>([]);
 
    const _connectorDb = connectorDbSelectors.getConnectorDbData(state);
    const searchCriteria = connectorDbSelectors.getSearchCriteria(state);

 
    const currentPage = PaginationSelectors.getCurrentPage(state, PaginationModules.CONNECTOR_DB);
    const pageSize = PaginationSelectors.getPageSize(state, PaginationModules.CONNECTOR_DB);
    const totalPages = PaginationSelectors.getTotalPages(state, PaginationModules.CONNECTOR_DB);
    const pagination = PaginationSelectors.getPaginationModule(state, PaginationModules.CONNECTOR_DB);


    const lastPageIndex = PaginationSelectors.getLastPageIndex(state, PaginationModules.CONNECTOR_DB);
    const hasMore = PaginationSelectors.hasMore(state, PaginationModules.CONNECTOR_DB) || false;
    const isPageFetching = PaginationSelectors.isPageFetching(state, PaginationModules.CONNECTOR_DB);
    const pageKeys = PaginationSelectors.getPageKeys(state, PaginationModules.CONNECTOR_DB, currentPage);


    const requestPage = (actionType: any, pageIndex: any, reset: boolean = false) => {

        
        //Prodaction
        if (isPageFetching) return;

        //Prodaction
        dispatch(pageRequested({
        url: Constants.CONNECTOR_DB.SEARCH,
            data: { body: { ...searchCriteria, pageIndex: pageIndex, pageSize: pageSize } },
            method: 'POST',
            onSuccess: actionType,
            onError: setError.type,
            args: {
                resetPages: reset,
                source: 'body',
                id: 'id',
                module: PaginationModules.CONNECTOR_DB,
                page: pageIndex
            }
        }));
    };

 
    const changeCurrentPage = (page: any) => {
        requestPage(connectorDbReceived.type, page);
    };

    useEffect(() => {
        if (!searchCriteria) return;
        requestPage(connectorDbReceived.type, 0, true);
    }, [searchCriteria]);

    useEffect(() => {
        if (pageKeys && pageKeys.keys) {
            let items: Array<any> = [];

            pageKeys.keys.map((key: any) => {
                items.push(_connectorDb[key]);
            });
            setConnectorDb(items); //            setOrganization(items.map(itm => OrganizationDbModel.mapToListModel(itm))); 
        }
    }, [pageKeys.keys]);

  
    useEffect(() => { 
        setConnectorDb(_connectorDb);
    }, [_connectorDb]);

    return { connectorDb, hasMore, isPageFetching, lastPageIndex, currentPage, pagination, totalPages, pageSize, changeCurrentPage };
};

export default useConnectorDbSearch;