import { FieldModel } from "../../../../Models";

export class tenantUsersManagementSearchModel {
  username: any;
  name: any;
  mobileNumber: any;
  email: any;
  status: any;

  public static getFieldsModel(model?: tenantUsersManagementSearchModel): any {
    model = new tenantUsersManagementSearchModel();
    let fildsObject = FieldModel.buildFromObject(model);
    fildsObject.status.value = 0;
    return fildsObject;
  }

  public static toDBModel(searchForm: tenantUsersManagementSearchModel) {
    let status = searchForm.status.value;
    const isString = typeof (status);
    if ((status === '0' || status === 0 || status == '') && status !== false)
      status = null;
    else if (isString == 'string')
      status = JSON.parse(searchForm?.status?.value.toLowerCase());
      
    return {
      name: searchForm?.name?.value,
      email: searchForm?.email?.value,
      username: searchForm?.username?.value,
      mobileNumber: searchForm.mobileNumber.value,
      isActive:status
    };
  }
}