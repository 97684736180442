import React, { useEffect, useState } from 'react'
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useUsersManagementSearch from '../../../Hooks/useUsersManagementSearch';
import { changeFullLoading } from '../../../Store/Reducers/UI';
import { DeactiveReason, PaginationModel } from '../../../Models';
import CustomeIconButton, { IconButtonsTypes } from '../../../Controls/IconButton';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import { GlobalService } from '../../../Services/global.service';
import { OrganizationStatus } from '../../../Config/Settings/Enums';
import { UsersManagementService } from '../../../Services/API/UsersManagement';
import { setFacilities, setRuleList, userUpdated } from '../../../Store/Reducers/UsersManagements';
import { PermissionScopes, PermissionServices, PermissionsService } from '../../../Services/permissions.service';
import ReasonSwitchToggle from '../../../Components/SwitchToggle';

const useUserSearchHook = () => {
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const usersManagementHook = useUsersManagementSearch();
    const [usersTableData, setUsersTableData] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<PaginationModel>(new PaginationModel());
    const [reason, setReason] = useState<DeactiveReason>(new DeactiveReason());
    const [roleDialog, setRoleDialog] = useState<boolean>(false);
    const [userId, setUserId] = useState<any>("");
    const [facilitiesDialog, setFacilitiesDialog] = useState<boolean>(false);
    const navigate = useNavigate();
    const [dialogs, setDialogs] = useState({
      password: false,
      rule: false,
      userInfo: false,
    });
  
    const columns = [
      { id: "id", label: T(Resources.AppResources.ID), align: "center" },
      { id: "userName", label: T(Resources.AppResources.USERNAME), align: "center" },
      { id: "name", label: T(Resources.AppResources.NAME), align: "center" },
      { id: "mobileNumber", label: T(Resources.AppResources.MOBILE_NUMBER), align: "center" },
      { id: "email", label: T(Resources.AppResources.EMAIL), align: "center" },
     
    ...(PermissionsService.hasPermission(PermissionScopes.CHANGE_USER_STATUS_ORGANIZATION) ? [{
      id: "status",
      label: T(Resources.AppResources.STATUS),
      align: "center",
    }] : []),
      
    ...(PermissionsService.hasPermission(PermissionScopes.EDIT_USER_ORGANIZATION) ||
        PermissionsService.hasPermission(PermissionScopes.VIEW_USER_DETAILS_ORGANIZATION) ||
        PermissionsService.hasPermission(PermissionScopes.ADD_ROLE_TO_USER_ORGANIZATION)
     ? [{
      id: "actions",
      label: T(Resources.AppResources.ACTIONS),
      align: "center",
    }] : []),
    ];

    
    // const navigate = useAppNavigate();
    


    //#endregion

    
  //#region :: Handlers
  
  const handleStatusChange = async (value: any, id: any, dataSource: any) => {
    const isCurrentUser = GlobalService.AppUser?.id == id;
    if (!isCurrentUser) {
      dispatch(changeFullLoading({ spin: true }));
      let statusChanged = value == true
        ? OrganizationStatus.IN_ACTIVE
        : value == false
          ? OrganizationStatus.ACTIVE
          : 0;
      try {
        let requestData: any = {
          status: !value,
          Reason: reason.value,
        };
        if (statusChanged == OrganizationStatus.IN_ACTIVE)
          requestData = {
            ...requestData,
            reason: reason.value
          }

        const response = await UsersManagementService.UpdateStatus(id, !value);
        setReason({
          ...reason,
          value: '0'
        });

        return {
          response: response,
          success: () => {
            dispatch(userUpdated({ body: dataSource, status: statusChanged }))
          },
        };
      }
      catch (error: any) {
        console.error(error);
      }
      finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    }
    else {
      console.log("you can't change your own status")
    }
  };


  const handleRoleDialog = (id: any) => {
    if (id)
      setUserId(id);
    setRoleDialog(!roleDialog)
    dispatch(setRuleList({ body: [] }));
  };

  const handleUserInfoDialog = async (id: any) => {
    setUserId(id);
    setDialogs({ ...dialogs, userInfo: !dialogs.userInfo });
  };

  const handleFacilitiesDialog = (id: any) => {
    if (id)
      setUserId(id);
    setFacilitiesDialog(!facilitiesDialog)
    dispatch(setFacilities({ body: [] }));
  };

  const mapData = (dataSource: any) => {
    dataSource && dataSource.usersManagement && setUsersTableData(dataSource?.usersManagement.map((item: any) => {
      var currentUser = GlobalService.AppUser?.id == item.id;
      return {
        id: item.id,
        name: item.name,
        userName: item?.userName,
        email: item.email,
        mobileNumber: item.mobileNumber,
        status: (
          PermissionsService.hasPermission(PermissionScopes.CHANGE_USER_STATUS_ORGANIZATION) && <ReasonSwitchToggle
            id={item.id}
            isActive={item.isActive}
            active={T(Resources.AppResources.ACTIVE)}
            inactive={T(Resources.AppResources.IN_ACTIVE)}
            switchable={PermissionsService.hasPermissions(PermissionServices.PRACTITIONERS, PermissionScopes.STATUS_CHANGE) && !currentUser}
            reasonValue={reason.value}
            handleStatusChange={() => handleStatusChange(item.isActive, item.id, dataSource)}
            />),
        actions: (<div>
          {PermissionsService.hasPermission(PermissionScopes.EDIT_USER_ORGANIZATION) && <CustomeIconButton
            id="sync"
            aria-label={T(Resources.AppResources.EDIT)}
            type={IconButtonsTypes.EDIT}
            onClick={() => navigate(PagesRoute.PAGES.EDIT_ORG_USER+item.id)}
             />}
          {PermissionsService.hasPermission(PermissionScopes.VIEW_USER_DETAILS_ORGANIZATION) && <CustomeIconButton
            id="sync"
            aria-label={T(Resources.AppResources.VIEW_USER)}
            type={IconButtonsTypes.VIEW}
            onClick={() => navigate(PagesRoute.PAGES.VIEW_ORG_USER+item.id)} />}
          {PermissionsService.hasPermission(PermissionScopes.ADD_ROLE_TO_USER_ORGANIZATION) && <CustomeIconButton
            id="sync"
            aria-label={T(Resources.AppResources.ROLES)}
            type={IconButtonsTypes.ACCESS_RULE}
            onClick={() => handleRoleDialog(item.id)} />}
        </div>)
      }
    }));

    setPagination({
      pageIndex: dataSource.currentPage,
      pageSize: dataSource.pageSize,
      totalCount: dataSource.pagination.defaultContext && dataSource.pagination.defaultContext.totalCount,
      totalPages: dataSource.totalPages
    });
  };
  //#endregion

  //#region :: [useEffect]
  useEffect(() => {
    if (!usersManagementHook) return;
    mapData(usersManagementHook);
  }, [usersManagementHook.usersManagement, reason]);
  //#endregion
 
    return { usersManagementHook, handleUserInfoDialog, usersTableData, columns, pagination, dialogs, userId, roleDialog, handleRoleDialog  }
}

export default useUserSearchHook