import React from 'react';
import Card from '../../../Controls/Card';
import TenantUsersProfile from './Shared/profile';

const EditRole = () => {

    return (
            <Card>
                <TenantUsersProfile />
            </Card>
    );
};

export default EditRole;