import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Card from '../../Controls/Card';
import AdvancedSearch from '../../Controls/Search/AdvancedSearch';
import DefaultSearch from '../../Controls/Search/DefaultSearch';
import CustomDropDown from '../../Controls/CustomDropDown';
import TextInput from '../../Controls/TextInputs';
import useTranslation from '../../Hooks/Shared/useTranslation';
import { getSearchCriteria, setSearchCriteria, setIsSearching } from '../../Store/Reducers/UsersManagements';
import { StatusDDList } from '../../Config/Settings/Lookups';
import { usersManagementSearchModel } from './Shared/usersManagement.model';

const Search = () => {

  //#region :: State
  const { T, Resources } = useTranslation();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const searchCriteria = getSearchCriteria(state);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [searchForm, setSearchForm] = useState(usersManagementSearchModel.getFieldsModel());
  //#endregion

  //#region :: Handlers
  const searchHandler = () => {
    const request = usersManagementSearchModel.toDBModel(searchForm);

    dispatch(setSearchCriteria({ body: request }));
  };

  const handleInputChange = (event: any) => {
    var { name, value } = event.target;
    handleInputChangeValue(name, value);
  };

  const handleInputChangeValue = (name: any, value: any) => {
    setSearchForm({
      ...searchForm,
      [name]: {
        ...searchForm[name],
        value: value
      }
    });
  };

  const resetForm = () => {
    setSearchForm(usersManagementSearchModel.getFieldsModel());
    dispatch(setSearchCriteria({ body: {} }))
  };

  const toggleAdvancedSearch = () => {
    if (isAdvancedSearch) {
      setIsAdvancedSearch(false);
    }
    else {
      setIsAdvancedSearch(true);
    }
  };
  const handleCPressEnter = (e) => {
      if (e.key === "Enter") {
          searchHandler()
      }
  }
  //#endregion

  //#region :: [useEffect]
  useEffect(() => {
    if (!searchCriteria) return;
    setSearchForm({
      ...searchForm,
      arabicName: {
        ...searchForm.arabicName,
        value: searchCriteria.arabicName
      },
      englishName: {
        ...searchForm.englishName,
        value: searchCriteria.englishName
      },
      mobileNumber: {
        ...searchForm.mobileNumber,
        value: searchCriteria.mobileNumber
      },
      email: {
        ...searchForm.email,
        value: searchCriteria.email
      },
      username: {
        ...searchForm.username,
        value: searchCriteria.username
      },
      status: {
        ...searchForm.status,
        value: searchCriteria.status == null ? '0' : searchCriteria.status
      }
    });
  }, [searchCriteria]);

  useEffect(() => {
    return () => {
      dispatch(setIsSearching({ isSearching: false }));
    }
  }, []);
  //#endregion

  return (
    <Card>
      <DefaultSearch
        searchHandler={searchHandler}
        resetHandler={resetForm}
        toggledvancedSearch={toggleAdvancedSearch}
        isAdvancedSearch={isAdvancedSearch}>
        <Grid item md={2.9} xs={12}>
          <TextInput
            id={searchForm?.username?.name}
            name={searchForm?.username?.name}
            label={T(Resources.AppResources.USERNAME)}
            value={searchForm?.username?.value}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)} />
        </Grid>
        <Grid item md={2.9} xs={12}>
          <TextInput
            id={searchForm?.name?.name}
            name={searchForm?.name?.name}
            label={T(Resources.AppResources.NAME)}
            value={searchForm?.name?.value}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)} />
        </Grid>
        <Grid item md={2.9} xs={12}>
          <TextInput
            id={searchForm?.mobileNumber?.name}
            name={searchForm?.mobileNumber?.name}
            label={T(Resources.AppResources.MOBILE_NUMBER)}
            value={searchForm?.mobileNumber?.value}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)} />
        </Grid>
        <Grid item md={2.9} xs={12}>
          <TextInput
            id={searchForm?.email?.name}
            name={searchForm?.email?.name}
            label={T(Resources.AppResources.EMAIL)}
            value={searchForm?.email?.value}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)} />
        </Grid>
      </DefaultSearch>
      {isAdvancedSearch && (
        <AdvancedSearch
          searchHandler={searchHandler}
          resetHandler={resetForm}
          toggledvancedSearch={toggleAdvancedSearch}>
          <Grid item md={2.9} xs={12}>
            <CustomDropDown
              id={searchForm?.status?.name}
              name={searchForm?.status?.name}
              label={T(Resources.AppResources.STATUS)}
              value={searchForm?.status?.value}
              options={StatusDDList}
              translate={true}
              showLabel={true}
              handleChange={(e) => handleInputChange(e)} />
          </Grid>
        </AdvancedSearch>
      )}
    </Card>
  );
};

export default Search;