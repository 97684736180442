import { FieldModel } from '../../../../Models';
import { regexMsgs, regexType } from "../../../../Config/Settings/Constants/Validations";
import { EnvLevelContext } from '../../../../Config/Settings/Enums';


export class RoleMappingSearch {
    searchText?: any;

    public static getFieldsModel(model?: RoleMappingSearch): any {
        if (!model) model = new RoleMappingSearch();
        let fildsObject = FieldModel.buildFromObject(model);


        return fildsObject;
    }

    public static toDBModel(model: any) {
        let searchText = model.searchText?.value;

        return {
            searchText,
        };
    }
}

export class RoleManagementSearchModel {
  name: any;

  public static getFieldsModel(model?: RoleManagementSearchModel): any {
    model = new RoleManagementSearchModel();
    let fildsObject = FieldModel.buildFromObject(model);
    return fildsObject;
  }

  public static toDBModel(searchForm: RoleManagementSearchModel) {

    return {
      name: searchForm?.name?.value
    };
  }
}

export class RoleMappingModel {
  arabicName: string;
  englishName: string;
  // role: string;
  level: string;
  permissions: Array<any>;

  public static getFieldsModel(model?: RoleMappingModel): any {
    if (!model) model = new RoleMappingModel();
    let fildsObject = FieldModel.buildFromObject(model);

    fildsObject.arabicName.required = true;
    fildsObject.arabicName.validate = true;
    // fildsObject.arabicName.regexValue = regexType.ARABICNAME;
    fildsObject.arabicName.errorMessages = {
      error: regexMsgs.INVALID_ARABIC_NAME
    };
    
    fildsObject.englishName.required = true;
    fildsObject.englishName.validate = true;
    // fildsObject.englishName.regexValue = regexType.ENGLISHNAME;
    fildsObject.englishName.errorMessages = {
      error: regexMsgs.INVALID_ENGLISH_NAME
    };

    // fildsObject.role.required = true;
    // fildsObject.role.validate = true;
    
    fildsObject.level.value = EnvLevelContext.TENANT+"";
    fildsObject.level.required = true;
    fildsObject.level.validate = true;
    
    // fildsObject.permissions.required = true;
    // fildsObject.permissions.validate = true;
    
    return fildsObject;
  }

  public static toDbModel(model: any) {
    return {
      ArabicName: model.ArabicName.value,
      EnglishName: model.EnglishName.value,
      role: model.role.value,
      level: model.level.value,
      permissions: model.permissions.value
    }
  }


}