import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { changeFullLoading } from '../../../../Store/Reducers/UI';
import { EnvLevelContext, EnvLevelContextText } from '../../../../Config/Settings/Enums';
import { PaginationModel, ValidationModel } from '../../../../Models';
import { Validation } from '../../../../Utils/Validation';
import { RolesMappingService } from '../../../../Services/API/RolesMapping';
import { RoleManagementSearchModel, RoleMappingModel } from '../Shared/roleMappings.model';
import PagesRoute from '../../../../Config/Settings/PagesRoute';
import { MESSAGE_TYPE, MessagesService } from '../../../../Services/messages.service';
import { getRolesDetails, setRoleMappingDetails, setSearchCriteria } from '../../../../Store/Reducers/RolesManagement';
import { PermissionsServices } from '../../../../Services/API/Permissions';
import Checkbox from '../../../../Controls/Checkbox';
import CostomReactCheckBox from '../../../../Controls/CostomReactCheckbox';

const useAddRoleMappingHook = () => {
    const { T, Resources } = useTranslation();
    const { id } = useParams();
    const state = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roleDetails = getRolesDetails(state);
    const [orgDataList, setOrgDataList] = useState<Array<any>>([]);
    const [permissionsTableList, setPermissionsTableList] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<PaginationModel>(new PaginationModel());
    const [formData, setFormData] = useState(RoleMappingModel.getFieldsModel());
    const [permissionCheckVal, setPermissionCheckVal] = useState<any>([]);
    // const navigate = useAppNavigate();



    //#endregion

    //#region Handlers
    const handleInputChange = (event: any) => {
        var { name, value } = event.target;
        handleSetData(name, value)
    }

    const handleSetData = (name: any, value: any) => {
        setFormData({
            ...formData,
            [name]: {
                ...formData[name],
                value: value
            }
        });
    }


    const getPermissionsList = async (searchText: string = '') => {
        const data = await PermissionsServices.GetAllLevel(formData?.level?.value || EnvLevelContext.TENANT, '', searchText);
        setOrgDataList(data);
        handlePrepareTableList(data.slice(0, 1 * 10))

        setPagination({
            pageIndex: 0,
            pageSize: pagination.pageSize,
            totalCount: data.length,
            totalPages: Math.ceil(data.length / pagination.pageSize)
        });
    }

    const handleSearch = async (searchText: string = '') => {
        
        const data =  orgDataList.filter(pre => pre.name.trim().toLowerCase().includes( searchText.trim().toLowerCase()));
        
        handlePrepareTableList(data.slice(0, 1 * 10))

        setPagination({
            pageIndex: 0,
            pageSize: pagination.pageSize,
            totalCount: data.length,
            totalPages: Math.ceil(data.length / pagination.pageSize)
        });
    }

    const changePageApi = (pageIndex) => {

        let tempPagObj = {
            pageSize: pagination?.pageSize || 10,
            totalCount: orgDataList.length,
            totalPages: Math.ceil(orgDataList.length / 10),
            pageIndex: pageIndex
        }

        // handlePrepareTableList(orgDataList.slice(pageIndex * 10, pageIndex * 10))
        handlePrepareTableList(orgDataList.slice(pageIndex * 10, (pageIndex + 1) * 10))

        setPagination(tempPagObj);
    }

    const determineCheckAllVal = () => {
        return Object.values(permissionCheckVal).filter(itm => itm).length == orgDataList.length;
    }

    const handelCheckAll = (e: any) => {
        var { name, checked } = e.target;

        let tempValueObj: any = {};

        orgDataList.forEach(itm => {

            tempValueObj[itm.id] = checked

        });
        setPermissionCheckVal({
            ...tempValueObj
        })
    }

    const permissionsColumns: Array<any> = [
        {
            id: "chk", label: (
                <Checkbox
                    id='permission_checkAll'
                    name='permission_checkAll'
                    key={`permission_checkAll`}
                    color='default'
                    checked={determineCheckAllVal()}
                    handleChange={(e: any) => { }}
                />
            ),
            align: "center",
            width: '5%',
            handler: handelCheckAll
        },
        { id: "name", label: T(Resources.AppResources.NAME), align: "center" },
        { id: "level", label: T(Resources.AppResources.LEVEL), align: "center" },
        { id: "moduleName", label: T(Resources.AppResources.MODULE), align: "center" },
    ];
    const handlePrepareTableList = (list: Array<any>) => {

        setPermissionsTableList(
            list.map((itm, index) => {
                return {
                    chk: (
                        <CostomReactCheckBox
                            id={`permission_check_${index}`}
                            name={`permission_check_${index}`}
                            key={`permission_check_${index}`}
                            checked={permissionCheckVal[itm.id]}
                            handleChange={(e: any) => {
                                // debugger
                                e.stopPropagation();
                                setPermissionCheckVal({
                                    ...permissionCheckVal,
                                    [itm.id]: e.target.checked
                                })
                            }}
                        />
                    )
                    ,
                    name: itm.name,
                    level: T(Resources.AppResources[EnvLevelContextText[itm.level]]),
                    moduleName: itm.moduleName
                }
            })
        )
    }

    const saveRole = async () => {

        try {
            dispatch(changeFullLoading({ spin: true }));
            let valid = Validation(formData);
            let validationResponse = ValidationModel.setValidations(valid, formData);
            setFormData(validationResponse);

            if (valid.isValidForm) {
                let model = {
                    englishName: validationResponse?.englishName?.value,
                    arabicName: validationResponse?.arabicName?.value,
                    level: parseInt(validationResponse?.level?.value),
                    permissions: Object.entries(permissionCheckVal).filter(itm => itm[1]).map(itm => itm[0]),
                }

                let data: any = {};
                if (!id) {
                    data = await RolesMappingService.CreateRole(model);
                } else {
                    data = await RolesMappingService.Update(model, id);
                }

                dispatch(setSearchCriteria({ body: new RoleManagementSearchModel() }));
                navigate(`/${PagesRoute.PAGES.ROLES_MANAGEMENT}`)
                if (data && data.id) {
                    MessagesService.Toast(T(Resources.AppResources.SAVED_SUCCESSFULLY), MESSAGE_TYPE.Success);
                }
            }
        } catch (error) {
            
            if(error?.response?.data?.status?.code == "B00201"){
                MessagesService.Toast(T(Resources.AppResources.DUPLICATE_ROLE_ERROR), MESSAGE_TYPE.Error);
            }
        } finally {
            dispatch(changeFullLoading({ spin: false }));
        }
    }

    //#endregion

    //#region :: get Data Source
    const getDataSource = async () => {
        dispatch(changeFullLoading({ spin: true }));
        try {
            const response = await RolesMappingService.GetById(id);
            dispatch(setRoleMappingDetails({ body: response }));
        }
        catch (error) {
            console.error(error);
        }
        finally {
            dispatch(changeFullLoading({ spin: false }));
        }
    };
    //#endregion


    //#region :: UseEffect

    useEffect(() => {
        if (id && roleDetails && Object.keys(roleDetails).length) {
            setFormData({
                ...formData,
                arabicName: {
                    ...formData.arabicName,
                    value: roleDetails?.arabicName
                },
                englishName: {
                    ...formData.englishName,
                    value: roleDetails?.englishName
                },
                permissions: {
                    ...formData.permissions,
                    value: roleDetails?.permissions?.map(per => { return { [per.id]: true } })

                },
                level: {
                    ...formData.level,
                    value: roleDetails?.level
                }
            });
            let Ob = {

            }
            let isChede = roleDetails.permissions?.map(per => {
                Ob = {
                    ...Ob,
                    [per.id]: true
                }
            })

            setPermissionCheckVal(Ob)
        }
    }, [roleDetails]);

    useEffect(() => {
        getPermissionsList()
    }, [formData?.level?.value]);

    useEffect(() => {
        // handlePrepareTableList(orgDataList.slice((pagination.pageIndex - 1 <= 0 ? 0 : pagination.pageIndex - 1) * 10, pagination.pageIndex * 10))
        handlePrepareTableList(orgDataList.slice(pagination.pageIndex * 10, (pagination.pageIndex + 1) * 10))
    }, [permissionCheckVal]);

    useEffect(() => {
        if (id)
            getDataSource();
    }, []);

    useEffect(() => () => {
        dispatch(setRoleMappingDetails({ body: null }));
    }, []);
    //#endregion 

    return {
        permissionsColumns,
        pagination,
        formData,
        permissionsTableList,
        changePageApi,
        saveRole,
        handleInputChange,
        handleSetData,
        handleSearch,
        setPermissionCheckVal,
    }
}

export default useAddRoleMappingHook