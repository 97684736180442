import React, { useEffect, useState } from "react";
import { PaginationModel } from "../../../Models";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import useConnectorDbsSearch from "../../../Hooks/useConnectorDbSearch";
import CustomeIconButton, {
  IconButtonsTypes,
} from "../../../Controls/IconButton";
import { FormatDate } from "../../../Utils/DateUtils";
import { MESSAGE_TYPE, MessagesService } from "../../../Services/messages.service";
import { DownloadExcel } from '../../../Utils/File';
import { ConnectorDbService } from "../../../Services/API/ConnectorDb";
import { changeFullLoading } from "../../../Store/Reducers/UI";
import { useDispatch } from "react-redux";

const useConnectorDbSearchHook = () => {
  const { T, Resources } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const useconnectorDbsHook = useConnectorDbsSearch();
  const [connectorDbsTableData, setConnectorDbsTableData] = useState<
    Array<any>
  >([]);
  const [pagination, setPagination] = useState<PaginationModel>(
    new PaginationModel()
  );
  const [descriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [detailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedJson, setSelectedJson] = useState<string>();
  const [selectedInvId, setSelectedInvId] = useState();

  const columns = [
    {
      id: "invoiceReferenceNumber",
      label: T(Resources.AppResources.INVOICE_NUMBER),
      align: "center",
    },
    {
      id: "issueDate",
      label: T(Resources.AppResources.ISSUE_DATE),
      align: "center",
    },
    { id: "amount", label: T(Resources.AppResources.AMOUNT), align: "center" },
    {
      id: "discount",
      label: T(Resources.AppResources.DISCOUNT),
      align: "center",
    },
    { id: "tax", label: T(Resources.AppResources.TAX), align: "center" },
    {
      id: "totalAmount",
      label: T(Resources.AppResources.TOTAL_AMOUNT),
      align: "center",
    },
    { id: "status", label: T(Resources.AppResources.STATUS), align: "center" },
    // { id: "description", label: T(Resources.AppResources.DESCRIPTION), align: "center" },
    {
      id: "actions",
      label: T(Resources.AppResources.ACTIONS),
      align: "center",
    },
    // ...(PermissionsService.hasPermission(PermissionScopes.VIEW_LOGGER_DETAILS) ?
    // [{ id: "actions", label: T(Resources.AppResources.ACTIONS), align: "center" }] : [])
  ];

  
  const handleDownloadExcel = async () => {
    try {
      const request = {
        body: {
          pageIndex: 0,
          pageSize: 1000000,
        },
      };
      const response = await ConnectorDbService.InvalidResponseReports(request);
      const filteredData = response?.data.body.map((item: any) => {
        return {
          id: item.id,
          invoiceReferenceNumber: item.invRefNumber,
          issueDate:  FormatDate(item.issueDate, "dd-MM-yyyy"),
          amount: item.totalLineExtensionAmount,
          discount: item.totalDiscountAmount,
          tax: item.organiztionTaxAmount,
          totalAmount: item.totalTaxInclusiveAmount,
          status: item?.status,
          description: item?.failuerDescription
        };
      });
      DownloadExcel(filteredData, T(Resources.AppResources.REPORTS));
    } catch (error) {
      MessagesService.Toast(
        T(Resources.AppResources.NO_ACTIONS),
        MESSAGE_TYPE.Error
      );
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  };

  const handleCloseDescriptionDialog = async () => {
    setOpenDescriptionDialog(false);
  };

  const handleCloseDetailsDialog = async () => {
    setOpenDetailsDialog(false);
  };

  const mapData = (dataSource: any) => {
    dataSource &&
      dataSource.connectorDb &&
      setConnectorDbsTableData(
        dataSource?.connectorDb.map((item: any) => {
          return {
            id: item.id,
            invoiceReferenceNumber: item.invRefNumber,
            issueDate:  FormatDate(item.issueDate, "dd-MM-yyyy"),
            amount: item.totalLineExtensionAmount,
            discount: item.totalDiscountAmount,
            tax: item.organiztionTaxAmount,
            totalAmount: item.totalTaxInclusiveAmount,
            status: item?.status,

            actions: (
              <div>
                {item.failueR_DESCRIPTION && (
                  <>
                    <CustomeIconButton
                      id="sync"
                      aria-label={T(Resources.AppResources.DESCRIPTION)}
                      type={IconButtonsTypes.VIEW}
                      onClick={() => {
                        setOpenDescriptionDialog(true);
                        setSelectedJson(item.failueR_DESCRIPTION);
                      }}
                    />
                
                    <CustomeIconButton
                      id="sync"
                      aria-label={T(Resources.AppResources.APPROVAL)}
                      type={IconButtonsTypes.ARTICLE}
                      onClick={() => {
                        setOpenDetailsDialog(true);
                        setSelectedJson(item.failueR_DESCRIPTION);
                        setSelectedInvId(item.id)
                      }}
                    />
                  </>
                 )}
                {/* {PermissionsService.hasPermission(PermissionScopes.VIEW_LOGGER_DETAILS) && <CustomeIconButton
            id="sync"
            aria-label={T(Resources.AppResources.VIEW_USER)}
            type={IconButtonsTypes.VIEW}
            onClick={() => setOpenDialog(true)} />
            } */}
              </div>
            ),
          };
        })
      );

    setPagination({
      pageIndex: dataSource.currentPage,
      pageSize: dataSource.pageSize,
      totalCount:
        dataSource.pagination.defaultContext &&
        dataSource.pagination.defaultContext.totalCount,
      totalPages: dataSource.totalPages,
    });
  };

  //#region :: [useEffect]
  useEffect(() => {
    if (!useconnectorDbsHook) return;

    mapData(useconnectorDbsHook);
  }, [useconnectorDbsHook.connectorDb]);
  //#endregion

  return {
    useconnectorDbsHook,
    connectorDbsTableData,
    columns,
    pagination, 
    descriptionDialog,
    handleCloseDescriptionDialog,
    selectedJson,
    detailsDialog, 
    setOpenDetailsDialog,
    handleCloseDetailsDialog,
    selectedInvId,
    handleDownloadExcel
  };
};

export default useConnectorDbSearchHook;
