import React, { useEffect, useRef, useState } from "react";
import AutoComplete from "../AutoComplete";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { IAutoCompleteSelectDeclarations } from "./IAutoCompleteSelectDeclarations";

const AutoCompleteSearchable = ({
  multiple = false,
  freeSolo = false,
  id,
  name,
  label,
  error,
  selected,
  handleChange,
  showIcon = true,
  showLabel = false,
  disabled = false,
  required = false,
  getDataSource,
  filterSelectedOptions = false,
}: IAutoCompleteSelectDeclarations) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const [inputValue, setInputValue] = useState<any>("");
  const [dropDownItems, setDropDownItems] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const localSelectedRef = useRef<any>("");


  //#endregion

  //#region :: Handlers
  const inputChange = async (event: any, newInputLable: any) => {
    setInputValue(newInputLable);
  };

  const handleChangeInput = (newInputId: any) => {
    if (!multiple && newInputId) {
      const select = dropDownItems.find((item) => item.id == newInputId.id);
      inputChange(null, newInputId);
      select && handleChange(name, select);
    } else {
      handleChange(name, newInputId);
      inputChange(null, newInputId);
    }
  };


  const handleSetSelection = async (valueSelected = selected, items = dropDownItems) =>{
    
    if(!multiple){
      if(valueSelected && typeof(valueSelected) == "object")
      {
        setInputValue(valueSelected?.id)
        if(!(items.some(itm => itm.id == valueSelected?.id)))
          setDropDownItems([{...valueSelected, label: valueSelected.value }, ...dropDownItems])
      }
      else if(valueSelected){
        let selectedItem:any;
        selectedItem = items.find(itm => itm.label == valueSelected);

        if(!selectedItem){
          localSelectedRef.current = selected;
          selectedItem =  await getDataSource( valueSelected);
          const newItem = {...selectedItem[0], label: selectedItem[0].value };

          setDropDownItems([newItem, ...items]);
          setInputValue(newItem.id);
        }
        else
        setInputValue(selectedItem?.id);
      }
      else if(valueSelected == 0){
        setInputValue(0);
      }
    }else {
      localSelectedRef.current = valueSelected;
      setInputValue(valueSelected)
    }
  }

  const callGetDataSource = async (searchText:string) => {
    try {
      setLoader(true);
      const list = await getDataSource(searchText)

      handleSetSelection(localSelectedRef.current, list);
      setDropDownItems(list);
    } catch (error) {
      
    }finally{
      
      setLoader(false);
    }

  }

  //#endregion

  //#region :: UseEffect
  useEffect(() => {
    callGetDataSource(searchText);
  }, [searchText]);


  useEffect(() => {
    handleSetSelection();
  }, [selected]);
  //#endregion

  return (
    <AutoComplete
      id={name}
      name={name}
      defaultValue={inputValue}
      value={inputValue}
      autoComplete={false}
      showLabel={showLabel}
      disabled={disabled}
      filterSelectedOptions={filterSelectedOptions}
      handlerSearch={(SearchText) => setSearchText(SearchText ?? "")}
      handleChange={handleChangeInput}
      error={error}
      options={dropDownItems}
      required={required}
      label={label && T(label)}
      multiple={multiple}
      noOptionsText={T(Resources.AppResources.NODATA)}
      filterOptions={(x) => x}
      isLoading={loader}
    />
  );
};

export default AutoCompleteSearchable;
