
import { combineReducers } from '@reduxjs/toolkit';
import ui from './Reducers/UI';
import auth from './Reducers/Auth';
import pagination from './Reducers/Pagination';
import organization from './Reducers/OrganizationManagement';
import invoicesBook from './Reducers/InvoicesBook';
import report from './Reducers/Reports';
import onboarding from './Reducers/Onboarding';
import fawateer from './Reducers/Fawateer';
import roleMapping from './Reducers/RolesManagement';
import tenantUsersManagement from './Reducers/TenantUsersManagements';
import usersManagement from './Reducers/UsersManagements';
import logger from './Reducers/LoggersManagement';
import connectorDb from './Reducers/ConnectorDbManagement';

export default combineReducers({
    ui,
    auth,
    pagination,
    organization,
    invoicesBook,
    report,
    onboarding,
    fawateer,
    roleMapping,
    tenantUsersManagement,
    usersManagement,
    logger,
    connectorDb
});