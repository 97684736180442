export class AutoCompleteModel {
    disable: boolean;
    error: boolean;
    required: boolean

    constructor() {
        this.disable = false;
        this.error = false;
        this.required = false;
    }
}