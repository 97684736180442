import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganizationsDetails } from '../../../../Store/Reducers/OrganizationManagement';
import { OrganizationService } from '../../../../Services/API/OrganizationManagement';
import { changeFullLoading } from '../../../../Store/Reducers/UI';
import { useParams } from 'react-router-dom';
import { AddressSearchResult, Addresses } from '../Shared/organization.model';
import { PaginationEnums } from '../../../../Config/Settings/Enums';
import { AddressesService } from '../../../../Services/API/Addresses';
import { PaginationModel } from '../../../../Models';
import LookupCodeLabel from '../../../../Components/LookupCodeLabel';

const useViewOrganizationHook = () => {
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const [pagination, setPagination] = useState<PaginationModel>(new PaginationModel());
    const { id } = useParams();
   
    const [addressesSource, setAddressesSource] = useState<Array<any>>([]);
    const columnsLabels: Array<any> = [
        { id: "name", label: T(Resources.AppResources.ADDRESS_NAME), align: "center" },
        { id: "countryCode", label: T(Resources.AppResources.COUNTRY), align: "center" },
        { id: "province", label: T(Resources.AppResources.PROVINCE), align: "center" },
        { id: "city", label: T(Resources.AppResources.CITY), align: "center" },
        { id: "district", label: T(Resources.AppResources.DISTRICT), align: "center" },
        { id: "street", label: T(Resources.AppResources.STREET_NAME), align: "center" },
        { id: "additionalStreet", label: T(Resources.AppResources.ADDITIONAL_STREET), align: "center" },
        { id: "buildingNumber", label: T(Resources.AppResources.BUILDING_NUMBER), align: "center" },
        { id: "postalCode", label: T(Resources.AppResources.POSTAL_CODE), align: "center" },
        { id: "additionalNumber", label: T(Resources.AppResources.ADDITIONAL_NUMBER), align: "center" },
        { id: "isDefault", label: T(Resources.AppResources.IS_DEFAULT), align: "center" },
    ];
    const getDataSource = async (orgId: any) => {
        try {
            const response = await OrganizationService.GetOrganizationsById(orgId);
            if (response){
                
                dispatch(setOrganizationsDetails({ body: response }));
            }
            
        }
        catch (error: any) {
            console.error(error);
        }
        finally {
            dispatch(changeFullLoading({ spin: false }));
        }
    };

    
    const getOrganizationsAddresses = async () => {
        try {
            let request = {
                pageIndex: 0,
                pageSize: PaginationEnums.SMALL_PAGE_SIZE,
                organizationId: id
                //searchText: "string"
              };
            let response: any;
            
            response = await  AddressesService.SearchAddressesByOrganization(request);
            setAddressesSource(response?.data?.body.map(x => prepareAddressListRow(x)))

            let tempPagination = new PaginationModel();
            tempPagination.pageIndex = response?.data?.pageIndex;
            tempPagination.pageSize = response?.data?.pageSize;
            tempPagination.totalCount = response?.data?.totalCount;
            tempPagination.totalPages= response?.data?.pagesCount;

            setPagination(tempPagination)
        } catch (error) {
        }finally{
        }
    };

    const changePageApi = async (pageIndex) => {
        setPagination({
            pageSize: pagination?.pageSize,
            totalCount: pagination?.totalCount,
            totalPages: pagination?.totalPages,
            pageIndex: pageIndex 
        })

        let request = {
            pageIndex: pageIndex,
            pageSize: PaginationEnums.SMALL_PAGE_SIZE,
            organizationId: id
            //searchText: "string"
          };
        let response: any;
        
        response = await  AddressesService.SearchAddressesByOrganization(request);
        setAddressesSource(response?.data?.body.map(x => prepareAddressListRow(x)))
    }
    
    const prepareAddressListRow = (rowData: AddressSearchResult)=>{
        return{
            
            id: rowData.id,
            name: rowData?.addressInfo.name,
            //country: <LookupCodeLabel value={rowData?.addressInfo?.countryCode} withTooltip />,// T(Resources.AppResources[ rowData?.addressInfo?.countryCode?.label || rowData?.addressInfo?.countryCode])|| (rowData?.addressInfo?.countryCode?.label || rowData?.addressInfo?.countryCode),
            countryCode: <LookupCodeLabel value={rowData?.addressInfo?.countryCode} withTooltip />,// rowData?.addressInfo?.countryCode?.value || rowData?.addressInfo?.countryCode,
            province: rowData?.addressInfo.province,
            city: rowData?.addressInfo.city,
            district: rowData?.addressInfo.district,
            street: rowData?.addressInfo.street,
            additionalStreet: rowData?.addressInfo.additionalStreet,
            buildingNumber: rowData?.addressInfo.buildingNumber,
            postalCode: rowData?.addressInfo.postalCode,
            additionalNumber: rowData?.addressInfo.additionalNumber,
            isDefault: rowData.isDefaultAddress ? T(Resources.AppResources.PRIMARY) : T(Resources.AppResources.NOT_PRIMARY),
        }
    }
    
    useEffect(() => {
        dispatch(changeFullLoading({ spin: true }));

        if (id !== undefined) {
            getDataSource(id);
            getOrganizationsAddresses();
        }
        else {
            dispatch(changeFullLoading({ spin: false }));
        }
    }, []);

    return { 
        columnsLabels, 
        addressesSource,
        pagination,
        changePageApi,
    };
}
export default useViewOrganizationHook