import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.min.css';

import * as alertify from 'alertifyjs';

export enum MESSAGE_TYPE {
    Information,
    Warning,
    Error,
    Success,
    Default
}

export enum DIALOG_TYPE {
    Alert,
    Confirm,
    Prompt
}

type ToastOptions = {
    useCoolDown?: boolean
}

export class MessagesService {

    private static messagesQueue: { message: string, timestamp, duration: number }[] = [];
    private static isMessageCoolingDown(message: string): boolean {
        const messageObj = this.messagesQueue.find((messageObj) => messageObj.message === message);
        if (messageObj) {
            const currentTime = new Date().getTime();
            const cooldownEndTime = messageObj.timestamp + messageObj.duration * 1000;

            if (currentTime >= cooldownEndTime) {
                return false;
            }

            return true;
        }

        return false;
    }


    private static showAlertify(message: string, type: string, onDismissed?: Function, duration: number = 10) {
        alertify.set('notifier', 'position', 'bottom-right');

        alertify.notify(message, type, duration, () => onDismissed && onDismissed());
    }

    public static Toast(message: string, type: MESSAGE_TYPE = MESSAGE_TYPE.Default, duration?: number, onDismissed?: Function, options?: ToastOptions) {
        if (options?.useCoolDown) {
            if (this.isMessageCoolingDown(message)) {
                return;
            }
            this.messagesQueue.push({ message, timestamp: new Date().getTime(), duration: duration || 10 });
        }

        const defaultOnDismissed = () => {
            if (options?.useCoolDown) {
                const messageObj = this.messagesQueue.find((messageObj) => messageObj.message === message);
                if (messageObj) {
                    this.messagesQueue.splice(this.messagesQueue.indexOf(messageObj), 1);
                }
            }
            onDismissed && onDismissed();
        }

        switch (type) {
            case MESSAGE_TYPE.Success:
                this.showAlertify(message, 'success', defaultOnDismissed, duration);
                break;
            case MESSAGE_TYPE.Error:
                this.showAlertify(message, 'error', defaultOnDismissed, duration);
                break;
            case MESSAGE_TYPE.Warning:
                this.showAlertify(message, 'warning', defaultOnDismissed, duration);
                break;
            default:
                this.showAlertify(message, 'custom', defaultOnDismissed, duration);
        }
    }

    public static Dialog(title: string = " ", message: string | JSX.Element = "", type: DIALOG_TYPE,
        okText: string = '', cancelText: string = '',
        onOk: Function = () => { }, onCancel: Function = () => { }) {


        switch (type) {

            case DIALOG_TYPE.Alert:
                alertify.alert(title, message, onOk).setting({ label: (okText && okText.length) ? okText : 'OK' }).set('movable', false);
                break;
            case DIALOG_TYPE.Confirm:
                alertify.confirm(title, message, onOk, onCancel).set('labels', { ok: (okText && okText.length) ? okText : 'OK', cancel: (cancelText && cancelText.length) ? cancelText : 'Cancel' }).set({ transitionOff: true }).set('movable', false);
                break;
            case DIALOG_TYPE.Prompt:
                alertify.prompt(title, message, onOk, onCancel).set('labels', { ok: (okText && okText.length) ? okText : 'OK', cancel: (cancelText && cancelText.length) ? cancelText : 'Cancel' }).set({ transitionOff: true }).set('movable', false);
                break;
            default:
                alertify.alert(title, message, onOk).set('labels', { ok: (okText && okText.length) ? okText : 'OK' }).set({ transitionOff: true }).set('movable', false);
        }
    }
}
export enum MESSAGES_TOAST_TYPE {
    Deafult,
    Success,
    Warning,
    Error
}

export class Messages {
    public static Alert(title: string, message?: string, onOk?: Function) {
        alertify.alert(title, message, function () {
            if (onOk) {
                onOk();
            }
        });
    }

    public static Toast(message: string, type: MESSAGES_TOAST_TYPE = MESSAGES_TOAST_TYPE.Deafult, deurationInSeconds: number = 5) {
        switch (type) {
            case MESSAGES_TOAST_TYPE.Success:
                alertify.success(message, deurationInSeconds);
                break;
            case MESSAGES_TOAST_TYPE.Error:
                alertify.error(message, deurationInSeconds);
                break;
            case MESSAGES_TOAST_TYPE.Warning:
                alertify.warning(message, deurationInSeconds);
                break;
            default:
                alertify.message(message, deurationInSeconds);
        }
    }
}