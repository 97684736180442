import { Grid } from '@mui/material'
import React from 'react'
import Button from '../../../Controls/Button';
import TextInput from '../../../Controls/TextInputs'
import useTranslation from '../../../Hooks/Shared/useTranslation';

const OtpDialog = ({ handleInputChange, formState, hadnleAddOnboard, handleCloseDialog }: any) => {
    const { T, Resources } = useTranslation();

    const hadnleCreateOnboard=()=>{
        try{
            hadnleAddOnboard();
            // handleCloseDialog()
        }catch(err){
            //console.log({err})
        }
        
    }
    return (
        <Grid container paddingY={2} spacing={1} xs={12}>
            <Grid style={{ textAlign: 'center' , padding:"10px" }} item md={12} sm={12} xs={12}>
                <label>{T(Resources.AppResources.OTP_MSG)}</label>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <TextInput
                    id={formState.otp.name}
                    name={formState.otp.name}
                    label={T(Resources.AppResources.OTP)}
                    value={formState.otp.value}
                    required={formState.otp.required}
                    helperText={formState.otp.message}
                    error={!formState.otp.valid}
                    handleChange={(e) => handleInputChange(e)} />
            </Grid>

            <Grid style={{ textAlign: 'center' }} item xs={12} marginTop={2}>
                <Button
                    variant='contained'
                    color='primary'
                    label={T(Resources.AppResources.START)}
                onClick={hadnleCreateOnboard}
                />
            </Grid> 
        </Grid>
    )
}

export default OtpDialog