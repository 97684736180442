import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loggerSelectors, setError, loggerReceived } from '../Store/Reducers/LoggersManagement';
import { pageRequested, PaginationSelectors, PaginationModules } from '../Store/Reducers/Pagination';
import Constants from '../Config/Settings/API';


const useLoggersSearch = () => {
  
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [logger, setLogger] = useState<Array<any>>([]);
 
    const _report = loggerSelectors.getLoggerData(state);
    const searchCriteria = loggerSelectors.getSearchCriteria(state);

 
    const currentPage = PaginationSelectors.getCurrentPage(state, PaginationModules.LOGGER_MANAGEMENT);
    const pageSize = PaginationSelectors.getPageSize(state, PaginationModules.LOGGER_MANAGEMENT);
    const totalPages = PaginationSelectors.getTotalPages(state, PaginationModules.LOGGER_MANAGEMENT);
    const pagination = PaginationSelectors.getPaginationModule(state, PaginationModules.LOGGER_MANAGEMENT);


    const lastPageIndex = PaginationSelectors.getLastPageIndex(state, PaginationModules.LOGGER_MANAGEMENT);
    const hasMore = PaginationSelectors.hasMore(state, PaginationModules.LOGGER_MANAGEMENT) || false;
    const isPageFetching = PaginationSelectors.isPageFetching(state, PaginationModules.LOGGER_MANAGEMENT);
    const pageKeys = PaginationSelectors.getPageKeys(state, PaginationModules.LOGGER_MANAGEMENT, currentPage);


    const requestPage = (actionType: any, pageIndex: any, reset: boolean = false) => {

        
        //Prodaction
        if (isPageFetching) return;

        //Prodaction
        dispatch(pageRequested({
        url: Constants.LOGGER.SEARCH,
            data: { body: { ...searchCriteria, pageIndex: pageIndex, pageSize: pageSize } },
            method: 'POST',
            onSuccess: actionType,
            onError: setError.type,
            args: {
                resetPages: reset,
                source: 'body',
                id: 'id',
                module: PaginationModules.LOGGER_MANAGEMENT,
                page: pageIndex
            }
        }));
    };

 
    const changeCurrentPage = (page: any) => {
        requestPage(loggerReceived.type, page);
    };

    useEffect(() => {
        if (!searchCriteria) return;
        requestPage(loggerReceived.type, 0, true);
    }, [searchCriteria]);

    useEffect(() => {
        if (pageKeys && pageKeys.keys) {
            let items: Array<any> = [];

            pageKeys.keys.map((key: any) => {
                items.push(_report[key]);
            });
            setLogger(items); //            setOrganization(items.map(itm => OrganizationDbModel.mapToListModel(itm))); 
        }
    }, [pageKeys.keys]);

  
    useEffect(() => { 
        setLogger(_report);
    }, [_report]);

    return { logger, hasMore, isPageFetching, lastPageIndex, currentPage, pagination, totalPages, pageSize, changeCurrentPage };
};

export default useLoggersSearch;