import BadgeIcon from "@mui/icons-material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ConstructionIcon from '@mui/icons-material/Construction';
import AddchartIcon from '@mui/icons-material/Addchart';
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton/IconButton";
import List from "@mui/material/List";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import useMediaQuery from "@mui/material/useMediaQuery";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import PagesRoute from "../../../Config/Settings/PagesRoute";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import { GlobalService } from "../../../Services/global.service";
import ListItems from "./ListItems";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorTheme, getUIThemeMode } from "../../../Store/Reducers/UI";
import Organization from "../../MasterPage/Header/Components/Organization";
import CustomeIconButton, { IconButtonsTypes } from "../../../Controls/IconButton";
import { getIsPermissionsUpdated, getIsTenantContext, setIsPermissionsUpdated, setIsTenantContext } from "../../../Store/Reducers/Auth";
import { Grid } from "@mui/material";
import { PermissionScopes, PermissionsService } from "../../../Services/permissions.service";

const DrawerMenu = ({ handleDrawerOpen, collapseMenu }) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const themeMode = getUIThemeMode(state);
  const colorModel = ColorTheme(state);
  const isTenantContext = getIsTenantContext(state);
  const isPermissionsUpdated = getIsPermissionsUpdated(state);

  const screenSize = useMediaQuery((theme) =>
    theme.breakpoints.up("MobileScreen")
  );
  const [openSystemSetup, setOpenSystemSetup] = useState(false);
  const [openSources, setOpenSources] = useState(false);

  const currentUser = GlobalService.AppUser;
  const SystemAdmin = currentUser && currentUser?.isSystemManager;
  const Manager = currentUser && currentUser?.isManager;
  const iconsStyle = { width: "18px", height: "18px", color: "#fff" };

  //#endregion

  const handleCloseAllMenus = () => {
    setOpenSystemSetup(false);
  };

  const selectMenuLevel = () => {
    // 
    // debugger
    if(isTenantContext )
        return tenantMenuItems;
    else 
        return menuItems;
  }

  const handleChangeContext = () => {
    dispatch(setIsTenantContext({value: !isTenantContext, refresh: true}))
  }
  //#region :: useEffect
  useEffect(() => {
  }, [themeMode]);


  useEffect(() => {
    if (!collapseMenu) {
      handleCloseAllMenus();
    }
  }, [collapseMenu]);


  //#region :: Menu Items
  const menuItems = [

    ...((
      PermissionsService.hasPermission(PermissionScopes.SEARCH_INVOICES_BOOKS_ORGANIZATION)  ||

      PermissionsService.hasPermission(PermissionScopes.SEARCH_USERS_ORGANIZATION)   
  )
  ? [
    {
      title: T(Resources.AppResources.SYSTEM_SETUP),
      path: undefined,
      icon: <ConstructionIcon />,
      // badgeContent: 0,
      collapseMenu: collapseMenu,
      collapseSub: openSystemSetup,
      menuSubItems: PermissionsService.filterPerPermissions(PagesRoute.PAGES.SYSTEMSETUP),
      // permission: undefined,
      click: () => setOpenSystemSetup(collapseMenu ? !openSystemSetup : openSystemSetup)
    }
  ]
  : []),
  ...((
    PermissionsService.hasPermission(PermissionScopes.SEARCH_ONBOARDING) 
)
? [
  {
    title: T(Resources.AppResources.ONBOARDING),
    path: PagesRoute.PAGES.ONBOARDING,
    icon: <CorporateFareIcon />,
    // badgeContent: [0],
    collapseMenu: collapseMenu,
    collapseSub: undefined,
    menuSubItems: undefined,
    // permission: SystemAdmin,
    click: undefined,
  }
]
: []),
...((
  PermissionsService.hasPermission(PermissionScopes.SEARCH_FOR_AN_INVOICE) 
)
? [
  {
    title: T(Resources.AppResources.INVOICES),
    path: PagesRoute.PAGES.INVOICES,
    icon: <AddchartIcon />,
    // badgeContent: [0],
    collapseMenu: collapseMenu,
    collapseSub: undefined,
    menuSubItems: undefined,
    // permission: SystemAdmin,
    click: undefined,
  }
]
: [])
   ,
   ...((GlobalService.TenantServices?.isHaveSources) ? 
    [{
    title: T(Resources.AppResources.SOURCES),
    path: undefined,
    icon: <CancelScheduleSendIcon />,
    // badgeContent: 0,
    collapseMenu: collapseMenu,
    collapseSub: openSources,
    menuSubItems: PagesRoute.PAGES.SOURCES,
    // permission: undefined,
    click: () => setOpenSources(collapseMenu ? !openSources : openSources)
  }] : [])
  // {
  //   title: T(Resources.AppResources.RESPONSE_ERROR),
  //   path: PagesRoute.PAGES.RESPONSE_ERROR_MANAGEMENT,
  //   icon: <CancelScheduleSendIcon />,
  //   // badgeContent: [0],
  //   collapseMenu: collapseMenu,
  //   collapseSub: undefined,
  //   menuSubItems: undefined,
  //   // permission: SystemAdmin,
  //   click: undefined,
  // }
  ];

  const tenantMenuItems = [
    
    ...((
      PermissionsService.hasPermission(PermissionScopes.SEARCH_ORGANIZATION) 
  )
  ? [
    {
      title: T(Resources.AppResources.ORGANIZATION_MANAGEMENT),
      path: PagesRoute.PAGES.ORGANIZATION_MANAGEMENT,
      icon: <CorporateFareIcon />,
      // badgeContent: [0],
      collapseMenu: collapseMenu,
      collapseSub: undefined,
      menuSubItems: undefined,
      // permission: SystemAdmin,
      click: undefined,
    }
  ]
  : []),
  ...((
    PermissionsService.hasPermission(PermissionScopes.SEARCH_ROLE)  
)
? [
  {
    title: T(Resources.AppResources.ROLES_MANAGEMENT),
    path: PagesRoute.PAGES.ROLES_MANAGEMENT,
    icon: <BadgeIcon />,
    // badgeContent: [0],
    collapseMenu: collapseMenu,
    collapseSub: undefined,
    menuSubItems: undefined,
    // permission: SystemAdmin,
    click: undefined,
  }
]
: []),
  ...((
    PermissionsService.hasPermission(PermissionScopes.SEARCH_USERS_TENANT)  
)
? [
  {
    title: T(Resources.AppResources.USERS_MANAGEMENT),
    path: PagesRoute.PAGES.USERSMANAGEMENT,
    icon: <AssignmentIndIcon />,
    // badgeContent: [0],
    collapseMenu: collapseMenu,
    collapseSub: undefined,
    menuSubItems: undefined,
    // permission: SystemAdmin,
    click: undefined,
  }
]
: []),
...((
  PermissionsService.hasPermission(PermissionScopes.SEARCH_LOGGER_LIST)  
)
? [
{
  title: T(Resources.AppResources.LOGGERS_MANAGEMENT),
  path: PagesRoute.PAGES.LOGGERMANAGEMENT,
  icon: <ManageHistoryRoundedIcon />,
  // badgeContent: [0],
  collapseMenu: collapseMenu,
  collapseSub: undefined,
  menuSubItems: undefined,
  // permission: SystemAdmin,
  click: undefined,
}
]
: []),

  ];

  
  //#region :: useEffect
  useEffect(() => {
  }, [isTenantContext]);

  useEffect(() => {
    dispatch(setIsPermissionsUpdated(false))
  }, [isPermissionsUpdated]);

  //#endregion
  return (
    <>
      <StyledDrawer
        colorModel={colorModel}
        className="hiddenHeaderPrint"
        collapsemenu={collapseMenu}
        screensize={screenSize}
        variant="permanent"
      >

        <List>
          <li>
            {!screenSize ? (
              <StyledCloseIcon
                color="inherit"
                aria-label="change drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <CloseIcon />
              </StyledCloseIcon>
            ) : null}
            <StyledLogo to={PagesRoute.PAGES.HOME}>
              <div
                style={{
                  fontFamily: "Cairo",
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
                className="div-header">
                <span
                  className=""
                  style={{
                    color: "#fff",
                    verticalAlign: "middle",
                  }}>
                  {T(Resources.AppResources.X4FATOORA)}
                </span>
              </div>
            </StyledLogo>
            <div style={{display: 'flex', alignItems:"center", paddingLeft: 10}}>
              {
                collapseMenu &&
                <Organization key="test" disabled={isTenantContext} />
              }
              {collapseMenu && GlobalService.AppUser?.accessLevels?.length == 2 && 
                  <span style={{marginTop: 20}}>
                    <CustomeIconButton
                    style={{stroke: "white", fill: `${isTenantContext ? 'white' : 'transparent'}`}}
                    id="sync"
                    aria-label={isTenantContext ?  T(Resources.AppResources.TENANT_CONTEXT) : T(Resources.AppResources.FACILITY_CONTEXT)}
                    type={ isTenantContext ?  IconButtonsTypes.MANAGEACCOUNTS_ICON : IconButtonsTypes.CORPORATEFARE_ICON}
                    onClick={() => handleChangeContext()} />
                  </span>
              }
            </div>
          </li>
        </List>
        <List>
          {selectMenuLevel()?.map((item) => {
            return Manager || SystemAdmin ? (
              item.permission === true || item.permission === undefined ? (
                <ListItems
                  key={item.title}
                  itemTitle={item.title}
                  path={item.path}
                  iconSrc={item.icon}
                  badgeContent={item.badgeContent}
                  collapseMenu={item.collapseMenu}
                  collapseSub={item.collapseSub}
                  menuSubItems={item.menuSubItems}
                  onClick={item.click}
                />
              ) : null
            ) : item.permission === true || item.permission === undefined ? (
              <ListItems
                key={item.title}
                itemTitle={item.title}
                path={item.path}
                iconSrc={item.icon}
                badgeContent={item.badgeContent}
                collapseMenu={item.collapseMenu}
                collapseSub={item.collapseSub}
                menuSubItems={item.menuSubItems}
                onClick={item.click}
              />
            ) : null;
          })}
        </List>
        {/* <div style={{
          backgroundColor: colorModel
        }}>colorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemec
        olorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorThemecolorTheme</div> */}
      </StyledDrawer>
    </>
  );
};

//#region :: Styled Components
const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme, collapsemenu, screensize, colorModel }) => ({
  "&.MuiDrawer-root": {
    width: collapsemenu ? drawerWidth : theme.spacing(9),
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: !collapsemenu ? "hidden" : "auto",
    visibility: !collapsemenu && !screensize ? "hidden" : "visible",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: collapsemenu
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    [!collapsemenu && theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  "& .MuiPaper-root": {
    paddingTop: "25px",
    paddingBottom: "50px",
    width: collapsemenu ? drawerWidth : theme.spacing(9),
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "103% !important",
    overflowX: !collapsemenu ? "hidden" : "auto",
    visibility: !collapsemenu && !screensize ? "hidden" : "visible",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: collapsemenu
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
    [!collapsemenu && theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    background: colorModel,
    // background: "#2a65a5",
    color: theme.status.white,
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px #697dbd70",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      outline: "1px solid #697DBD",
    },
  },
}));

const StyledCloseIcon = styled(IconButton)(({ }) => ({
  float: "right",
  fontSize: 18,
  color: "#fff",
}));

const StyledLogo = styled(NavLink)(({ theme }) => ({
  width: "100%",
  fontSize: 22,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.status.white,
  cursor: "pointer",
  margin: "-17px 0 0 0",
  textDecoration: "none",
}));
//#endregion

//#region :: Validation PropTypes
DrawerMenu.propTypes = {
  handleDrawerOpen: propTypes.func,
  collapseMenu: propTypes.bool,
};
//#endregion

export default DrawerMenu;
