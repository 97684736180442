import React from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import useTranslation from "../../Hooks/Shared/useTranslation";

export const InputTypes = {
  NUMBER: "number",
  TEXT: "text",
  DATE: "date",
};

export interface TextInputProps {
  value: any;
  type?: any;
  variant?: string; //| 'outlined',
  size?: string; //|'small',
  name: string;
  onKeyPress?: any;
  id: string;
  label: string;
  isDisabled?: boolean;
  error?: boolean;
  focus?: boolean;
  handleChange: any;
  helperText?: any;
  required?: boolean;
  autoComplete?: string;
}

const TextInput = ({
  onKeyPress,
  value,
  type = InputTypes.TEXT,
  variant = "outlined",
  size = "small",
  name,
  id,
  label,
  isDisabled,
  error = false,
  focus = false,
  handleChange,
  helperText,
  required = false,
  autoComplete = "on",
}: TextInputProps) => {
  const { T } = useTranslation();

  const blockInvalidNumber = (e: any) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();


  return (
    <StyledFormControl>
      <StyledTextInput
        inputProps={{ style: { fontSize: "0.7rem" } }}
        InputLabelProps={{ style: { fontSize: "0.8rem" } }}
        required={required}
        autoFocus={focus}
        error={error}
        value={value}
        variant={variant}
        onKeyPress={onKeyPress}
        name={name}
        id={id}
        label={label}
        helperText={helperText && T(helperText)}
        type={type}
        disabled={isDisabled}
        onChange={handleChange}
        onKeyDown={type === InputTypes.NUMBER ? blockInvalidNumber : null}
        size={size}
        autoComplete={autoComplete}
      />
    </StyledFormControl>
  );
};

//#region :: Styled Components
const StyledFormControl = styled(FormControl)(() => ({
  width: "100%",
  // marginBottom: '10px'
}));

const StyledTextInput: any = styled(TextField)(({ theme }) => ({
  width: "100%",
  // marginBottom: '10px',
  ".MuiInputBase-input.Mui-disabled": {
    opacity: 1,
    background: theme.palette.primary.gainsboro,
  },
  // '.MuiInputBase-root': {
  //     lineHeight: '0.4',
  //     margin: '3px 0',

  // },
}));
//#endregion

//#region :: Validation PropTypes
TextInput.propTypes = {
  value: propTypes.any,
  name: propTypes.string,
  id: propTypes.string,
  label: propTypes.string,
  helperText: propTypes.string,
  type: propTypes.string,
  Width: propTypes.number,
  maxLength: propTypes.number,
  error: propTypes.bool,
  focus: propTypes.bool,
  isDisabled: propTypes.bool,
  required: propTypes.bool,
  handleChange: propTypes.func,
  keyDown: propTypes.func,
  autoComplete: propTypes.string
};
//#endregion

export default TextInput;
