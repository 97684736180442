import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

const Label = ({ text, variant, component, display, textWeight, textColor, textSize = '1rem', marginBottom }: any) => {

    return (
        <StyledTypography
            textweight={textWeight}
            textcolor={textColor}
            textsize={textSize}
            display={display}
            variant={variant}
            marginBottom={marginBottom}
            component={component}>
            {text}
        </StyledTypography>
    );
};

//#region :: Styled Component
const StyledTypography: any = styled(Typography)`
    &.MuiTypography-root {
        color: ${({ textcolor }: any) => textcolor && textcolor};
        font-weight: ${({ textweight }: any) => textweight && textweight};
        display: block;
        font-size: ${({ textsize }: any) => textsize && textsize};
        margin-bottom: ${({ marginBottom }: any) => marginBottom && marginBottom};
    }
`;
//#endregion

//#region :: Validation PropTypes
Label.propTypes = {
    text: propTypes.string,
    variant: propTypes.string,
    component: propTypes.string,
    display: propTypes.string,
    textWeight: propTypes.string,
    textColor: propTypes.string,
    textSize: propTypes.string
};
//#endregion

export default Label;