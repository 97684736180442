import React from 'react'
import Card from '../../Controls/Card'
import Profile from './Shared/profile'

const AddOnboarding = () => {
    return (
        <>
            <Card>
                <Profile />
            </Card>
        </>
    )
}

export default AddOnboarding




