import Locales from '../Services/Internationalization/Locales';

export const numberWithCommas = (number: any) => {
    const roundedNumber = roundNumber(number);
    return roundedNumber.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const roundNumber = (number: any) => {
    const parsedNumber = parseFloat(number);

    return Math.round((parsedNumber + Number.EPSILON) * 100) / 100;
};

export const getRandomInt = (max: any = 9989898989) => {
    return Math.floor(Math.random() * max);
};

export const formatCurrency = (value: number, currentLanguage: string) => {
    const formatter =
        new Intl.NumberFormat(currentLanguage == Locales.ENGLISH ? 'en-US' : 'ar-SA', {
            style: 'currency',
            currency: 'SAR',
        });
    if (isNaN(value)) value = 0;
    value ||= 0;

    let format = formatter.format(value);
    if (currentLanguage == Locales.ENGLISH) {
        var number = format.substring(4, format.length);
        format = `${number} SAR`;
        if (value < 0) format = `-${format}`
    }

    return format;
}