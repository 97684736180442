import Grid from '@mui/material/Grid';
import React, {  } from 'react';
import Card from '../../Controls/Card';
import TableComp from '../../Controls/Table';
import useTranslation from '../../Hooks/Shared/useTranslation';
import Search from './search';
import useConnectorDbSearchHook from './hooks/useConnectorDbSearchHook';
import ModalComponent from "../../Controls/Modal/Modal";
import Button, { ButtonsTypes } from '../../Controls/Button';
import ResponseErrorDialog from './Shared/ResponseErrorDialog';
import ResponseDetilasDialog from './Shared/ResponseDetilasDialog';

const ConnectorDbManagement = () => {

  //#region :: State
  const { T, Resources } = useTranslation();
   const {
    useconnectorDbsHook, 
    connectorDbsTableData, 
    columns, 
    pagination, 
    descriptionDialog, 
    handleCloseDescriptionDialog, 
    selectedJson, 
    detailsDialog, 
    setOpenDetailsDialog, 
    handleCloseDetailsDialog,
    selectedInvId,
    handleDownloadExcel,
   } = useConnectorDbSearchHook();
  //#endregion
  
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Search />
        </Grid>
        <Card> 
          
        <Grid
            item
            xs={12}
            display="flex"
            justifyContent="end"
            marginBottom={1.5}
          >
            <Button
              variant="contained"
              color="primary"
              type={ButtonsTypes.EXPORT}
              onClick={() => handleDownloadExcel()}
              label={T(Resources.AppResources.EXPORT)}
            />
          </Grid>
          <TableComp
            dataSource={connectorDbsTableData}
            tableColumns={columns}
            changePageApi={useconnectorDbsHook.changeCurrentPage}
            pagination={pagination} />  
            
        </Card>  
      </Grid>
      <ModalComponent
          maxWidth="sm"
          handleClose={() => handleCloseDescriptionDialog()}
          title={T(Resources.AppResources.DESCRIPTION)}
          open={descriptionDialog}
        >
          <ResponseErrorDialog
            data={selectedJson}
          />
        </ModalComponent>
      <ModalComponent
          maxWidth="lg"
          handleClose={() => handleCloseDetailsDialog()}
          title={T(Resources.AppResources.INVOICE_DETAILS)}
          open={detailsDialog}
        >
          <ResponseDetilasDialog
            data={selectedJson}
            invId={selectedInvId}
          />
        </ModalComponent>
    </Grid>
  );
};
export default ConnectorDbManagement;