import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CookiesConstants from '../Config/Settings/Cookies';
import { EnvLevelContext, EnvMode, StatusCode } from '../Config/Settings/Enums';
// import { GlobalServicesServices } from '../Services/GlobalServices';
import { StorageService } from '../Services/storage.service';
import { changeFullLoading } from '../Store/Reducers/UI';
import { setCookie } from '../Utils/Cookies';
import Config from './multitenantConfig';
import { useTenant } from './tenantContext';
import { WebServiceClient } from '../Services/API/WebServiceClient';
import { GlobalService } from '../Services/global.service';
import { isLoggedIn, setIsTenantContext, userInfo } from '../Store/Reducers/Auth';
import { PermissionsServices } from '../Services/API/Permissions';

export const AuthContext = React.createContext<any | undefined>(undefined);
export const useAuth = () => useContext(AuthContext);

const logoutChannel = new BroadcastChannel('logout');

export const AuthProvider = ({ children }: any) => {
    const { currentTenant } = useTenant() || {};
    const state = useSelector((state) => state);
    // const refreshDrawerNowVal = getRefreshDrawerNow(state);
    const isLoggedInUser = isLoggedIn(state);
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState();


    const getUser = async () => {
        try {
            
            // ToDo review 
            if(window.location.href.endsWith('/403') || window.location.href.endsWith('/401'))
            return ; 


            setIsLoading(true);
            dispatch(changeFullLoading({ spin: true }));
            let userInfoResponse: any= {};
            let isTenantContext: boolean= false;
            const response = await WebServiceClient.get(Config.Routes.GetUserInfo(currentTenant.id),false,false);
            userInfoResponse  = response?.data?.body;
            isTenantContext = userInfoResponse.accessLevels.includes(EnvLevelContext.TENANT);

            GlobalService.AppUser = {...userInfoResponse, isTenantContext};
            const tempIsTenantVal = GlobalService.IsTenantContext == null ?  isTenantContext : GlobalService.IsTenantContext;
            // GlobalService.IsTenantContext = tempIsTenantVal
            dispatch(setIsTenantContext({value: tempIsTenantVal, refresh: false}))
            if(!GlobalService.OrgsList){
                GlobalService.OrgsList = userInfoResponse?.organizations;
            }
            setUser(userInfoResponse);
            dispatch(userInfo({ body: userInfoResponse }));
            // dispatch(setIsTenantContext(isTenantContext));
            
            //we need here to call Permissions/Currentuser only when the user have Tenant Context to fetch his Permissions on that Context
            if(isTenantContext)
                GlobalService.AppUserAuthorizationInfo = await PermissionsServices.GetCurrentUserPermissions(false);//The parameter here is false where I alway just need the TenantContext Permissions 
            //end
            
            setIsAuthenticated(true);
        }
        catch (error) {
            setIsAuthenticated(false);
            // 
            if(error?.response?.status == StatusCode.FORBIDDEN && GlobalService.EnvMode == EnvMode.SIM)
            {
                const baseUrl = window.location.origin + currentTenant.clientBaseRoute;
                window.location.href = baseUrl;
                if(!isLoggedInUser){
                    GlobalService.EnvMode = EnvMode.PROD;
                    throw error;
                }
            }

            if (error?.response?.status == StatusCode.UNAUTHORIZED) {
                const baseUrl = window.location.origin + currentTenant.clientBaseRoute;
                if (baseUrl != window.location.href)
                    window.location.href = baseUrl;
            }
            

            if (error?.response?.status == StatusCode.FORBIDDEN) {
                const baseUrl = window.location.origin + currentTenant.clientBaseRoute  + '/403';
                if (baseUrl != window.location.href)
                    window.location.href = baseUrl;
            }
        }
        finally {
            dispatch(changeFullLoading({ spin: false }));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getUser();
        logoutAllTabs();
    }, []);

    const login = (returnUrl: string = window.location.href) => {
        window.location.href = `${Config.Routes.Login(currentTenant.id)}?returnUrl=${returnUrl}`;
    }

    const logout = async (redirectUrl: string = "/") => {
        setCookie(CookiesConstants.AUTH.IS_LOGGED_IN, false);
        StorageService.clearStorage();
        const logoutUrl = `${Config.Routes.Logout(currentTenant.id)}?redirectUrl=${redirectUrl}`;
        // await GlobalServicesServices.Logout(logoutUrl);
        logoutChannel.postMessage("logout");
        window.location.href = redirectUrl;
    }

    const logoutAllTabs = () => {
        logoutChannel.onmessage = () => {
            window.location.href=window.location.origin + "/" + currentTenant.id;
            logoutChannel.close();
        }
    }

    const value: any = {
        isAuthenticated,
        user,
        isLoading,
        login,
        logout,
        getUser
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};