
import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from '../../Utils/Cookies';
import { setItem } from '../../Utils/LocalStorage';
import CookiesConstants from '../../Config/Settings/Cookies';
import LocalStorageConstants from '../../Config/Settings/LocalStorage';
import { GlobalService } from '../../Services/global.service';

const auth = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: getCookie(CookiesConstants.AUTH.IS_LOGGED_IN),
        currentUser: null,
        error: null,
        isLoading: false,
        isTenantContext: GlobalService.IsTenantContext,
        isPermissionsUpdated: false,
    },
    reducers: {
        loginBegan: (auth, action) => {
            auth.isLoading = true;
        },
        loginSucceeded: (auth, { payload }) => {
            const data = payload.body;
            setItem(LocalStorageConstants.CURRENT_USER, data);
            setCookie(CookiesConstants.AUTH.IS_LOGGED_IN, true);
            auth.currentUser = data;
            auth.isLoggedIn = true;
            auth.isLoading = false;
        },
        loginFailed: (auth, { payload }) => {
            auth.error = payload;
            auth.isLoading = false;
        },
        logoutBegan: (auth, action) => {
            auth.isLoading = true;
        },
        logoutSucceeded: (auth, { payload }) => {
            auth.isLoggedIn = payload;
            auth.isLoading = false;
        },
        logoutFailed: (auth, { payload }) => {
            auth.error = payload;
            auth.isLoading = false;
        },
        userInfo: (auth, { payload }) => {
            const data = payload.body;
            auth.currentUser = data;
        },
        setIsTenantContext: (auth, { payload }) => {
            auth.isTenantContext = payload.value;
            GlobalService.IsTenantContext = payload.value;
            if(payload.refresh){
                if(payload){
                    GlobalService.CurrentActiveOrganization = null;
                    window.location.href = window.location.origin + "/" +  GlobalService.CurrentTenant;
                    // window.location.reload();
    
                }else{
                    GlobalService.CurrentActiveOrganization =  GlobalService.AppUser?.organizations && GlobalService.AppUser?.organizations.length > 0 ?
                     GlobalService.AppUser?.organizations[0] : null;
                    window.location.href = window.location.origin + "/" +  GlobalService.CurrentTenant;
                }
            }
        },
        setIsPermissionsUpdated: (auth, { payload }) => {
            auth.isPermissionsUpdated = payload;
        },

    }
});

export const {
    loginBegan, loginSucceeded, loginFailed, logoutBegan, logoutFailed, logoutSucceeded , userInfo, setIsTenantContext, setIsPermissionsUpdated
} = auth.actions;

export default auth.reducer;

//#region :: Selectors

export const getAuth = (state: any) => {
    return state.auth;
}

export const isLoggedIn = (state: any) => {
    return state.auth.isLoggedIn;
};

export const GetUserInfo = (state: any) => {
    return state.auth.currentUser;
};

export const getIsTenantContext = (state) => {
    return state.auth.isTenantContext;
};

export const getIsPermissionsUpdated = (state) => {
    return state.auth.isPermissionsUpdated;
};

export const AuthSelectors = {
    getAuth,
    isLoggedIn,
    GetUserInfo,
    getIsTenantContext,
    getIsPermissionsUpdated
};

//#endregion