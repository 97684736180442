import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { StatusCode } from "../../Config/Settings/Constants/StatusCode";
import ModalComponent, { IconTypes } from "../../Controls/Modal/Modal";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { changeFullLoading } from "../../Store/Reducers/UI";

const SwitchToggle = ({
  classname,
  active,
  inactive,
  isActive,
  isOutlined = true,
  handleStatusChange = undefined,
  id,
  message = "",
  switchable = true,
}: any) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(isActive);
  const [showModal, setShowModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    Resources.AppResources.CHANGE_STATUS_CONFIRMATION_MSG
  );
  const [itemStatus, setItemStatus] = useState();
  const [dialog, setDialog] = useState({
    error: {
      name: "error",
      show: false,
      title: Resources.AppResources.ERROR,
      message: "SOMETHING_WENT_WRONG",
    },
  });
  const errorDialogActions = [
    {
      order: 1,
      id: "btnClose",
      title: Resources.AppResources.CLOSE,
      variant: "outlined",
      action: () =>
        setDialog({
          ...dialog,
          error: {
            ...dialog.error,
            show: !dialog.error.show,
          },
        }),
    },
  ];
  const [apiResponseModel, setApiResponseModel] = useState({
    showModel: false,
    response: "",
  });
  //#endregion

  //#region :: Change Status
  const handleChange = async (value: any) => {

    if (message !== "" && !value) {
      setAlertMessage(message);
    } else {
      setAlertMessage(Resources.AppResources.CHANGE_STATUS_CONFIRMATION_MSG);
    }
    setItemStatus(value);
    setSelected(value);
    setShowModal(!showModal);
  };

  const handleClose = () => {
    setSelected(!selected);
    setShowModal(!showModal);
  };

  const alertActions = [
    {
      order: 2,
      id: "btnYes",
      title: Resources.AppResources.YES,
      variant: "contained",
      color: "primary",
      action: async () => {
        setShowModal(!showModal);
        dispatch(changeFullLoading({ spin: false }));

        var result = await handleStatusChange(itemStatus, id);

        dispatch(changeFullLoading({ spin: true }));

        if (
          result?.response !== undefined &&
          result?.response?.status !== undefined &&
          result?.response?.status === StatusCode.OK
        ) {
          dispatch(changeFullLoading({ spin: false }));
          result.success();
        } else {
          setApiResponseModel({
            showModel: !apiResponseModel.showModel,
            response: result?.response,
          });
          dispatch(changeFullLoading({ spin: false }));
        }
      },
    },
    {
      order: 1,
      id: "btnCancel",
      title: Resources.AppResources.CANCEL,
      variant: "outlined",
      action: () => handleClose(),
    }
  ];

  useEffect(() => {
    setSelected(isActive);
  }, [isActive, id]);
  //#endregion
  // console.log(`${id}:selected, isActive`,`${selected}, ${isActive}`);

  return (
    <StyledGrid
      className={selected ? "active" : "inactive"}
      container
      id={`statuc-${id}`}
      name={`statuc-${id}`}
    >
      {switchable ? (
        <>
          <ToggleButtonActive
            isoutlined={+isOutlined}
            className={selected ? "active" : "inactive"}
            size="small"
            id={`active-${id}`}
            name={`active-${id}`}
            value={true}
            disabled={selected}
            onChange={(e: any) => handleChange(true)}
          >
            <span>{active && T(active)}</span>
          </ToggleButtonActive>
          <ToggleButtonInactive
            isoutlined={+isOutlined}
            className={!selected ? "active" : "inactive"}
            size="small"
            id={`inactive-${id}`}
            name={`inactive-${id}`}
            value={false}
            disabled={!selected}
            onChange={(e: any) => handleChange(false)}
          >
            <span>{inactive && T(inactive)}</span>
          </ToggleButtonInactive>
        </>
      ) : active !== undefined ? (
        <ToggleButtonActive
          isoutlined={+isOutlined}
          className={selected ? "active" : "inactive"}
          size="small"
          id={`active-${id}`}
          name={`active-${id}`}
          value={true}
          disabled={selected}
        >
          <span>{active && T(active)}</span>
        </ToggleButtonActive>
      ) : (
        <ToggleButtonInactive
          isoutlined={+isOutlined}
          className={!selected ? "active" : "inactive"}
          size="small"
          id={`inactive-${id}`}
          name={`inactive-${id}`}
          value={false}
          disabled={!selected}
        >
          <span>{inactive && T(inactive)}</span>
        </ToggleButtonInactive>
      )}
      {showModal ? (
        <ModalComponent
          title={Resources.AppResources.CONFIRMATION}
          divider={false}
          open={showModal}
          actions={alertActions}
          loading={false}
          handleClose={() => handleClose()}
        >
          <StyledParagraph>{alertMessage && T(alertMessage)}</StyledParagraph>
        </ModalComponent>
      ) : null}
      <ModalComponent
        title={dialog.error.title}
        status={IconTypes.ERROR}
        open={dialog.error.show}
        actions={errorDialogActions}
        loading={false}
        divider={false}
        handleClose={() => {
          setSelected(!selected);
          setDialog({
            ...dialog,
            error: {
              ...dialog.error,
              show: !dialog.error.show,
            },
          });
        }}
      >
        <label>{dialog.error.message && T(dialog.error.message)}</label>
      </ModalComponent>
    </StyledGrid>
  );
};

//#region :: Styled Components
const StyledGrid: any = styled(Grid)`
  flex-flow: nowrap !important;
  border-radius: 5rem !important;
  width: fit-content !important;
  padding: 2px !important;
  &.active {
    border: 1px solid #28a745 !important;
    cursor: not-allowed;
  }
  &.inactive {
    border: 1px solid #ca0b00 !important;
    cursor: not-allowed;
  }
`;

const StyledParagraph: any = styled.p`
  margin: 0px;
`;

const ToggleButtonActive: any = styled(ToggleButton)`
  transition: all ease-in-out 0.4s;
  font-size: x-small !important;
  font-weight: bold !important;
  border-radius: 5rem !important;
  width: 65px !important;
  border: 1px solid transparent !important;
  padding: 0px !important;
  &.active {
    background: #28a745 !important;
    color: #fff !important;
  }
  &.inactive {
    background: transparent !important;
    color: #28a745 !important;
  }
`;

const ToggleButtonInactive: any = styled(ToggleButton)`
  transition: all ease-in-out 0.4s;
  font-size: x-small !important;
  font-weight: bold !important;
  border-radius: 5rem !important;
  width: 65px !important;
  border: 1px solid transparent !important;
  padding: 0px !important;
  &.active {
    background: #ca0b00 !important;
    color: #fff !important;
  }
  &.inactive {
    background: transparent !important;
    color: #ca0b00 !important;
  }
`;
//#endregion

export default SwitchToggle;
