import React, { } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router'
import Grid from '@mui/material/Grid';
import Card from '../../../Controls/Card'
import Accordion from '../../../Controls/Accordion'
import PagesRoute from '../../../Config/Settings/PagesRoute'
import useTranslation from '../../../Hooks/Shared/useTranslation'
import { getFieldValue } from '../../../Utils/StringUtils';
import Button from '../../../Controls/Button';
import { getOrganizationsDetails } from '../../../Store/Reducers/OrganizationManagement';
import { ImageNotAvailable } from '../../../Controls/Icons/Icons';
import "./style.scss"
import TableComp from '../../../Controls/Table';
import useViewOrganizationHook from './hooks/useViewOrganizationHook';

const ViewOrganization = () => {
    const { T, Resources } = useTranslation();
    const state = useSelector(state => state);
    const organizationsDetails = getOrganizationsDetails(state);
    const navigate = useNavigate();
    const {
        columnsLabels,
        addressesSource,
        pagination,
        changePageApi,
    } = useViewOrganizationHook();


    //#region :: Data List
    const list: Array<any> = [
        {
            id: 'panel1a-header',
            ariaControls: 'p1-content',
            title: T(Resources.AppResources.ORGANIZATION_DETAILS),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.ORGANIZATION_DETAILS)}</span>),
            details: (
                <Grid container paddingTop={1} spacing={1} xs={12}>
                    <Grid item xs={11}>
                        <Grid container spacing={1}>

                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.BUSINESS_CATEGORY)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(organizationsDetails?.organizationInfo?.businessCategory)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.NAME)}</Grid>
                                </Grid>
                                <Grid>
                                    {/* <Grid className='sub-title'>{getFieldValue(policiesDetails?.policyNo)}</Grid> */}
                                    <Grid className='sub-title'>{getFieldValue(organizationsDetails?.organizationInfo?.name)}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.TIN)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(organizationsDetails?.organizationInfo?.tin)}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container paddingTop={0.5} spacing={1}>


                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.IDENTITY_TYPE)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(T(Resources.AppResources[organizationsDetails?.organizationInfo?.identityInfo?.identityType?.description]))}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.IDENTITY_NUMBER)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(organizationsDetails?.organizationInfo?.identityInfo?.identityNumber)}</Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.VAT_REGISTRATION_NUMBER)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(organizationsDetails?.organizationInfo?.vatRegistrationNumber)}</Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container spacing={1}>


                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.CURRENCY)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>{getFieldValue(organizationsDetails?.organizationInfo?.currencyCode)}</Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Grid>
                                    <Grid className='title'>{T(Resources.AppResources.STATUS)}</Grid>
                                </Grid>
                                <Grid>
                                    <Grid className='sub-title'>    {getFieldValue(organizationsDetails?.isActive ? T(Resources.AppResources.ACTIVE) : T(Resources.AppResources.IN_ACTIVE))}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {organizationsDetails?.organizationInfo?.logoData ?
                            <Grid className={'zoom'} item xs={3} md={3}>
                                <img src={organizationsDetails?.organizationInfo?.logoData} />
                            </Grid>
                            :
                            <Grid style={{ marginTop: '-17px' }} className={'zoom'} item xs={3} md={3}>
                                <ImageNotAvailable color="#888" width={"175px"} height={"100px"} />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            )
        },
        {
            id: 'panel2a-header',
            ariaControls: 'p2-content',
            title: T(Resources.AppResources.ADDRESS),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.ADDRESS)}</span>),
            details: (

                <Grid paddingTop={2} md={12} sm={12} xs={12}>
                    <TableComp
                        dataSource={addressesSource}
                        tableColumns={columnsLabels}
                        changePageApi={changePageApi}
                        pagination={pagination}
                    />
                </Grid>
            )
        }
    ];
    //#endregion

    return (
        <Grid container spacing={1}>
            <Card>
                <Grid item xs={12}>
                    <Accordion content={list} />
                </Grid>

                <Grid item xs={12} marginTop={2}>
                    <Button
                        variant='outlined'
                        color='primary'
                        label={T(Resources.AppResources.BACK)}
                        onClick={() => navigate(PagesRoute.PAGES.ORGANIZATION_MANAGEMENT)} />
                </Grid>
            </Card>
        </Grid>
    )
}

export default ViewOrganization