import { Grid } from "@mui/material";
import React, { useState } from "react";
import Button, { ButtonsTypes } from "../../Controls/Button";
import Card from "../../Controls/Card";
import TableComp from "../../Controls/Table";
import useTranslation from "../../Hooks/Shared/useTranslation";
import useReportsListHook from "./hooks/useReportsListHook";
import Search from "./search";
import { DownloadExcel } from "../../Utils/File";
import ModalComponent from "../../Controls/Modal/Modal";
import QRDialog from "./Shared/qrDialog";

const Reports = () => {
  const { T, Resources } = useTranslation();
  const {
    cardLabels,
    reportsSource,
    pagination,
    dialog,
    handleCloseDialog,
    id,
    base64, 
    dialogPdf,
    handleCloseDialogPdf,
    reportsSearch,
    handleDownloadExcel
  } = useReportsListHook();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Search />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="end"
            marginBottom={1.5}
          >
            <Button
              variant="contained"
              color="primary"
              type={ButtonsTypes.EXPORT}
              onClick={handleDownloadExcel}
              label={T(Resources.AppResources.EXPORT)}
            />
          </Grid>
          <TableComp
            dataSource={reportsSource}
            tableColumns={cardLabels}
            pagination={pagination}
            changePageApi={reportsSearch.changeCurrentPage}
          />
        </Card>
      </Grid>
      <Grid>
        <ModalComponent
          maxWidth="sm"
          handleClose={() => handleCloseDialog()}
          title={"Scan QR Code"}
          open={dialog}
        >
          <QRDialog Id={id} />
        </ModalComponent>
      </Grid>
    </Grid>
  );
};

export default Reports;
