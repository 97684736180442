import React, { useState } from 'react'
import propTypes from 'prop-types';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import useTranslation from '../../Hooks/Shared/useTranslation';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

export interface DropDownProps {
    value?: any;
    name?: string;
    id?: string;
    label?: string;
    variant?: string; //| 'outlined', 
    helperText?: any;
    isDisabled?: boolean;
    handleChange?: any;
    options: any;
    group?: boolean;
    error?: boolean;
    required?: boolean;
    advancedDropDown?: boolean;
    advancedType?: string;
    size?: string; //|'small',
    defaultValue?: any; //'flat'
    translate?: boolean;
    showDefault?: boolean;
    multiCheck?: boolean;
    renderValue?: any;
    height?: any;
    onKeyPress?: any;
};

//#region :: MenuProps Multi Check DDList
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
//#endregion

const CustomDropDown = ({ value, name, id, label, renderValue, multiCheck = false, variant = 'outlined', helperText, isDisabled, handleChange, options,
    group = false, error = false, required = false, advancedDropDown = false, advancedType = 'flat', size = 'small',
    defaultValue, translate = false, showDefault = true, height = '33px', onKeyPress }: DropDownProps) => {

    const { T, Resources } = useTranslation();
    const [inputValue, setInputValue] = useState('');

    //#region :: Advanced DropDown Props
    const props = advancedDropDown ?
        advancedType === 'multiple'
            ? {
                options: options,
                getOptionLabel: (option: any) => translate ? T(option.label) : option.label,
                multiple: true
            }
            : {
                options: options !== undefined ? options.map((option: any) => translate ? T(option.label) : option.label) : [],
                flat: advancedType,
                onInputChange: (event: any, newInputValue: any) => {
                    setInputValue(newInputValue);
                }
            }
        : {};

    //#endregion

    return (
        <StyledFormControl error={error} variant={variant} size={size}>
            {advancedDropDown
                ? <StyledAutocomplete
                    style={{ height: height }}
                    {...props}
                    id={id}
                    label={label}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    disabled={isDisabled}
                    disableClearable={true}
                    inputValue={inputValue}
                    onKeyPress={onKeyPress}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            variant={variant}
                            label={label}
                            placeholder={label}
                            required={required}
                            error={error}
                            size={size}
                            helperText={helperText && T(helperText)} />
                    )} />
                : !multiCheck
                    ? <>
                        <InputLabel htmlFor={id}>{label}{required ? <span > * </span> : <></>}</InputLabel>
                        <StyledSelectInput
                            style={{ height: height }}
                            native
                            error={error}
                            label={label}
                            disabled={isDisabled}
                            required={required}
                            onChange={handleChange}
                            defaultValue={defaultValue}
                            value={value}
                            onKeyPress={onKeyPress}
                            inputProps={{
                                id: id,
                                name: name,
                                style: { fontSize: '0.7rem' }
                            }}>
                            {showDefault && <option aria-label='' value={undefined} defaultValue={undefined}>{T(Resources.AppResources.SELECT)}</option>}
                            {options !== undefined ?
                                !group ?
                                    options.map((item: any) => {
                                        return <option
                                            key={item.id}
                                            value={item.id}
                                            // regex={item.regex}
                                            defaultValue={item.id === defaultValue ? 1 : 0}>
                                            {translate
                                                ? T(item.label)
                                                : item.label}
                                        </option>
                                    }) :
                                    options.map((item: any, index: number) => (
                                        <optgroup label={index.toString()}>
                                            {item.listItem.map((opt: any) => {
                                                return <option
                                                    key={opt.id}
                                                    // regex={item.regex}
                                                    value={opt.id}>
                                                    {translate
                                                        ? T(opt.label)
                                                        : opt.label}
                                                </option>
                                            })}
                                        </optgroup>
                                    ))
                                : null}
                        </StyledSelectInput>
                        <FormHelperText>{helperText && T(helperText)}</FormHelperText>
                    </>
                    : <>
                        <StyledFormControl error={error} variant={variant} size={size}>
                            <>
                                <InputLabel htmlFor={id}>{label}{required ? <span > * </span> : <></>}</InputLabel>
                                <StyledSelectInput
                                    style={{ height: height }}
                                    multiple
                                    onKeyPress={onKeyPress}
                                    size={size}
                                    MenuProps={MenuProps}
                                    error={error}
                                    label={label}
                                    disabled={isDisabled}
                                    required={required}
                                    onChange={handleChange}
                                    value={value}
                                    inputProps={{
                                        id: id,
                                        name: name
                                    }}
                                    renderValue={renderValue}>
                                    {options && options.map((item: any) => (
                                        <MenuItem key={item.label} value={item.id}>
                                            <Checkbox size='small' color='primary' checked={value.indexOf(item.id) > -1} />
                                            <ListItemText primary={translate ? item.label && T(item.label) : item.label} />
                                        </MenuItem>
                                    ))}
                                </StyledSelectInput>
                            </>
                        </StyledFormControl>
                    </>
            }
        </StyledFormControl>
    )
};

//#region :: Styled Components
const StyledFormControl: any = styled(FormControl)`
    width: 100%;
    & .MuiInputBase-root {
        font-size: 0.9rem !important;
    }
    & .MuiInputLabel-root {
        font-size: 0.9rem !important;
    }
`;

const StyledSelectInput: any = styled(Select)`
    font-size: 0.9rem !important;
    // marginRight: '5px !important',
`;

const StyledAutocomplete: any = styled(Autocomplete)`
    width: 100%;
   
`;
//#endregion

//#region :: Validation PropTypes
CustomDropDown.propTypes = {
    value: propTypes.any,
    name: propTypes.string,
    id: propTypes.string,
    label: propTypes.string,
    helperText: propTypes.string,
    advancedType: propTypes.string,
    searchVariant: propTypes.string,
    Width: propTypes.number,
    error: propTypes.bool,
    required: propTypes.bool,
    advancedDropDown: propTypes.bool,
    group: propTypes.bool,
    handleChange: propTypes.func,
    options: propTypes.array
};
//#endregion

export default CustomDropDown;


