import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { UnauthorizedErrorImg } from '../../../Controls/Icons/Icons';
import '../style.css';

const UnauthorizedError = () => {

    return (
        <Grid item xs={12} spacing={1}>
            <div className="unauthorized-Error">
                <UnauthorizedErrorImg />
                <div className="unauthorized-Error-button">
                    <Box p={1}>
                        <h1 style={{ fontFamily: 'cairo' }}>غير مصرح لك بالدخول الى الخدمة</h1>
                    </Box>
                </div>
            </div>
        </Grid>
    );
};

export default UnauthorizedError;