import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportSearch } from '../../../Pages/Reports/Shared/reports.model';

const report = createSlice({
    name: 'report',
    initialState: {
        report: [],
        keys: [],
        reportDetails: {},
        searchCriteria: ReportSearch.toDBModel(ReportSearch.getFieldsModel()),
        showError: false,
        error: null
    },

    reducers: {
        reportReceived: (report: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                report.report[item.id] = item;
                report.keys = [...report.keys, item.id]
            });
            report.customerDetails = {};
        },

        setReportsDetails: (report: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            report.reportDetails = data;
        },

        setReports: (report, { payload }) => {
            report.report = payload;
        },

        setSearchCriteria: (report: any, { payload }: PayloadAction<any>) => {
            report.searchCriteria = payload.body;
        },

        setError: (report: any, { payload }: PayloadAction<any>) => {
            report.error = payload.response;
            report.showError = true;
        }
    }
});


export const { reportReceived, setReports, setSearchCriteria, setReportsDetails, setError } = report.actions;
export default report.reducer;

//#region :: Selectors
export const getReportsBookData = (state: any) => {
    return state.report.report;
};

export const getReportsDetails = (state: any) => {
    return state.report.reportDetails;
};

export const getSearchCriteria = (state: any) => {
    return state.report.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.report.showError;
};

export const getError = (state: any) => {
    return state.report.error;
};

export const reportSelectors = {
    getReportsBookData,
    getSearchCriteria,
    getShowError,
    getError,
};
//#endregion
