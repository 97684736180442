import React from 'react';
import Card from '../../Controls/Card';
import UsersProfile from './Shared/profile';

const EditOrgUser = () => {

    return (
            <Card>
                <UsersProfile />
            </Card>
    );
};

export default EditOrgUser;