import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const usersManagement = createSlice({
    name: 'usersManagement',
    initialState: {
        usersManagement: [],
        keys: [],
        usersManagementDetails: {},
        users: {},
        userDetails: null,
        facilities: [],
        ruleList: [],
        rulePageList:null,
        searchCriteria: {
            arabicName: '',
            englishName: '',
            mobileNumber: '',
            email: '',
            username: '',
            status: null
        },
        isSearching: true,
        error: null,
        showError: false
    },
    reducers: {
        usersRecived: (usersManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                usersManagement.users[item.id] = item;
                usersManagement.keys = [...usersManagement.keys, item.id]
            });

            usersManagement.userDetails = null;
        },
        userUpdated: (usersManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            //fixers here
            let dataFild = data?.body;
            usersManagement.users[data.id] = {
                ...usersManagement.users[data.id],
                englishName: dataFild?.englishName,
                arabicName: dataFild?.arabicName,
                email:dataFild?.email,
                username:dataFild?.username
            };
            usersManagement.keys = [...usersManagement.keys, data.id];
        },
        usersManagementReceived: (usersManagement: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                usersManagement.usersManagement[item.id] = item;
                usersManagement.keys = [...usersManagement.keys, item.id]
            });
            usersManagement.customerDetails = {};
        },

        setUserDetails: (usersManagement: any, { payload }: PayloadAction<any>) => {
            const currentUser = payload.body;
            usersManagement.userDetails = currentUser;
        },
        setFacilities: (usersManagement: any, { payload }: PayloadAction<any>) => {
            const facilities = payload.body;
            usersManagement.facilities = facilities;
        },
        setRuleList: (usersManagement: any, { payload }: PayloadAction<any>) => {
            const rule = payload.body;
            usersManagement.ruleList = rule;
        },
        setSearchCriteria: (usersManagement: any, { payload }: PayloadAction<any>) => {
            usersManagement.searchCriteria = payload.body;
            usersManagement.isSearching = true;
        },
        setIsSearching: (usersManagement: any, { payload }: PayloadAction<any>) => {
            usersManagement.isSearching = payload.isSearching;
        },
        setRulePageList: (usersManagement: any, { payload }: PayloadAction<any>) => {
            usersManagement.rulePageList ={...usersManagement.rulePageList,['page'+payload.pageIndex]:payload.ruleList};
        },
        clearRulePageList: (usersManagement: any, { payload }: PayloadAction<any>) => {
            usersManagement.rulePageList =null;
        },
        setError: (usersManagement: any, { payload }: any) => {
            usersManagement.error = payload.response;
            usersManagement.showError = true;
        }
    }
});

export const {
    usersRecived, userUpdated, setUserDetails, setSearchCriteria, setIsSearching, setError, setFacilities, setRuleList, usersManagementReceived
    ,setRulePageList,clearRulePageList
} = usersManagement.actions;

export default usersManagement.reducer;

//#region :: Selectors
export const getUsers = (state: any) => {
    return state.usersManagement.users;
};

export const getFacilities = (state: any) => {
    return state.usersManagement.facilities;
};

export const getRuleList = (state: any) => {
    return state.usersManagement.ruleList;
};

export const getUserDetails = (state: any) => {
    return state.usersManagement.userDetails;
};

export const getUserKeys = (state: any) => {
    return state.usersManagement.keys;
};

export const getShowError = (state: any) => {
    return state.usersManagement.showError;
};

export const getError = (state: any) => {
    return state.usersManagement.error;
};

export const getSearchCriteria = (state: any) => {
    return state.usersManagement.searchCriteria;
};

export const getIsSearching = (state: any) => {
    return state.usersManagement.isSearching;
};
export const getRulePageList = (state: any) => {
    return state.usersManagement.rulePageList;
};
export const getUsersManagementsBookData = (state: any) => {
    return state.usersManagement.usersManagement;
};

export const usersManagementSelectors = {
    getUsersManagementsBookData,
    getUsers,
    getUserDetails,
    getUserKeys,
    getShowError,
    getError,
    getSearchCriteria,
    getIsSearching,
    getFacilities,
    getRuleList
};
//#endregion