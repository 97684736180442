import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "../../Controls/Card";
import DefaultSearch from "../../Controls/Search/DefaultSearch";
import TextInput from "../../Controls/TextInputs";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { ConnectorDbManagementSearchModel } from "./Shared/connectorDb.model";

import {
  getSearchCriteria,
  setSearchCriteria,
} from "../../Store/Reducers/ConnectorDbManagement";
import { TenantsService } from "../../Services/API/Tenants";
import CustomDropDown from "../../Controls/CustomDropDown";

import DateInput from "../../Controls/DateInput";
import { GlobalService } from "../../Services/global.service";

const Search = () => {
  const { T, Resources } = useTranslation();

  //#region :: State
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const searchCriteria = getSearchCriteria(state);
  const [searchForm, setSearchForm] = useState(
    ConnectorDbManagementSearchModel.getFieldsModel()
  );
  const [sourcesDDList, setSourcesDDList] = useState<Array<any>>();
  //#endregion

  //#region :: Handlers
  const searchHandler = () => {
    const request = ConnectorDbManagementSearchModel.toDBModel(searchForm);

    dispatch(setSearchCriteria({ body: request }));
  };

  const handleInputChange = (event: any) => {
    var { name, value } = event.target;
    handleInputChangeValue(name, value);
  };

  const handleInputChangeValue = (name: any, value: any) => {
    setSearchForm({
      ...searchForm,
      [name]: {
        ...searchForm[name],
        value: value,
      },
    });
  };

  const resetForm = () => {
    setSearchForm(ConnectorDbManagementSearchModel.getFieldsModel());
    dispatch(setSearchCriteria({ body: {} }));
  };

  const handleCPressEnter = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };

  const getTenantsConnectorsList = async () => {
    try {
      const tenant = GlobalService.TenantServices;
      const response = await TenantsService.getConnectors(tenant?.id);

      setSourcesDDList(response);

      handleInputChangeValue("sourceId", response[0]?.id);
      const request = ConnectorDbManagementSearchModel.toDBModel(searchForm);

      dispatch(setSearchCriteria({ body: {...request, sourceId: response[0]?.id} }));
    } catch (error) {}
  };
  //#endregion

  //#region :: [useEffect]
  useEffect(() => {
    if (!sourcesDDList) {
      getTenantsConnectorsList();
    }

    if (!searchCriteria) return;
    setSearchForm({
      ...searchForm,
    });
  }, [searchCriteria]);

  return (
    <Card>
      <DefaultSearch
        searchHandler={searchHandler}
        resetHandler={resetForm}
        showAdvance={false}
      >
        <Grid item md={2.9} xs={12}>
          <CustomDropDown
            id={searchForm?.sourceId?.name}
            name={searchForm?.sourceId?.name}
            label={T(Resources.AppResources.SOURCE_NAME)}
            value={searchForm?.sourceId?.value}
            defaultValue={(sourcesDDList && sourcesDDList[0]?.id) || 0}
            options={sourcesDDList}
            showLabel={true}
            showDefault={false}
            handleChange={(e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item md={2.9} xs={12}>
          <TextInput
            id={searchForm?.referenceNumber?.name}
            name={searchForm?.referenceNumber?.name}
            label={T(Resources.AppResources.INVOICE_NUMBER)}
            value={searchForm?.referenceNumber?.value}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)}
          />
        </Grid>

        <Grid item md={2.9} xs={12}>
          <DateInput
            value={searchForm?.invoiceDateFrom?.value ? searchForm?.invoiceDateFrom?.value : null}
            minDate={new Date("1900-01-01")}
            id={searchForm?.invoiceDateFrom?.name}
            name={searchForm?.invoiceDateFrom?.name}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            size="small"
            isHijri={false}
            label={T(Resources.AppResources.INVOICE_DATE_FROM)}
            handleChange={(value: any) => {
              handleInputChangeValue(searchForm?.invoiceDateFrom.name, value);
            }}
          />
        </Grid>
        <Grid item md={2.9} xs={12}>
          <DateInput
            value={searchForm?.invoiceDateTo?.value? searchForm?.invoiceDateTo?.value : null}
            minDate={new Date("1900-01-01")}
            id={searchForm?.invoiceDateTo?.name}
            name={searchForm?.invoiceDateTo?.name}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            size="small"
            isHijri={false}
            label={T(Resources.AppResources.INVOICE_DATE_TO)}
            handleChange={(value: any) => {
              handleInputChangeValue(searchForm?.invoiceDateTo.name, value);
            }}
          />
        </Grid>
      </DefaultSearch>
    </Card>
  );
};

export default Search;
