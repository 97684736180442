import React, {  } from 'react';
import Grid from '@mui/material/Grid';
import Card from '../../../Controls/Card';
import DefaultSearch from '../../../Controls/Search/DefaultSearch';
import TextInput from '../../../Controls/TextInputs';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import useRoleMappingSearchHook from './hooks/useRoleMappingSearchHook';

const Search = () => {
  const { handleInputChange, searchHandler, searchForm, resetForm, handleCPressEnter } = useRoleMappingSearchHook();
  //#region :: State
  const { T, Resources } = useTranslation();
  //#endregion


  return (
    <Card>
      <DefaultSearch
        searchHandler={searchHandler}
        resetHandler={resetForm}
        showAdvance={false}>
        <Grid item md={12} xs={12}>
          <TextInput
            id={searchForm?.name?.name}
            name={searchForm?.name?.name}
            label={T(Resources.AppResources.NAME)}
            value={searchForm?.name?.value}
            onKeyPress={handleCPressEnter}
            handleChange={(e: any) => handleInputChange(e)} />
        </Grid>
      </DefaultSearch>
    </Card>
  );
};

export default Search;