import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../Layout/Auth';
import ForbiddenError from '../Pages/Errors/ForbiddenError';
import UnauthorizedError from '../Pages/Errors/UnauthorizedError';
import { useSelector } from 'react-redux';
import { GetUserInfo } from '../Store/Reducers/Auth';

const SignIn = lazy(() => import('../Pages/Auth/SignIn'));
const SignUp = lazy(() => import('../Pages/Auth/SignUp'));
// const ForgotPassword = lazy(() => import('../Pages/Auth/ForgotPassword'));
// const ResetPassword = lazy(() => import('../Pages/Auth/ResetPassword'));

// const NotFound = () => {
//   return <><div>Not Found</div></>
// };

const FrontendRoutes = () => {
  const state = useSelector((state) => state);
  const userInfo = GetUserInfo(state);
  return (
    <>
    
      {userInfo === null ? (
        <Routes>
          <Route path="*" element={<UnauthorizedError />} />
        </Routes>
      ) : (
        <Routes>
          {/* <Route exact path="/forgotPassword" component={ForgotPassword} /> */}
          {/* <Route exact path="/reset_password" component={ResetPassword} /> */}
          <Route path="401" element={<UnauthorizedError />} />
          <Route path="403" element={<ForbiddenError />} />
          <Route path="register" element={<SignUp />} />
          <Route path="/" element={<SignIn />} />
          <Route path="*" element={<SignIn />} />
        </Routes>
      )}
    </>
  );
};

export default Layout(FrontendRoutes);