import { FieldModel } from "..";
import { regexMsgs, regexType } from "../../Config/Settings/Constants/Validations";

export class InvoicesBookModel {

    name?: string;
    language?: any;
    invoicingType?: any;
    preferredAddress?: any;
    b2BInvoicing?: boolean; 
    b2CInvoicing?: boolean;
    // enableInProdution?: boolean;
    // enableInSimulation?: boolean;
    // otp?: any;
    tags?: any;
    isOffline?: any;



    
    public static getFieldsModel(model?: InvoicesBookModel): any {
        if (!model) model = new InvoicesBookModel();
        let fildsObject = FieldModel.buildFromObject(model);

        fildsObject.isOffline.required = true;
        fildsObject.isOffline.validate = true;
        fildsObject.isOffline.value = false;

        fildsObject.language.required = true;
        fildsObject.language.validate = true;
        fildsObject.language.regexValue = regexType.DDL_VALUE;
        fildsObject.language.errorMessages = {
          error: regexMsgs.PERIOD,
        };
        fildsObject.language.value = 0;

        fildsObject.preferredAddress.required = true;
        fildsObject.preferredAddress.validate = true;
        fildsObject.preferredAddress.regexValue = regexType.DDL_VALUE;
        fildsObject.preferredAddress.errorMessages = {
          error: regexMsgs.PERIOD,
        };
        fildsObject.preferredAddress.value = 0;

        // fildsObject.category.required = true;
        // fildsObject.category.validate = true;
        // fildsObject.category.value = null;

        fildsObject.b2BInvoicing.value = false;
        fildsObject.b2CInvoicing.value = false;


        
        fildsObject.name.required = true;
        fildsObject.name.validate = true;
        fildsObject.name.regexValue = regexType.MIN_2_MAX_100;
        fildsObject.name.errorMessages = {
          error: regexMsgs.MIN_2_MAX_100,
        };

        fildsObject.invoicingType.required = true;
        fildsObject.invoicingType.validate = true;

        // fildsObject.organization.required = true;
        // fildsObject.organization.validate = true;


        fildsObject.tags.required = true;
        fildsObject.tags.validate = true;
        // fildsObject.tags.regexValue = regexType.MIN_2_MAX_20;
        // fildsObject.tags.errorMessages = {
        //   error: regexMsgs.MIN_2_MAX_20,
        // };


        fildsObject.invoicingType.value = null;
        // fildsObject.organization.value = null;
        return fildsObject;
    }


    public static mapToFormModel(dbModel: InvoicesBookDbModel): any {
        const fildsObject = InvoicesBookModel.getFieldsModel();
    
        fildsObject.name.value = dbModel.invoicesBookInfo.name;
        fildsObject.language.value = dbModel.invoicesBookInfo.language.code;
        fildsObject.invoicingType.value = dbModel.invoicesBookInfo.b2BInvoicing || dbModel.invoicesBookInfo.b2CInvoicing;
        fildsObject.preferredAddress.value = dbModel?.invoicesBookInfo?.preferedAddressId;
        fildsObject.b2BInvoicing.value = dbModel?.invoicesBookInfo?.b2BInvoicing;
        fildsObject.b2CInvoicing.value = dbModel?.invoicesBookInfo?.b2CInvoicing;
        fildsObject.tags.value = dbModel?.invoicesBookInfo?.tags;
        fildsObject.isOffline.value = dbModel?.isOffline;
    
        return fildsObject;
      }

    public static toDBModel(model:any){
        //To do 
        const invoicesBookMetaData: Array<any> = [];
        let body={
            name: model.name.value,
            language:{
                code: parseInt(model.language.value),
            } ,
            // category:parseInt(model.category.value),
            b2BInvoicing: model.b2BInvoicing.value,
            b2CInvoicing: model.b2CInvoicing.value,
            preferedAddressId: model.preferredAddress.value,
            tags: model.tags.value,
            isOffline: model.isOffline.value,
            invoicesBookMetaData
        }
        return body;
    }
}


export interface InvoicesBookDbModel {
    isOffline: boolean;
    serialNumber: string
    status: Status
    isActive: boolean
    invoicesBookInfo: InvoicesBookInfo
    invoicingAddress: any
    organizationId: number
    id: number
    createdOn: string
    createdBy: string
    lastUpdatedOn: any
    lastUpdatedBy: any
  }
  
  export interface Status {
    description: string
    code: number
    source: string
  }
  
  export interface InvoicesBookInfo {
    name: string
    tags: string
    language: Language
    category: any
    b2BInvoicing: boolean
    b2CInvoicing: boolean
    preferedAddressId: number
  }
  
  export interface Language {
    description: string
    code: number
    source: string
  }
  

  export class InvoicesBookOnBoardingModel {
    otp?: any;
    
    
    public static getFieldsModel(model?: InvoicesBookOnBoardingModel): any {
        if (!model) model = new InvoicesBookOnBoardingModel();
        let fildsObject = FieldModel.buildFromObject(model);


        fildsObject.otp.required = true;
        fildsObject.otp.validate = true;

        return fildsObject;
    }
  }
