import React, { useEffect, useState } from "react";
import useTranslation from "../../../../Hooks/Shared/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import { changeFullLoading } from "../../../../Store/Reducers/UI";
import { ValidationService } from "../../../../Utils/Validation.services";
import { ValidationModel } from "../../../../Models";
import { useParams } from "react-router";
import { getInvoicesBookLists } from "../../../../Store/Reducers/InvoicesBook";
import { InvoicesBookService } from "../../../../Services/API/InvoicesBook";
import { GlobalService } from "../../../../Services/global.service";
import { InvoicesBookModel, InvoicesBookOnBoardingModel } from "../../../../Models/system/invoices-book.model";
import {
  MESSAGE_TYPE,
  MessagesService,
} from "../../../../Services/messages.service";
import PagesRoute from "../../../../Config/Settings/PagesRoute";
import { useNavigate } from "react-router-dom";
import { AddressesService } from "../../../../Services/API/Addresses";
import { MetaDataModel } from "../Shared/invoicesBook.model";
import CustomeIconButton, { IconButtonsTypes } from "../../../../Controls/IconButton";
import { InvoicesBookMetaService } from "../../../../Services/API/InvoicesBookMeta";
import { InvoicesBookStatuses, MetaDataSourceEnumText } from "../../../../Config/Settings/Enums";

const useAddInvoicesBookHook = () => {
  const { T, Resources } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const state = useSelector((state: any) => state);
  const invoicesBookLists = getInvoicesBookLists(state);
  const [orgAddressesList, setOrgAddressesList] = useState<Array<any>>();
  const [invoiceBookDetails, setInvoiceBookDetails] = useState<any>(null);
  const [TAGS, setTAGS] = useState<Array<{ id: number, label: string }>>([]);
  const [isOnboarded, setIsOnboarded] = useState(false);
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(
    InvoicesBookModel.getFieldsModel()
  );
  const [metaDataFormState, setMetaDataFormState] = useState(
    MetaDataModel.getFieldsModel()
  );
  const [lastUpdatedItem, setLastUpdatedItem] = useState<any>(null);
  const [metaDataSource, setMetaDataSource] = useState<Array<any>>([]);
  const [metaDataList, setMetaDataList] = useState<Array<any>>([]);
  const current_organization = GlobalService.CurrentActiveOrganization;

  const columnsLabels: Array<any> = [
    { id: "name", label: T(Resources.AppResources.NAME), align: "center" },
    { id: "description", label: T(Resources.AppResources.DESCRIPTION), align: "center" },
    { id: "isVisible", label: T(Resources.AppResources.VISIBLE), align: "center" },
    { id: "order", label: T(Resources.AppResources.ORDER), align: "center" },
    { id: "placement", label: T(Resources.AppResources.PLACEMENT), align: "center" },
    { id: "belongsTo", label: T(Resources.AppResources.BELONGS_TO), align: "center" },
    { id: "action", label: T(Resources.AppResources.ACTIONS), align: "center" },
];

  

  //#region :: Handlers
  const handleInputChange = (event: any) => {
    var { name, value } = event.target;
    handleSetInputChange(name, value);
  };

  const handleSetInputChange = (name: any, value: any) => {
    setFormState({
      ...formState,
      [name]: {
        ...formState[name],
        value: value,
      },
    });
  };


  const handleMetaDataInputChange = (event: any) => {
    var { name, value } = event.target;
    handleSetMetaDataInputChange(name, value);
  };

  const handleMetaDataVisibleChange = (event: any) => {
    var { name, checked } = event.target;
    handleSetMetaDataInputChange(name, checked);
  };

  const handleSetMetaDataInputChange = (name: any, value: any) => {
    setMetaDataFormState({
      ...metaDataFormState,
      [name]: {
        ...metaDataFormState[name],
        value: value,
      },
    });
  };

  const handleInvoiceTypeChange = (name: any, value: any) => {
    
    let b2CInvoicing = formState.b2CInvoicing.value;
    let b2BInvoicing = formState.b2BInvoicing.value;

    if (name == "b2CInvoicing") {
      b2CInvoicing = value;
    }

    if (name == "b2BInvoicing") {
      b2BInvoicing = value;
    }

    setFormState({
      ...formState,
      [name]: {
        ...formState[name],
        value: value,
      },
      invoicingType: {
        ...formState.invoicingType,
        value: b2CInvoicing || b2BInvoicing ? true : null,
      },
      // isOffline: {
      //   ...formState.isOffline,
      //   value: !b2CInvoicing  ? false : formState.isOffline.value,
      // },
    });
  };
  
  const handleOfflineChange = ( value: any) => {
    
    setFormState({
      ...formState,
      "isOffline": {
        ...formState["isOffline"],
        value: !value,
      },
      b2BInvoicing: {
        ...formState.b2BInvoicing,
        value: value ? false : formState.b2BInvoicing.value,
      },
    });
  };

  const handleInvoiceTypeChangeB2C = (value: any) => {
    
    setFormState({
      ...formState,
      b2CInvoicing: {
        ...formState.b2CInvoicing,
        value: value,
      },
    });
  };

  const createInvoicesBook = async (createAndNew: boolean = false) => {
    let valid = ValidationService.Valid(formState);
    let validationResponse = ValidationModel.setValidations(valid, formState);
    setFormState(validationResponse);

    if (valid.isValidForm) {
      // if (
      //   !validationResponse?.b2BInvoicing?.value &&
      //   !validationResponse?.b2CInvoicing?.value
      // ) {
      //   MessagesService.Toast(
      //     T(Resources.AppResources.B2B_B2C_ERROR_MESSAGE),
      //     MESSAGE_TYPE.Error
      //   );
      //   return false;
      // }
      // if (
      //   !validationResponse?.enableInSimulation?.value &&
      //   !validationResponse?.enableInProdution?.value
      // ) {
      //   MessagesService.Toast(
      //     T(Resources.AppResources.SIMULATOR_PRODUCTION_ERROR_MESSAGE),
      //     MESSAGE_TYPE.Error
      //   );
      //   return false;
      // }
      dispatch(changeFullLoading({ spin: true }));
      let invoicesBook: Array<any> = [...invoicesBookLists];
      let request = InvoicesBookModel.toDBModel(formState);
      
      try {
        // debugger
        if (id) {
          await InvoicesBookService.UpdateInvoicesBook(id, request);
          MessagesService.Toast(
            T(Resources.AppResources.UPDATED_SUCCESS),
            MESSAGE_TYPE.Success
          );
          navigate(PagesRoute.PAGES.INVOICES_BOOK_MANAGEMENT);
        } else {
          request.invoicesBookMetaData   = metaDataList.map(itm => {return{...itm, id: 0}})
          await InvoicesBookService.AddInvoicesBook({
            body: request,
          });
          MessagesService.Toast(
            T(Resources.AppResources.CREATED_SUCCESS),
            MESSAGE_TYPE.Success
          );
          if (createAndNew) {
            setFormState(InvoicesBookModel.getFieldsModel());
            setMetaDataFormState( MetaDataModel.getFieldsModel());
            setMetaDataList([]);
            setMetaDataSource([])
          } else {
            navigate(PagesRoute.PAGES.INVOICES_BOOK_MANAGEMENT);
          }
        }
      } catch (error: any) {
        //console.log(error);
      } finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    } else {
      // MessagesService.Toast(T(Resources.AppResources.PLEASE_FILL_THE), MESSAGE_TYPE.Error);
      //console.log("plesae fill");
    }
  };

  const getOrgAddressesDataSource = async () => {
    try {
      let response: any;

      response = await AddressesService.ListAddressesByOrganization();

      let items: Array<any> = [];

      if (response?.data?.body?.length > 0) {
        response?.data?.body?.map((item: any) => {
          const obj = {
            id: item?.id,
            label: item?.addressInfo?.name,
          };
          items.push(obj);
        });
        setOrgAddressesList(items);
      }
    } catch (error) {
    } finally {
    }
  };

  const handelFetchData = async () => {
    try {
      dispatch(changeFullLoading({ spin: true }));
      let response: any;
      let metaResponse: any;
      let request = {
        pageIndex: 0,
        pageSize: 100
      };
      if (id) {
        response = await InvoicesBookService.GetInvoicesBookById(id);
        metaResponse = await InvoicesBookMetaService.SearchInvoicesBook(request, id);
        const formData = InvoicesBookModel.mapToFormModel(response);

        // //console.log("newForm",formState);
        // { id: 1, label:formState.tags.value}
        setFormState(formData);
        setInvoiceBookDetails(response);
        setMetaDataList(metaResponse);
        
        setIsOnboarded(id &&
          response &&
          (
            response?.status?.code == InvoicesBookStatuses.ONBOARDING_PENDING || 
            response?.status?.code == InvoicesBookStatuses.READY || 
            response?.status?.code == InvoicesBookStatuses.PROD_CSID_EXPIRED ||
            response?.status?.code == InvoicesBookStatuses.PROD_CSID_RENEWAL_FAILED ||  
            response?.status?.code == InvoicesBookStatuses.PROD_CSID_RENEWAL_PENDING 
          )
          )
      }
    } catch (error) {
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  };

  const handleTagsChange = async (newList: Array<{ id: number, label: string }>) => {
    let newTags = newList.length ?  newList.map(x => x.label).join(',') : null;
    handleInvoiceTypeChange("tags", newTags)

  };
  const onEnter = async (e) => {
    if (e.key === "Enter" && e.target.value && e.target.value.trim().length) {
      const value = e.target.value.trim().toLowerCase()
      value && addTags(value);
      
 
    }
  };

  const handelTagBlur = async (e) => {
    const value = e?.target?.value?.trim().toLowerCase()
    value && addTags(value);
      
  };

  const addTags = (value: any) => {
    // setTAGS(tags)
    handleInvoiceTypeChange("tags", formState?.tags?.value ? `${formState?.tags?.value},${value}` : value)
  }
  const prepareTagsList = (listText) => {
    
    return listText &&  listText?.split(',').map(x => {return{ id: Date.now(), label:x}})
  }

  const handelAddMetaData = async () => {
    let valid = ValidationService.Valid(metaDataFormState);
    let validationResponse = ValidationModel.setValidations(valid, metaDataFormState);
    setMetaDataFormState(validationResponse);
    if(metaDataList.some(itm => itm.order == metaDataFormState.order.value)){
      MessagesService.Toast(
        T(Resources.AppResources.ORDER_EXIST_ERROR),
        MESSAGE_TYPE.Error
      );
      return;
    }
    
    if (valid.isValidForm) { 
      // debugger
      let newMetaData = MetaDataModel.toDBModel(metaDataFormState);
 
      if(id && newMetaData.isNew){
        await InvoicesBookMetaService.AddInvoiceBookMeta( newMetaData, id);
      }else if(id && !newMetaData.isNew){
        await InvoicesBookMetaService.UpdateInvoiceBookMeta(newMetaData.id, newMetaData, id);
      }
      if(lastUpdatedItem) setLastUpdatedItem(null);
      setMetaDataList([...metaDataList, newMetaData]);
      setMetaDataFormState(MetaDataModel.getFieldsModel());
    }
  };

  const handleEditMetaData = (metaDataId:any) => {
 
    //if(lastUpdatedItem)setMetaDataList([...metaDataList, lastUpdatedItem]);
    const editedItem = metaDataList.find(x => x.id == metaDataId);

    setLastUpdatedItem(editedItem);
    setMetaDataFormState(MetaDataModel.getFieldsModel(editedItem));
    
    if(lastUpdatedItem)
      setMetaDataList([...metaDataList.filter(x => x.id != metaDataId), lastUpdatedItem]);
    else 
    setMetaDataList(metaDataList.filter(x => x.id != metaDataId));
  };

  const handleDelete =async (metaDataId:any) => {
    
    if(id && metaDataList.some(itm => itm.id == metaDataId && !itm.isNew)){
      await InvoicesBookMetaService.DeleteInvoiceBookMeta( metaDataId);
    }
    setMetaDataList(metaDataList.filter(x => x.id != metaDataId));
  };

  useEffect(() => {
    if (id) handelFetchData();
  }, [id]);

  useEffect(() => {
    getOrgAddressesDataSource();
  }, []);

  useEffect(() => {
    
    setMetaDataSource(metaDataList.sort(function(a,b){
      return a.order - b.order
    })
      .map(itm => {
      return{
        id: itm.id,
        name: itm.name,
        description: itm.description,
        isVisible: itm.isVisible ? T(Resources.AppResources.YES) :  T(Resources.AppResources.NO),
        order: itm.order,
        placement: itm.placement?.value || itm?.placement,
        belongsTo:  T(itm?.belongsTo?.label || MetaDataSourceEnumText[itm?.belongsTo]),
        action:(
          <>
            <CustomeIconButton
                id='edit'
                aria-label='edit'
                type={IconButtonsTypes.EDIT}
                onClick={() => { handleEditMetaData(itm.id); }}
                disabled={isOnboarded} />
            <CustomeIconButton
                id='delete'
                aria-label='delete'
                onClick={() => { handleDelete(itm.id) }}
                type={IconButtonsTypes.DELETE}
                disabled={isOnboarded}
            />
          </>
        ),
      }
    }));
  }, [metaDataList, isOnboarded]);
  return {
    formState,
    handleSetInputChange,
    handleInputChange,
    createInvoicesBook,
    handleInvoiceTypeChangeB2C,
    orgAddressesList,
    handleInvoiceTypeChange,
    TAGS, 
    setTAGS,
    handleTagsChange,
    handelTagBlur,
    prepareTagsList,
    onEnter,
    isOnboarded,
    metaDataFormState,
    handleMetaDataInputChange,
    handleMetaDataVisibleChange,
    columnsLabels,
    metaDataSource,
    handelAddMetaData,
    handleSetMetaDataInputChange,
    handleOfflineChange
  };
};

export default useAddInvoicesBookHook;
