import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class OnboardingService {

    
    public static GetOnboardingById = async (id: any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.ONBOARDING.ONBOARDING_STATUS.replace("{invoicesBookId}", id));
            let result = response?.data?.body;
            //console.log({result});
            return result;
        } catch (error: any) {
            WebServiceClient.getError(error);
            throw error;
        }
    };

    public static AddOnboarding = async (invoiceBookId: any, request: any) => {
        try {
            let url = APIConstants.ONBOARDING.POST.replace("{invoicesBookId}", invoiceBookId)
            const response = await WebServiceClient.post(url, request);
            return response;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static RenewOnboarding = async (invoiceBookId: any, request: any) => {
        try {
            let url = APIConstants.ONBOARDING.RENEW.replace("{invoicesBookId}", invoiceBookId)
            const response = await WebServiceClient.patch(url, request);
            return response;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static UpdateOnboarding = async (id: any, data: any) => {
        try {
            let object = {
                body: {
                    ...data
                }
            }
            const result = await WebServiceClient.put(APIConstants.ONBOARDING.GET + id, object)
            return result;
        }
        catch (error: any) {
            throw error;
        }
    }

    public static ActivateOnboarding = async (id: any, value) => {
        try {
            const response = await WebServiceClient.patch(APIConstants.ONBOARDING.ACTIVE_STATUS.replace('{invoicesBookId}', id), {body: value});;
            return response
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }


}