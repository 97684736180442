import { EnvMode } from "../Config/Settings/Enums";
import { StorageService, STORAGE_KEY } from "./storage.service";

export class GlobalService {


    public static get AppUser(): User | null {
        let storageItem = StorageService.getItem(STORAGE_KEY.CURRENT_USER);
        if (storageItem && storageItem)
            return storageItem;
        return null;
    }

    public static set AppUser(value: any) {
        StorageService.setItem(STORAGE_KEY.CURRENT_USER, value);
    }

    public static get CurrentActiveOrganization(): any {

        return StorageService.getItem(StorageService.generateKey(STORAGE_KEY.CURRENT_ORGANIZATION));
    }

    public static set CurrentActiveOrganization(value: any) {

        StorageService.setItem(StorageService.generateKey(STORAGE_KEY.CURRENT_ORGANIZATION), value);
    }

    public static set AccessToken(value: any) {
        StorageService.setItem(STORAGE_KEY.ACCESS_TOKEN, value);
    }

    public static get AccessToken(): string {
        return StorageService.getItem(STORAGE_KEY.ACCESS_TOKEN);
    }

    public static set AppUserAuthorizationInfo(value: any) {
        
        StorageService.setItem(STORAGE_KEY.USER_AUTHORIZATION_INFO, value);

    }

    public static get AppUserAuthorizationInfo(): any {
        return StorageService.getItem(STORAGE_KEY.USER_AUTHORIZATION_INFO);
    }

    public static set IsTenantContext(value: any) {
        StorageService.setItem(STORAGE_KEY.IS_TENANTCONTEXT, value);
    }

    public static get IsTenantContext(): any {
        return StorageService.getItem(STORAGE_KEY.IS_TENANTCONTEXT);// == null ?? true  ;
    }

    public static set EnvMode(value: any) {
        StorageService.setItem(STORAGE_KEY.ENV_MODE, value);
    }

    public static get EnvMode(): any {
        return StorageService.getItem(STORAGE_KEY.ENV_MODE) || EnvMode.SIM;
    }

    public static set OrgsList(value: any) {
        StorageService.setItem(STORAGE_KEY.ORGS_LIST, value);
    }

    public static get OrgsList(): any {
        return StorageService.getItem(STORAGE_KEY.ORGS_LIST);
    }

    public static set RefetchOrgs(value: any) {
        StorageService.setItem(STORAGE_KEY.REFETCH_ORGS, value);
    }

    public static get RefetchOrgs(): any {
        return StorageService.getItem(STORAGE_KEY.REFETCH_ORGS) || false;
    }
    
    // public static set CurrentActiveFacility(value: any) {
    //     let currentFacility = StorageService.getItem(StorageService.generateKey(STORAGE_KEY.CURRENT_ACTIVE_FACILITY));
    //     const redirect = value?.id != currentFacility?.id;
    //     StorageService.setItem(StorageService.generateKey(STORAGE_KEY.CURRENT_ACTIVE_FACILITY), value, true);
        
    //     if (redirect)
    //         window.location.href = `${window.location.origin}/${this.TenantServices.id}`;
        
    // }

    // public static get CurrentActiveFacility(): any {
    //     var currenValue = StorageService.getItem(StorageService.generateKey(STORAGE_KEY.CURRENT_ACTIVE_FACILITY));
    //     var currentUser = GlobalService.AppUser;

    //     if (currentUser) {
    //         var facilityExists = currentUser?.facilities?.some((faci: any) => faci.id == currenValue?.id);

    //         if (!facilityExists) {
    //             currenValue = {
    //                 id: currentUser?.facilities[0].id,
    //                 name: currentUser?.facilities[0].englishName
    //             };
    //             GlobalService.CurrentActiveFacility = currenValue;
    //         }

    //         if (currenValue) return currenValue;

    //         if (currentUser != null && currentUser && currentUser.facilities && currentUser.facilities.length) {
    //             currenValue = currentUser.facilities[0];
    //         }
    //     }


    //     GlobalService.CurrentActiveFacility = currenValue;



    //     return currenValue;
    // }

    // public static set FacilityCategoryType(value: any) {
    //     StorageService.setItem(StorageService.generateKey(STORAGE_KEY.FACILITY_CATEGORY_TYPE), value);
    // }

    // public static get FacilityCategoryType(): any {
    //     return StorageService.getItem(StorageService.generateKey(STORAGE_KEY.FACILITY_CATEGORY_TYPE));
    // }

    public static set TenantServices(value: any) {
        StorageService.setItem(STORAGE_KEY.TenantServices, value);
    }

    public static get TenantServices(): any {
        return StorageService.getItem(STORAGE_KEY.TenantServices);
    }

    public static set TenantUrl(value: any) {
        StorageService.setItem(STORAGE_KEY.TenantUrl, value);
    }
    public static get TenantUrl(): any {
        return StorageService.getItem(STORAGE_KEY.TenantUrl);
    }

    public static get CurrentTenant(): string {
        return this.TenantServices.id || "";
    }

}



export interface User {

    id: number;
    name: number;
    isSystemManager: boolean;
    isManager: boolean;
    organizations: Array<Organization>;
    permissions: Array<any>;

}

export interface  Organization{
    id: number
    value: string;
    name: string;
}