import Grid from '@mui/material/Grid';
import React, {  } from 'react';

import Card from '../../Controls/Card';
import TableComp from '../../Controls/Table';
import Button, { ButtonsTypes } from '../../Controls/Button';
import useTranslation from '../../Hooks/Shared/useTranslation';
import Search from './search';
import UserInfoDialog from './Shared/AddUserDialog/adUsersDialog';
import useUserSearchHook from './hooks/useUserSearchHook';
import RulesDialog from './Shared/AddUserRolesDialogs/orgRulesDialog';
import { PermissionScopes, PermissionsService } from '../../Services/permissions.service';
import { DownloadExcel } from '../../Utils/File';
import { MESSAGE_TYPE, MessagesService } from '../../Services/messages.service';
import { useDispatch } from 'react-redux';
import { changeFullLoading } from '../../Store/Reducers/UI';
import { GlobalService } from '../../Services/global.service';
import { ReportsService } from '../../Services/API/Reports';

const UsersManagement = () => {

  //#region :: State
  const { T, Resources } = useTranslation();
  const { usersManagementHook, handleUserInfoDialog, usersTableData, columns, pagination, dialogs, userId, roleDialog, handleRoleDialog  } = useUserSearchHook();
  //#endregion
  const dispatch = useDispatch();
  const handleDownloadExcel = async (invoiceId: any) => {
  
    try{
 const request = {
        "body": {
          "organizationsId": GlobalService.CurrentActiveOrganization.id,
          "pageIndex": 0,
          "pageSize": 1000000
        }
      };
      const response = await ReportsService.OrganizationUsersReports(request);
      const filteredData = response?.data.body.map((user: any) => {
        return {
          Id: user.id,
          UserName: user.userName,
          Name: user.name,
          MobileNumber: user.mobileNumber,
          Email: user.email,
          IsActive: user.isActive
        };
      });
      DownloadExcel(filteredData, T(Resources.AppResources.REPORTS));
    } catch (error) {
      MessagesService.Toast(T(Resources.AppResources.NO_ACTIONS), MESSAGE_TYPE.Error);
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  }
  

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Search />
        </Grid>
        
        
        <Card>
        <Button
              variant="contained"
              color="primary"
              type={ButtonsTypes.EXPORT}
              onClick={handleDownloadExcel}
              label={T(Resources.AppResources.EXPORT)}
            />
         {PermissionsService.hasPermission(PermissionScopes.ADD_NEW_USER_ORGANIZATION) &&  <Grid item xs={12} display='flex' justifyContent='end' marginBottom={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { handleUserInfoDialog(null); }}
              type={ButtonsTypes.ADD}
              label={T(Resources.AppResources.ADD_USER)} />
          </Grid>}
          <TableComp
            dataSource={usersTableData}
            tableColumns={columns}
            changePageApi={usersManagementHook.changeCurrentPage}
            pagination={pagination} /> 
        </Card>
             </Grid>
      {roleDialog && <RulesDialog open={roleDialog} handleClose={handleRoleDialog} userId={userId} />}
       
      {dialogs?.userInfo && <UserInfoDialog open={dialogs.userInfo} id={userId} handleClose={
        (isReload: boolean) => {
          handleUserInfoDialog(null);
          if (isReload) {
            usersManagementHook.refetch();
          }
        }} />}
    </Grid>
  );
};
export default UsersManagement;