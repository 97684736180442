/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text: any, size: any) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const getFieldValue = (field: any) => {
  if (!field) return 'ــــ';
  return field;
};

const combineCodeAndName = (code: any, name: any) => {
  let result = `${code ? `(${code})` : ''} ${name ?? ''}`
  return result;
}

const combineWithSpace = (firstStr: any, secondStr: any) => {
  let result = `${firstStr} ${secondStr}`
  return result;
}

export { ellipsis, getFieldValue, combineCodeAndName, combineWithSpace };