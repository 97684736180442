import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingSelectors, setError, onboardingReceived } from '../Store/Reducers/Onboarding';
import { pageRequested, PaginationSelectors, PaginationModules } from '../Store/Reducers/Pagination';
import Constants from '../Config/Settings/API';
const useOnboardingSearch = () => {

    //#region :: State

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [onboarding, setOnboarding] = useState<Array<any>>([]);
    const _onboarding = onboardingSelectors.getOnboardingsData(state);
    const searchCriteria = onboardingSelectors.getSearchCriteria(state);

    const currentPage = PaginationSelectors.getCurrentPage(state, PaginationModules.ONBOARDING);
    const pageSize = PaginationSelectors.getPageSize(state, PaginationModules.ONBOARDING);
    const totalPages = PaginationSelectors.getTotalPages(state, PaginationModules.ONBOARDING);
    const pagination = PaginationSelectors.getPaginationModule(state, PaginationModules.ONBOARDING);

    const lastPageIndex = PaginationSelectors.getLastPageIndex(state, PaginationModules.ONBOARDING);
    const hasMore = PaginationSelectors.hasMore(state, PaginationModules.ONBOARDING) || false;
    const isPageFetching = PaginationSelectors.isPageFetching(state, PaginationModules.ONBOARDING);
    const pageKeys = PaginationSelectors.getPageKeys(state, PaginationModules.ONBOARDING, currentPage);

    const requestPage = (actionType: any, pageIndex: any, reset: boolean = false) => {

        if (isPageFetching) return;


        dispatch(pageRequested({
            url: Constants.ONBOARDING.SEARCH,
            data: { body: { ...searchCriteria, pageIndex: pageIndex, pageSize: pageSize } },
            method: 'POST',
            onSuccess: actionType,
            onError: setError.type,
            args: {
                resetPages: reset,
                source: 'body',
                id: 'id',
                module: PaginationModules.ONBOARDING,
                page: pageIndex
            }
        }));
    };

    const changeCurrentPage = (page: any) => {
        requestPage(onboardingReceived.type, page);
    };

    useEffect(() => {
        if (!searchCriteria) return;
        requestPage(onboardingReceived.type, 0, true);
    }, [searchCriteria]);

    useEffect(() => {
        if (pageKeys && pageKeys.keys) {
            let items: Array<any> = [];

            pageKeys.keys.map((key: any) => {
                items.push(_onboarding[key]);
            });
            setOnboarding(items);
        }
    }, [pageKeys.keys]);

    useEffect(() => {
        setOnboarding(_onboarding);
    }, [_onboarding]);

    return { onboarding, hasMore, isPageFetching, lastPageIndex, currentPage, pagination, totalPages, pageSize, changeCurrentPage };
};

export default useOnboardingSearch;