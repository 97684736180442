import { Grid } from '@mui/material'
import React from 'react'
import Button from '../../../../Controls/Button';
import TextInput from '../../../../Controls/TextInputs'
import useAddInvoicesBookHook from '../hooks/useAddInvoicesBookHook';
import useTranslation from '../../../../Hooks/Shared/useTranslation';

const OtpDialog = ({ onboardingFormState, handleOnboardingInputChange, handelSendOtp }: any) => {
    const { T, Resources } = useTranslation();

    return (
        <Grid container paddingY={2} spacing={1} xs={12}>
            <Grid style={{ textAlign: 'center', padding: "10px" }} item md={12} sm={12} xs={12}>
                <label>{T(Resources.AppResources.OTP_MSG)}</label>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <TextInput
                    id={onboardingFormState.otp.name}
                    name={onboardingFormState.otp.name}
                    label={T(Resources.AppResources.OTP)}
                    value={onboardingFormState.otp.value}
                    required={onboardingFormState.otp.required}

                    helperText={!onboardingFormState.otp.valid ? T(Resources.AppResources.OTP_MSG_ERROR) : onboardingFormState.name}
                    error={!onboardingFormState.otp.valid}
                    handleChange={(e) => handleOnboardingInputChange(e)} />
            </Grid>

            <Grid style={{ textAlign: 'center' }} item xs={12} marginTop={2}>
                <Button
                    variant='contained'
                    color='primary'
                    label={T(Resources.AppResources.START)}
                    onClick={handelSendOtp}
                />
            </Grid>
        </Grid>
    )
}

export default OtpDialog