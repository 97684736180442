import Grid from '@mui/material/Grid';
import React, { } from 'react';
import styled from 'styled-components';
import Card from '../../../Controls/Card';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import Button from '../../../Controls/Button';
import useAppNavigate from '../../../Hooks/Shared/useAppNavigate';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { getFieldValue } from '../../../Utils/StringUtils';
import './styles.scss'
import ReactJson from 'react-json-view';


const containerStyle = {
  borderRadius: '3px',
  backgroundColor: 'rgb(240, 240, 240)',
};

const getJsonFieldValue = (JText) => {
  try {
    const jsonData = JSON.parse(JText);


    if (typeof jsonData === 'object' && jsonData !== null) {
      return (
        <div dir="ltr"><ReactJson src={jsonData} collapsed={true} collapseStringsAfterLength={100} style={containerStyle} /> </div>
      );
    } else {
      return <span>{JText}</span>;
    }
  } catch (error) {
    return <span>{JText}</span>;
  }
}

const LogReuestDetails = ({ dataSource }) => {
  const { T, Resources } = useTranslation();
  const navigate = useAppNavigate();
  //#region :: State
  console.log(dataSource);


  return (

    <Grid container spacing={1}>
      <Grid item xs={6} md={4}>
        <Grid item>
          <StyledSubParagraph>{T(Resources.AppResources.MESSAGE_ID)}</StyledSubParagraph>
        </Grid>
        <Grid item>
          <StyledDetails>{dataSource && getFieldValue(dataSource.messageId)}</StyledDetails>
        </Grid>
      </Grid>
      <Grid item xs={6} md={4}>
        <Grid item>
          <StyledSubParagraph>{T(Resources.AppResources.MESSAGE)}</StyledSubParagraph>
        </Grid>
        <Grid item>
          <StyledDetails>{dataSource && getFieldValue(dataSource.message)}</StyledDetails>
        </Grid>
      </Grid>
      <Grid item xs={6} md={4}>
        <Grid item>
          <StyledSubParagraph>{T(Resources.AppResources.DIRECTION)}</StyledSubParagraph>
        </Grid>
        <Grid item>
          <StyledDetails>{dataSource && getFieldValue(dataSource.dircation)}</StyledDetails>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid item>
          <StyledSubParagraph>{T(Resources.AppResources.REQUEST_HEADERS)}</StyledSubParagraph>
        </Grid>
        <Grid item>
          <StyledDetails>
            <StyledDetails>{dataSource && getJsonFieldValue(dataSource?.headers)}</StyledDetails>
          </StyledDetails>
        </Grid>
      </Grid>
      {dataSource?.requestBody && dataSource?.requestBody.trim() !== '' && (<Grid item xs={12} md={12}>
        <Grid item>
          <StyledSubParagraph>{T(Resources.AppResources.REQUEST_BODY)}</StyledSubParagraph>
        </Grid>
        <Grid item>
          <StyledDetails>{dataSource && getJsonFieldValue(dataSource?.requestBody)}</StyledDetails>
        </Grid>
      </Grid>
      )}
 
 
      {dataSource?.responseBody && dataSource?.responseBody.trim() !== '' && (
        <Grid item xs={12} md={12}>
          <Grid item>
            <StyledSubParagraph>{T(Resources.AppResources.RESPONSE_BODY)}</StyledSubParagraph>
          </Grid>
          <Grid item>
            <StyledDetails>{dataSource && getJsonFieldValue(dataSource?.responseBody)}</StyledDetails>
          </Grid>
        </Grid>
      )}
 
    </Grid>

  );
};

//#region :: Styled Components
const StyledSubParagraph: any = styled.span(() => ({
  fontWeight: 'bold!important',
  fontSize: 'medium!important'
}));

const StyledDetails: any = styled.p(({ theme }: any) => ({
  fontSize: '0.8rem',
  padding: '0px',
  marginTop: '0px',
  color: theme.palette.primary.darkGrayish
}));
//#endregion

export default LogReuestDetails;