import React, {  } from 'react'
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { Card, Grid } from '@mui/material';
import Search from './search';
// import Button, { ButtonsTypes } from '../../../Elements/Button';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import TableComp from '../../../Controls/Table';
import Button, { ButtonsTypes } from '../../../Controls/Button';
import { PermissionScopes, PermissionsService } from '../../../Services/permissions.service';
import useRoleMappingListHook from './hooks/useRoleMappingListHook';

const RolesMapping = () => {
    //#region :: State
    const { T, Resources } = useTranslation();
    const {
        pageData,
        columns,
        changeCurrentPage,
        innerPagination,
    } = useRoleMappingListHook();
    //#endregion

    

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12} marginBottom={'10px'}>
                    <Search />
                </Grid>
                <Card style={{padding: '16px'}}>
                    {PermissionsService.hasPermission(PermissionScopes.ADD_NEW_ROLE) && <Grid item xs={12} display='flex' justifyContent='end' marginBottom={2}>
                        <Button
                        variant="contained"
                        color="primary"
                        link={true}
                        to={PagesRoute.PAGES.CREATEROLE}
                        type={ButtonsTypes.ADD}
                        label={T(Resources.AppResources.ADD_ROLE)} />
                    </Grid>}
                    <TableComp
                        dataSource={pageData}
                        tableColumns={columns}
                        changePageApi={changeCurrentPage}
                        pagination={innerPagination} />
                </Card>
            </Grid>
        </Grid>
    );
}

export default RolesMapping