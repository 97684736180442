import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class LoggerService {
    
  // public static removeLogger= async (id: any) => {
    //     try {
    //         var response = await WebServiceClient.delete(APIConstants.LOGGER.DELETE.replace("{Id}", id), false, false);
    //         return response;
    //     } catch (error: any) {
    //         WebServiceClient.getError(error);
    //     }
    // }

    public static GetById = async (id:any) => {
        try {
            const response = await WebServiceClient.get(APIConstants.LOGGER.GET+ id,true,false);
            return response?.data?.body;
        } catch (error: any) {
            WebServiceClient.getError(error);
        }
    };
 
}