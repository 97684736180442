import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '../../../../../Controls/Card';
import DefaultSearch from '../../../../../Controls/Search/DefaultSearch';
import TextInput from '../../../../../Controls/TextInputs';
import useTranslation from '../../../../../Hooks/Shared/useTranslation';

interface Props {
  searchHandler: Function;
}
const AdUsersSearch = ({searchHandler}) => {

  //#region :: State
  const { T, Resources } = useTranslation();
  const [nameSearchText, setNameSearchText] = useState("");
  const [emailSearchText, setEmailSearchText] = useState("");
  const [userNameSearchText, setUserNameSearchText] = useState("");


  const resetForm = () => {
    setNameSearchText("");
    setEmailSearchText("");
    setUserNameSearchText("");
    searchHandler();
  };

  //#endregion


  return (
    <Card>
      <DefaultSearch
        searchHandler={() => searchHandler(nameSearchText, emailSearchText,userNameSearchText )}
        resetHandler={resetForm}
        showAdvance={false}>
        <div style={{display:'flex', gap:5, width:' 100%', padding: '0 5px', alignItems: 'center', paddingTop: '7px'}}>
          <TextInput
              id={"email-SearchText"}
              name={"emailSearchText"}
              value={emailSearchText}
              label={T(Resources.AppResources.EMAIL)}
              maxLength={100}
              handleChange={(e: any) => setEmailSearchText(e?.target?.value)}
          />
          <TextInput
              id={"name-SearchText"}
              name={"nameSearchText"}
              value={nameSearchText}
              label={T(Resources.AppResources.NAME)}
              maxLength={100}
              handleChange={(e: any) => setNameSearchText(e?.target?.value)}
          />
            <TextInput
              id={"userName-SearchText"}
              name={"userNameSearchText"}
              value={userNameSearchText}
              label={T(Resources.AppResources.USERNAME)}
              maxLength={100}
              handleChange={(e: any) => setUserNameSearchText(e?.target?.value)}
          />
        </div>
      </DefaultSearch>
    </Card>
  );
};

export default AdUsersSearch;