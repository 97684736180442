import React from 'react';
import styled from 'styled-components';
import MuiPagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';

export interface PaginationProps {
    dataCount?: number;
    totalPages?: number;
    totalCount?: number;
    pageIndex?: number;
    handleChange?: any;
    showFirstButton?: boolean;
    showLastButton?: boolean;
    size?: string;
    variant?: string;
    shape?: string;
    totalresultText?: any;
};

const Pagination = ({ dataCount = 1, totalPages = 1, totalCount = 0, pageIndex = 0, handleChange, showFirstButton = true, showLastButton = true,
    size = 'small', variant = 'text', shape = 'rounded', totalresultText = '' }: PaginationProps) => {

    return (
        dataCount != undefined && dataCount > 0
            ? <PaginationGrid container>
                <Grid item xs={4}>
                    <PaginationText>{totalresultText} {totalCount}</PaginationText>
                </Grid>
                <Grid item xs={4}>
                    <PaginationStyle
                        page={pageIndex + 1}
                        count={totalPages}
                        onChange={(e: any, page: any) => handleChange && handleChange(e, page)}
                        showFirstButton={showFirstButton}
                        showLastButton={showLastButton}
                        size={size}
                        variant={variant}
                        shape={shape} />
                </Grid>
            </PaginationGrid>
            : null
    );
};

//#region :: Styled Components
const PaginationGrid: any = styled(Grid)`
    padding-top: 1rem;
`;

const PaginationText: any = styled.span`
    align-self: center;
    padding-left: 10px;
`;

const PaginationStyle: any = styled(MuiPagination)`
    display: flex;
    justify-content: center;
`;
//#endregion

export default Pagination;