import { LocalesEnum } from "../../../Services/Internationalization/Locales";

export const mainTypeDDList = [
  { label: "app.administrative", id: 1 },
  { label: "app.billable", id: 2 },
  { label: "app.development", id: 3 },
  { label: "app.others", id: 4 },
];

export const subTypeDDList = [{ label: "app.others", id: 1 }];

export const PeriodTypeDDList = [
  { label: "app.day", id: 1 },
  { label: "app.month", id: 2 },
  { label: "app.year", id: 3 },
];

export const StatusDDList = [
  { label: "status.active", id: true },
  { label: "status.inActive", id: false },
];

export const GenderDDList = [
  { label: "app.male", id: 1 },
  { label: "app.female", id: 2 },
];

export const NationalityCode = [
  { label: "app.sau", id: 1 },
  { label: "app.jor", id: 2 },
];


export const LanguagesValues = {
  [LocalesEnum.ARABIC]: "ARABIC_LANGUAGE",
  [LocalesEnum.ENGLISH]: "ENGLISH_LANGUAGE"
};

export const LanguageDDList = [
  { label: "app.arabic_language", id: 1 },
  { label: "app.english_language", id: 2 },
];

export const MaritalStatusDDList = [
  { label: "app.single", id: 1 },
  { label: "app.married", id: 2 },
  { label: "app.divorced", id: 3 },
  { label: "app.widower", id: 4 },
  { label: "app.unselect", id: 5 },
];


export const TaxDDList = [
  { label: "First", id: 1 },
  { label: "Second", id: 2 },
  { label: "Third", id: 3 },
  { label: "Fourth", id: 4 },
  { label: "Fifth", id: 5 },
  { label: "Sixth", id: 6 }
];

export const IdentityTypesDDList = [
  { label: "TAX_IDENTIFICATION_NUMBER", id: 1 }, 
  { label: "COMMERCIAL_REGISTRATION_NUMBER", id: 2 }, 
  { label: "MOMRA", id: 3 }, 
  { label: "MLSD", id: 4 }, 
  { label: "CODE_700", id: 5 }, 
  { label: "SAGIA", id: 6 }, 
  { label: "NATIONAL_ID", id: 7 }, 
  { label: "GCC", id: 8 }, 
  { label: "IQAMA", id: 9 }, 
  { label: "PASSPORT", id: 10 }, 
  { label: "OTHER_ID", id: 11 }, 
];

export const CountriesDDList = [
  { label: "country.sa", id: "SA" }, 
];

export const ContextLevelsList = [
  { label: 'app.tenant', id: 1 },
  { label: 'app.organization', id: 2 }
];

export const MetaDataSource = [
  { label: 'app.invoices', id: 1 },
  { label: 'app.invoices_book', id: 2 }
];

export const InvoiceStatuses = [
  { label: 'app.pending', id: 1 },
  { label: 'app.reported', id: 2 },
  { label: 'app.reported_with_warnings', id: 201 },
  { label: 'app.cleared', id: 3 },
  { label: 'app.cleared_with_warnings', id: 301 },
  { label: 'app.failed', id: 4 },
  { label: 'app.rejected', id: 5 }
];