import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import PrintIcon from '@mui/icons-material/Print';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiIconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import MenuIcon from '@mui/icons-material/Menu';
import { theme as MainThemeVariables } from '../../Config/Theme/ThemeVariables';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CheckBox from '@mui/icons-material/CheckBox';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddCardIcon from '@mui/icons-material/AddCard';
import ArticleIcon from '@mui/icons-material/Article';
import { Tooltip } from '@mui/material';
export const IconButtonsTypes = {
    VIEW: 'view',
    QR: 'qr',
    EXPORT: 'export',
    EDIT: 'edit',
    DELETE: 'delete',
    CANCEL: 'cancel',
    ADD: 'add',
    PLUS: 'plus',
    MINUS: 'minus',
    PRINT: 'print',
    ERRORDETAILS: 'errorDetails',
    WARNING_ICON: 'warning_icon',
    REFRESH: 'refresh',
    DETAILS: 'details',
    SYNC: 'sync',
    CLOSE: 'close',
    MENUE: 'menue',
    SAVE: 'save',
    ARROWDOUN: 'arrowdoun',
    ARROWUP: 'arrowup',
    CHECKBOX: 'checkbox',
    ADD_FACILITIES: 'addFacilities',
    CORPORATEFARE_ICON: 'CorporatefareIcon',
    MANAGEACCOUNTS_ICON: 'ManageaccountsIcon',
    ACCESS_RULE: 'accessRule',
    ARTICLE: 'ARTICLE',

};

export interface CustomeIconButtonProps {
    id?: any;
    ariaLabel?: any;
    component?: any;
    to?: any;
    disabled?: boolean;
    onClick?: any;
    type?: any;
    customeColor?: any;
    color?: any;
    className?: any;
    style?: any;
};

const CustomeIconButton = ({ id, ariaLabel, component, to, disabled = false, onClick, type = '', customeColor, color, className, style }: CustomeIconButtonProps) => {

    const iconButtons = (type: any) => {
        switch (type) {
            case IconButtonsTypes.ADD:
                return <MuiIconButton
                    id={id}
                    className={className}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyleAddCircleOutlineIcon />
                </MuiIconButton>;
            case IconButtonsTypes.ERRORDETAILS:
                return <MuiIconButton
                    id={id}
                    customecolor={MainThemeVariables['Chestnut']}
                    color={color}
                    className={className}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledErrorIcon />
                </MuiIconButton>;
            case IconButtonsTypes.WARNING_ICON:
                return <MuiIconButton
                    id={id}
                    customecolor={MainThemeVariables['Chestnut']}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    className={className}
                    to={to}
                    onClick={onClick}>
                    <StyledWarningIcon />
                </MuiIconButton>;
            case IconButtonsTypes.MENUE:
                return <MuiIconButton
                    id={id}
                    customecolor={MainThemeVariables['white-color']}
                    color={color}
                    aria-label={ariaLabel}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <MenuIcon style={{ color: MainThemeVariables['white-color'] }} />
                </MuiIconButton>;
            case IconButtonsTypes.DETAILS:
                return <MuiIconButton
                    id={id}
                    customecolor={customeColor}
                    className={className}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <MoreHorizIcon />
                </MuiIconButton>;
            case IconButtonsTypes.EXPORT:
                return <MuiIconButton
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledExportIcon />
                </MuiIconButton>;
            case IconButtonsTypes.QR:
                return <MuiIconButton
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledQrIcon />
                </MuiIconButton>;
            case IconButtonsTypes.VIEW:
                return <StyledIconButton
                    id={id}
                    aria-label={ariaLabel}
                    customecolor={customeColor}
                    color={color}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledVisibilityIcon customecolor={customeColor} />
                </StyledIconButton>;
            case IconButtonsTypes.REFRESH:
                return <StyledIconButton
                    id={id}
                    aria-label={ariaLabel}
                    customecolor={customeColor}
                    color={color}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledSyncIcon />
                </StyledIconButton>;
            case IconButtonsTypes.CLOSE:
                return <StyledIconButton
                    id={id}
                    aria-label={ariaLabel}
                    customecolor={customeColor}
                    color={color}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledCloseIcon color={color} />
                </StyledIconButton>;
            case IconButtonsTypes.EDIT:
                return <StyledIconButton
                    id={id}
                    aria-label={ariaLabel}
                    customecolor={customeColor}
                    color={color}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledEditIcon />
                </StyledIconButton>;
            case IconButtonsTypes.CANCEL:
                return <StyledIconButton
                    id={id}
                    aria-label={ariaLabel}
                    customecolor={customeColor}
                    color={color}
                    className={className}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledCancelIcon />
                </StyledIconButton>;
            case IconButtonsTypes.DELETE:
                return <StyledIconButton
                    id={id}
                    aria-label={ariaLabel}
                    customecolor={customeColor}
                    color={color}
                    disabled={disabled}
                    className={className}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledDeleteIcon />
                </StyledIconButton>;
            case IconButtonsTypes.PRINT:
                return <MuiIconButton
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    className={className}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <PrintIcon />
                </MuiIconButton>;
            case IconButtonsTypes.SYNC:
                return <StyledIconButton
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    className={className}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledSyncIcon />
                </StyledIconButton>;
            case IconButtonsTypes.SAVE:
                return <StyledIconButton
                    className={className}
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <SaveIcon />
                </StyledIconButton>;
            case IconButtonsTypes.ARROWUP:
                return <StyledIconButton
                    className={className}
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <KeyboardArrowUpIcon />
                </StyledIconButton>;
            case IconButtonsTypes.ARROWDOUN:
                return <StyledIconButton
                    className={className}
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <KeyboardArrowDownIcon />
                </StyledIconButton>;
            case IconButtonsTypes.CHECKBOX:
                return <StyledIconButton
                    className={className}
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <CheckBox />
                </StyledIconButton>;
            case IconButtonsTypes.ADD_FACILITIES:
                return (
                <Tooltip title="Add Facilties" placement='top'>
                    <StyledIconButton
                    className={className}
                    //   style={style}
                    id={id}
                    customecolor={customeColor}
                    color={color}
                    aria-label={ariaLabel}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <DomainAddIcon className="width18" />
                    </StyledIconButton>
                </Tooltip>
                );
                case IconButtonsTypes.MANAGEACCOUNTS_ICON
                  :
                  return (
                    <Tooltip title="Organization Context" placement='top'>
                      <StyledIconButton
                        className={className}
                        id={id}
                        style={style}
                        customecolor={customeColor}
                        color={color}
                        aria-label={ariaLabel}
                        disabled={disabled}
                        component={component}
                        to={to}
                        onClick={onClick}>
                        <ManageAccountsIcon style={style} className="width18" />
                      </StyledIconButton>
                    </Tooltip>
                  );
                case IconButtonsTypes.CORPORATEFARE_ICON
                  :
                  return (
                    <Tooltip title="Tenant Context" placement='top'>
                      <StyledIconButton
                        className={className}
                        id={id}
                        style={style}
                        customecolor={customeColor}
                        color={color}
                        aria-label={ariaLabel}
                        disabled={disabled}
                        component={component}
                        to={to}
                        onClick={onClick}>
                        <CorporateFareIcon style={style} className="width18" />
                      </StyledIconButton>
                    </Tooltip>
                  );
                  case IconButtonsTypes.ACCESS_RULE:
                    return (
                      <Tooltip title="Add Role" placement='top'>
                        <StyledIconButton
                          // className={className}
                          id={id}
                          style={style}
                          customecolor={customeColor}
                          color={color}
                          aria-label={ariaLabel}
                          disabled={disabled}
                          component={component}
                          to={to}
                          onClick={onClick}>
                          <AddCardIcon className="width18" />
                        </StyledIconButton>
                      </Tooltip>
                    );
                  case IconButtonsTypes.ARTICLE:
                    return (
                      <Tooltip title="Article" placement='top'>
                        <StyledIconButton
                          // className={className}
                          id={id}
                          style={style}
                          customecolor={customeColor}
                          color={color}
                          aria-label={ariaLabel}
                          disabled={disabled}
                          component={component}
                          to={to}
                          onClick={onClick}>
                          <ArticleIcon className="width18" />
                        </StyledIconButton>
                      </Tooltip>
                    );
            default:
                return <StyledIconButton
                    className={className}
                    id={id}
                    aria-label={ariaLabel}
                    customecolor={customeColor}
                    color={color}
                    disabled={disabled}
                    component={component}
                    to={to}
                    onClick={onClick}>
                    <StyledVisibilityIcon />
                </StyledIconButton>;
        }
    };

    return (iconButtons(type))
};

//#region :: Styled Component
const StyledIconButton: any = styled(MuiIconButton)`
padding: 3px!important;
`;

const StyledVisibilityIcon: any = styled(VisibilityIcon)`
width: 18px!important;
&.MuiSvgIcon-root{
color: ${({ customecolor }: any) => customecolor && customecolor};
}
`;
const StyledSyncIcon: any = styled(SyncIcon)`
width: 18px!important;
`;

const StyledQrIcon: any = styled(QrCodeScannerIcon)`
width: 18px!important;
`;
const StyledExportIcon: any = styled(SaveAltIcon)`
width: 18px!important;
`;

const StyledCloseIcon: any = styled(CloseIcon)`
width: 18px!important;
color: ${({ color }) => color && color};
`;

const StyledCancelIcon: any = styled(CancelIcon)`
width: 18px!important;
`;

const StyleAddCircleOutlineIcon: any = styled(AddCircleOutlineIcon)`
width: 18px!important;
`;

const StyledErrorIcon: any = styled(ErrorIcon)(({ theme }) => ({
    width: '18px!important',
    margin: '-14px!important',
    color: theme.palette.primary.Chestnut
}));
const StyledWarningIcon: any = styled(WarningIcon)(({ theme }) => ({
    width: '18px!important',
    margin: '-14px!important',
    color: theme.palette.primary.warningColor,
    fill: theme.palette.primary.warningColor,
}));

const StyledEditIcon: any = styled(EditIcon)`
width: 18px!important;
`;

const StyledDeleteIcon: any = styled(DeleteIcon)`
width: 18px!important;
`;
//#endregion

//#region :: Validation PropTypes
CustomeIconButton.propTypes = {
    id: propTypes.string,
    ariaLabel: propTypes.string,
    color: propTypes.string,
    disabled: propTypes.bool,
    to: propTypes.string,
    onClick: propTypes.func,
    component: propTypes.any
};
//#endregion

export default CustomeIconButton;