import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOnboardingsDetails,
  setOnboardingsDetails,
} from "../../../Store/Reducers/Onboarding";
import { useNavigate, useParams } from "react-router-dom";
import { OnboardingStatusModel } from "../../../Models/system/onboarding-status.model";
import { OnboardingService } from "../../../Services/API/Onboarding";
import { changeFullLoading } from "../../../Store/Reducers/UI";
import {
  OnboardingProgress,
  OnboardingStatusCode,
} from "../../../Config/Settings/Enums";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { OnboardingModel } from "../Shared/onboarding.model";
import { ValidationService } from "../../../Utils/Validation.services";
import {
  DIALOG_TYPE,
  MESSAGE_TYPE,
  MessagesService,
} from "../../../Services/messages.service";
import { ValidationModel } from "../../../Models";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import PagesRoute from "../../../Config/Settings/PagesRoute";

const useViewOnboardingHook = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { T, Resources } = useTranslation();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const onboardingsDetails = getOnboardingsDetails(state);
  const [onboardingDetail, setOnboardingDetail] =
    useState<OnboardingStatusModel>(new OnboardingStatusModel());
  const [dialog, setDialog] = useState<boolean>(false);
  const [isRenew, setIsRenew] = useState<boolean>(false);
  const [formState, setFormState] = useState(
    OnboardingModel.getOTPFieldsModel()
  );

  //#region :: Handlers

  const handleOpenDialog = () => {
    setDialog(true);
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleInputChange = (event: any) => {
    var { name, value } = event.target;
    handleSetInputChange(name, value);
  };

  const handleSetInputChange = (name: any, value: any) => {
    setFormState({
      ...formState,
      [name]: {
        ...formState[name],
        value: value,
      },
    });
  };

  const getDataSource = async (reportId: any) => {
    try {
      const response = await OnboardingService.GetOnboardingById(reportId);
      dispatch(setOnboardingsDetails({ body: response }));
      let OnboardingDetail: OnboardingStatusModel = response;
      //console.log({ OnboardingDetail });
      setOnboardingDetail(OnboardingDetail);
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  };

  const handelOnboardingProgress = (
    statusId: number | string | null | undefined
  ) => {
    if (statusId)
      switch (statusId) {
        case OnboardingProgress.COMPLETED:
          return <CheckCircleIcon color="success" />;
        case OnboardingProgress.PENDING:
          return <HourglassTopIcon color="warning" />;
        case OnboardingProgress.FAILED:
          return <HighlightOffIcon color="error" />;
        case OnboardingProgress.NOT_STARTED:
          return <DoNotDisturbOnIcon color="error" />;
        default:
          return <DoNotDisturbOnIcon color="error" />;
      }
  };

  const createOnboarding = async (isRenew: boolean = false) => {
    let valid = ValidationService.Valid(formState);
    let validationResponse = ValidationModel.setValidations(valid, formState);
    setFormState(validationResponse);

    if (valid.isValidForm) {
      dispatch(changeFullLoading({ spin: true }));
      // let onboarding: Array<any> = [...onboardingLists];
      let request = OnboardingModel.addBoardingDBModel(formState);

      try {
        if (isRenew)
          await OnboardingService.RenewOnboarding(
            onboardingsDetails?.invoicesBookId,
            { body: request }
          );
        else
          await OnboardingService.AddOnboarding(
            onboardingsDetails?.invoicesBookId,
            { body: request }
          );

        MessagesService.Toast(
          T(Resources.AppResources.CREATED_SUCCESS),
          MESSAGE_TYPE.Success
        );
        handleCloseDialog();
        window.location.reload();
      } catch (error: any) {
      } finally {
        dispatch(changeFullLoading({ spin: false }));
      }
    }
  };

  //#region :: Search Changes [useEffect]

  useEffect(() => {
    dispatch(changeFullLoading({ spin: true }));

    if (id !== undefined) {
      getDataSource(id);
    } else {
      dispatch(changeFullLoading({ spin: false }));
    }
  }, [id]);

  useEffect(() => {
    setFormState(OnboardingModel.getOTPFieldsModel());
  }, [dialog]);

  //#endregion

  return {
    onboardingsDetails,
    handelOnboardingProgress,
    onboardingDetail,
    dialog,
    handleOpenDialog,
    handleCloseDialog,
    formState,
    handleInputChange,
    createOnboarding,
    isRenew,
    setIsRenew,
  };
};

export default useViewOnboardingHook;
