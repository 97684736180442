import { PaginationEnums } from '../Config/Settings/Enums';

export class DeactiveReason {
  value: any;
  required?: boolean;
  valid?: boolean;
  name?: any;

  constructor() {
    this.value = undefined;
    this.valid = true;
    this.required = true;
    this.name = "deActiveReason";
  }
}

export class ResponseDialogModel {
  showModel?: boolean;
  response?: any;
  status: any;
  icon: string | undefined;
  message: string | undefined;

  constructor() {
    this.showModel = false;
    this.response = '';
  }
}

export class SearchCriteriaFieldModel {
  value: string | null;
  name: string;

  constructor(name: string = "") {
    this.value = "";
    this.name = name;
  }

  public static buildFromObject(model: any) {
    let fildsObject: any = {};
    Object.keys(model).forEach((key) => {
      fildsObject[key] = new SearchCriteriaFieldModel(key);
      let modelValue = model[key];

      if (modelValue instanceof Date) {
        fildsObject[key].value = modelValue;
      } else if (typeof modelValue === "object" && modelValue !== null && !Array.isArray(modelValue)) {
        fildsObject[key] = SearchCriteriaFieldModel.buildFromObject(modelValue);
      } else if (modelValue) {
        fildsObject[key].value = modelValue;
      }
    });

    return fildsObject;
  }

  public static getDBModel(fieldsFormModel: any) {
    let model: any = {};
    Object.keys(fieldsFormModel).forEach((key) => {
      let currentField = fieldsFormModel[key];
      if (currentField) {
        if (currentField.isField) {
          if (currentField.value != null && currentField.value != undefined) {
            model[currentField.name] = currentField.value;
          }
        } else if (
          !currentField.isField &&
          typeof currentField === "object" &&
          currentField !== null &&
          !Array.isArray(currentField)
        ) {
          let objectResult = SearchCriteriaFieldModel.getDBModel(currentField);
          model[key] = objectResult;
        }
      }
    });

    return model;
  }
}

export class ErrorModel {
  requiredMsg: string = "";
  error: string = "";

  constructor() {
    this.requiredMsg = "";
    this.error = "";
  }
}

export class PaginationModel {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;

  constructor() {
    this.pageIndex = 0;
    this.pageSize = PaginationEnums.PAGE_SIZE;
    this.totalCount = 0;
    this.totalPages = 0;
  }
}

export class FieldModel {
  name: string;
  value: any;
  required: boolean = false;
  error: boolean = false;
  message: string = "";
  regexValue: string = "";
  errorMessages!: ErrorModel;
  validate: boolean = true;
  valid: boolean = false;
  isField: boolean = true;

  constructor(name: string = "", value: any = "", required = false, error = false, validate = false, valid = true) {
    this.name = name;
    this.value = value;
    this.required = required;
    this.error = error;
    this.message = "";
    this.regexValue = "";
    this.errorMessages = new ErrorModel();
    this.validate = validate;
    this.valid = valid;
    this.isField = true;
  }

  public static buildFromObject(model: any) {
    let fildsObject: any = {};
    Object.keys(model).forEach((key) => {
      fildsObject[key] = new FieldModel(key);
      let modelValue = model[key];
      if (modelValue instanceof Date) {
        fildsObject[key].value = modelValue;
      } else if (typeof modelValue === "object" && modelValue !== null && !Array.isArray(modelValue)) {
        fildsObject[key] = FieldModel.buildFromObject(modelValue);
      } else if (modelValue) {
        fildsObject[key].value = modelValue;
      }
    });
    return fildsObject;
  }

  public static getDBModel(fieldsFormModel: any) {
    let model: any = {};
    Object.keys(fieldsFormModel).forEach((key) => {
      let currentField = fieldsFormModel[key];
      if (currentField) {
        if (currentField.isField) {
          if (currentField.value != null && currentField.value != undefined) {
            model[currentField.name] = currentField.value;
          }
        }
        else if (
          !currentField.isField &&
          typeof currentField === "object" &&
          currentField !== null &&
          !Array.isArray(currentField)
        ) {
          let objectResult = FieldModel.getDBModel(currentField);
          model[key] = objectResult;
        }
      }
    });

    return model;
  }
}

export class ValidationModel {
  public static setValidations(model: any, form: any) {
    let tempObj = { ...form };
    let dataForm : any;

    if (model?.responseObject != undefined) {
      dataForm = model.responseObject;
    } else {
      dataForm = model;
    }
    let keyss: Array<any> = [];
    Object.keys(dataForm).map((key: any) => {
      keyss.push(key);
    });
    keyss.map((item: any) => {
      if (tempObj[item]?.isField == false) {
        let response = this.setValidations(dataForm[item], tempObj[item]);
        tempObj[item] = response;
      } else {
        if (tempObj[item] != undefined) {
          tempObj[item].valid = dataForm[item]?.valid;
          tempObj[item].message = dataForm[item]?.message;
        }
      }
    });

    return tempObj;
  }
}
