import React, { useEffect, useState } from 'react'
import { ReportSearch } from '../Shared/reports.model';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchCriteria, setSearchCriteria } from '../../../Store/Reducers/Reports';
import { InvoicesBookService } from '../../../Services/API/InvoicesBook';
import { FormatDate } from '../../../Utils/DateUtils';

const useSearchReportsHook = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const searchCriteria = getSearchCriteria(state)
    const [searchForm, setSearchForm] = useState(ReportSearch.getFieldsModel());
    const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
    //#region :: Handlers

    const handleInputChange = (event: any) => {
        var { name, value } = event.target;
        handleChangeInput(name, value);
    };

    const handleChangeInput = (name: any, value: any) => {
        setSearchForm({
            ...searchForm,
            [name]: {
                ...searchForm[name],
                value: value
            }
        });
    };

    const toggleAdvancedSearch = () => {
        if (isAdvancedSearch)
            setIsAdvancedSearch(false);

        else
            setIsAdvancedSearch(true);
    };

    const searchHandler = async () => {
        const request = ReportSearch.toDBModel(searchForm);
        dispatch(setSearchCriteria({ body: request})); // this line is work around because the axios reduce the date by one day before the request is send, this happen in Middleware/Api

    };

    const resetForm = () => {
        setSearchForm(ReportSearch.getFieldsModel());
        dispatch(setSearchCriteria({ body: {} }));
    };

    const getInvoicesBooksDataSource = async (searchText?: string) => {
        try {
            const request = {
                searchText,
                pageIndex: 0,
                pageSize: 10
            }
            const response = await InvoicesBookService.GetInvoicesBooksList(request);
    
            let items: Array<any> = [];
            if (response?.length > 0) {
              response?.map((item: any) => {
                const obj = {
                  id: item?.id,
                  label: item?.value,
                };
                items.push(obj);
              });
            }
            return items;
            
        } catch (error) {
        }
      };
  
      const handleCPressEnter = (e) => {
        if (e.key === "Enter") {
            searchHandler()
        }
    }


    //#region :: Search Changes [useEffect]
    useEffect(() => {
        if (!searchCriteria) return;
        // setSearchForm({
        //     ...searchForm,
        //     metaData: {
        //         ...searchForm.metaData,
        //         value: searchCriteria.metaData
        //     },
        // });
    }, [searchCriteria]);
    //#endregion


    return {
        
        searchForm, 
        searchHandler, 
        handleChangeInput , 
        handleInputChange, 
        resetForm, 
        isAdvancedSearch, 
        toggleAdvancedSearch, 
        getInvoicesBooksDataSource,
        handleCPressEnter
    };
}

export default useSearchReportsHook;