import { IdentityTypes } from "../Enums";

export const regexType = {
    EMAIL: /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    MOBILENUMBER: /^(((05)[0-9]{8})|((00)[0-9]{8,13}))$/,
    USERMOBILENUMBER: /^((05)[0-9]{8})$/,
    ARABICNAME: /^[\u0621-\u064A-\s ]{0,50}$/,
    ENGLISHNAME: /^[A-Za-z][A-Za-z-\s ]{0,50}$/,
    CODE: /^[A-Za-z]{1,8}$/,
    NUMBER: /^[0-9]+$/,
    NUMBER_OPTIONAL: /^[0-9]*$/,
    FLOATING_POINT: /^0(\.\d{1,3})?$/,
    SERVICEFEES: /^([1-9][0-9]{0,5}(\.[0-9]{1,2})?)$|^0(\.[0-9]{1,2})$/,
    BILLNUMBER: /^\d{5,16}$/,
    PERIOD: /^[1-9][0-9]*$/,
    GREATERTHANZERO: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
    INTERGERPOSITIVENUMBER: /^[1-9]\d*$/,
    CHARS1000: /^[\s\S]{0,1000}$/,
    DOCUMENTID: /^\d{10}$/,
    PHONE_NUMBER_GLOBAL: /^(\+)(5|0|3|6|4|9|1|8|7)([0-9]{10,11})$/,
    DIGITS_10_MAX: /^[a-z0-9_-]{1,10}$/,
    DIGITS_5_MAX: /^[a-z0-9_-]{1,5}$/,
    URL_VALIDATION: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    NUMBERS_FROM_2_TO_5: /^[0-9]{2,5}$/,
    NUMBERS_FROM_1_TO_3_WITH_SLASH: /^[0-9]{1,3}[/][0-9]{1,3}$/,
    FREE_TEXT_FROM_1_TO_2000: /^[\s\S]{1,2000}$/,
    TEXT_REQUIRED: /^[\s\S]{1,2}$/,
    VAT_REGISTRATION_NUMBER: /^3\d{13}3$/,
    MIN_2_MAX_2000: /^[\s\S]{2,2000}$/,
    MIN_1_MAX_1000: /^[\s\S]{1,1000}$/,
    MIN_0_MAX_127: /^[\s\S]{0,127}$/,
    MIN_1_MAX_127: /^[\s\S]{1,127}$/,
    MIN_2_MAX_100: /^[\s\S]{2,100}$/,
    MIN_2_MAX_30: /^[\s\S]{2,30}$/,
    MIN_2_MAX_10: /^[\s\S]{2,10}$/,
    MIN_2_MAX_10_DIGITS: /^[1-9][0-9]{2,9}$/,
    MIN_2_MAX_20_DIGITS: /^[1-9][0-9]{19}$/,
    MIN_2_MAX_20: /^[\s\S]{2,20}$/,
    MIN_2_MAX_5: /^[\s\S]{2,5}$/,
    MIN_1_MAX_3: /^[\s\S]{1,3}$/,
    DDL_VALUE: /^[1-9A-Za-z][0-9A-Za-z]*$/,
    IS_10_DIGITS: /^[1-9][0-9]{9}$/,
    IS_5_DIGITS: /^[1-9][0-9]{4}$/,
    IS_4_DIGITS: /^[1-9][0-9]{3}$/,
    VAT_REG_NUMBER: /^3[0-9]{13}3$/,
    ADDRESS_ADDITIONAL_NUMBER: /^[1-9][0-9]{3}$"/,
    TIN: /^[1-9][0-9]{9}$/,
    ORDER_NUMBER: /^[1-9][0-9]{0,2}$/,
    RCM_PHONE_NUMBER_GLOBAL: /^(\+)(5|0|3|6|4|9|1|8|7)([0-9]{10,11})$/,
};

export const regexMsgs = {
    INVALID_EMAIL: "INVALID_EMAIL",
    CUSTOMER_MOBILE_NUMBER_ERROR: "CUSTOMER_MOBILE_NUMBER_ERROR",
    USER_MOBILE_NUMBER_ERROR: "USER_MOBILE_NUMBER_ERROR",
    INVALID_ARABIC_NAME: "INVALID_ARABIC_NAME",
    INVALID_ENGLISH_NAME: "INVALID_ENGLISH_NAME",
    INVALID_CATEGORY_CODE: "INVALID_CATEGORY_CODE",
    NUMBER_ERROR_MSG: "NUMBER_ERROR_MSG",
    REQUIRED_ERROR_MSG: "REQUIRED_ERROR_MSG",
    BILL_INVALID_AMOUNT: "BILL_INVALID_AMOUNT",
    POSITIVE_NUMBER: "POSITIVE_NUMBER",
    SERVICE_AMOUNT_ERROR: "SERVICE_AMOUNT_ERROR",
    PERIOD: "PERIOD",
    CHARS_1000: 'chars_1000',
    MIN_2_MAX_2000: 'MIN_2_MAX_2000',
    MIN_1_MAX_1000: 'MIN_1_MAX_1000',
    MIN_2_MAX_100: 'MIN_2_MAX_100',
    MIN_2_MAX_30: 'MIN_2_MAX_30',
    MIN_2_MAX_20: 'MIN_2_MAX_20',
    MIN_2_MAX_10: 'MIN_2_MAX_10',
    EQUAL_TO_10: 'EQUAL_TO_10',
    MIN_2_MAX_5: 'MIN_2_MAX_5',
    MIN_1_MAX_3: 'MIN_1_MAX_3',
    IS_10_DIGITS: 'IS_10_DIGITS',
    IS_5_DIGITS: 'IS_5_DIGITS',
    IS_4_DIGITS: 'IS_4_DIGITS',
    VAT_NUMBER_ERROR: 'VAT_NUMBER_ERROR',
    VAT_REG_NUMBER: 'VAT_REG_NUMBER',
    ADDRESS_ADDITIONAL_NUMBER: 'ADDRESS_ADDITIONAL_NUMBER',
    TIN: 'TIN',
    ORDER_NUMBER: 'ORDER_NUMBER',
    INVALID_INPUT: 'INVALID_INPUT',
    MIN_0_MAX_127: 'MIN_0_MAX_127',
    MIN_1_MAX_127: 'MIN_1_MAX_127',
    PHONE_NUMBER_ERROR: 'PHONE_NUMBER_ERROR',
    NUMBER_OPTIONAL: 'NUMBER_OPTIONAL',
};

export const IdentityTypesValidation = {
    
    [IdentityTypes.COMMERCIAL_REGISTRATION_NUMBER]: { id: [IdentityTypes.COMMERCIAL_REGISTRATION_NUMBER], regex: /^(1)[0-9]{9}$/ },
    [IdentityTypes.MOMRA]: { id: [IdentityTypes.MOMRA], regex: /^[\s\S]{2,20}$/  },
    [IdentityTypes.MLSD]: { id: [IdentityTypes.MLSD], regex: /^[\s\S]{2,20}$/  },
    [IdentityTypes.CODE_700]: { id: [IdentityTypes.CODE_700], regex: /^[\s\S]{2,20}$/  },
    [IdentityTypes.SAGIA]: { id: [IdentityTypes.SAGIA], regex: /^[\s\S]{2,20}$/  },
    [IdentityTypes.OTHER_ID]: { id: [IdentityTypes.OTHER_ID], regex:/^[\s\S]{2,20}$/  },
};
