import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '../../../../../Controls/Card';
import DefaultSearch from '../../../../../Controls/Search/DefaultSearch';
import TextInput from '../../../../../Controls/TextInputs';
import useTranslation from '../../../../../Hooks/Shared/useTranslation';

interface Props {
  searchHandler: Function;
}
const Search = ({searchHandler}) => {

  //#region :: State
  const { T, Resources } = useTranslation();
  const [permissionSerachText, setPermissionSerachText] = useState("");
  //#endregion

  //#region :: Handlers


  const resetForm = () => {
    setPermissionSerachText("");
    searchHandler();
  };
  const handleCPressEnter = (e) => {
      if (e.key === "Enter") {
        searchHandler(permissionSerachText)
      }
  }

  //#endregion

  //#region :: [useEffect]


  //#endregion

  return (
    <Card>
      <DefaultSearch
        searchHandler={() => searchHandler(permissionSerachText)}
        resetHandler={resetForm}
        showAdvance={false}>
        <Grid item md={3} xs={12}>
          <TextInput
              id={"permission-serachText"}
              name={"permissionSerachText"}
              value={permissionSerachText}
              label={T(Resources.AppResources.SEARCH)}
              maxLength={100}
              onKeyPress={handleCPressEnter}
              handleChange={(e: any) => setPermissionSerachText(e?.target?.value)}
          />
        </Grid>
      </DefaultSearch>
    </Card>
  );
};

export default Search;