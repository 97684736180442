import React, { useEffect, useMemo, useState } from "react";
import AutoComplete from "../AutoComplete";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { AutoCompleteSelectDeclarations } from "./IAutoComplete";
import { OrganizationService } from "../../Services/API/OrganizationManagement";
import { getUILanguage } from "../../Store/Reducers/UI";
import { useSelector } from "react-redux";
import Locales from "../../Services/Internationalization/Locales";
import { TenantUsersManagementService } from "../../Services/API/TenantUsersManagement";

const AutoCompleteOrganization = ({
  multiple = false,
  id,
  name,
  label,
  error,
  selected,
  withGlobalAccess = false,
  handleInputChange,
  showLabel = undefined,
  disabled = false,
  style = undefined,
  userId = undefined,
  isChecked = false
}: AutoCompleteSelectDeclarations) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const state = useSelector((state) => state);
  const currentLanguage = getUILanguage(state);
  const [inputValue, setInputValue] = useState<any>("");
  const [dropDownItems, setDropDownItems] = useState<any>([]);
  const [orgDataList, setOrgDataList] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion


  const globalAccessObject = useMemo(() => {
    return {
      id: null,
      label: "Universal Org Access"// T(Resources.AppResources.UNIVERSAL_ORG_ACCESS),
    };
  }, [])

  //#region :: Handlers

  const handleChangeInput = (newInputId: any) => {
    if (newInputId && !Array.isArray(newInputId)) {
      const select = orgDataList.find((item) => item.id == newInputId.id);
      select && handleInputChange(select);
    } else {
      if (withGlobalAccess && Array.isArray(newInputId)) {
        if (newInputId.some(itm => itm.id == null) && newInputId.indexOf(globalAccessObject) > newInputId.indexOf(newInputId.find(itm => itm.id != null))) {
          setDropDownItems([globalAccessObject]);
          handleInputChange([]);
        } else {
          setDropDownItems(prepareWithGlobalAccessList(orgDataList));
          handleInputChange(newInputId.filter(itm => itm.id != null).map(x => x.id));
        }
      } else
        handleInputChange(newInputId);
    }
  };


  const handleSelectedOptions = (dataList: Array<any> = dropDownItems) => {
    if (multiple && selected && Array.isArray(selected)) {
      const tempSelected = [...dataList.filter(facil => selected.includes(facil.id))];

      if (!isChecked) {
        setInputValue([]);
        return;
      }
      if (tempSelected?.length)
        setInputValue(tempSelected);
      else if (tempSelected?.length == 0 && !isLoading) {
        setInputValue([
          globalAccessObject
        ]);
      }
    }
  }

  const applyfilter = async (serachText: string = "") => {
    try {
      setIsLoading(true);
      let response: any = {};
      if (userId)
        response = await TenantUsersManagementService.SearchOrganization({ name: serachText }, userId);
      else
        response = await OrganizationService.SearchOrganization({ serachText: serachText });
      setOrgDataList(response?.data.body);
      let dataList = [...response?.data.body];


      if (!dataList || !dataList.length) return;


      let items: Array<any> = [];
      if (withGlobalAccess) {
        items = prepareWithGlobalAccessList(dataList)
      } else {
        items = dataList.map((itm) => {
          return {
            id: itm.id,
            label: itm?.name
          };
        })
      }
      handleSelectedOptions(items);
      setDropDownItems(items);
    } catch (ex) { }
    finally {
      setIsLoading(false)
    }
  };

  const prepareWithGlobalAccessList = (list: Array<any>) => {
    return [
      globalAccessObject,
      ...list.map((itm) => {
        return {
          id: itm.id,
          label: itm?.name
        };
      }),
    ];
  }
  //#endregion

  //#region :: UseEffect
  useEffect(() => {
    applyfilter();
  }, []);
  useEffect(() => {
    handleSelectedOptions();
  }, [selected, dropDownItems, isChecked, isLoading]);


  return (
    <AutoComplete
      id={name}
      name={name}
      defaultValue={inputValue}
      value={inputValue}
      autoComplete={false}
      showLabel={showLabel}
      disabled={disabled}
      filterSelectedOptions={false}
      handlerSearch={applyfilter}
      handleChange={handleChangeInput}
      error={error}
      options={dropDownItems}
      required={true}
      isLoading={isLoading}
      label={label && T(label)}
      multiple={multiple}
      noOptionsText={T(Resources.AppResources.NODATA)}
      filterOptions={(x) => x}
      // style={style}
    />
  );
};

export default AutoCompleteOrganization;
