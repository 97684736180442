import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roleMappingSelectors, setError, roleMappingReceived, setRoleMappingDetails } from '../Store/Reducers/RolesManagement';
import { pageRequested, PaginationSelectors, PaginationModules } from '../Store/Reducers/Pagination';
import Constants from '../Config/Settings/API';
const useRoleMappingSearchHook = () => {

    //#region :: State

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [roleMapping, setRoleMapping] = useState<Array<any>>([]);
    const _roleMapping = roleMappingSelectors.getRolesData(state);
    const searchCriteria = roleMappingSelectors.getSearchCriteria(state);

    const currentPage = PaginationSelectors.getCurrentPage(state, PaginationModules.ROLES_MANAGEMENT);
    const pageSize = PaginationSelectors.getPageSize(state, PaginationModules.ROLES_MANAGEMENT);
    const totalPages = PaginationSelectors.getTotalPages(state, PaginationModules.ROLES_MANAGEMENT);
    const pagination = PaginationSelectors.getPaginationModule(state, PaginationModules.ROLES_MANAGEMENT);

    const lastPageIndex = PaginationSelectors.getLastPageIndex(state, PaginationModules.ROLES_MANAGEMENT);
    const hasMore = PaginationSelectors.hasMore(state, PaginationModules.ROLES_MANAGEMENT) || false;
    const isPageFetching = PaginationSelectors.isPageFetching(state, PaginationModules.ROLES_MANAGEMENT);
    const pageKeys = PaginationSelectors.getPageKeys(state, PaginationModules.ROLES_MANAGEMENT, currentPage);

    const requestPage = (actionType: any, pageIndex: any, reset: boolean = false) => {

        //Prodaction
        if (isPageFetching) return;

        //Prodaction
        dispatch(pageRequested({
        url: Constants.ROLES.SEARCH,
            data: { body: { ...searchCriteria, pageIndex: pageIndex, pageSize: pageSize } },
            method: 'POST',
            onSuccess: actionType,
            onError: setError.type,
            args: {
                resetPages: reset,
                source: 'body',
                id: 'id',
                module: PaginationModules.ROLES_MANAGEMENT,
                page: pageIndex
            }
        }));
    };

    const changeCurrentPage = (page: any) => {
        requestPage(roleMappingReceived.type, page);
    };
    const resetPage = () => {
        requestPage(roleMappingReceived.type, 0, true);
    }
    useEffect(() => {
        if (!searchCriteria) return;
        resetPage();
    }, [searchCriteria]);

    //Prodaction
    useEffect(() => {
        if (pageKeys && pageKeys.keys) {
            let items: Array<any> = [];

            pageKeys.keys.map((key: any) => {
                items.push(_roleMapping[key]);
            });
            setRoleMapping(items);
        }
    }, [pageKeys.keys]);

    useEffect(() => {
        setRoleMapping(_roleMapping);
    }, [_roleMapping]);

    return { roleMapping, hasMore, isPageFetching, lastPageIndex, currentPage, pagination, totalPages, pageSize, changeCurrentPage, resetPage };
};

export default useRoleMappingSearchHook;