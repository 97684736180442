import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { changeFullLoading } from '../../../Store/Reducers/UI';
import { useDispatch } from 'react-redux';
import { ReportsService } from '../../../Services/API/Reports';

const QRDialog = ({ Id }: any) => {
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const [qrCode, setQrCode] = useState<any>();
    const getDataSource = async () => {
        try {
            dispatch(changeFullLoading({ spin: true }));
            const response = await ReportsService.GetQrById(Id);
            if (response)
                setQrCode(response.data);
        }
        catch (error: any) {
            console.error(error);
        }
        finally {
            dispatch(changeFullLoading({ spin: false }));
        }
    };



    
    useEffect(() => {
        getDataSource()
    }, [Id])


    return (
        <Grid container paddingY={1} paddingBottom={3} spacing={1} xs={12}>
            <Grid style={{ textAlign: 'center', padding: "10px" }} item md={12} sm={12} xs={12}>
                {/* <label>{T(Resources.AppResources.)}</label> */}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: 'center' }}>
                    <img width={"250px"} height={"250px"} src={qrCode} />
                </div>
            </Grid>

        </Grid>
    )
}

export default QRDialog