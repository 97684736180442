import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import propTypes from 'prop-types';
import React , { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CookiesConstants from '../../../Config/Settings/Cookies';
import { theme as MainThemeVariables } from '../../../Config/Theme/ThemeVariables';
import AvatarImage from '../../../Controls/AvatarImage';
import { MenuBurgerIcon } from '../../../Controls/Icons/Icons';
import PageHeader from '../../../Controls/PageHeader';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import Locales from '../../../Services/Internationalization/Locales';
import { getAuth, logoutSucceeded } from '../../../Store/Reducers/Auth';
import { getUILanguage, languageChanged } from '../../../Store/Reducers/UI';
import { setCookie } from '../../../Utils/Cookies';
import HeaderWraper from './HeaderWraper';
import SwitchColorToggle from '../../../Components/SwitchColorToggle';
import { GlobalService } from '../../../Services/global.service';
import { EnvMode } from '../../../Config/Settings/Enums';
import { OrganizationService } from '../../../Services/API/OrganizationManagement';
import { useTenant } from '../../../MultiTenant/tenantContext';

type HeaderProps = {
    handleDrawerOpen?: any;
    open?: any;
};

const Header = ({ handleDrawerOpen, open }: HeaderProps) => {

    //#region :: State
    const { T, Resources } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const opens = Boolean(anchorEl);
    const screenSizeEs = useMediaQuery((theme: any) => theme.breakpoints.up('xsMobile'));
    const state = useSelector(state => state);
    const dispatch = useDispatch();
    const auth = getAuth(state);
    const language = getUILanguage(state);
    const isLoggedIn = getAuth(state).isLoggedIn;
    const currentUserName = GlobalService.AppUser?.name;
    const navigate = useNavigate();
    const envMode = GlobalService.EnvMode;
    const { currentTenant } = useTenant() || {}; 
    //#endregion

    //#region :: Handlers
    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguageHandler = () => {
        if (language === Locales.ARABIC) {
            setCookie(CookiesConstants.SETTINGS.LANG, Locales.ENGLISH, 365);
            dispatch(languageChanged({ language: Locales.ENGLISH }));
        }
        else {
            setCookie(CookiesConstants.SETTINGS.LANG, Locales.ARABIC, 365);
            dispatch(languageChanged({ language: Locales.ARABIC }));
        }
    };


    const handleEnvModeChange = async (isSimulation: any) => {
        GlobalService.EnvMode = isSimulation? EnvMode.SIM : EnvMode.PROD;
        GlobalService.CurrentActiveOrganization = null;
        GlobalService.AppUserAuthorizationInfo = null;
        GlobalService.AppUser = null;
        GlobalService.OrgsList = null;
        GlobalService.IsTenantContext = false;
        GlobalService.RefetchOrgs = true;

        window.location.href=window.location.origin + "/" + currentTenant.id;;
    };

    //#endregion

    return (
        <HeaderWraper open={open}>
            <StyledMenuButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start">
                <MenuBurgerIcon
                    color={MainThemeVariables['light-blue-color']}
                    size="32" />
            </StyledMenuButton>
            <StyledBreadCrumbs>
                <PageHeader breadCrumbs />
            </StyledBreadCrumbs>
            <StyledHeaderMenuLogo />
            <div>

 {(!GlobalService.TenantServices.singleEnvironment) && (
    <SwitchColorToggle
    switchable={(true)}
    isOutlined={false}
      isActive={envMode == EnvMode.SIM ? true: false}
      id={"1"}
      active={T(Resources.AppResources.SIMULATION)}
      inactive={T(Resources.AppResources.PRODUCTION)}
      handleStatusChange={handleEnvModeChange}
    />
  )}

            </div>
            <StyledHeaderRightSide>
                <StyledFlagButton
                    aria-label="Change Language"
                    onClick={changeLanguageHandler}>
                    <StyledLanguage>
                        {
                            language === Locales.ENGLISH
                                ? <StyledLanguageFlag>  {T(Resources.AppResources.ARABIC_LANGUAGE)} <LanguageIcon /> </StyledLanguageFlag>  // ? <SaudiFlag width={35} height={25} /> 
                                : <StyledLanguageFlag>  {T(Resources.AppResources.ENGLISH_LANGUAGE)} <LanguageIcon /> </StyledLanguageFlag> // : <UnitedKingDomFlag width={35} height={25} />
                        }
                    </StyledLanguage>
                </StyledFlagButton>
                <StyledAccountButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit">
                    <AvatarImage userName={currentUserName} />
                    <StyledUserNameContainer>
                        <StyledUserName screen={screenSizeEs}>
                            {currentUserName}
                        </StyledUserName>
                        {/* <KeyboardArrowDownIcon /> */}
                    </StyledUserNameContainer>
                </StyledAccountButton>
                {/* <StyledProfileMenu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={opens}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            navigate(`${AppConfigService.getConfig().reactAppBaseUrl}/change_password`);
                            handleClose()
                        }}>
                        {T(Resources.AppResources.CHANGE_PASSWORD)}
                    </MenuItem>
                    <MenuItem onClick={() => handleUserLogin(isLoggedIn)}>
                        {T(Resources.AppResources.LOGOUT)}
                    </MenuItem>
                </StyledProfileMenu> */}
            </StyledHeaderRightSide>
        </HeaderWraper>
    );
};

//#region :: Styled Component
const StyledLanguage: any = styled.span(() => ({
    fontSize: 15,
    fontFamily: 'Cairo'
}));

const StyledLanguageFlag: any = styled.span(() => ({
    display: 'flex',
    alignItems: 'center',
    color: '#757575',
    fontWeight: '600'
}));

const StyledMenuButton: any = styled(IconButton)(({ theme }: any) => ({
    "&.MuiButtonBase-root": {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        "&.MuiIconButton-root:hover": {
            backgroundColor: theme.status.transparent
        }
    }
}));

const StyledHeaderMenuLogo: any = styled.div(() => ({
    flexGrow: 1
}));

const StyledBreadCrumbs: any = styled.div(({ theme }: any) => ({
    margin: '24px'
}));

const StyledHeaderRightSide: any = styled.div(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const StyledFlagButton: any = styled(IconButton)`
    &.MuiIconButton-root:hover{
         background-color: ${({ theme }: any) => theme && theme.status.transparent};
    }
`;

const StyledAccountButton: any = styled(IconButton)(({ theme }: any) => ({
    "&.MuiButtonBase-root": {
        [theme.direction === 'ltr' ? 'marginRight' : 'marginLeft']: theme.spacing(0),
        paddingRight: 0,
        paddingLeft: 3,
        borderRadius: 0,
        "&.MuiIconButton-root:hover": {
            backgroundColor: theme.status.transparent
        }
    }
}));

const StyledUserNameContainer: any = styled.span(({ theme }: any) => ({
    fontSize: 'initial',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center'
}));

const StyledUserName: any = styled.span`
    display: ${({ screen }: any) => !screen && 'none'};
`;

const StyledProfileMenu: any = styled(Menu)(({ theme }: any) => ({
    "& .MuiPaper-root": {
        marginTop: 40,
        [theme.direction === 'ltr' ? 'marginRight' : 'marginLeft']: -25
    }
}));
//#endregion

//#region :: Validation PropTypes
Header.propTypes = {
    handleDrawerOpen: propTypes.func,
    open: propTypes.bool
};
//#endregion

export default Header;