import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { PaginationModel } from '../../../../Models';
import { useDispatch, useSelector } from 'react-redux';
import { getError, getShowError, getOrganizationData, deActiveOrg } from '../../../../Store/Reducers/OrganizationManagement';
import useOrganizationSearch from '../../../../Hooks/useOrganizationSearch';
import CustomeIconButton, { IconButtonsTypes } from '../../../../Controls/IconButton';
import PagesRoute from '../../../../Config/Settings/PagesRoute';
import { Grid } from '@mui/material';
import { OrganizationService } from '../../../../Services/API/OrganizationManagement';
import useAppNavigate from '../../../../Hooks/Shared/useAppNavigate';
import SwitchToggle from '../../../../Components/SwitchToggle';
import CBadge from '../../../../Components/Badg/badge';
import { PermissionScopes, PermissionsService } from '../../../../Services/permissions.service';

const useOrganizationListHook = () => {
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const organizationData = getOrganizationData(state);
    const showError = getShowError(state);
    const error = getError(state);
    const organizationSearchHook = useOrganizationSearch();
    const [organizationSource, setOrganizationSource] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<PaginationModel>(new PaginationModel());
    const appNavigate = useAppNavigate();
    const cardLabels: Array<any> = [
        { id: 'img' },
        { id: 'name', label: T(Resources.AppResources.NAME), },
        { id: 'businessCategory', label: ' ' + T(Resources.AppResources.BUSINESS_CATEGORY) },
        { id: 'vatRegistrationNumber', label: ' ' + T(Resources.AppResources.VAT_REGISTRATION_NUMBER) },
        { id: 'tin', label: ' ' + T(Resources.AppResources.TIN) },
        { id: 'identityNumber' },
        { id: 'action' },
    ];

    const handelChangeStatus = async (id: any, value: any) => {
        await OrganizationService.ActivateOrganization(id, value)
        dispatch(deActiveOrg({id, value}));

    }

    useEffect(() => {
        if (!organizationSearchHook?.organization) return;

        setOrganizationSource(organizationSearchHook?.organization?.map((item: any) => {
            const { id, name,  identityInfo, vatRegistrationNumber,  tin,  businessCategory, img, isActive } = item;
            
            return {
                img: <Grid style={{ display: 'flex', justifyContent: "end", marginLeft: '20px'}}><img style={{ width: "50px" }} src={img} /></Grid>,
                id,
                name,
                identityNumber: <Grid style={{ display: 'flex', justifyContent: 'flex-start', gap: '0.5%'}}>
                    <div>{T(Resources.AppResources.IDENTITY_NUMBER) + " : "}</div>
                    <Grid style={{ display: 'flex', justifyContent: 'flex-start', gap: '5%'}}>
                        <div>{identityInfo?.identityNumber}</div> 
                        <div><CBadge bgColor={'whitesmoke'}  expectedWith="fit-content" id={identityInfo?.identityType?.code}>{T(Resources.AppResources[identityInfo?.identityType?.description])}</CBadge></div>
                    </Grid>
                </Grid>,
                vatRegistrationNumber,
                tin,
                businessCategory,
                action: (
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between'}} >
                        <Grid item xs={4} style={{ display: 'flex' ,justifyContent:"start"}} spacing={1}>
                            {PermissionsService.hasPermission(PermissionScopes.CHANGE_ORGANIZATION_STATUS) && <SwitchToggle
                                id={id}
                                active={T(Resources.AppResources.ACTIVE)}
                                inactive={T(Resources.AppResources.IN_ACTIVE)}
                                isActive={isActive}
                                handleStatusChange={() => handelChangeStatus(id, !isActive)}
                            />}
                        </Grid>
                        <Grid style={{ display: 'flex', justifyContent: "end" }}>
                            {PermissionsService.hasPermission(PermissionScopes.EDIT_ORGANIZATION) && <CustomeIconButton
                                id='view'
                                aria-label='view'
                                type={IconButtonsTypes.EDIT}
                                onClick={() => appNavigate.push(PagesRoute.PAGES.EDIT_ORGANIZATION + id)}
                                />}
                            {PermissionsService.hasPermission(PermissionScopes.VIEW_ORGANIZATION_DETAILS) && <CustomeIconButton
                                id='view'
                                aria-label='view'
                                type={IconButtonsTypes.VIEW}
                                onClick={() => appNavigate.push(PagesRoute.PAGES.VIEW_ORGANIZATION + id)}
                                />}
                        </Grid>
                    </Grid>
                )
            };
        }));
        
        setPagination({
            //Prodaction
            pageIndex: organizationSearchHook.currentPage ,
            pageSize: organizationSearchHook.pageSize,
            totalCount: organizationSearchHook.pagination.defaultContext !== undefined && organizationSearchHook.pagination.defaultContext.totalCount,
            totalPages: organizationSearchHook.totalPages
        });
    }, [organizationSearchHook.organization]);
    //#endregion
    return {
        cardLabels, pagination, organizationSource, changeCurrentPage: organizationSearchHook.changeCurrentPage
    }
}

export default useOrganizationListHook