import { AppConfigService } from "./AppConfig";
await AppConfigService.init();

const Env_Process = {
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
    DEMO: 'demo'
};

const GetBaseUrl = () => {
    // switch (process.env.REACT_APP_ENV) {
    //     case Env_Process.DEVELOPMENT:
    //         return process.env.REACT_APP_DEV_BACKEND_BASE_URL;
    //     case Env_Process.STAGING:
    //         return process.env.REACT_APP_STAGING_BACKEND_BASE_URL;
    //     case Env_Process.PRODUCTION:
    //         return process.env.REACT_APP_PROD_BACKEND_BASE_URL;
    //     case Env_Process.DEMO:
    //         return process.env.REACT_APP_DEMO_BACKEND_BASE_URL;
    // }
    return AppConfigService.getConfig().baseUrl; //.REACT_APP_DEV_BACKEND_BASE_URL;
};

const api_base_url = `${GetBaseUrl()}`;
//console.log("api_base_url", api_base_url)
const APIConstants = {
    BASE_URL: api_base_url,
    IS_TEST_MODE: false,
    LOCALERESOURCES: 'LocaleResources',
    ORGANIZATION: {
        GETALL: 'Organizations',
        GET: 'Organizations/',
        PUT: 'Organizations/',
        ADD_ORGANIZATION: 'Organizations',
        SEARCH_ORGANIZATION: 'Organizations/Search',
        LIST: 'Organizations/list',
        GET_BY_ID: 'Organizations/',
        ACTIVATE: 'Organizations/{id}/active-status',
    },
    INVOICES_BOOK: {
        GETALL: 'invoices-books',
        GET: 'invoices-books/',
        ADD_INVOICES_BOOK: 'invoices-books',
        SEARCH_INVOICES_BOOK: 'invoices-books/Search',
        ACTIVATE: 'invoices-books/{id}/active-status',
        LIST: 'invoices-books/list',
    },
    FAWATEER: {
        GETALL: 'Fawateer',
        GET: 'Fawateer/',
        ADD_FAWATEER: 'add_Fawateer',
        SEARCH_FAWATEER: 'Fawateer/Search',
    },
    ONBOARDING: {
        GETALL: 'Onboarding',
        ADD_ONBOARDING: 'invoices-books/*/Onboarding',
        STATUS_ONBOARDING: "invoices-books/*/Onboarding/status",

        GET: 'Onboarding/',
        POST: 'Onboarding/invoices-books/{invoicesBookId}',
        RENEW: 'Onboarding/invoices-books/{invoicesBookId}/renewal',
        ONBOARDING_STATUS: 'Onboarding/invoices-books/{invoicesBookId}/status',
        SEARCH: 'Onboarding/search',
        ACTIVE_STATUS: 'Onboarding/invoices-books/{invoicesBookId}/active-status',
    },
    INVOICES: {
        SEARCH_REPORTS: 'Invoices/Search',
        GET_BY_ID: 'Invoices/',
        GET_QR: 'Invoices/{invoiceId}/qr-code/image?scale=5',
        GET_QR_BASE64: 'Invoices/{invoiceId}/qr-code/base64?scale=5&html=true',
        GET_PDF_BLOB: 'Invoices/{invoiceId}/pdf-a3?pageSize=3',
        GET_PDF_BASE64: 'Invoices/{invoiceId}/pdf-a3/base64?pageSize=3',//TODO to be configerd
        REPORT: 'Invoices/report',
    },
    AUTH: {
        LOGIN: 'Accounts/Login',
        FORGOT_PASSWORD: 'Accounts/ForgotPassword',
        RESET_PASSWORD: 'Accounts/ForgotPasswordReset',
        CHANGE_PASSWORD: 'Accounts/ChangePassword',
        GET_SERVER_TIME: 'Accounts/GetServerTime',
        UNLOCK_USER: 'Accounts/UnlockUser',

    },
    LOOKUPS: {
        GET: 'Lookups',
        POST: 'Lookups',
    },
    IDENTITY_TYPES: {
        GET: 'identity-types',
    },
    ADDRESSES: {
        GET: 'Addresses/{id}',
        POST: 'Addresses',
        PUT: 'Addresses/{id}',
        DELETE: 'Addresses/{id}',
        LIST: 'Addresses/List',
        DEFAULT: 'Addresses/default',
        SEARCH: 'Addresses/search',
        SET_DEFAULT: 'Addresses/{id}/default',
    },
    INVOICES_BOOK_META: {
        POST: 'invoices-book/{invoiceBookId}/meta',
        PUT: 'invoices-book/{invoiceBookId}/meta/{id}',
        GET: 'invoices-book/meta/{id}',
        DELETE: 'invoices-book/meta/{id}',
        SEARCH: 'invoices-book/{invoiceBookId}/list',
    },
    USERS:{
        AD_SEARCH: 'Users/ActiveDirectory',
        SEARCH: 'Users/Search',
        ADD_USER: 'Users',
        GET: 'Users/{id}',
        PUT: 'Users/',
        ACTIVATE: 'Users/{id}/active-status',
        USER_Roles: 'Users/{Id}/Roles',
        USER_ORGANIZATIONS: 'Users/{Id}/Organizations/Search',
        ADD_ROLE: 'Users/{Id}/AddRole/{RoleId}',
        UPDATE_ROLE: 'Users/{Id}/UpdateRole/{RoleId}',
        REMOVE_ROLE: 'Users/{Id}/RemoveRole/{RoleId}',
    },
    ROLES:{
        SEARCH: 'Roles/Search',
        POST: 'Roles/',
        PUT: 'Roles/',
        GET: 'Roles/',
        DELETE: 'Roles/',
    },
    PERMISSIONS:{
        GET: 'Permissions',
        CURRENT_USER: 'Permissions/CurrentUser',
    },
    ORG_USERS:{
        AD_SEARCH: 'Users/ActiveDirectory',
        SEARCH: 'OrganizationUsersManagement/Search',
        ADD_USER: 'OrganizationUsersManagement',
        GET: 'Users/{id}',
        PUT: 'Users/',
        ACTIVATE: 'Users/{id}/active-status',
        USER_Roles: 'Users/{Id}/Roles',
        ADD_ROLE: 'Users/{Id}/AddRole/{RoleId}',
        UPDATE_ROLE: 'Users/{Id}/UpdateRole/{RoleId}',
        REMOVE_ROLE: 'Users/{Id}/RemoveRole/{RoleId}',
    },
    LOGGER:{
        SEARCH: 'Logger/Search', 
        GET: 'Logger/',
        DELETE: 'Logger/',
    },
    CONNECTOR_DB:{
        SEARCH: 'ConnectorDb/Search', 
        Get: 'ConnectorDb/{Id}'
    },
    TENANT:{
        CONNECTORS: 'Tenants/connectors', 
    }
};

export default APIConstants;