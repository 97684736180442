import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TenantUsersManagementService } from "../../../Services/API/TenantUsersManagement";

const useViewTenantUserHook = () => {
  const { id } = useParams();
  const [dataSource, setDataSource] = useState<any>();
  //#endregion

  //#region :: Initial [useEffect]
  const getDataSource = async () => {
      try {
          let response = await TenantUsersManagementService.GetById(id);
          setDataSource(response);

      } catch (error) {

      }
  }

  useEffect(() => {
      getDataSource()
  }, [id]);


  //#endregion

  return {
    dataSource
  };
};

export default useViewTenantUserHook;
