import React, { useEffect, useState } from "react";
import { PaginationModel } from "../../../Models";
import useReportsSearch from "../../../Hooks/useReportsSearch";
import CustomeIconButton, {
  IconButtonsTypes,
} from "../../../Controls/IconButton";
import { Link } from "react-router-dom";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import {
  DownloadExcel,
  downloadPDFFromBlob,
  handlerDownloadExcelFromBlob,
} from "../../../Utils/File";
import { ReportsService } from "../../../Services/API/Reports";
import { FormatDate } from "../../../Utils/DateUtils";
import { useDispatch } from "react-redux";
import { changeFullLoading } from "../../../Store/Reducers/UI";
import { DIALOG_TYPE, MESSAGE_TYPE, MessagesService } from "../../../Services/messages.service";
import { numberWithCommas } from "../../../Utils/NumberUtils";
import { PermissionScopes, PermissionsService } from "../../../Services/permissions.service";
import { GlobalService } from "../../../Services/global.service";

const useReportsListHook = () => {
  const { T, Resources } = useTranslation();
  const dispatch = useDispatch();
  const reportsSearch = useReportsSearch();

  const [reportsSource, setReportsSource] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState<PaginationModel>(
    new PaginationModel()
  );
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogPdf, setDialogPdf] = useState<boolean>(false);
  const [id, setId] = useState<any>();
  const [base64, setBase64] = useState<string>("");
  const handleOpenDialog = (id: any) => {
    setId(id);
    id && setDialog(true);
  };
  const handleCloseDialog = () => {
    setDialog(false);
  };

  //#region :: Organization Data [useEffect]

  // useEffect(() => {
  //     dispatch(changeFullLoading({ spin: true }));
  // }, [qrCode]);

  const cardLabels: Array<any> = [
    {
      id: "invoiceNumber",
      label: T(Resources.AppResources.INVOICE_NUMBER),
      align: "center",
    },
    {
      id: "invoiceType",
      label: T(Resources.AppResources.INVOICE_TYPE),
      align: "center",
    },
    {
      id: "issueDate",
      label: T(Resources.AppResources.ISSUE_DATE),
      align: "center",
    },
    {
      id: "organizationName",
      label: T(Resources.AppResources.ORGANIZATION),
      align: "center",
    },
    {
      id: "invoicesBookName",
      label: T(Resources.AppResources.INVOICES_BOOK),
      align: "center",
    },
    {
      id: "currencyCode",
      label: T(Resources.AppResources.CURRENCY),
      align: "center",
    },
    { id: "amount", label: T(Resources.AppResources.AMOUNT), align: "center" },
    {
      id: "discount",
      label: T(Resources.AppResources.DISCOUNT),
      align: "center",
    },
    { id: "tax", label: T(Resources.AppResources.TAX), align: "center" },
    {
      id: "totalAmount",
      label: T(Resources.AppResources.TOTAL_AMOUNT),
      align: "center",
    },
    { id: "status", label: T(Resources.AppResources.STATUS), align: "center" },
    ...((PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_DETAILS) ||
      PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICE_QR_CODE) ||
      PermissionsService.hasPermission(PermissionScopes.DOWNLOAD_INVOICE)) ? [{ id: "action", label: T(Resources.AppResources.ACTIONS), align: "center" }] : [])
    ,
  ];

  //#region :: Organization Data [useEffect]

  // const getPdf = async (id, download: boolean) => {
  //   try {
  //     
  //     var response = await ReportsService.GetPdfFile(id);
  //     //console.log(response?.data);
  //     if(download)
  //       downloadPDF(response?.data);
  //     else{
  //       setBase64(response?.data);
  //       setDialogPdf(true);
  //     }

  //   } catch (error) {}
  // };

  // function downloadPDF(pdf) {
  //   const linkSource = `data:application/pdf;base64,${pdf}`;
  //   const downloadLink = document.createElement("a");
  //   const fileName = "invoice.pdf";
  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // }

  const getPdf = async (invoiceId: any) => {
    try {
      dispatch(changeFullLoading({ spin: true }));
      var response = await ReportsService.GetPdfFile(invoiceId);
      handlerDownloadExcelFromBlob(response?.data, "Invoice.pdf")
    } catch (error) {
      MessagesService.Toast(T(Resources.AppResources.NO_ACTIONS), MESSAGE_TYPE.Error);
    }
    finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  }

  const getPdfReader = async (invoiceId: any) => {
    try {
      dispatch(changeFullLoading({ spin: true }));
      var response = await ReportsService.GetPdfFile(invoiceId);
      var url = URL.createObjectURL(response?.data)
      const a = document.createElement('a');
      a.href = url;
      a.target = "_blank"
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      MessagesService.Toast(T(Resources.AppResources.NO_ACTIONS), MESSAGE_TYPE.Error);
    }
    finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  }

  const handleDownloadExcel = async (invoiceId: any) => {
    try {
      const request = {
        "body": {
          "organizationsId": GlobalService.CurrentActiveOrganization.id,
          "pageIndex": 0,
          "pageSize": 1000000
        }
      };
      var response = await ReportsService.SearchReports(request);
      var data = response?.data.body.map(item => ({
        invoiceNumber: item.invoiceReferenceNumber,
        invoiceType: T(Resources.AppResources[item.invoiceType.description]),
        issueDate: FormatDate(item.issueDate, "dd-MM-yyyy"),
        organizationName: item.organizationName,
        invoicesBookName: item.invoicesBookName,
        currencyCode: item.currencyCode,
        amount: numberWithCommas(item.lineExtensionAmount),
        discount: numberWithCommas(item.discountTotalAmount),
        tax: numberWithCommas(item.taxTotalAmount),
        totalAmount: numberWithCommas(item.amountDue),
        status: T(Resources.AppResources[item.status.description]),
      }));
      DownloadExcel(data, T(Resources.AppResources.REPORTS));
    } catch (error) {
      MessagesService.Toast(T(Resources.AppResources.NO_ACTIONS), MESSAGE_TYPE.Error);
    }
    finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  }
  

  const handleCloseDialogPdf = () => {
    setDialogPdf(false);
  };

  const showInvoiceErrors = (errors: Array<any>) => {
    MessagesService.Dialog(
      T(Resources.AppResources.ERRORS),
      errors.map(err => `- ${err}`).join('<br/>'),
      DIALOG_TYPE.Alert,
      T(Resources.AppResources.OK),
      undefined,
      undefined
    );
  };

  const showInvoiceWarnings = (warnings: Array<any>) => {
    // const list = ["err1", "err2", "err3", "err4", "err5"]
    MessagesService.Dialog(
      T(Resources.AppResources.WARNINGS),
      warnings.map(war => `- ${war}`).join('<br/>'),
      DIALOG_TYPE.Alert,
      T(Resources.AppResources.OK),
      undefined,
      undefined
    );
  };

  useEffect(() => {
    if (!reportsSearch?.report) return;
    // debugger
    setReportsSource(
      reportsSearch?.report?.map((item: any) => {
        const {
          id,
          invoiceReferenceNumber,
          invoiceType,
          issueDate,
          organizationName,
          invoicesBookName,
          currencyCode,
          lineExtensionAmount,
          discountTotalAmount,
          taxTotalAmount,
          amountDue,
          status,
          errors,
          warnings
        } = item;
        return {
          invoiceNumber: invoiceReferenceNumber,
          invoiceType: T(Resources.AppResources[invoiceType.description]),
          issueDate: FormatDate(issueDate, "dd-MM-yyyy"),
          organizationName,
          invoicesBookName,
          currencyCode,
          amount: numberWithCommas(lineExtensionAmount),
          discount: numberWithCommas(discountTotalAmount),
          tax: numberWithCommas(taxTotalAmount),
          totalAmount: numberWithCommas(amountDue),
          status: T(Resources.AppResources[status.description]),
          action: (
            <>

              {(warnings && warnings.length) ? <CustomeIconButton
                id='warnings-details'
                aria-label='warnings-details'
                // className={true ? 'reports-loader' : ''}
                type={IconButtonsTypes.WARNING_ICON}
                onClick={() => { showInvoiceWarnings(warnings); }} /> : null}

              {(errors && errors.length) ? <CustomeIconButton
                id='error-details'
                aria-label='error-details'
                // className={true ? 'reports-loader' : ''}
                type={IconButtonsTypes.ERRORDETAILS}
                onClick={() => { showInvoiceErrors(errors); }} /> : null}
              {PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_DETAILS) && <CustomeIconButton
                id='view'
                aria-label='view'
                // className={true ? 'reports-loader' : ''}
                type={IconButtonsTypes.VIEW}
                onClick={() => { getPdfReader(id); }} />}
              {PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICE_QR_CODE) && <CustomeIconButton
                id='save'
                aria-label='save'
                onClick={() => { handleOpenDialog(id) }}
                type={IconButtonsTypes.QR}
              />}
              {PermissionsService.hasPermission(PermissionScopes.DOWNLOAD_INVOICE) && <CustomeIconButton
                id='save'
                aria-label='save'
                onClick={() => { getPdf(id) }}
                type={IconButtonsTypes.EXPORT}
              />}
            </>
          ),
        };
      })
    );

    setPagination({
      //Prodaction
      pageIndex: reportsSearch.currentPage,
      pageSize: reportsSearch.pageSize,
      totalCount:
        reportsSearch.pagination.defaultContext !== undefined &&
        reportsSearch.pagination.defaultContext.totalCount,
      totalPages: reportsSearch.totalPages,
    });
  }, [reportsSearch.report]);
  //#endregion

  return {
    cardLabels,
    pagination,
    reportsSource,
    handleCloseDialog,
    handleOpenDialog,
    dialog,
    id,
    setId,
    base64,
    dialogPdf,
    handleCloseDialogPdf,
    reportsSearch,
    handleDownloadExcel
  };
};

export default useReportsListHook;
