import Grid from '@mui/material/Grid';
import React, {  } from 'react';

import Card from '../../../Controls/Card';
import TableComp from '../../../Controls/Table';
import Button, { ButtonsTypes } from '../../../Controls/Button';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import Search from './search';
import { useNavigate } from 'react-router-dom';
import UserInfoDialog from './Shared/AddUserDialog/adUsersDialog';
import useTenantUserSearchHook from './hooks/useTenantUserSearchHook';
import RulesDialog from './Shared/AddUserRolesDialogs/roleDialog';
import { PermissionScopes, PermissionsService } from '../../../Services/permissions.service';
import { DownloadExcel } from '../../../Utils/File';
import { ReportsService } from '../../../Services/API/Reports';
import { GlobalService } from '../../../Services/global.service';
import { MESSAGE_TYPE, MessagesService } from '../../../Services/messages.service';
import { changeFullLoading } from '../../../Store/Reducers/UI';
import { useDispatch } from 'react-redux';

const TenantUsersManagement = () => {

  //#region :: State
  const { T, Resources } = useTranslation();
  const { tenantUsersManagementHook, handleUserInfoDialog, usersTableData, columns, pagination, dialogs, userId, roleDialog, handleRoleDialog  } = useTenantUserSearchHook();
  //#endregion
  const dispatch = useDispatch();


  const handleDownloadExcel = async (invoiceId: any) => {
    try {
      const request = {
        "body": {
          "organizationsId": GlobalService.CurrentActiveOrganization.id,
          "pageIndex": 0,
          "pageSize": 1000000
        }
      };
      const response = await ReportsService.UsersReports(request);
  
      const filteredData = response?.data.body.map((user: any) => {
        return {
          Id: user.id,
          UserName: user.userName,
          Name: user.name,
          MobileNumber: user.mobileNumber,
          Email: user.email,
          IsActive: user.isActive
        };
      });
  
      DownloadExcel(filteredData, T(Resources.AppResources.REPORTS));
    } catch (error) {
      MessagesService.Toast(T(Resources.AppResources.NO_ACTIONS), MESSAGE_TYPE.Error);
    } finally {
      dispatch(changeFullLoading({ spin: false }));
    }
  }
  


  
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Search />
        </Grid>
        <Card>
        {PermissionsService.hasPermission(PermissionScopes.ADD_NEW_USER_TENANT) &&  <Grid item xs={12} display='flex' justifyContent='end' marginBottom={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { handleUserInfoDialog(null); }}
              type={ButtonsTypes.ADD}
              label={T(Resources.AppResources.ADD_USER)} />
                   <Button
              variant="contained"
              color="primary"
              type={ButtonsTypes.EXPORT}
              onClick={handleDownloadExcel}
              label={T(Resources.AppResources.EXPORT)}
            />
          </Grid>}
          
          <TableComp
            dataSource={usersTableData}
            tableColumns={columns}
            changePageApi={tenantUsersManagementHook.changeCurrentPage}
            pagination={pagination} /> 
        </Card>
   
      </Grid>
       {/* {FacilitiesDialog && <FacilitiesDialog open={facilitiesDialog} handleClose={handleFacilitiesDialog} userId={userId} />}*/}
      {roleDialog && <RulesDialog open={roleDialog} handleClose={handleRoleDialog} userId={userId} />}
       
      {dialogs?.userInfo && <UserInfoDialog open={dialogs.userInfo} id={userId} handleClose={
        (isReload: boolean) => {
          handleUserInfoDialog(null);
          if (isReload) {
            tenantUsersManagementHook.refetch();
          }
        }} />}
    </Grid>
  );
};
export default TenantUsersManagement;