import axios from "axios";
import APIConstants from "../../Config/Settings/API";
import { StatusCode } from "../../Config/Settings/Constants/StatusCode";
import CookiesConstants from "../../Config/Settings/Cookies";
import LocalStorageConstants from "../../Config/Settings/LocalStorage";
import { setCookie,getCookie } from "../../Utils/Cookies"; 
import { getItem } from "../../Utils/LocalStorage";
import { GlobalService } from "../global.service";
import { MESSAGE_TYPE, MessagesService } from "../messages.service";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { AppConfigService } from "../../Config/Settings/AppConfig"; 


const API_ENDPOINT = APIConstants.BASE_URL;
const ACCESS_TOKEN = LocalStorageConstants.ACCESS_TOKEN;

export enum MESSAGE_ERRORS_TYPE {
  IDENTITY_ID_NOT_UNIQUE= "IDENTITY_ID_NOT_UNIQUE",
  VAT_ID_NOT_UNIQUE= "VAT_ID_NOT_UNIQUE",
  TIN_NOT_UNIQUE= "TIN_NOT_UNIQUE",
  INVOICE_BOOK_NAME_NOT_UNIQUE= "INVOICE_BOOK_NAME_NOT_UNIQUE",
}

const authHeader = () => ({
  Authorization: `Bearer ${getItem(ACCESS_TOKEN)}`,

});

axios.defaults.baseURL = API_ENDPOINT;
//axios.defaults.headers.common["Authorization"] = `Bearer ${getItem(ACCESS_TOKEN)}`;
axios.defaults.headers.post["Content-Type"] = 'application/json';       
axios.defaults.headers.post["Accept-Language"] = getCookie(CookiesConstants.SETTINGS.LANG);

 //[ToDo review to local]
 const replacer = function (this: any, key: string, value: any) {

  function isoDateWithoutTimeZone(date) {
      if (date == null) return date;
      var timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
      var correctDate = new Date(timestamp);
      // correctDate.setUTCHours(0, 0, 0, 0); // uncomment this if you want to remove the time
      return correctDate.toISOString();
  }

  
  if (this[key] instanceof Date && this[key]) {
      return isoDateWithoutTimeZone(this[key]);
  }
  return value;


}
// axios.defaults.transformRequest =  [(data, headers) => JSON.stringify(data, replacer), ...(axios.defaults.transformRequest as AxiosRequestTransformer[])]  //ToDo review to local       
//[ToDo review to local]


const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    //...authHeader(),
    "Content-Type": "application/json",
    "Accept-Language": getCookie(CookiesConstants.SETTINGS.LANG),
  },
  withCredentials: true
});


class WebServiceClient {

  static addTenantHeaders(addHeaders: boolean, useOrgHeader: boolean){
    const current_organization = GlobalService.CurrentActiveOrganization?.id;    
    client.defaults.headers["env"] = GlobalService.EnvMode;
    client.defaults.headers["Accept-Language"] = getCookie(CookiesConstants.SETTINGS.LANG);

    const currentTenant = GlobalService.TenantServices;  

    if(addHeaders)
     {
       client.defaults.headers= {
        ...client.defaults.headers, 
        "TenantId": currentTenant?.id
      }
      if(useOrgHeader)
        client.defaults.headers["Organizationid"] = current_organization;
      else
        delete client.defaults.headers["Organizationid"];
      }
    else{
      delete client.defaults.headers["Organizationid"];
      delete client.defaults.headers["TenantId"];
    }
  }

  static get(path = "", useDefaultHeaders = true, useOrgHeader = true, isFile = false) {

    WebServiceClient.addTenantHeaders(useDefaultHeaders, useOrgHeader);
      let params = {
      }
      if (isFile == true) {
        params = {
          responseType: "blob"
        }
      }
      return client({
        method: "GET",
        url: path,
        ...params,
      });
    }
  static delete(path = "", useDefaultHeaders = true, useOrgHeader = true) {

    WebServiceClient.addTenantHeaders(useDefaultHeaders, useOrgHeader);
    return client({
      method: "DELETE",
      url: path,
    });
  }
  static post(path = "", data = {}, optionalHeader = {}, useDefaultHeaders = true, useOrgHeader = true) {

    WebServiceClient.addTenantHeaders(useDefaultHeaders, useOrgHeader);
    return client({
      method: "POST",
      url: path,
      data,
    });
  }

  static patch(path = "", data = {}, useDefaultHeaders = true, useOrgHeader = true) {

    WebServiceClient.addTenantHeaders(useDefaultHeaders, useOrgHeader);
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
    });
  }

  static put(path = "", data = {}, useDefaultHeaders = true, useOrgHeader = true) {

    WebServiceClient.addTenantHeaders(useDefaultHeaders, useOrgHeader);
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
    });
  }

  static loginPost(path = "", data = {}, optionalHeader = {}, useDefaultHeaders = true, useOrgHeader = true) {

    WebServiceClient.addTenantHeaders(useDefaultHeaders, useOrgHeader);
    return client({
      method: "GET",
      url: path,
      data,
    });
  }

  static getError(error: any) {
    let errorResponse = error;
    if (error.response)
      errorResponse = error.response;
    else
      errorResponse = error;

    throw errorResponse;
  }

  static handleError(error: any) {
    var errorResponse = error;
    if (error.response !== undefined) {
      if (error.response.data) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach(key => {
            
            if(error.response.data.errors[key].message ) MessagesService.Toast(this.MapErrorMessages(error.response.data.errors[key].message), MESSAGE_TYPE.Error);
            else
              MessagesService.Toast(`${key.split('.')[2]}  ${error.response.data.errors[key].join(' - ')}`, MESSAGE_TYPE.Error);
            
          })
        } else if (error.response.data.status)
          errorResponse = error.response.data.status;
      }
    } else {
      errorResponse = error;
    }
    return errorResponse;
  }
  

  static MapErrorMessages(error: any) {
    let errorText= "";
    switch (error) {
      case MESSAGE_ERRORS_TYPE.IDENTITY_ID_NOT_UNIQUE:
        errorText = "Identity number entered is already in use. Please provide a unique Identity number.";
        break;
      case MESSAGE_ERRORS_TYPE.TIN_NOT_UNIQUE:
        errorText = "The TIN number entered is already in use. Please provide a unique TIN number.";
        break;

      case MESSAGE_ERRORS_TYPE.VAT_ID_NOT_UNIQUE:
        errorText = "The VAT number entered is already in use. Please provide a unique VAT number.";
        break;

      case MESSAGE_ERRORS_TYPE.INVOICE_BOOK_NAME_NOT_UNIQUE:
        errorText = "Invoice Book entered is already in use. Please provide a unique Invoice Book.";
        break;
    
      default:
        errorText = error;
        break;
    }
    return errorText;
  }

}

const GoTo = (page: any) => {
  window.location.pathname = `${AppConfigService.getConfig().reactAppBaseUrl}/${page}`;
  // history.push(`${AppConfigService.getConfig().reactAppBaseUrl}/${page}`);
  // history.go(`${AppConfigService.getConfig().reactAppBaseUrl}/${page}`);
};

const configRequestInterceptor = (config: any) => {
  const requestConfig = config;

  //requestConfig.withCredentials = true;
  // const { headers } = config;
  // requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem(ACCESS_TOKEN)}` };

  return requestConfig;
};

const responseErrorInterceptor = async (error: any) => {
 
  const { header, status, body, extras } = error?.response?.data;
  const statusCode = status.code;
  const statusDescription = status.description;

  if (statusCode && (statusCode.startsWith('B0')||statusCode.startsWith('E'))&& statusDescription) { 
      MessagesService.Toast(statusDescription,MESSAGE_TYPE.Error,50);
  }

  return Promise.reject(error);
 
};

client.interceptors.request.use(configRequestInterceptor);

client.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

axios.interceptors.request.use(configRequestInterceptor);

axios.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export { WebServiceClient, axios };

