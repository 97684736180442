import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ServiceUnavailableImg } from '../../../Controls/Icons/Icons';
import { ServiceUnavailableProps } from '../definitions';
import '../style.css';

const ServiceUnavailable = ({ error = null, errorInfo }: ServiceUnavailableProps) => {

    return (
        <Grid container item xs={12} spacing={1}>
            <StyledGrid>
                <StyledImgGrid>
                    <ServiceUnavailableImg />
                </StyledImgGrid>
                <Box p={1}>
                    <h1 style={{ fontFamily: 'cairo' }}>الخدمة غير متوفرة حالياً الرجاء المحاولة لاحقاً</h1>
                </Box>
            </StyledGrid>
        </Grid>
    );
};

//#region :: Styled Components
const StyledGrid: any = styled(Grid)`
   text-align: center;
    width: 100%;
     padding-top: 1rem;
`;

const StyledImgGrid: any = styled(Grid)`
    display: flex;
    justify-content: center;
`;

const ALink: any = styled.a`
text-decoration: none;
color: #2e49a2;
cursor: pointer;
`;

const Img: any = styled.img`
max-width: 24%;
`;
//#endregion

export default ServiceUnavailable;