import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleMappingSearch } from '../../../Pages/TenantContext/RoleMapping/Shared/roleMappings.model';

const roleMapping = createSlice({
    name: 'roleMapping',
    initialState: {
        roleMapping: [],
        keys: [],
        roleMappingDetails: {},
        searchCriteria: RoleMappingSearch.toDBModel(RoleMappingSearch.getFieldsModel()),
        // searchCriteria: null,
        showError: false,
        error: null,
        isSearching: true,
    },

    reducers: {
        roleMappingReceived: (roleMapping: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {

                roleMapping.roleMapping[item.id] = item;
                roleMapping.keys = [...roleMapping.keys, item.id]
            });
            roleMapping.customerDetails = {};
        },

        setRoleMappingDetails: (roleMapping: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            roleMapping.roleMappingDetails = data;
        },

        setRoles: (roleMapping, { payload }) => {
            roleMapping.roleMapping = payload;
        },

        setSearchCriteria: (roleMapping: any, { payload }: PayloadAction<any>) => {
            roleMapping.searchCriteria = payload.body;
        },

        setError: (roleMapping: any, { payload }: PayloadAction<any>) => {
            roleMapping.error = payload.response;
            roleMapping.showError = true;
        },
        setIsSearching: (roleMapping: any, { payload }: PayloadAction<any>) => {
            roleMapping.isSearching = payload.body;
        }
    }
});


export const { roleMappingReceived, setRoles, setSearchCriteria, setRoleMappingDetails, setError, setIsSearching } = roleMapping.actions;
export default roleMapping.reducer;

//#region :: Selectors
export const getRolesData = (state: any) => {
    return state.roleMapping.roleMapping;
};

export const getRolesDetails = (state: any) => {
    return state.roleMapping.roleMappingDetails;
};

export const getSearchCriteria = (state: any) => {
    return state.roleMapping.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.roleMapping.showError;
};

export const getError = (state: any) => {
    return state.roleMapping.error;
};

export const getIsSearching = (state: any) => {
    return state.roleMapping.isSearching;
};

export const roleMappingSelectors = {
    getRolesData,
    getSearchCriteria,
    getShowError,
    getError,
    getIsSearching,
    getRolesDetails,
};
//#endregion
