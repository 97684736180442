import { GlobalService } from "./global.service";

export class StorageService {

    private static isEncripted: boolean = false;

    public static getItem(key: STORAGE_KEY | string) {

        let data: any = sessionStorage.getItem(key);

        if (!data) {
            data = localStorage.getItem(key);
        }

        if (!data) {
            return null;
        }

        try {
            return JSON.parse(data);
        }
        catch (err) {
            return data;
        }
    };

    public static setItem(key: STORAGE_KEY | string, value?: any, isLocalStorage: boolean = false) {
        let stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
        if (isLocalStorage) {
            localStorage.setItem(key, stringify);
        } else {
            sessionStorage.setItem(key, stringify);
        }
    };

    public static removeItem(key: STORAGE_KEY) {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    };

    public static clearStorage() {
        localStorage.clear();
        sessionStorage.clear();
    };

    public static generateKey(key: STORAGE_KEY) {
        const userId = GlobalService.AppUser?.id ? `${GlobalService.AppUser?.id}-` : "";
        const tenantId = GlobalService.TenantServices?.id ? `${GlobalService.TenantServices?.id}-` : "";
        return `${userId}${tenantId}${key}`;
    }

    
}

export enum STORAGE_KEY {
    CURRENT_USER = "current_user",
    CURRENT_ORGANIZATION = 'current_organization',
    ACCESS_TOKEN = 'access_token',
    REFRESH_TOKEN = 'refresh_token',
    CURRENT_ACTIVE_ORGANIZATION = 'current_active_organization',
    TenantUrl = "TenantUrl",
    TenantServices = "TenantServices",
    FACILITY_CATEGORY_TYPE = "facility_category_type",
    USER_AUTHORIZATION_INFO = 'user_authorization_info',
    IS_TENANTCONTEXT = 'IS_TENANTCONTEXT',
    ENV_MODE = 'ENV_MODE',
    ORGS_LIST = 'ORGS_LIST',
    REFETCH_ORGS = 'REFETCH_ORGS',
};