import { AppConfigService } from "../Config/Settings/AppConfig";
import { MULTI_TENANT_BASE_URL } from "../Config/Settings/baseUrls";
import { GlobalService } from "../Services/global.service";

const BASE_URL = MULTI_TENANT_BASE_URL;
const DEFAULT_TENANT_ID = 'master';

const config = {
  DefaultTenantId: DEFAULT_TENANT_ID,
  BaseUrl: BASE_URL,
  Routes: {
    TenantInfo: (tenantId: string) => `${BASE_URL}/api/tenants/${tenantId}`,
    Login: (tenantId: string) => {
      if (tenantId.toLowerCase() == DEFAULT_TENANT_ID) return `${BASE_URL}/api/Auth/Login`;

      return `${BASE_URL}/${tenantId}/api/Auth/Login`;
    },
    Logout: (tenantId: string) => {
      if (tenantId.toLowerCase() == DEFAULT_TENANT_ID) return `${BASE_URL}/api/Auth/Logout`;

      return `${BASE_URL}/${tenantId}/api/Auth/Logout`;
    },
    GetUserInfo: (tenantId: string) => {
      // if (tenantId.toLowerCase() == DEFAULT_TENANT_ID) return `${BASE_URL}/api/Users/current`;

      // return `${BASE_URL}/${tenantId}/api/Users/current`;
      return `${BASE_URL}/api/Users/current`;
    },
    GetGateWay: () => {
      const tenant = GlobalService.TenantServices;
  
      return `${tenant?.id}/api/`;

    },
  },
};
export default config;
