import { useNavigate } from 'react-router-dom';
import { useTenant } from '../../MultiTenant/tenantContext';

const useAppNavigate = () => {
    const { currentTenant } = useTenant();
    const navigate = useNavigate();

    const push = (path = "/", nonRoute = false, replace = false, queryParams = {}) => {
        const searchParams = new URLSearchParams(queryParams).toString();
        const fullPath = nonRoute
            ? currentTenant.id + path + (searchParams ? `?${searchParams}` : "")
            : path + (searchParams ? `?${searchParams}` : "");

        if (nonRoute) {
            window.location.pathname = fullPath;
        } else {
            navigate(fullPath, { replace });
        }
    };

    const redirect = (path = "/", queryParams = {}) => {
        const searchParams = new URLSearchParams(queryParams).toString();
        const fullPath = currentTenant.clientBaseRoute + path + (searchParams ? `?${searchParams}` : "");
        window.location.href = fullPath;
    };

    const open = (path = "/", isNewWindow = true, queryParams = {}) => {
        const searchParams = new URLSearchParams(queryParams).toString();
        const fullPath = window.location.origin + "/" + currentTenant.id + path + (searchParams ? `?${searchParams}` : "");
        window.open(fullPath, isNewWindow ? "_blank" : "_self");
    };

    const back = (isRouter = true) => {
        isRouter ? navigate(-1) : window.history.back();
    }
    const refresh=()=>{
        navigate(0);
    }

    return { push, redirect, open, back,refresh };
};

export default useAppNavigate;