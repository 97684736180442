import Locales from '../Locales';
import { AppResources } from '../Resources';


const enUs = {
        [Locales.ENGLISH]: {
                [AppResources.FAWATEER]: 'Fawateer',
                [AppResources.GREETING]: 'Hello',
                [AppResources.X4FATOORA]: 'X4Fatoora',



                [AppResources.JOB_POSITION_CODE]: 'Job Position',
                [AppResources.JOB_CLASS_CODE]: 'Job Class',
                [AppResources.POSITION_STATUS]: 'Position Status',
                [AppResources.START_DATE]: 'Start Date',
                [AppResources.END_DATE]: 'End Date',




                //**************************************** */
                [AppResources.STEP_ID]: 'Step',
                [AppResources.TRANSACTION_CODE]: 'Transaction',
                [AppResources.TRANSACTION_START_DATE]: 'Start date',
                [AppResources.TRANSACTION_END_DATE]: 'End date',

                [AppResources.QUALIFICATION_ID]: 'Qualification Number',
                [AppResources.QUALIFICATION_CODE]: 'Qualification',
                [AppResources.MAJOR_CODE]: 'Major',
                [AppResources.UNIVERSITY_CODE]: 'University',
                [AppResources.QUALIFICATION_STATUS]: 'Qualification Status',


                [AppResources.NET_PAY]: 'NetPay',
                [AppResources.PAID_DATE]: 'Paid Date',
                [AppResources.GREGORIAN_DATE]: 'Gregorian Date',
                [AppResources.ELEMENTS]: 'Elements',



                [AppResources.APPRAISAL_ID]: 'Appraisal',
                [AppResources.APPRAISAL_TYPE_CODE]: 'Appraisal Type',
                [AppResources.RESULT]: 'Result',
                [AppResources.RATING_CODE]: 'Rating',
                [AppResources.ACTIVITY_STATUS]: 'Activity Status',


                [AppResources.VACATION_ID]: 'Vacation Id',
                [AppResources.PERIOD]: 'Period',
                [AppResources.VACATION_CODE]: 'Vacation',



                //*********************************************************** */



                [AppResources.ACTIONS]: 'Actions',
                [AppResources.REPORTS]: 'Reports',
                [AppResources.REPORT]: 'Report',
                [AppResources.REQUESTS]: 'Request',
                [AppResources.REQUESTREPORT]: 'Request Report',
                [AppResources.MOBILE_NUMBER]: 'Mobile Number',
                [AppResources.SEARCH]: 'Search',
                [AppResources.RESET]: 'Resest',
                [AppResources.DASHBOARD]: 'Dashboard',
                [AppResources.BENEFICIARY]: 'Beneficiary',
                [AppResources.BLANKPAGE]: 'Blank Page',
                [AppResources.CLAIMS]: 'Claim',
                [AppResources.ELIGIBILITY]: 'Eligibility',
                [AppResources.HOME]: 'Home',
                [AppResources.SETTINGS]: 'Settings',
                [AppResources.PAYMENT]: 'Payment',
                [AppResources.PREAUTHORIZATION]: 'PreAuthorization',
                [AppResources.PROFILE]: 'Profile',
                [AppResources.THEME]: 'Theme',
                [AppResources.MY_ACCOUNT]: 'My Account',
                [AppResources.USER_IMAGE]: 'User Image',
                [AppResources.DOWNLOAD]: 'Download',
                [AppResources.CANCEL]: 'Cancel',
                [AppResources.DATE_VALIDATION]: 'Date From Should Be Befor Date To',
                [AppResources.SELECT]: 'Select',
                [AppResources.NODATA]: 'No Data',
                [AppResources.NAME]: 'Name',
                [AppResources.DOCUMENTID]: 'Document Id',
                [AppResources.FILEID]: 'File Id',
                [AppResources.CONTACTNO]: 'Contact Number',
                [AppResources.DATEOFBIRTH]: 'Date Of Birth',
                [AppResources.INSURANCEPLAN]: 'Insurance Plan',
                [AppResources.ELIGIBILITYSTATUS]: 'Eligibility Status',
                [AppResources.DATE]: 'Date',
                [AppResources.TOTAL_RESULTS]: 'Total Result',
                [AppResources.MEMBER_CARD_ID]: 'Member Card ID',
                [AppResources.ELIGIBILITY_ID]: 'Eligibility ID',
                [AppResources.TRANSACTION_DATE]: 'Transaction Date',
                [AppResources.INSURANCE_PLAN]: 'Insurance Plan',
                [AppResources.REQUEST_NEW_ELIGIBILITY]: 'Request New Eligibility',
                [AppResources.BENEFICIARY_ID]: 'Beneficiary ID',
                [AppResources.ENGLISH_LANGUAGE]: 'English',
                [AppResources.ARABIC_LANGUAGE]: 'العربية',
                [AppResources.CHECK_ELIGIBILITY]: 'Check Eligibility',
                [AppResources.FROM]: 'From',
                [AppResources.TO]: 'To',
                [AppResources.OK]: 'Ok',
                [AppResources.CLEAR]: 'Clear',
                [AppResources.INSURER]: 'Insurer',
                [AppResources.SERVICE_DATE]: 'Service Date',
                [AppResources.ADD_NEW_BENEFICIARY]: 'Add New Benefeciary',
                [AppResources.NAME]: 'Name',
                [AppResources.AGE]: 'Age',
                [AppResources.VIEWELIGIBILITY]: 'Eligibility Details',
                [AppResources.VIEWELIGIBILITY]: 'View Eligibility',
                [AppResources.ITEMS]: 'Items',
                [AppResources.ITEM]: 'Item',
                [AppResources.CHAPTER]: 'Chapter',
                [AppResources.PAYSLIP]: 'Payslip',
                [AppResources.BLOCK]: 'Block',
                [AppResources.ALIAS_CODE]: 'Alias Code',
                [AppResources.ALIAS]: 'Alias',
                [AppResources.CATEGORY]: 'Category',
                [AppResources.CREATED_DATE]: 'Created Date',
                [AppResources.SHORT_DESCRIPTION]: 'Short Description',
                [AppResources.ITEM_CODE]: 'Item Code',
                [AppResources.ADD_NEW_ITEM]: 'Add New Item',
                [AppResources.ADDITEM]: 'Add Item',
                [AppResources.UPLOAD]: 'Upload',
                [AppResources.EXPORT]: 'Export',
                [AppResources.CODE]: 'Code',
                [AppResources.LONG_DESCRIPTION]: 'Long Description',
                [AppResources.OWNER]: 'Owner',
                [AppResources.ADD_CATEGORY]: 'Add Category',
                [AppResources.ADD_CHAPTER]: 'Add Chapter',
                [AppResources.ADD_BLOCK]: 'Add Block',
                [AppResources.ID]: 'ID',
                [AppResources.SAVE]: 'Save',
                [AppResources.VIEWITEM]: 'View Item',
                [AppResources.BACK]: 'Back',
                [AppResources.EDIT]: 'Edit',
                [AppResources.DELETE]: 'Delete',
                [AppResources.YES]: 'Yes',
                [AppResources.NO]: 'No',
                [AppResources.DIAGNOSIS]: 'Diagnosis',
                [AppResources.ADD_NEW_DIAGNOSIS]: 'Add New Diagnosis',
                [AppResources.PARENT]: 'Parent',
                [AppResources.ADDDIAGNOSIS]: 'Add Diagnosis',
                [AppResources.VERSION]: 'Version',
                [AppResources.YEAR]: 'Year',
                [AppResources.SECONDARY_ASSOCIATIONS]: 'Secondary Associations',
                [AppResources.ASSOCIATION]: 'Associations',
                [AppResources.EDITITEM]: 'Edit Item',
                [AppResources.TERMINOLOGY]: 'Terminology',
                [AppResources.ADDTERMINOLOGY]: 'Add Terminology',
                [AppResources.EDITTERMINOLOGY]: 'Edit Terminology',
                [AppResources.VIEWTERMINOLOGY]: 'View Terminology',
                [AppResources.ADD_NEW_TERMINOLOGY]: 'Add New Terminology',
                [AppResources.CODE_SYSTEM]: 'Code System',
                [AppResources.VALUE_SET]: 'Value Set',
                [AppResources.PAYERS]: 'Payers',
                [AppResources.PAYER]: 'Payer',
                [AppResources.ADDPAYER]: 'Add Payer',
                [AppResources.EDITPAYER]: 'Edit Payer',
                [AppResources.VIEWPAYER]: 'View Payer',
                [AppResources.ADD_NEW_PAYER]: 'Add New Payer',
                [AppResources.NAME_AR]: 'Arabic Name',
                [AppResources.NAME_EN]: 'English Name',
                [AppResources.UNIFIED_ID]: 'Unified ID',
                [AppResources.VAT_NUMBER]: 'VAT Number',
                [AppResources.COUNTRY]: 'Country',
                [AppResources.CITY]: 'City',
                [AppResources.CONTACT_NAME]: 'Contact Name',
                [AppResources.PHONE_NUMBER]: 'Phone Number',
                [AppResources.BUILDING_NUMBER]: 'Building Number',
                [AppResources.STREET_NAME]: 'Street',
                [AppResources.DISTRICT]: 'District',
                [AppResources.POSTAL_CODE]: 'Postal Code',
                [AppResources.ADDITIONAL_NUMBER]: 'Additional Number',
                [AppResources.CREATE]: 'Create',
                [AppResources.CREATE_AND_NEW]: 'Create And New',
                [AppResources.EXT]: 'Ext',
                // Start View Beneficiary
                [AppResources.CREATEBENEFICIARY]: 'Create Beneficiary',
                [AppResources.EDITBENEFICIARY]: 'Edit Beneficiary',
                [AppResources.PERSONAL_INFORMATION]: 'Personal Information',
                [AppResources.ENEFICIARY_FILE_ID]: 'Beneficiary File ID',
                [AppResources.BENEFICIARY_NAME]: 'Beneficiary Name',
                [AppResources.DATE_OF_BIRTH]: 'Date of Birth',
                [AppResources.BLOOD_GROUP]: 'Blood Group',
                [AppResources.HIJRI]: 'Hijri',
                [AppResources.NATIONALITY]: 'Nationality',
                [AppResources.RESIDENT_TYPE]: 'Resident Type',
                [AppResources.DOCUMENT_TYPE]: 'Document Type',
                [AppResources.DOCUMENT_ID]: 'Document ID',
                [AppResources.PATIENT_PHONE_NUMBER]: 'Patient Phone Number',
                [AppResources.EMERGENCY_PHONE_NUMBER]: 'Emergency Phone Number',
                [AppResources.EMERGENCY_CONTACT_RELATION]: 'Emergency Contact Relation',
                [AppResources.EMAIL]: 'Email',
                [AppResources.MARTIAL_STATUS]: 'Marital status',
                [AppResources.STATUS]: 'Status',
                [AppResources.PREFERRED_LANGUAGE]: 'Preferred Language',
                [AppResources.ADDRESS]: 'Address',
                [AppResources.MEMBERSHIP_NO]: 'Membership No',
                [AppResources.PPOLICY_NO]: 'Policy No',
                [AppResources.GROUP_NAME]: 'Group Name',
                [AppResources.APPROVAL_LIMIT]: 'Approval Limit',
                [AppResources.NETWORK_NO]: 'Network No',
                [AppResources.TERM]: 'Term',
                [AppResources.CO_PAY]: 'Co Pay',
                [AppResources.NOTES]: 'Notes',
                [AppResources.APPROVALS]: 'Approvals',
                [AppResources.VIEWBENEFICIARY]: 'Beneficiary Details',
                [AppResources.TYPE]: 'type',
                [AppResources.LIMIT]: 'Limit',
                [AppResources.MAX_OUT_OF_POCKET]: 'Max Out of Pocket',
                [AppResources.EXCEPTIONS]: 'Exceptions',
                [AppResources.EXCLUSIONS]: 'Exclusions',
                // Start View Eligibility 
                [AppResources.ELIGIBILITY_DETAILS]: 'Eligibility Details',
                [AppResources.ELIGIBILITY_ID]: 'Eligibility ID',
                [AppResources.CONTACT_NUMBER]: 'Contact Number',
                [AppResources.COVARAGE]: 'Covarage',
                [AppResources.CLASS]: 'Class',
                [AppResources.NETWORK]: 'Network',
                [AppResources.DEPENDENT_RELATIONSHIP]: 'Dependent Relationship',
                [AppResources.ROOM_TYPE]: 'Room Type',
                [AppResources.PERIOD]: 'Period',
                [AppResources.MAX_ALLOWED]: 'Max Allowed',
                [AppResources.ELIGIBILITY]: 'Eligibility',
                [AppResources.SERVICE_DATE]: 'Service Date',
                [AppResources.END_DATE]: 'End Date',
                [AppResources.DEPARTMENT_NAME]: 'Department Name',
                [AppResources.DEPARTMENT_ROLE]: 'Department Role',
                [AppResources.TRANSACTION_DATE]: 'Transaction Date',
                [AppResources.RESPONSE_TIME]: 'Response Time',
                [AppResources.DOWNLOAD_TIME]: 'Download Time',
                [AppResources.ELIGIBILITY_PURPOSE]: 'Eligibility Purpose',
                [AppResources.INCLUSIONS]: 'Inclusions',
                [AppResources.UNIT]: 'Unit',
                // Start Practitioners 
                [AppResources.PRACTITIONERS]: 'Practitioners',
                [AppResources.DESCRIPTION]: 'Description',
                [AppResources.ADD_NEW_PARTITIONER]: 'Add New Partitioner',
                [AppResources.LICENSE]: 'License',
                [AppResources.ENGLISH_NAME]: 'English Name',
                [AppResources.SPECIALTY]: 'Specialty',
                [AppResources.ROLE]: 'Role',
                [AppResources.ACTION_FROM]: 'Active From',
                [AppResources.ACTION_TO]: 'Active To',
                [AppResources.CREATEPRACTITIONERS]: 'Create Practitioners',
                [AppResources.EDITPRACTITIONERS]: 'Edit Practitioners',
                [AppResources.PARTITIONER_CODE]: 'Partitioner Code',
                [AppResources.PRACTITIONER_SPECIALTY]: 'Practitioner Specialty',
                [AppResources.PRACTITIONER_ROLE]: 'Practitioner Role',
                [AppResources.VIEWPRACTITIONERS]: 'View Practitioner',
                [AppResources.BACK]: 'Back',
                [AppResources.ADD]: 'Add',
                [AppResources.EDIT]: 'Edit',
                // Start TypesManagement 
                [AppResources.SERVICES_MANAGEMENT]: 'Services Management',
                [AppResources.TYPESMANAGEMENT]: 'Types Management',
                [AppResources.CODE_SYSTEM_URL]: 'Code System URL',
                [AppResources.ADD_NEW_TYPE]: 'Add New Type',
                [AppResources.CREATETYPESMANAGEMENT]: 'Create Types Management',
                [AppResources.EDITTYPESMANAGEMENT]: 'Edit Types Management',
                [AppResources.VIEWTYPESMANAGEMENT]: 'Types Management Details',
                // Start PROVIDER_GROUPS 
                [AppResources.PROVIDER]: 'Provider',
                [AppResources.PROVIDERGROUPS]: 'Provider Groups',
                [AppResources.ADDNEWGROUP]: 'Add New Group',
                [AppResources.CREATEPROVIDERGROUPS]: 'Create Provider Groups',
                [AppResources.EDITPROVIDERGROUPS]: 'Edit Provider Groups',
                [AppResources.VIEWPROVIDERGROUPS]: 'Provider Groups Details',
                [AppResources.SERVICECATEGORIES]: 'Service Categories',
                [AppResources.VIEWSERVICECATEGORIES]: 'View Service Categories',
                [AppResources.EDITSERVICECATEGORIES]: 'Edit Service Categories',
                [AppResources.ADDSERVICECATEGORIES]: 'Add Service Categories',
                [AppResources.ADD_NEW_SERVICE_CATEGORIE]: 'Add New Service Categorie',
                [AppResources.SERVICESUBGROUPS]: 'Service Sub Groups',
                [AppResources.VIEWSERVICESUBGROUPS]: 'View Service Sub Groups',
                [AppResources.EDITSERVICESUBGROUPS]: 'Edit Service Sub Groups',
                [AppResources.ADDSERVICESUBGROUPS]: 'Add Service Sub Groups',
                [AppResources.ADD_NEW_SERVICE_SUB_GROUPS]: 'Add New Sub Group',
                [AppResources.SERVICE_GROUP]: 'Service Group',

                [AppResources.PRE_AUTHORIZATION_ID]: 'PreAuthorization ID',
                [AppResources.ADD_NEW_PREAUTHORIZATION]: 'Add New PreAuthorization',
                [AppResources.ADDPREAUTHORIZATION]: 'Add PreAuthorization',
                [AppResources.EDITPREAUTHORIZATION]: 'Edit PreAuthorization',
                [AppResources.VIEWPREAUTHORIZATION]: 'View PreAuthorization',


                [AppResources.PRE_AUTHORIZATION_INFO]: 'Pre Authorization Info',
                [AppResources.DATE_ORDERED]: 'Date Ordered',
                [AppResources.TYPE]: 'Type',
                [AppResources.SUBTYPE]: 'Sub Type',
                [AppResources.SEQUENCE]: 'Sequence',
                [AppResources.CODE_DESCRIPTION]: 'Code - Description',
                [AppResources.ON_ADMISSION]: 'On Admission',


                // Start Facilities 
                [AppResources.FACILITIES]: 'Facilities',
                [AppResources.LICENSE_NO]: 'License Number',
                [AppResources.LICENSE_PERIOD]: 'License Period',
                [AppResources.LICENSE_START]: 'License Start',
                [AppResources.LICENSE_END]: 'License End',
                [AppResources.CHHI_ID]: 'CHHI ID',
                [AppResources.ADDFACILITIES]: 'Add Facilities',
                [AppResources.EDITFACILITIES]: 'Edit Facilities',
                [AppResources.VIEWFACILITIES]: 'View Facilities',

                [AppResources.SERVICECATALOG]: 'Service Catalog',
                [AppResources.ADDSERVICECATALOG]: 'Add Service Catalog',
                [AppResources.EDITSERVICECATALOG]: 'Edit Service Catalog',
                [AppResources.VIEWSERVICECATALOG]: 'View Service Catalog',
                [AppResources.CATALOG_NAME]: 'Catalog Name',
                [AppResources.ADD_NEW_SERVICE_CATALOG]: 'Add New Service Catalog',
                [AppResources.SERVICE_CATEGORY]: 'Service Category',
                [AppResources.SERVICE]: 'Service',
                [AppResources.SERVICE_SUB_GROUP]: 'Service Sub Group',


                [AppResources.PRICELISTS]: 'Price Lists',
                [AppResources.ADDPRICELISTS]: 'Add Price Lists',
                [AppResources.EDITPRICELISTS]: 'Edit Price Lists',
                [AppResources.VIEWPRICELISTS]: 'View Price Lists',
                [AppResources.ADD_NEW_PRICE_LISTS]: 'Add New Price Lists',


                [AppResources.SELECTED_FILES]: 'Selected Files',
                [AppResources.DRAG_AND_DROP_A_FILE_HERE_OR_CLICK]: 'Drag And Drop A File Here Or Click',

                [AppResources.UNITPRICE]: 'Unit Price',
                [AppResources.VAT_CATEGORY]: 'VAT Category',
                [AppResources.NON_STANDARD_CODE]: 'Non Standard Code',
                [AppResources.PACKAGE]: 'Package',

                // Start ServiceGroups 
                [AppResources.SERVICEGROUPS]: 'Service Groups',
                [AppResources.ADD__NEW_SERVICE_GROUPS]: 'Add New Service Groups',
                [AppResources.ADDSERVICEGROUPS]: 'Add Service Groups',
                [AppResources.EDITSERVICEGROUPS]: 'Edit Service Groups',
                [AppResources.VIEWSERVICEGROUPS]: 'View Service Groups',
                [AppResources.LOGIN]: 'Login',
                [AppResources.USERNAME]: 'User Name',
                [AppResources.PASSWORD]: 'Password',


                // Start PriceLists 
                [AppResources.ITEM_TYPE]: 'Item Type',
                [AppResources.ITEM_CATEGORY]: 'Item Category',
                [AppResources.ITEM_CHAPTER]: 'Item Chapter',
                [AppResources.ITEM_BLOCK]: 'Item Block',


                [AppResources.VIEWDIAGNOSIS]: 'View Diagnosis',
                [AppResources.EDITDIAGNOSIS]: 'Edit Diagnosis',
                [AppResources.DOWNLOAD_SAMPLE]: 'Download Sample',
                [AppResources.VIEWPAYMENT]: 'View Payment',


                [AppResources.PLANS]: 'Plans',
                [AppResources.INSURANCEPOLICIES]: 'Insurance policies',
                [AppResources.SYSTEM_SETUP]: 'System Setup',
                [AppResources.POLICIES]: 'Policies',
                [AppResources.POLICYLISTS]: 'Policy Lists',
                [AppResources.ADDPOLICIESLISTS]: 'Add Policy',
                [AppResources.EDITPOLICIESLISTS]: 'Edit Policy',
                [AppResources.VIEWPOLICIESLISTS]: 'View Policy',
                [AppResources.ENCOUNTERS]: 'Encounters',
                [AppResources.ADDCLAIM]: 'Add Claim',
                [AppResources.EDITCLAIM]: 'Edit Claim',
                [AppResources.VIEWCLAIM]: 'View Claim',
                [AppResources.LOCAION]: 'Location',
                [AppResources.FILTER]: 'filter',
                [AppResources.GENDER]: 'Gender',
                [AppResources.JOINING_DATE]: 'Join Date',
                [AppResources.TERMINATE_DATE]: 'Terminate Date',
                [AppResources.TOTAL_HOURS]: 'Total Hours',
                [AppResources.EMPLOYEE_TYPE]: 'Employee Type',


                [AppResources.WEEK_ONE]: 'First Week',
                [AppResources.WEEK_TWO]: 'Second Week',
                [AppResources.WEEK_THREE]: 'Third Week',
                [AppResources.WEEK_FOUR]: 'fourth week',
                [AppResources.WEEK_FIVE]: 'Fifth Week',

                [AppResources.AR_FIRSTNAME]: 'Arabic First Name',
                [AppResources.AR_SECONDNAME]: 'Arabic Second Name',
                [AppResources.AR_thirdAME]: 'Arabic Third Name',
                [AppResources.AR_FourthNAME]: 'Arabic Family Name',
                [AppResources.POSITION]: 'Position',
                [AppResources.IS_MANGER]: 'IS Manger?',
                [AppResources.IS_SYSTEM_ADMIN]: 'Is System Admin?',
                [AppResources.LOGOUT]: 'Logout',
                [AppResources.MANAGER]: 'Manager',
                [AppResources.CREATE_EMP]: 'Create Employee',
                [AppResources.EDIT_EMP]: 'Update Employee',
                [AppResources.SINGLE]: 'Single',
                [AppResources.MARRIED]: 'Married',
                [AppResources.DIVORCED]: 'Divorced',
                [AppResources.WIDOWER]: 'Widower',
                [AppResources.UNSELECT]: 'Unselect',
                [AppResources.ADD_CHARGE_CODE_CONF]: 'Are You sure you want to add charge code',




                [AppResources.EN_FIRSTNAME]: 'English First Name',
                [AppResources.EN_SECONDNAME]: 'English Second Name',
                [AppResources.EN_thirdAME]: 'English Third Name',
                [AppResources.EN_FourthNAME]: 'English Family Name',
                [AppResources.TITLE]: 'TITLE',
                [AppResources.MATERIAL_STATUS]: 'Marital Status',
                [AppResources.QUARTRY]: 'Quarterly',
                [AppResources.MONTHLY]: 'Monthly',
                [AppResources.YEARLY]: 'Yearly',
                [AppResources.WEEKLY]: 'Weekly',
                [AppResources.MALE]: 'Male',
                [AppResources.FEMALE]: 'Female',

                // Start Reports 
                [AppResources.REPORT]: 'Collection Report',
                [AppResources.COLLECTIONREPORT]: 'Collection Report',
                [AppResources.AGINGREPORT]: 'Aging Report',
                [AppResources.DICTIONARIES]: 'Dictionaries',
                [AppResources.ENCOUNTERS_CLAIMS]: 'Encounters',
                [AppResources.BENEFITS]: 'Benefits',
                [AppResources.ADDBENEFIT]: 'Add Benefit',
                [AppResources.EDITBENEFIT]: 'Edit Benefit',
                [AppResources.VIEWBENEFIT]: 'View Benefit',
                [AppResources.ADD_NEW_BENEFIT]: 'Add New Benefit',

                [AppResources.PATIENT]: 'Patient',
                [AppResources.CREATEPATIENT]: 'Create Patient',
                [AppResources.EDITPATIENT]: 'Edit Patient',
                [AppResources.VIEWPATIENT]: 'View Patient',
                [AppResources.PATIENT_ID]: 'Patient ID',
                [AppResources.ADD_NEW_PATIENT]: 'Add New Patient',

                [AppResources.SUMMARY]: 'Summary',
                [AppResources.REJECTION]: 'Rejection',
                [AppResources.REJECTED]: 'Rejected',
                [AppResources.SUBMITTE]: 'Submit',
                [AppResources.PENDING]: 'Pending',
                [AppResources.SUBMITTED]: 'Submitted',
                [AppResources.FOLLOWUPS]: 'Follow Ups',
                [AppResources.HOSPITALCODE]: 'Hospital Code',
                [AppResources.SERVICEITEMS]: 'Service Items',
                [AppResources.CLOSE]: 'Close',
                [AppResources.ADDSERVICEITEMS]: 'Add Service Items',
                [AppResources.EDITSERVICEITEMS]: 'Edit Service Items',
                [AppResources.VIEWSERVICEITEMS]: 'View Service Items',
                [AppResources.POLICYMANAGEMENT]: 'Policy management',
                [AppResources.ENCOUNTER]: 'Encounter',
                [AppResources.CONFIRMATION]: 'Confirmation',
                [AppResources.CHANGE_STATUS_CONFIRMATION_MSG]: 'Are you sure you want to change the status?',
                [AppResources.EMPLOYEESPROCEDURES]: 'Employees Procedures',
                [AppResources.VIEWEMPLOYEEPROCEDURE]: 'View Employee Procedure',
                [AppResources.JOBSPROCEDURES]: 'Jobs Procedures',
                [AppResources.SALARYRALLIES]: 'Salary Rallies',
                [AppResources.VIEWSALARYRALLIES]: 'View Salary Rallies',
                [AppResources.VACATIONS]: 'Vacations',
                [AppResources.VIEWVACATIONS]: 'View Vacations',

                [AppResources.QUALIFICATIONS]: 'Qualifications',
                [AppResources.VIEWQUALIFICATIONS]: 'View Qualifications',

                [AppResources.REVIEWS]: 'Reviews',
                [AppResources.VIEWREVIEWS]: 'View Reviews',

                [AppResources.ADDSERVICEITEMS]: 'Add Service Items',
                [AppResources.EDITSERVICEITEMS]: 'Edit Service Items',
                [AppResources.VIEWSERVICEITEMS]: 'View Service Items',
                [AppResources.POLICYMANAGEMENT]: 'Policy management',
                [AppResources.ENCOUNTER]: 'Encounter',
                [AppResources.PERSONALINFORMATION]: 'Personal Information',
                [AppResources.JOBDATA]: 'Job Data',
                [AppResources.VACATIONDATA]: 'Vacation Data',
                [AppResources.PERFORMANCEDATA]: 'Performance Data',
                [AppResources.RATINGDATA]: 'Rating Data',
                [AppResources.SALARYDATA]: 'Salary Data',
                [AppResources.CONFIRMATION]: 'Confirmation',
                [AppResources.LOCATIONDATA]: 'Other',
                [AppResources.CHANGE_STATUS_CONFIRMATION_MSG]: 'Are you sure you want to change the status?',
                [AppResources.EMPLOYEESPROCEDURES]: 'Employees Procedures',
                [AppResources.VIEWEMPLOYEEPROCEDURE]: 'View Employee Procedure',
                [AppResources.JOBSPROCEDURES]: 'Jobs Procedures',
                [AppResources.SALARYRALLIES]: 'Salary Rallies',
                [AppResources.VIEWSALARYRALLIES]: 'View Salary Rallies',
                [AppResources.VACATIONS]: 'Vacations',
                [AppResources.VIEWVACATIONS]: 'View Vacations',
                [AppResources.QUALIFICATIONS]: 'Qualifications',
                [AppResources.VIEWQUALIFICATIONS]: 'View Qualifications',
                [AppResources.REVIEWS]: 'Reviews',
                [AppResources.VIEWREVIEWS]: 'View Reviews',

                [AppResources.EMPLOYEESREPORTS]: 'Employees Report',
                [AppResources.PERFORMANCEREPORTS]: 'Performance Report',
                [AppResources.REQUESTS_MANAGEMENT]: 'Requests Management',
                [AppResources.APPROVAL]: 'Approval',
                [AppResources.DETAILED_REPORT]: 'Detailed Report',
                [AppResources.CATEGORIES_MATCHING_MANAGEMENT]: 'Categories Matching Management',
                [AppResources.REPORTS_MANAGEMENT]: 'Reports Management',
                [AppResources.RESEND]: 'Resend',
                [AppResources.COMMITMENT_RATE]: 'Commitment Rate',
                [AppResources.PROCEDURES_TYPES]: 'Procedures Types',

                [AppResources.SYSTEM_PROPERTIES_TITLE]: 'System Properties',
                [AppResources.MATCHING_DATA]: 'Matching Data',
                [AppResources.VIEWJOBPROCEDURE]: 'View Job Procedure',
                //Start

                [AppResources.CONFIGURATIONS]: 'Configurations',
                [AppResources.ORGANIZATION_STRUCTURE]: 'Organization Structure',
                [AppResources.REVENUE_STREAMS]: 'Revenue Stream',
                [AppResources.ADD_NEW_CHARGE_CODES]: 'Add New charge Code',
                [AppResources.PROJECTS]: 'Project',
                [AppResources.PROJECT_NUMBER]: 'Project Number',
                [AppResources.CHARGE_CODES]: 'Charge Code',
                [AppResources.ACTIVE]: 'Active',
                [AppResources.CUSTOMER]: 'Customer',
                [AppResources.IN_ACTIVE]: 'Inactive',
                [AppResources.EMPLOYEES]: 'Employee',
                [AppResources.EXAMPLE]: 'Example',
                [AppResources.EMPLOYEE_TIME_SHEET]: 'My Time Sheet',
                [AppResources.AGREE]: 'Agree',
                [AppResources.DISAGREE]: 'Disagree',
                [AppResources.WUTHDRAWAL]: 'Withdraw',
                [AppResources.TODAY]: 'Today',
                [AppResources.PREVIOUS]: 'Previous',
                [AppResources.NEXT]: 'Next',
                [AppResources.DEPARTMENT]: 'Department',
                [AppResources.EMPLOYEE_DEPARTMENT]: 'Emp.Dep.',
                [AppResources.UPDATE]: 'Update',
                [AppResources.SUMMARY_REPORT]: 'Summary Report',
                [AppResources.GENERAL_REPORT]: 'General Report',
                [AppResources.COST_REPORT]: 'Cost Report',
                [AppResources.TOTAL]: 'Total',
                [AppResources.COPY_WEEK]: 'Copy Last Week',
                [AppResources.SOME_THING_WENT_WRONG]: 'The Username or password is not correct.',
                [AppResources.DELETED_DEILY_WORK_ROW]: 'Are You sure you want to delete this row',
                [AppResources.DRAFT]: 'Draft',
                [AppResources.APPROVED]: 'Approved',
                [AppResources.APPROVE]: 'Approve',

                [AppResources.CREATED_SUCCESS]: 'Created Successfully',
                [AppResources.DELETED_SUCCESS]: 'Deleted successfully',
                [AppResources.UPDATED_SUCCESS]: 'Updated successfully',
                [AppResources.DELETE_CONFIRMATION_MESSAGE]: 'Are you sure you want to delete this record?',
                [AppResources.OOPS]: "!Oops",
                [AppResources.HOME_PAGE]: 'Home Page',
                [AppResources.MSG404]: "404 - This page can't be found",
                [AppResources.SOMETHING_WENT_WRONG_MESSAGE]: 'Something went wrong',
                [AppResources.SOME_THING_WENT_WRONG]: 'Something went wrong',
                [AppResources.ADMINISTRATIVE]: 'Administrative',
                [AppResources.BILLABLE]: 'app.billable',
                [AppResources.DEVELOPMENT]: 'Development',
                [AppResources.OTHERS]: 'Others',
                [AppResources.CHARGECODE_ALREDY_EXIST]: 'ChargeCode Already Exists',
                [AppResources.CHANGE_PASSWORD]: 'Change Password',
                [AppResources.REJECT_REASON]: 'Reject Reason',
                [AppResources.OLD_PASSWORD]: 'Old Password',
                [AppResources.NEW_PASSWORD]: 'New Password',
                [AppResources.CONFIRM_PASSWORD]: 'Confirm Password',
                [AppResources.FORGET_PASSWORD]: 'Forget My Password',
                [AppResources.WELCOME_LOGIN]: 'Welcome to X4Fatoora Content Navigator',
                [AppResources.SEND]: 'Send',
                [AppResources.RESET_PASSWORD]: 'Reset Password',
                [AppResources.RESET_PASSWORD_MESSAGE]: 'A password reset link has been sent',
                [AppResources.HOURS_VALIDATIONS_MESSAGE]: 'Total hours should not be less than 40 hours',
                [AppResources.NUMBER]: 'Number',
                [AppResources.NO_ACTIONS]: 'No Action',
                [AppResources.DIRECTREPORTEES]: 'Direct Reportees',
                [AppResources.TOTAL_HOURS_8_Ratio]: '8H Ratio',
                [AppResources.HOURS_Ratio]: 'Hour Ratio',
                [AppResources.UNLOCK]: 'Unlock account',
                [AppResources.CONFIGURATION_HOUR]: 'Configuration hour',
                [AppResources.HOURS]: 'Hours',



                /////////////////////////////// Start X4Fatoora
                [AppResources.ORGANIZATION_MANAGEMENT]: 'Organizations',
                [AppResources.INVOICES_BOOK_MANAGEMENT]: 'Invoices Books',
                [AppResources.FAWATEER]: 'Fawateer',
                [AppResources.ONBOARDING]: 'Onboarding',
                [AppResources.SIMULATION]: 'Simulation',
                [AppResources.PRODUCTION]: 'Production',

                [AppResources.ADD_ORGANIZATION]: 'Add Organization',
                [AppResources.EDIT_ORGANIZATION]: 'Edit Organization',
                [AppResources.VIEW_ORGANIZATION]: 'View Organization',
                [AppResources.PROVINCE]: 'Province',
                [AppResources.ADDITIONAL_STREET]: 'Additional Street',
                [AppResources.BASIC_INFO]: 'Basic Information',

                [AppResources.BUSINESS_CATEGORY]: 'Business Category',
                [AppResources.FIRST_NAME]: 'Name',
                [AppResources.IDENTITY_TYPE]: 'Identity Type',
                [AppResources.IDENTITY_NUMBER]: 'Identity Number',
                [AppResources.VAT_REGISTRATION_NUMBER]: 'VAT Registration Number',
                [AppResources.VAT_REGISTRATION]: 'VAT Registration',
                [AppResources.TAX_IDENTIFICATION_NUMBER]: 'Tax Identification Number',
                [AppResources.CURRENCY]: 'Currency',
                [AppResources.LOGO]: 'Logo',
                [AppResources.UPLOAD_IMAGE_ERROR]: 'The File Is Not Of Type Image',

                [AppResources.INVOICES]: 'Invoices',
                [AppResources.ADD_INVOICES_BOOK]: 'Add Invoices Book',
                [AppResources.EDIT_INVOICES_BOOK]: 'Edit Invoices Book',
                [AppResources.VIEW_INVOICES_BOOK]: 'View Invoices Book',
                [AppResources.CREATE_NEW_INVOICES_BOOK]: 'Create New Invoices Book',

                [AppResources.INVOICING_TYPE]: 'Invoicing Type',
                [AppResources.ORGANIZATION]: 'Organization',
                [AppResources.ORGANIZATION_ID]: 'Organization Id',
                [AppResources.LANGUAGE]: 'Language',
                [AppResources.PREFERRED_ADDRESS]: 'Preferred Address',
                [AppResources.TAGS]: 'Tags',
                [AppResources.INVOICE_BOOK_DETAILS]: 'Invoice Book Details',
                [AppResources.INVOICE_TYPE]: 'Invoice Type',
                [AppResources.ONBOARD]: 'ONBOARD',
                [AppResources.RENEW]: 'RENEW',
                [AppResources.OTP_VERIFICATION]: 'OTP Verification',
                [AppResources.OTP_MSG]: 'Please enter the OTP code from Fatoora to continue.',
                [AppResources.OTP_MSG_ERROR]: ' Please enter the OTP code from Fatoora to complete the process',


                [AppResources.VALIDATE]: 'Validate',
                [AppResources.OTP]: 'OTP',

                [AppResources.INVOICE_NUMBER]: 'Invoice Number',
                [AppResources.ISSUE_DATE]: 'Issue Date',
                [AppResources.INVOICES_BOOK]: 'Invoices Book',
                [AppResources.AMOUNT]: 'Amount',
                [AppResources.DISCOUNT]: 'Discount',
                [AppResources.TAX]: 'Tax',
                [AppResources.TOTAL_AMOUNT]: 'Total Amount',

                [AppResources.INVOICE_DATE_FROM]: 'Invoice Date From',
                [AppResources.INVOICE_DATE_TO]: 'Invoice Date To',
                [AppResources.CLIENT_ID]: 'Customer Number',
                [AppResources.SERIAL_NUMBER]: 'Serial Number',
                [AppResources.SEQUENCE_NUMBER]: 'Sequence Number',
                [AppResources.TAX_ID]: 'Tax Id',
                [AppResources.CLIENT_VAT_NUMBER]: 'Client VAT Number',
                [AppResources.VIEW_REPORT]: 'View Report',

                [AppResources.CREATE_NEW_INVOICES_BOOK_ONBOARDING]: 'Create New Invoices Book Onboarding',
                [AppResources.ADD_ONBOARDING]: 'Add Onboarding',
                [AppResources.EDIT_ONBOARDING]: 'Edit Onboarding',
                [AppResources.VIEW_ONBOARDING]: 'View Onboarding',
                [AppResources.ONBOARDING_PROGRESS]: 'Onboarding Progress',
                [AppResources.PROGRESS]: 'Progress',

                [AppResources.PROCEED]: 'Proceed',
                [AppResources.RETRY]: 'Retry',
                [AppResources.WARNING_LIST]: 'Warning List',
                [AppResources.ERROR_LIST]: 'Error List',
                [AppResources.INFO_LIST]: 'Info List',
                [AppResources.CREATED]: 'Created',
                [AppResources.ONBORDING_PENDING]: 'Onbording Pending',
                [AppResources.READY]: 'Ready',
                [AppResources.NEW]: 'New',
                [AppResources.CSR_GENERATION]: 'CSR generation',
                [AppResources.COMPLIANCE_CSID]: 'Compliance CSID',
                [AppResources.COMPLIANCE_CHECKS]: 'Compliance Checks',
                [AppResources.PRODUCTION_CSID]: 'Production CSID',
                [AppResources.COMPLETED]: 'Completed',
                [AppResources.FAILED]: 'Failed',
                [AppResources.NOT_STARTED]: 'Not Started',

                [AppResources.FAWATEER_LIST]: 'Fawateer List',
                [AppResources.B2B_B2C_ERROR_MESSAGE]: 'You must activate at least one of the B2B or B2C',
                [AppResources.SIMULATOR_PRODUCTION_ERROR_MESSAGE]: "You must activate at least one of the 'Activate in Simulation' or 'Activate in Production' categories",
                [AppResources.IS_DEFAULT]: "Default",
                [AppResources.ALREADY_HAVE_DEFAULT]: "You already have a default address",
                [AppResources.MIN_2_MAX_2000]: "This filed accepts minimum 2 characters and maximum 2000 characters",
                [AppResources.MIN_1_MAX_1000]: "This filed accepts minimum 1 characters and maximum 1000 characters",
                [AppResources.MIN_1_MAX_127]: "This filed accepts minimum 1 characters and maximum 127 characters",
                [AppResources.MIN_0_MAX_127]: "This filed accepts minimum 0 characters and maximum 127 characters",
                [AppResources.MIN_2_MAX_100]: "This filed accepts minimum 2 characters and maximum 100 characters",
                [AppResources.MIN_2_MAX_30]: "This filed accepts minimum 2 characters and maximum 30 characters",
                [AppResources.MIN_2_MAX_20]: "This filed accepts minimum 2 characters and maximum 20 characters",
                [AppResources.EQUAL_TO_10]: "Your input have to be equal 10 digits.",
                [AppResources.MIN_2_MAX_10]: "This filed accepts minimum 2 characters and maximum 10 characters",
                [AppResources.MIN_2_MAX_5]: "Your input have to be more than 2 Chars and less than 5.",
                [AppResources.MIN_1_MAX_3]: "This filed accepts minimum 1 digit and maximum 3 digits",
                [AppResources.VAT_NUMBER_ERROR]: "VAT Number should start with 3 and end with 3 and consist of 15 digits",
                [AppResources.ORDER_NUMBER]: "This field only accepts numerical input, with a range of 1 to 3 digits, excluding zero.",
                [AppResources.ORGANIZATION_DETAILS]: "Organization Details",
                [AppResources.COMMERCIAL_REGISTRATION_NUMBER]: "Commercial Registration Number",
                [AppResources.MOMRA]: "MOMRAH license",
                [AppResources.MLSD]: "MHRSD license",
                [AppResources.CODE_700]: "700 Number",
                [AppResources.SAGIA]: "MISA license",
                [AppResources.NATIONAL_ID]: "National Id",
                [AppResources.GCC]: "GCC",
                [AppResources.IQAMA]: "IQAMA",
                [AppResources.PASSPORT]: "Passport",
                [AppResources.OTHER_ID]: "Other ID",
                [AppResources.START]: "Start",
                [AppResources.ADDRESS_NAME]: "Address Name",
                [AppResources.SA]: "Saudi Arabia",
                [AppResources.DEBIT_NOTE]: "Debit Note",
                [AppResources.CREDIT_NOTE]: "Credit Note",
                [AppResources.TAX_INVOICE]: "Tax Invoice",
                [AppResources.REPORTED]: "Reported",
                [AppResources.REPORTED_WITH_WARNINGS]: "Reported With Warnings",
                [AppResources.CLEARED]: "Cleared",
                [AppResources.CLEARED_WITH_WARNINGS]: "Cleared With Warnings",

                [AppResources.CSR_GENERATED]: "CSR Generated",
                [AppResources.CSR_GENERATION_FAILED]: "CSR Generation Failed",
                [AppResources.COMPLIANCE_CSID_OBTAINED]: "Compliance CSID Obtained",
                [AppResources.COMPLIANCE_CSID_FAILED]: "Compliance CSID Failed",
                [AppResources.COMPLIANCE_CHECK_DONE]: "Compliance Check Done",
                [AppResources.COMPLIANCE_CHECK_FAILED]: "Compliance Check Failed",
                [AppResources.PRODUCATION_CSID_FAILED]: "Producation CSID Failed",
                [AppResources.RENEWAL_PRODUCTION_CSID_FAILED]: "Renewal Production CSID Failed",
                [AppResources.EXPIRED]: "Expired",
                [AppResources.ONBOARDING_PENDING]: "Onboarding Pending",
                [AppResources.ONBOARDING_FAILED]: "Onboarding Failed",
                [AppResources.PROD_CSID_EXPIRED]: "Prod CSID Expired",
                [AppResources.PROD_CSID_RENEWAL_PENDING]: "Prod CSID Renewal Pending",
                [AppResources.PROD_CSID_RENEWAL_FAILED]: "Prod CSID Renewal Ffailed",
                [AppResources.IS_10_DIGITS]: "Your input have to be 10 digits.",
                [AppResources.IS_5_DIGITS]: "Your input have to be 5 digits.",
                [AppResources.IS_4_DIGITS]: "Your input have to be 4 digits.",
                [AppResources.TIN]: "Tax Identification Number (TIN)",
                [AppResources.ADDRESS_ADDITIONAL_NUMBER]: "Your input have to be 4 digits.",
                [AppResources.IDENTITY_ID_NOT_UNIQUE]: "Identity number is not unique",
                [AppResources.PRIMARY]: "Primary",
                [AppResources.NOT_PRIMARY]: "Not Primary",
                [AppResources.INVALID_INPUT]: "Invalid input",
                [AppResources.PRIMARY_ADDRESS_REQUIRED]: "You have to insert a primary address",
                [AppResources.METADATA]: "MetaData",
                [AppResources.ATTRIBUTE_NAME]: "Attribute Name",
                [AppResources.ATTRIBUTE_DESCRIPTION]: "Attribute Description",
                [AppResources.PRIORITY]: "Proiority",
                [AppResources.VISIBLE]: "Visible",
                [AppResources.ORDER_EXIST_ERROR]: "The inserted order is already exsit in your metaData orders.",
                [AppResources.ROLES_MANAGEMENT]: "Roles Management", 
                [AppResources.LOGGERS_MANAGEMENT]: "Logger Management", 
                [AppResources.ADD_ROLE]: "Add Role",
                [AppResources.LEVEL]: "Level",
                [AppResources.SAVED_SUCCESSFULLY]: "Saved Successfully",
                [AppResources.MODULE]: "Module",
                [AppResources.ROLE_INFO]: "Role Info",
                [AppResources.ARABIC_NAME]: "Arabic Name",
                [AppResources.ROLE_PERMISSIONS]: "Role Permissions",
                [AppResources.CREATEROLE]: "Create Role",
                [AppResources.EDITROLE]: "Edit Role",
                [AppResources.VIEW_USER]: "View User",
                [AppResources.ADD_USER_ROLE]: "Add User Role",
                [AppResources.USERSMANAGEMENT]: "Users Management",
                [AppResources.ROLES]: "Roles",
                [AppResources.USER]: "user",
                [AppResources.ADD_USER]: "Add New User",
                [AppResources.USERS_MANAGEMENT]: "Users Management",
                [AppResources.ORG_USERS_MANAGEMENT]: "Users Management",
                [AppResources.EDIT_USER]: "Edit User",
                [AppResources.UNIVERSAL_ORG_ACCESS]: 'Universal Org Access',
                [AppResources.ORGANIZATION_CONTEXT]: 'Organization context',
                [AppResources.TENANT_CONTEXT]: 'Tenant context',
                [AppResources.ADD_ROLES]: 'Add Roles',
                [AppResources.TENANT_LEVEL]: 'Tenant Level',
                [AppResources.ORGANIZATION_LEVEL]: 'Organization Level',
                [AppResources.TENANT]: 'Tenant',
                [AppResources.ORGANIZATIONS]: 'Organizations',
                [AppResources.IS_OFF_LINE]: 'is off-line',
                [AppResources.OFF_LINE]: 'off-line',
                [AppResources.ON_LINE]: 'on-line',
                [AppResources.PLACEMENT]: 'Placement',
                [AppResources.BELONGS_TO]: 'Belongs To',
                [AppResources.ORDER]: 'Order',
                [AppResources.ERRORS]: 'ERRORS',
                [AppResources.WARNINGS]: 'Warnings',
                [AppResources.PHONE_NUMBER_ERROR]: 'Please enter a valid number',
                [AppResources.LOGO_50KB_SIZE_ERROR]: 'The logo image file should not exceed 50 kilobytes (KB).',
                [AppResources.LOGO_SIZE_ERROR]: 'The logo image file should not exceed the maximum size.',
                [AppResources.LOGO_DIMENSIONS_ERROR]: 'The height and width of the logo image should not exceed the maximum dimensions.',
                [AppResources.LOGO_320_DIMENSIONS_ERROR]: 'The height and width of the logo image should be 320 pixels by 320 pixels',
                [AppResources["403"]]: 'Forbidden',
                [AppResources.USED_ROLE_ERROR]: 'You are unable to delete a role that has been utilized.',
                [AppResources.DUPLICATE_ROLE_ERROR]: 'A role with the same name already exists. Please choose or enter a different value',
                [AppResources.DUPLICATE_USER_ERROR]: 'The user already exists',
                [AppResources.USER_ADDED_SUCCESSFULLY]: 'User has been successfully added',
                [AppResources.MESSAGE_ID]: 'Message Id',
                [AppResources.MESSAGE]: 'Message',
                [AppResources.DIRECTION]: 'Direction level',
                [AppResources.LOG_LEVEL]: 'Log level',
                [AppResources.DURATION]: 'Duration',
                [AppResources.IS_INTERNAL]: 'Is Internal' ,
                [AppResources.REQUEST_HEADERS]: 'Request Headers',
                [AppResources.REQUEST_BODY]: 'Request Body', 
                [AppResources.RESPONSE_BODY]: 'Response Body',
                [AppResources.INNER_REQUEST]: 'Inner Requests', 
                [AppResources.HOST]: 'Host',
                [AppResources.VIEW_LOGGER]: 'View Logger',
                [AppResources.TAX_CALCULATION_TOLERANCE]: 'Tax Calculation Tolerance',
                [AppResources.NUMBER_OPTIONAL]: 'Please insert numbers only.',
                [AppResources.MAX_TAX_CALCULATION_TOLERANCE_GREATER_ERROR]: 'Please insert a number less than ',
                [AppResources.MIN_TAX_CALCULATION_TOLERANCE_SMALLER_ERROR]: 'Please insert a number greater than ',
                [AppResources.RESPONSE_ERROR]: 'Response Error',
                [AppResources.VALIDATION_LOG]: 'Validation Log',
                [AppResources.SOURCE]: 'Source',
                [AppResources.SOURCES]: 'Sources',
                [AppResources.SOURCE_NAME]: 'Source Name',
                [AppResources.VALUE]: 'Value',
                [AppResources.TRANSACTION_TYPE]: 'Transaction Type',
                [AppResources.INVOICE_DETAILS]: 'Invoice Details',
        }
};

export default enUs;