import { FieldModel } from '../../../Models';

export class ConnectorDbManagementSearchModel {
  
  referenceNumber: any;
  sourceId: any;
  invoiceDateFrom: any;
  invoiceDateTo: any;

  public static getFieldsModel(model?: ConnectorDbManagementSearchModel): any {
    model = new ConnectorDbManagementSearchModel();
    let fildsObject = FieldModel.buildFromObject(model);
    fildsObject.referenceNumber.value = "";
    fildsObject.sourceId.value = "";
    fildsObject.invoiceDateFrom.vaule = null;
    fildsObject.invoiceDateTo.vaule = null;
    return fildsObject;
  }

  public static toDBModel(searchForm: ConnectorDbManagementSearchModel) {
     
    let referenceNumber = searchForm.referenceNumber?.value;
    let sourceId = searchForm.sourceId?.value;
    let invoiceDateFrom = searchForm.invoiceDateFrom?.value;
    let invoiceDateTo = searchForm.invoiceDateTo?.value;

    return {
      referenceNumber:  referenceNumber,
      sourceId:  sourceId,
      invoiceDateFrom: invoiceDateFrom == "" ? null : invoiceDateFrom,
      invoiceDateTo: invoiceDateTo == "" ? null : invoiceDateTo,
    };
  }
}