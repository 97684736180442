import React, { useEffect, useState } from 'react';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme as MainThemeVariables } from '../../Config/Theme/ThemeVariables';
import Pagination from '../../Components/Pagination';
import { CardListDeclarations } from './index.d';
import useTranslation from '../../Hooks/Shared/useTranslation';
import { getUILanguage } from '../../Store/Reducers/UI';
import EmptyTableComp from '../../Controls/EmptyTable';
import Locales from '../../Services/Internationalization/Locales';

const CardList = ({ ListData = [], labels, pagination, changePageApi = undefined, md = 4}: CardListDeclarations) => {

    //#region :: State
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [data, setData] = useState<Array<any>>([]);
    const currentLanguage = getUILanguage(state)

    //#region :: listData change [useEffect]
    useEffect(() => {
        if (!ListData) return;
        let arr: Array<any> = [];
        ListData.map((item: any) => arr.push({
            ...item,
        }));
        setData(arr);
    }, [ListData]);
    //#endregion

    return (
        data && data.length <= 0
            ?
            <EmptyTableComp />
            : <Grid item container spacing={1}>
                {data && data.map((item: any, index: number) => {
                    return <Grid key={index} item md={md} xs={12}>
                        <StyledCard>
                            <StyledCardContent>
                                <Grid item container spacing={1}>
                                    <Grid item container spacing={1}>
                                        {labels.map((label: any, i: number) => {
                                            const column = label.id;
                                            return (item[column] &&
                                                <StyledGridValues key={i} item>
                                                    <StyledDetails>
                                                        {item[column] &&
                                                            <Tooltip title={item[column]} placement={currentLanguage == Locales.ARABIC ? "top-end" : "top-start"}>
                                                                <>
                                                                    {
                                                                        label.id == "img" ?
                                                                            <Grid style={{ position: "absolute", right: '10px', top: '10px', width: "100%" }}>{item[column]}</Grid>
                                                                            :
                                                                            ''
                                                                    }
                                                                    {
                                                                        label.id == "img" || label.id == "action" ?
                                                                            ""
                                                                            :
                                                                            <StyledSpanValues>
                                                                                {label.label && label.label + " : "}
                                                                                {item[column]} </StyledSpanValues>
                                                                    }
                                                                    <StyledSpanImg>
                                                                        {label.id == "action" ? item[column] : ''} </StyledSpanImg>
                                                                </>
                                                            </Tooltip>}
                                                    </StyledDetails>
                                                </StyledGridValues>)
                                        })}
                                    </Grid>
                                </Grid>
                            </StyledCardContent>
                        </StyledCard>
                    </Grid>
                })}
                {pagination && <Pagination
                    totalresultText={T(Resources.AppResources.TOTAL_RESULTS)}
                    dataCount={pagination.totalCount}
                    totalCount={pagination.totalCount}
                    totalPages={pagination.totalPages}
                    pageIndex={pagination.pageIndex }
                    handleChange={(e: any, page: any) => changePageApi(page - 1)} 
                    />
                    }
            </Grid>
    );
};

//#region :: Styled Components
const StyledCardContent: any = styled(CardContent)({
    '&.MuiCardContent-root:last-child': {
        padding: 10,
        paddingBottom: 0
    },
    position: 'relative',
    paddingBottom: '5px!important'
});


const StyledDetails: any = styled.span(({ theme, cursor }: any) => ({
    color: "#838199",
    fontSize: '0.8rem',
    padding: '0px',
    marginTop: '0px',
    paddingInlineStart: '5px',
    // display: 'flex',
    alignItems: 'center',
    cursor: "default",
    width: '100% !important',

}));




const StyledCard: any = styled(MuiCard)`
    margin: ${({ spacing }: any) => spacing && spacing + 'px'};
    margin-top: 10px;
    width: 100%!important;
    box-shadow:0px 1px 2px 1px rgb(0 0 0 / 20%) !important;
    border-radius: 15px!important;
    height: 100%;
`;

const StyledSpanValues: any = styled.span`
    margin-left: 3px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // width: 60vw;
`;
const StyledSpanImg: any = styled.span`
    margin-left: 3px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100% !important;
`;

const StyledGridValues: any = styled(Grid)` 
width:100% !important;
    color:"black";
    padding: 0px;
    display: flex;
    justify-content: space-between;
`;



//#endregion

export default CardList;