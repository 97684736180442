import { Integer } from '../../../Config/Settings/Enums';
import { FieldModel } from '../../../Models';
import { GlobalService } from '../../../Services/global.service';

export class ReportModel {
    invoiceNumber?: any;
    invoiceType?: any;
    issueDate?: any;
    organization?: any;
    invoicesBook?: any;
    currency?: any;
    amount?: any;
    discount?: any;
    tax?: any;
    totalAmount?: any;

    public static getFieldsModel(model?: ReportModel): any {
        if (!model) model = new ReportModel();
        let fildsObject = FieldModel.buildFromObject(model);

        return fildsObject;
    }

    public static searchRequest() {
        return {
            pageIndex: 0,
            pageSize: Integer.Max,
            metaData: ''
        };
    }
}

export class ReportSearch {
    organization?: any;
    invoicesBook?: any;
    invoiceDateFrom?: any;
    invoiceDateTo?: any;
    invoiceNumber?: any;
    serialNumber?: any;
    sequenceNumber?: any;
    status?: any;
    
    // invoiceType?: any;
    // issueDate?: any;
    // currency?: any;
    // amount?: any;
    // discount?: any;
    // tax?: any;
    // totalAmount?: any;

    // clientId?: any;
    // phoneNumber?: any;
    // taxId?: any;
    // clientVatNumber?: any;


    public static getFieldsModel(model?: ReportSearch): any {
        if (!model) model = new ReportSearch();
        let fildsObject = FieldModel.buildFromObject(model);

        fildsObject.invoiceDateFrom.value = null;
        fildsObject.invoiceDateTo.value = null;
        fildsObject.status.value = 0;
        fildsObject.organization.value = GlobalService.CurrentActiveOrganization?.id;

        return fildsObject;
    }

    public static toDBModel(model: any) {
        let organizationsId = model.organization?.value;
        let invoiceBookId = model.invoicesBook?.value?.id;
        let invoiceDateFrom = model.invoiceDateFrom?.value;
        let invoiceDateTo = model.invoiceDateTo?.value;
        let invoiceNumber = model.invoiceNumber.value;
        let serialNumber = model.serialNumber.value;
        let sequenceNumber = model.sequenceNumber.value;
        let status = (model.status.value && model.status.value != "0") ?  parseInt(model.status.value) : null;

        // let invoiceType = model.invoiceType.value;
        // let issueDate = model.issueDate.value;
        // let currency = model.currency.value;
        // let amount = model.amount.value;
        // let discount = model.discount.value;
        // let tax = model.tax.value;
        // let totalAmount = model.totalAmount.value;

        // let clientId = model.clientId.value;
        // let phoneNumber = model.phoneNumber.value;
        // let taxId = model.taxId.value;
        // let clientVatNumber = model.clientVatNumber.value;




        return {
            organizationsId,
            invoiceBookId,
            invoiceDateFrom,
            invoiceDateTo,
            invoiceNumber,
            serialNumber,
            sequenceNumber,
            status,

            // invoiceType,
            // issueDate,
            // currency,
            // amount,
            // discount,
            // tax,
            // totalAmount,
            // clientId,
            // phoneNumber,
            // taxId,
            // clientVatNumber,


        };
    }


}
