import React, { useEffect } from 'react'
import CardList from '../../../Components/CardList'
import useInvoicesBookListHook from './hooks/useInvoicesBookListHook'
import Search from './search';
import { Grid } from '@mui/material';
import Card from '../../../Controls/Card';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import Button, { ButtonsTypes } from '../../../Controls/Button';
import useTranslation from '../../../Hooks/Shared/useTranslation';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import MoveVert from './Shared/MoveVert';
import { PermissionScopes, PermissionsService } from '../../../Services/permissions.service';

const InvoicesBookManagement = () => {
  const navigate = useNavigate();
  const { T, Resources } = useTranslation();
  const { cardLabels, invoicesBookSource, pagination, changeCurrentPage } = useInvoicesBookListHook();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Search />
        </Grid>

        <Grid item xs={12}>
          <Card>
            {/* <Grid item xs={12} display="flex" justifyContent="end" marginBottom={1} >
              <Button
                variant="contained"
                color="primary"
                link={true}
                to={PagesRoute.PAGES.ADD_INVOICES_BOOK}
                type={ButtonsTypes.ADD}
                label={T(Resources.AppResources.ADD_INVOICES_BOOK)} />
            </Grid> */}

            <CardList
              ListData={invoicesBookSource}
              labels={cardLabels}
              pagination={pagination}
              changePageApi={changeCurrentPage} 
            />
          </Card>
        </Grid>
        {PermissionsService.hasPermission(PermissionScopes.ADD_INVOICES_BOOK_ORGANIZATION) && <Button
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => navigate(PagesRoute.PAGES.ADD_INVOICES_BOOK)}
          // buttonsAction={ButtonActions}
          type={ButtonsTypes.FLOATIONG} />}
        
      </Grid>

    </>
  )
}

export default InvoicesBookManagement