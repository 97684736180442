import './style.scss';

import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import DropDownInput from '../../../../Controls/DropDownInput';
import { GlobalService } from '../../../../Services/global.service';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserInfo, getIsTenantContext, setIsPermissionsUpdated } from '../../../../Store/Reducers/Auth';
import useTranslation from '../../../../Hooks/Shared/useTranslation';
import { TenantUsersManagementService } from '../../../../Services/API/TenantUsersManagement';
import { PermissionsServices } from '../../../../Services/API/Permissions';
// import { TaxDDList } from '../../../../Config/Settings/Lookups';

type Props = {
    handleClose: any;
    disabled: any;
};

const Organization = ({ handleClose, disabled }: Props) => {

    //#region :: State
    const { T, Resources } = useTranslation();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const isTenantContext = getIsTenantContext(state);
    const userInfo = GetUserInfo(state);
    const [selectedOrganization, setSelectedOrganization] = useState<any>({});
    const [orgsDataList, setOrgsDataList] = useState<Array<any>>([]);
    //#endregion

    //#region :: Handlers
    const handleInputChange = (event: any) => {

        var { name, value } = event.target;

        if (value == 0) {
            GlobalService.IsTenantContext = true;
            window.location.reload();
        } else {
            GlobalService.IsTenantContext = false;
            // const selectedOrg = orgsDataList.find(w => w.id == value);
            // setSelectedOrganization(selectedOrg);
            // doOnOk(selectedOrg);
            const selectedOrganization = value;

            window.dispatchEvent(new Event("storage"));
            setSelectedOrganization(selectedOrganization);
            doOnOk({ name: event.target?.selectedOptions[0].text || name, value });
        }
    };

    const doOnOk = async (selected: any = selectedOrganization) => {

        GlobalService.CurrentActiveOrganization = { id: selected.value, name: selected.name };
        GlobalService.AppUserAuthorizationInfo = await PermissionsServices.GetCurrentUserPermissions(GlobalService.CurrentActiveOrganization?.id);
        // window.location.reload();
        window.location.href = window.location.origin + "/" +  GlobalService.CurrentTenant;
        
    };
    
    const getOrgsDataList = async () => {
        
        let orgsListData = GlobalService.OrgsList || userInfo?.organizations;
        const isTenantContext = GlobalService.IsTenantContext;
        const isSystemManager = GlobalService.AppUser?.isSystemManager;

        if (GlobalService.AppUser?.id ) {
            const universalOrgAccess = {
                id: null,
                value: "Universal Org Access",
            };
            if( (GlobalService.RefetchOrgs || !(orgsListData && orgsListData.length))){

                const request = {
                    pageIndex: 0,
                    pageSize: 100,
                    searchText: null
                }
                const result = await TenantUsersManagementService.SearchOrganization(request, GlobalService.AppUser?.id);
                orgsListData = result?.data.body;
    
               
                if (isTenantContext)
                    orgsListData.push(universalOrgAccess);
                
    
                const current_organization = GlobalService.CurrentActiveOrganization;
                // 
                if (!current_organization) {
                    let tmpOrg = orgsListData[0];
                    if(tmpOrg) GlobalService.CurrentActiveOrganization = { id: tmpOrg.id, name: tmpOrg.value }
                }
    
                if(isTenantContext)
                    GlobalService.CurrentActiveOrganization = universalOrgAccess;
                
    
                
            }else{
                
                if ((isTenantContext) && !orgsListData.some(x => x.id == null)) 
                    orgsListData=[universalOrgAccess, ...orgsListData];

                if(!isTenantContext){
                    orgsListData = orgsListData.filter(x => x.id);
                    let tmpCurrentOrg = GlobalService.CurrentActiveOrganization;
                    if(!orgsListData.some(x => x.id == tmpCurrentOrg?.id)){
                        let tmpOrg = orgsListData[0];
                        if(tmpOrg) GlobalService.CurrentActiveOrganization = { id: tmpOrg.id, name: tmpOrg.value }
                    }
                }
            }

            GlobalService.AppUserAuthorizationInfo = await PermissionsServices.GetCurrentUserPermissions((GlobalService.CurrentActiveOrganization?.id && !isTenantContext));
            GlobalService.OrgsList = orgsListData;
            GlobalService.RefetchOrgs = false;
            dispatch(setIsPermissionsUpdated(true))
        }

        orgsListData && setOrgsDataList(orgsListData.map(itm => {
            return {
                id: itm?.id,
                label: itm?.value ?? itm?.label
            }
        }));
    }

    useEffect(() => {
        getOrgsDataList();
        //generateOrganizationList();
    }, [userInfo]);

    // const generateOrganizationList = () => {
    //     const listOfFaciltes: any[] = isTenantContext ? [{ id: 0, label: T(Resources.AppResources.UNIVERSAL_ORG_ACCESS) }] : [];
    //     let authInfo = GlobalService.AppUser;

    //     if (authInfo?.organizations) {
    //         for (const facility of authInfo?.organizations) {
    //             listOfFaciltes.push({ id: facility.id, label: facility.name });
    //         }
    //     }
    //     setOrgsDataList(listOfFaciltes);
    // };

    return (
        <Grid container>
            <Grid md={12} container spacing={1}>
                <Grid className='container-organization' marginTop={1} item xs={12}>
                    <DropDownInput
                        name='Organization'
                        value={disabled ? 0 : selectedOrganization?.id || GlobalService.CurrentActiveOrganization?.id || '0'}
                        showDefault={false}
                        options={orgsDataList}
                        translate={true}
                        isDisabled={disabled}
                        handleChange={(e: any) => handleInputChange(e)} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Organization;