import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';

import useTranslation from '../../../../../Hooks/Shared/useTranslation';
import RoleTableRow from './roleTableRow';
import { EnvLevelContext } from '../../../../../Config/Settings/Enums';

interface Props{
    itemsList: Array<any>;
    pagination: any;
    handleChangePage: Function;
    userId: any;
    userRolesList: any;
    level: number;
}

const RoleTable = ({itemsList, pagination, handleChangePage, userId, userRolesList: userRolesList, level}:Props) => {

  //#region :: State
  const { T, Resources } = useTranslation();
  //#endregion

  useEffect(()=>{

  },[])

    return(
        <table style={{ width: "100%" }} className="table-container">
          <thead className="table-header">
            <tr>
              <th align="center" style={{width: '10%'}}></th>
              <th align="center" style={{width: '10%'}}>{T(Resources.AppResources.ID)}</th>
              <th align="center" style={{width: '40%'}}>{T(Resources.AppResources.NAME)}</th>
              <th align="center" style={{width: '40%'}}>{T(Resources.AppResources.LEVEL)}</th>
              {level == EnvLevelContext.ORGANIZATION && <th align="center" style={{    maxWidth: "255px", width: "255px"}}>{T(Resources.AppResources.ORGANIZATIONS)}</th>}

            </tr>
          </thead>
          <tbody className="table-body">
            {itemsList.map((item, index) => {
              return (
               <RoleTableRow 
                  index={index} 
                  item={item} 
                  userId={userId}
                  userRolesList={userRolesList}
                  level={level}
                 />
              )
            })
            }
          <tr>
            <td colSpan={5} style={{border: 'unset'}}>
              <Grid container justifyContent={'space-between'} paddingTop={'0.8rem'}>
                    <span color='black'>{T(Resources.AppResources.TOTAL_RESULTS)} {pagination.totalCount}</span>
                    <Pagination style={{display: 'flex', justifyContent: 'center'}}
                        page={pagination.pageIndex}
                        count={pagination.totalPages}
                        onChange={(e: any, page: any) => handleChangePage(e, page)}
                        showFirstButton={true}
                        showLastButton={true}
                        size='small'
                        variant='text'
                        shape='rounded' />
                </Grid>
            </td>
          </tr>
          </tbody>
        </table>
    )
}

export default RoleTable;