import { Tooltip } from '@mui/material'
import React from 'react'

const CBadge = ({ children, expectedWith = "10%", bgColor = "transparent", title = "", id="" }: any) => {
    return (
        <Tooltip title={title} placement="top" >
            <span id={id} style={{
                display: "flex", alignItems: "center", justifyContent: "center", border: '1px solid rgba(0, 0, 0, 0.08)',
                width: expectedWith, height: '100%', padding: '0 3px', borderRadius: "5px", backgroundColor: bgColor
            }}>
                {children}
            </span>
        </Tooltip>
    )

}

export default CBadge