import DateFnsUtils from '@date-io/date-fns';
import HijriUtils from '@date-io/hijri';
import EventIcon from '@mui/icons-material/Event';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import 'moment/locale/ar-sa';
import { useState } from 'react';
import useTranslation from '../../Hooks/Shared/useTranslation';
// import '../Style/elements-styles.scss';
import styled from 'styled-components';
import './style.scss';

const DateInput = ({ isHijri = true, format, isDisabled = false, error = false, value, name, inputVariant, id, label, margin,
    handleChange, size, maxDate, minDate }: any) => {

    //#region :: State && Handlers
    const { T, Resources } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //#endregion

    return (
        <>
            {
                !isHijri ?
                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                        <StyledDatePicker
                            inputFormat={format}
                            open={open}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            label={label}
                            value={value}
                            defaultValue={null}
                            onChange={handleChange}
                            name={name}
                            inputVariant={inputVariant}
                            margin={margin}
                            id={id}
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={isDisabled}
                            size={size}
                            renderInput={(params: any) => (
                                <StyledTextField size="small"
                                    {...params}
                                    onClick={handleOpen}
                                    error={error}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            fontSize: '0.7rem',
                                            padding: '8.5px 14px'
                                        }
                                    }}
                                    InputLabelProps={{ style: { fontSize: '0.8rem', marginTop: '0px' } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton edge='end'>
                                                    <EventIcon style={{ width: '20px', height: '20px' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            okLabel={T(Resources.AppResources.OK)}
                            cancelLabel={T(Resources.AppResources.CANCEL)} />
                    </LocalizationProvider>
                    :
                    <LocalizationProvider dateAdapter={HijriUtils} locale='ar-sa'>
                        <StyledDatePicker
                            disabled={isDisabled}
                            open={open}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            labelFunc={(date: any) => (date ? date.format('iYYYY/iMM/iDD') : '')}
                            name={name}
                            size={size}
                            inputVariant={inputVariant}
                            margin={margin}
                            id={id}
                            value={value === '' ? moment() : value}
                            defaultValue={null}
                            onChange={handleChange}
                            minDate={minDate}
                            maxDate={maxDate}
                            renderInput={(params: any) => (
                                <StyledTextField size="small"
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            fontSize: '0.7rem',
                                            padding: '8.5px 14px'
                                        }
                                    }}
                                    InputLabelProps={{ style: { fontSize: '0.8rem', marginTop: '0px' } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton edge='end' onClick={handleOpen}>
                                                    <EventIcon style={{ width: '20px', height: '20px' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            KeyboardButtonProps={{
                                'aria-label': 'change date'
                            }}
                            okLabel={T(Resources.AppResources.OK)}
                            cancelLabel={T(Resources.AppResources.CANCEL)} />
                    </LocalizationProvider>
            }
        </>
    )
};

//#region :: Styled Components
const StyledDatePicker: any = styled(DatePicker)`
    width: 100%;
    margin: 16px;
`;

const StyledTextField: any = styled(TextField)`
    width: 100% !important;
`;
//#endregion

export default DateInput;