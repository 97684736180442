import { OnboardingProgress } from "../../Config/Settings/Enums";

export class OnboardingStatusModel {
    invoicesBookStatus?: StatusBaseModel;
    onboardingStatus?: StatusBaseModel;
    onboardingStage?: StatusBaseModel;
    preferredAddress? : any; //TODO Address model
    details?: any;



    public static getInfoMessages(model: OnboardingStatusModel) {
        var result = "";

        if (model && model.details && Array.isArray(model.details)) {
            model.details.forEach(d => {
                if (d.Result && d.Result.ValidationResults && d.Result.ValidationResults.InfoMessages && d.Result.ValidationResults.InfoMessages.length > 0) {
                    d.Result.ValidationResults.InfoMessages.forEach(im => {
                        result += im.Code + " - " + im.Message + "\r\n";
                    });
                }
            });
        }

        return result;
    }

    public static getWarningMessages(model: OnboardingStatusModel) {
        var result = "";

        if (model && model.details && Array.isArray(model.details)) {
            model.details.forEach(d => {
                if (d.Result && d.Result.ValidationResults && d.Result.ValidationResults.WarningMessages && d.Result.ValidationResults.WarningMessages.length > 0) {
                    d.Result.ValidationResults.WarningMessages.forEach(im => {
                        result += im.Code + " - " + im.Message + "\r\n";
                    });
                }
            });
        }

        return result;
    }

    public static getErrorMessages(model: OnboardingStatusModel) {
        var result = "";

        if (model && model.details) {
            if (typeof model.details === 'string') {
                result = model.details;
            } else if (Array.isArray(model.details)) {
                model.details.forEach(d => {
                    if (d.Result && d.Result.ValidationResults && d.Result.ValidationResults.ErrorMessages && d.Result.ValidationResults.ErrorMessages.length > 0) {
                        d.Result.ValidationResults.ErrorMessages.forEach(im => {
                            result += im.Code + " - " + im.Message + "\r\n";
                        });
                    }
                });
            }
        }

        return result;
    }

    public static getStageStatus(model: OnboardingStatusModel, stage: ONBOARDING_STAGE) {

        // case no data
        if (!model) return null;

        // case of ready and every thing is work then everything is compleated
        if (model.invoicesBookStatus?.code == INVOICES_BOOK_STATUS.READY) {
            return ONBOARDING_PROGRESS.COMPLETED;
        }


        // check "Created Stage" status
        if (stage == ONBOARDING_STAGE.CREATED) {
            // if (model.invoicesBookStatus?.code == INVOICES_BOOK_STATUS.CREATED 
            //     || model.invoicesBookStatus?.code == INVOICES_BOOK_STATUS.ONBOARDING_PENDING 
            //     || model.invoicesBookStatus?.code  == INVOICES_BOOK_STATUS.ONBOARDING_FAILED
            //     )
            return ONBOARDING_PROGRESS.COMPLETED;
            // else
            //     return ONBOARDING_PROGRESS.PENDING;
        }


        // check "Onboarding pending Stage" status
        if (stage == ONBOARDING_STAGE.ONBOARDING_PENDING) {
            if (model.invoicesBookStatus?.code == INVOICES_BOOK_STATUS.ONBOARDING_PENDING)
                return ONBOARDING_PROGRESS.PENDING;
            else
                return ONBOARDING_PROGRESS.FAILED;
        }


        // check "Onboarding pending Stage" status
        if (stage == ONBOARDING_STAGE.NEW) {
            if (model.onboardingStatus?.code)
                return ONBOARDING_PROGRESS.COMPLETED;
            else
                return ONBOARDING_PROGRESS.NOT_STARTED;
        }


        if (stage == ONBOARDING_STAGE.CSR_GENERATION) {
            if (!model.onboardingStatus?.code) return ONBOARDING_PROGRESS.NOT_STARTED;

            if (model.onboardingStatus.code == ONBOARDING_STATUS.CSR_GENERATED)
                return ONBOARDING_PROGRESS.PENDING;
            else if (model.onboardingStatus.code == ONBOARDING_STATUS.CSR_GENERATED_FAILD)
                return ONBOARDING_PROGRESS.FAILED;
            else if (
                model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CSID
                || model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CSID_FAILD
                || model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CHECK
                || model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CHECK_FAILED
                // || model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID
                || model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID_FAILED
            )
                return ONBOARDING_PROGRESS.COMPLETED;
            else
                return ONBOARDING_PROGRESS.NOT_STARTED;
        }


        if (stage == ONBOARDING_STAGE.COMPLIANCE_CSID) {
            if (!model.onboardingStatus?.code) return ONBOARDING_PROGRESS.NOT_STARTED;

            if (model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CSID)
                return ONBOARDING_PROGRESS.PENDING;
            else if (model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CSID_FAILD)
                return ONBOARDING_PROGRESS.FAILED;
            else if (
                model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CHECK
                || model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CHECK_FAILED
                //  || model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID
                || model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID_FAILED
            )
                return ONBOARDING_PROGRESS.COMPLETED;
            else
                return ONBOARDING_PROGRESS.NOT_STARTED;
        }


        if (stage == ONBOARDING_STAGE.COMPLIANCE_CHECK) {
            if (!model.onboardingStatus?.code) return ONBOARDING_PROGRESS.NOT_STARTED;

            if (model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CHECK)
                return ONBOARDING_PROGRESS.PENDING;
            else if (model.onboardingStatus.code == ONBOARDING_STATUS.COMPLIANCE_CHECK_FAILED)
                return ONBOARDING_PROGRESS.FAILED;
            else if (
                //model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID
                model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID_FAILED)
                return ONBOARDING_PROGRESS.COMPLETED;
            else
                return ONBOARDING_PROGRESS.NOT_STARTED;
        }


        if (stage == ONBOARDING_STAGE.PRODUCTION_CSID) {
            if (!model.onboardingStatus?.code) return ONBOARDING_PROGRESS.NOT_STARTED;

            // if (model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID)
            //     return ONBOARDING_PROGRESS.PENDING;
            if (model.onboardingStatus.code == ONBOARDING_STATUS.PRODUCTION_CSID_FAILED)
                return ONBOARDING_PROGRESS.FAILED;
            // else
            return ONBOARDING_PROGRESS.NOT_STARTED;
        }



        if (stage == ONBOARDING_STAGE.READY) {
            if (model.invoicesBookStatus?.code == INVOICES_BOOK_STATUS.READY) {
                return ONBOARDING_PROGRESS.COMPLETED;
            } else if (model.invoicesBookStatus?.code == INVOICES_BOOK_STATUS.ONBOARDING_FAILED) {
                return ONBOARDING_PROGRESS.FAILED;
            } else {
                return ONBOARDING_PROGRESS.NOT_STARTED;
            }
        }


    }

}


export class StatusBaseModel {
    description: string;
    code: number;
    source: string;
}


export enum INVOICES_BOOK_STATUS {
    CREATED = 1,
    ONBOARDING_PENDING = 2,
    ONBOARDING_FAILED = 3,
    READY = 4,
}

export enum ONBOARDING_STATUS {
    NEW = 1,
    CSR_GENERATED = 2,
    CSR_GENERATED_FAILD = 3,
    COMPLIANCE_CSID = 4,
    COMPLIANCE_CSID_FAILD = 5,//
    COMPLIANCE_CHECK = 6,
    // PRODUCTION_CSID = 900,
    COMPLETED = 8,

    COMPLIANCE_CHECK_FAILED = 7,
    PRODUCTION_CSID_FAILED = 9,
}


// NEED CHECK WITH ALI
export enum ONBOARDING_STAGE {
    CREATED = 100,
    NEW = 200,
    ONBOARDING_PENDING = 300,

    CSR_GENERATION = 1,
    COMPLIANCE_CSID = 2,
    COMPLIANCE_CHECK = 3,
    PRODUCTION_CSID = 4,
    READY = 5,
}

export enum ONBOARDING_PROGRESS {
    COMPLETED = '1',
    PENDING = '2',
    FAILED = '3',
    NOT_STARTED = '4',
};