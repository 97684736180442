import React, { useState } from 'react'
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { Grid } from '@mui/material';
import Card from '../../../Controls/Card';
import Accordion from '../../../Controls/Accordion';
import Button from '../../../Controls/Button';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import useAddOnboardingHook from '../hooks/useAddOnboardingHook';
import ModalComponent from '../../../Controls/Modal/Modal'
import OtpDialog from './otpDialog';
import { useParams } from 'react-router';
import AutoCompleteSearchable from '../../../Components/AutoCompleteSearchable';
import TextInput from '../../../Controls/TextInputs';
import { GlobalService } from '../../../Services/global.service';

const Profile = () => {
  const { T, Resources } = useTranslation();
  const { formState,handleSetInputChange, handleInputChange, createOnboarding, getInvoicesBooksDataSource } = useAddOnboardingHook();
  const [dialog, setDialog] = useState<boolean>(false);
  const organizationName = GlobalService.CurrentActiveOrganization.name;
  const { id } = useParams();

  //#region :: Data List
  const list: Array<any> = [
    {
      id: 'panel1a-header',
      ariaControls: 'p1-content',
      title: T(Resources.AppResources.CREATE_NEW_INVOICES_BOOK_ONBOARDING),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: (<span>{T(Resources.AppResources.CREATE_NEW_INVOICES_BOOK_ONBOARDING)}</span>),
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          <Grid item md={4} xs={12}>
              
              <AutoCompleteSearchable
                id={formState?.invoiceBook?.name}
                name={formState?.invoiceBook?.name}
                selected={formState?.invoiceBook?.value}
                label={T(Resources.AppResources.INVOICES_BOOK)}
                handleChange={handleSetInputChange}
                error={!formState.invoiceBook.valid}
                showLabel 
                getDataSource={getInvoicesBooksDataSource}/> 
          </Grid>
          
          <Grid item md={4} sm={12} xs={12}>
              <TextInput
                id={"organizationName"}
                name={"organizationName"}
                label={T(Resources.AppResources.ORGANIZATION)}
                value={organizationName}
                isDisabled
              />
            </Grid>
        </Grid>
      )
    }
  ];
  //#endregion

  const handleOpenDialog = () => {
    setDialog(true)
  };
  const handleCloseDialog = () => {
    setDialog(false)
    handleSetInputChange("otp", null);
  };

  return (
    <Grid container spacing={1}>
      <Card>
        <Accordion content={list} />
        <Grid item xs={12} marginTop={2}>
          <Button
            disabled={!formState?.invoiceBook?.value}
            variant='contained'
            color='primary'
            onClick={handleOpenDialog}
            label={T(Resources.AppResources.PROCEED)} />

          <Button
            marginLeft={10}
            marginRight={10}
            variant='outlined'
            color='primary'
            link={true}
            to={PagesRoute.PAGES.ONBOARDING}
            label={T(Resources.AppResources.CANCEL)} />
        </Grid>
      </Card>

      <Grid>
        <ModalComponent
          maxWidth="sm"
          handleClose={() => handleCloseDialog()}
          title={T(Resources.AppResources.OTP_VERIFICATION)}
          open={dialog}
        >
          <OtpDialog handleCloseDialog={handleCloseDialog} handleInputChange={handleInputChange} formState={formState} hadnleAddOnboard={()=>createOnboarding()} />
        </ModalComponent>
      </Grid>

    </Grid>
  )
}
export default Profile;