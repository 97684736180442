import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import Grid from "@mui/material/Grid";
import Card from "../../../Controls/Card";
import ModalComponent from "../../../Controls/Modal/Modal";
import Accordion from "../../../Controls/Accordion";
import PagesRoute from "../../../Config/Settings/PagesRoute";
import useTranslation from "../../../Hooks/Shared/useTranslation";
import { getFieldValue } from "../../../Utils/StringUtils";
import Button from "../../../Controls/Button";
import OtpDialog from "./Shared/otpDialog";
import "./style.scss";
import useViewInvoiceBookHook from "./hooks/useViewInvoiceBookHook";
import { LanguagesValues } from "../../../Config/Settings/Lookups";
import CBadge from "../../../Components/Badg/badge";
import TableComp from "../../../Controls/Table";
import { PermissionScopes, PermissionsService } from "../../../Services/permissions.service";
const ViewInvoicesBook = () => {
  const { T, Resources } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { 
    invoicesBookDetails,
    onboardingFormState,
    handleOnboardingInputChange,
    handelSendOtp,
    dialog, 
    setDialog,
    isRenew, 
    setIsRenew,
    columnsLabels,
    metaDataSource,
    onBoardable
 } = useViewInvoiceBookHook();

  //#region :: Data List
  const list: Array<any> = [
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.INVOICE_BOOK_DETAILS),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.INVOICE_BOOK_DETAILS)}</span>,
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.ORGANIZATION)}
                  </Grid>
                </Grid>
                <Grid>
                  {/* <Grid className='sub-title'>{getFieldValue(policiesDetails?.policyNo)}</Grid> */}
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.organizationName)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.NAME)}
                  </Grid>
                </Grid>
                <Grid>
                  {/* <Grid className='sub-title'>{getFieldValue(policiesDetails?.policyNo)}</Grid> */}
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.invoicesBookInfo?.name)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.INVOICE_TYPE)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(
                      invoicesBookDetails?.invoicesBookInfo?.b2CInvoicing &&
                        invoicesBookDetails?.invoicesBookInfo?.b2BInvoicing
                        ? (<Grid style={{ display: 'flex', justifyContent: 'flex-start', gap: '1%'}}>
                            <CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={'B2B'}>B2B</CBadge>
                            <CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={'B2C'}>B2C</CBadge>
                          </Grid>)
                        : invoicesBookDetails?.invoicesBookInfo?.b2BInvoicing
                        ? (<CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={'B2B'}>B2B</CBadge>)
                        : (<CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={'B2C'}>B2C</CBadge>)
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.LANGUAGE)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(
                      T(
                        Resources.AppResources[
                          LanguagesValues[
                            invoicesBookDetails?.invoicesBookInfo?.language
                              ?.code
                          ]
                        ]
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            
              <Grid item xs={6} md={4}>
                  <Grid>
                    <Grid className="title">
                      {T(Resources.AppResources.STATUS)}
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="sub-title">
                      {getFieldValue(T(Resources.AppResources[invoicesBookDetails?.status?.description]))}
                    </Grid>
                  </Grid>
                </Grid>
              {/* <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.PREFERRED_ADDRESS)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.name)}
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.TAGS)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    <Grid style={{ display: 'flex', justifyContent: 'flex-start', gap: '1%'}}>
                      {invoicesBookDetails?.tags?.split(',').map(x => {return(<CBadge bgColor={'whitesmoke'} expectedWith="fit-content" id={x}>{x}</CBadge>)})}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.ADDRESS),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.ADDRESS)}</span>,
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          <Grid item xs={12}>
            <Grid paddingTop={1} container spacing={1}>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.ADDRESS_NAME)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.name)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.COUNTRY)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue( invoicesBookDetails?.countryLookup?.name || invoicesBookDetails?.preferredAddress?.countryCode)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.PROVINCE)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.province)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid paddingTop={1} container spacing={1}>
              
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.CITY)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.city)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.DISTRICT)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.district)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.STREET_NAME)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.street)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid paddingTop={1} container spacing={1}>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.POSTAL_CODE)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.postalCode)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.ADDITIONAL_NUMBER)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.additionalNumber)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.IS_DEFAULT)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.isDefault ? T(Resources.AppResources.PRIMARY) : T(Resources.AppResources.NOT_PRIMARY))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.BUILDING_NUMBER)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.buildingNumber)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid>
                  <Grid className="title">
                    {T(Resources.AppResources.ADDITIONAL_STREET)}
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="sub-title">
                    {getFieldValue(invoicesBookDetails?.preferredAddress?.additionalStreet)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      id: "panel1a-header",
      ariaControls: "p1-content",
      title: T(Resources.AppResources.METADATA),
      grid: 12,
      showSection: true,
      expanded: true,
      defaultExpanded: false,
      showExpandIcon: false,
      summary: <span>{T(Resources.AppResources.METADATA)}</span>,
      details: (
        <Grid container paddingTop={1} spacing={1} xs={12}>
          <Grid paddingTop={2} md={12} sm={12} xs={12}>
            <TableComp
              dataSource={metaDataSource}
              tableColumns={columnsLabels}
              showPagination={false}
            />
          </Grid>
        </Grid>
      ),
    },
  ];
  //#endregion

  const handleOpenDialog = (id: any) => {
    setDialog(true);
  };
  const handleCloseDialog = () => {
    setDialog(false);
  };

  return (
    <Grid container spacing={1}>
      <Card>
        <Grid item xs={12}>
          <Accordion content={list} />
        </Grid>

        <Grid container xs={12} marginTop={2} gap={"10px"}>
          <Button
            variant="outlined"
            color="primary"
            label={T(Resources.AppResources.BACK)}
            onClick={() => navigate(PagesRoute.PAGES.INVOICES_BOOK_MANAGEMENT)}
          />
          {PermissionsService.hasPermission(PermissionScopes.EDIT_INVOICES_BOOK_ORGANIZATION) && <Button
            variant="outlined"
            color="primary"
            label={T(Resources.AppResources.EDIT)}
            onClick={() => navigate(PagesRoute.PAGES.EDIT_INVOICES_BOOK + id)}
          />}
          <Button
            disabled={!invoicesBookDetails?.canBeRenewed}
            variant="contained"
            color="warning"
            label={T(Resources.AppResources.RENEW)}
            onClick={() => {handleOpenDialog(id); setIsRenew(true);}}
          />
          <Button
            variant="contained"
            color="primary"
            label={T(Resources.AppResources.ONBOARD)}
            onClick={() => {handleOpenDialog(id); setIsRenew(false);}}
            disabled={onBoardable}
          />
        </Grid>

        <Grid>
          <ModalComponent
            maxWidth="sm"
            handleClose={() => handleCloseDialog()}
            title={T(Resources.AppResources.OTP_VERIFICATION)}
            open={dialog}
            onClose={handleCloseDialog}
          >
            <OtpDialog 
                onboardingFormState={onboardingFormState}
                handleOnboardingInputChange={handleOnboardingInputChange}
                handelSendOtp={() => handelSendOtp(isRenew)} />
          </ModalComponent>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ViewInvoicesBook;
