import React, { useState } from 'react'
import propTypes from 'prop-types';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import useTranslation from '../../Hooks/Shared/useTranslation';
import { getUILanguage } from '../../Store/Reducers/UI';
import { useSelector } from 'react-redux';

export interface DropDownProps {
    value: any;
    name: string;
    id: string;
    label: string;
    variant?: string; //| 'outlined', 
    helperText?: any;
    isDisabled?: boolean;
    handleChange: any;
    options: any;
    group?: boolean;
    error?: boolean;
    required?: boolean;
    advancedDropDown?: boolean;
    advancedType?: string;
    size?: string; //|'small',
    defaultValue?: any; //'flat'
    translate?: boolean;
    showDefault?: boolean;
    onBlur?: any;
    showLabel?: any;
    labelStyles?: any;
};

const CustomDropDown = ({ value, name, id, label, variant = 'outlined', helperText, isDisabled, handleChange, options,
    group = false, error = false, required = false, advancedDropDown = false, advancedType = 'flat', size = 'small',
    defaultValue, translate = false, showDefault = true, onBlur, showLabel = undefined, labelStyles={} }: DropDownProps) => {
    
    const state = useSelector(state => state);
    const currentLanguage = getUILanguage(state);
    const { T, Resources } = useTranslation();
    const [inputValue, setInputValue] = useState('');

    //#region :: Advanced DropDown Props
    const props = advancedDropDown ?
        advancedType === 'multiple'
            ? {
                options: options,
                getOptionLabel: (option: any) => translate ? T(option.label) : option.label,
                multiple: true
            }
            : {
                options: options !== undefined ? options.map((option: any) => translate ? T(option.label) : option.label) : [],
                flat: advancedType,
                onInputChange: (event: any, newInputValue: any) => {
                    setInputValue(newInputValue);
                }
            }
        : {};
    //#endregion

    return (
        <StyledFormControl error={error} variant={variant} size={size}>
            {advancedDropDown
                ? <StyledAutocomplete
                    {...props}
                    id={id}
                    label={label}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    disabled={isDisabled}
                    disableClearable={true}
                    inputValue={inputValue}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            variant={variant}
                            label={label}
                            placeholder={label}
                            required={required}
                            error={error}
                            size={size}
                            helperText={helperText && T(helperText)} />
                    )} />
                : <>
                    {/* <InputLabel htmlFor={id}>{label}</InputLabel> */}
                    
                    <InputLabel
                        style={{
                            width: 'fit-content',
                            transform: (showLabel && currentLanguage === "en-us" ) ? 'translate(14px, -9px) scale(0.75)' : (showLabel ? 'translate(-14px, -9px) scale(0.75)' : "none"),
                            ...labelStyles
                        }}
                        htmlFor={id}>{label}{required ? <span > * </span> : <></>}
                    </InputLabel>
                    <StyledSelectInput
                        native
                        error={error}
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        onChange={handleChange}
                        defaultValue={defaultValue}
                        onBlur={onBlur && onBlur}
                        value={value}
                        inputProps={{
                            id: id,
                            name: name,
                            style: { fontSize: '0.7rem' }
                        }}>
                        {showDefault && <option aria-label='' value='0' defaultValue='0'>{T(Resources.AppResources.SELECT)}</option>}
                        {options !== undefined ?
                            !group ?
                                options.map((item: any) => {
                                    return <option
                                        key={item.id}
                                        value={item.id}
                                        // regex={item.regex}
                                        defaultValue={item.id === defaultValue ? 1 : 0}>
                                        {translate
                                            ? T(item.label)
                                            : item.label}
                                    </option>
                                }) :
                                options.map((item: any, index: number) => (
                                    <optgroup label={index.toString()}>
                                        {item.listItem.map((opt: any) => {
                                            return <option
                                                key={opt.id}
                                                // regex={item.regex}
                                                value={opt.id}>
                                                {translate
                                                    ? T(opt.label)
                                                    : opt.label}
                                            </option>
                                        })}
                                    </optgroup>
                                ))
                            : null}
                    </StyledSelectInput>
                    <FormHelperText>{helperText && T(helperText)}</FormHelperText>
                </>}
        </StyledFormControl>
    )
};

//#region :: Styled Components
const StyledFormControl: any = styled(FormControl)`
    width: 100%;
    & .MuiInputBase-root {
        font-size: 0.9rem !important;
    }
    & .MuiInputLabel-root {
        font-size: 0.9rem !important;
    }
`;

const StyledSelectInput: any = styled(Select)`
    font-size: 0.9rem !important;
    // marginRight: '5px !important',
`;

const StyledAutocomplete: any = styled(Autocomplete)`
    width: 100%;
`;
//#endregion

//#region :: Validation PropTypes
CustomDropDown.propTypes = {
    value: propTypes.any,
    name: propTypes.string,
    id: propTypes.string,
    label: propTypes.string,
    helperText: propTypes.string,
    advancedType: propTypes.string,
    searchVariant: propTypes.string,
    Width: propTypes.number,
    error: propTypes.bool,
    required: propTypes.bool,
    advancedDropDown: propTypes.bool,
    group: propTypes.bool,
    handleChange: propTypes.func,
    options: propTypes.array
};
//#endregion

export default CustomDropDown;


