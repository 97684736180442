import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import React , { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { changeFullLoading, setColorTheme, themeModeChanged } from "../../Store/Reducers/UI";

const SwitchColorToggle = ({
    classname,
    active,
    inactive,
    isActive,
    isOutlined = true,
    handleStatusChange = undefined,
    id,
    switchable = true,
    disableAll = false,
}: any) => {
    
    //#region :: State
    const { T, Resources } = useTranslation();
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(isActive);
    const [showModal, setShowModal] = useState(false);
    const [itemStatus, setItemStatus] = useState();


    //#endregion

    //#region :: Change Status
    const handleChange = async (value: any, color: any) => {
        setItemStatus(value);
        // dispatch(setColorTheme({ body: color  }))
        setSelected(value);
        handleStatusChange(value)
    };

    useEffect(() => {
        setSelected(isActive);
    }, [isActive]);
    //#endregion

    return (
        <StyledGrid
            className={selected ? "active" : "inactive"}
            container
            id={`statuc-${id}`}
            name={`statuc-${id}`}
            disableAll={disableAll}
        >
            {switchable ? (
                <>
                    <ToggleButtonActive
                        isoutlined={+isOutlined}
                        className={selected ? "active" : "inactive"}
                        size="small"
                        id={`active-${id}`}
                        name={`active-${id}`}
                        value={true}
                        disabled={selected || disableAll}
                        onChange={(e: any) => handleChange(true, "#2a65a5")}
                    >
                        <span>{active && T(active)}</span>
                    </ToggleButtonActive>
                    <ToggleButtonInactive
                        isoutlined={+isOutlined}
                        className={!selected ? "active" : "inactive"}
                        size="small"
                        id={`inactive-${id}`}
                        name={`inactive-${id}`}
                        value={false}
                        disabled={!selected || disableAll}
                        onChange={(e: any) => handleChange(false, "#28a745")}
                    >
                        <span>{inactive && T(inactive)}</span>
                    </ToggleButtonInactive>
                </>
            ) : active !== undefined ? (
                <ToggleButtonActive
                    isoutlined={+isOutlined}
                    className={selected ? "active" : "inactive"}
                    size="small"
                    id={`active-${id}`}
                    name={`active-${id}`}
                    value={true}
                    disabled={selected || disableAll}
                >
                    <span>{active && T(active)}</span>
                </ToggleButtonActive>
            ) : (
                <ToggleButtonInactive
                    isoutlined={+isOutlined}
                    className={!selected ? "active" : "inactive"}
                    size="small"
                    id={`inactive-${id}`}
                    name={`inactive-${id}`}
                    value={false}
                    disabled={!selected || disableAll}
                >
                    <span>{inactive && T(inactive)}</span>
                </ToggleButtonInactive>
            )}
        </StyledGrid>
    );
};

//#region :: Styled Components
const StyledGrid: any = styled(Grid)`
  flex-flow: nowrap !important;
  border-radius: 5rem !important;
  width: fit-content !important;
  margin: 7px auto !important;
  padding: 2px !important;
  background-color:${props => (props.disableAll ? 'whitesmoke' : 'none')};
  
  &.active {
    border: 1px solid #2a65a5 !important;
  }
  
  &.inactive {
    border: 1px solid #28a745 !important;
  }
`;



const ToggleButtonActive: any = styled(ToggleButton)`
  transition: all ease-in-out 0.4s;
  font-size: x-small !important;
  font-weight: bold !important;
  border-radius: 5rem !important;
  width: 70px !important;
  border: 1px solid transparent !important;
  padding: 0px !important;
  &.active {
    background: #2a65a5 !important;
    color: #fff !important;
  }
  &.inactive {
    background: transparent !important;
    color: #2a65a5 !important;
  }
`;

const ToggleButtonInactive: any = styled(ToggleButton)`
  transition: all ease-in-out 0.4s;
  font-size: x-small !important;
  font-weight: bold !important;
  border-radius: 5rem !important;
  width: 70px !important;
  border: 1px solid transparent !important;
  padding: 0px !important;
  &.active {
    background: #28a745 !important;
    color: #fff !important;
  }
  &.inactive {
    background: transparent !important;
    color: #28a745 !important;
  }
`;
//#endregion

export default SwitchColorToggle;
