import React from 'react';

import Card from '../../../Controls/Card';
import RoleMappingProfile from './Shared/profile';

const CreateRole = () => {
    return (
        <Card>
            <RoleMappingProfile />
        </Card>
    );
};

export default CreateRole;