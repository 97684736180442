import { Grid } from '@mui/material';
import React from 'react';
import Accordion from '../../../../../Controls/Accordion';
import Button, { ButtonsTypes } from '../../../../../Controls/Button';
import useTranslation from '../../../../../Hooks/Shared/useTranslation';
import TextInput from '../../../../../Controls/TextInputs';
import DropDownInput from '../../../../../Controls/CustomDropDown';
import TableComp from '../../../../../Controls/Table';
import { ContextLevelsList } from '../../../../../Config/Settings/Lookups';
import PagesRoute from '../../../../../Config/Settings/PagesRoute';
import Search from './search';
import useAddRoleMappingHook from '../../hooks/useAddRoleMappingHook';
import { useParams } from 'react-router-dom';
import AutoComplete from '../../../../../Components/AutoComplete';
import "./style.scss"
const RoleMappingProfile = () => {
    //#region :: State
    const { T, Resources } = useTranslation();
    const { id } = useParams();
    const {
        permissionsColumns,
        pagination,
        formData,
        permissionsTableList,
        changePageApi,
        saveRole,
        handleInputChange,
        handleSetData,
        handleSearch,
        setPermissionCheckVal,
    } = useAddRoleMappingHook();



    //#region :: data
    const list: Array<any> = [
        {
            id: 'panel3a-header',
            ariaControls: 'p3-content',
            title: T(Resources.AppResources.ROLE_INFO),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.ROLE_INFO)}</span>),
            details: (
                <Grid className='container-form-ddl-disabled' paddingTop={1} container spacing={1} md={12}>
                    <Grid item md={3}>
                        <TextInput
                            id={formData.arabicName.name}
                            error={!formData.arabicName.valid}
                            required={formData.arabicName.required}
                            name={formData.arabicName.name}
                            value={formData.arabicName.value}
                            label={T(Resources.AppResources.ARABIC_NAME)}
                            maxLength={100}
                            handleChange={(e: any) => handleInputChange(e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextInput
                            id={formData.englishName.name}
                            error={!formData.englishName.valid}
                            required={formData.englishName.required}
                            name={formData.englishName.name}
                            value={formData.englishName.value}
                            label={T(Resources.AppResources.ENGLISH_NAME)}
                            maxLength={100}
                            handleChange={(e: any) => handleInputChange(e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DropDownInput
                            id={formData.level.name}
                            name={formData.level.name}
                            value={formData.level.value}
                            error={!formData.level.valid}
                            options={ContextLevelsList}
                            showDefault={false}
                            isDisabled={!!id}
                            label={T(Resources.AppResources.LEVEL)}
                            handleChange={(e) => {
                                handleInputChange(e);
                                setPermissionCheckVal([]);
                            }}
                            showLabel
                            translate />
                        {/* <AutoComplete
                                id={formData.level.name}
                                name={formData.level.name}
                                defaultValue={formData.level.value}
                                value={formData.level.value}
                                autoComplete={false}
                                showLabel={true}
                                filterSelectedOptions={false}
                                showSearchIcon={false}
                                // handlerSearch={(SearchText) => setSearchText(SearchText ?? "")}
                                handleChange={(value) => {
                                    debugger
                                    handleSetData(formData.level.name, value);
                                    setPermissionCheckVal([]);
                                }}
                                error={!formData.level.valid}
                                options={ContextLevelsList}
                                required={true}
                                label={T(Resources.AppResources.LEVEL)}
                                multiple={false}
                                noOptionsText={T(Resources.AppResources.NODATA)}
                                filterOptions={(x) => x}
                                /> */}
                    </Grid>
                </Grid>
            )
        },
        {
            id: 'panel3a-header',
            ariaControls: 'p3-content',
            title: T(Resources.AppResources.ROLE_PERMISSIONS),
            grid: 12,
            showSection: true,
            expanded: true,
            defaultExpanded: false,
            showExpandIcon: false,
            summary: (<span>{T(Resources.AppResources.ROLE_PERMISSIONS)}</span>),
            details:
                (<Grid container spacing={2}>
                    <Grid item xs={12} marginBottom={'10px'}>
                        <Search searchHandler={handleSearch} />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TableComp
                            dataSource={permissionsTableList}
                            tableColumns={permissionsColumns}
                            changePageApi={changePageApi}
                            pagination={pagination}
                            showPagination={true} />
                    </Grid>
                </Grid>
                )
        }
    ];
    //#endregion


    return (
        <Grid className='plans-container' container>
            <Grid item xs={12}>
                <Accordion content={list} />
            </Grid>
            <Grid item xs={12}>

                <Button
                    marginLeft={5}
                    marginRight={5}
                    marginTop={10}
                    variant='contained'
                    color='primary'
                    label={T(Resources.AppResources.SAVE)}
                    onClick={() => saveRole()}
                />
                <Button
                    marginTop={10}
                    marginLeft={5}
                    marginRight={5}
                    variant='outlined'
                    color='primary'
                    type={ButtonsTypes.CANCEL}
                    label={T(Resources.AppResources.CANCEL)}
                    link
                    to={PagesRoute.PAGES.ROLES_MANAGEMENT}
                />
            </Grid>
        </Grid>
    )
}

export default RoleMappingProfile