import React, { useEffect, useState } from 'react'
import useTranslation from '../../../Hooks/Shared/useTranslation';
import { PaginationModel } from '../../../Models';
import useOnboardingSearch from '../../../Hooks/useOnboardingSearch';
import CustomeIconButton, { IconButtonsTypes } from '../../../Controls/IconButton';
import { Link } from 'react-router-dom';
import PagesRoute from '../../../Config/Settings/PagesRoute';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import SwitchToggle from '../../../Components/SwitchToggle';
import { OnboardingService } from '../../../Services/API/Onboarding';
import { OnboardingStatuses } from '../../../Config/Settings/Enums';
import { OnboardingModel } from '../Shared/onboarding.model';
import { PermissionScopes, PermissionsService } from '../../../Services/permissions.service';

const useOnboardingListHook = () => {
    const { T, Resources } = useTranslation();
    const onboardingSearch = useOnboardingSearch();
    const [onboardingSource, setOnboardingSource] = useState<Array<any>>([]);
    const [pagination, setPagination] = useState<PaginationModel>(new PaginationModel());

    const cardLabels: Array<any> = [
        { id: 'organizationName', label: T(Resources.AppResources.ORGANIZATION) },
        { id: 'invoiceBookName', label: T(Resources.AppResources.INVOICES_BOOK), },
        { id: 'status', label: ' ' + T(Resources.AppResources.STATUS) },
        { id: 'progress', label: T(Resources.AppResources.PROGRESS) },
        { id: 'action' },
    ];
    
    //#region :: Onboarding Data [useEffect]

    useEffect(() => {
        if (!onboardingSearch?.onboarding) return;

        setOnboardingSource(onboardingSearch?.onboarding?.map((item: any) => {
            const { id, invoiceBookName, organizationName, isActive, invoicesBookId, invoicesBookStatus,onboardingStatus  } = item;
            return {
                invoiceBookName,
                organizationName,
                status:  T(Resources.AppResources[onboardingStatus?.description]) || "",
                progress: (
                    <Grid md={12} >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant="determinate" value={OnboardingModel.onboardingStatusPercent(onboardingStatus?.code)} />
                            </Box>
                            <Typography variant="body2" color="text.secondary">{`${Math.round(
                                OnboardingModel.onboardingStatusPercent(onboardingStatus?.code)
                            )}%`}</Typography>
                        </Box>
                    </Grid>
                ),
                action: (
                    <Grid style={{ display: 'flex' }}>
                        <Grid item xs={4} style={{ display: 'flex' ,justifyContent:"start"}} spacing={1}>
                            {/* <SwitchToggle
                                active={T(Resources.AppResources.ACTIVE)}
                                inactive={T(Resources.AppResources.IN_ACTIVE)}
                                isActive={isActive}
                                handleStatusChange={()=>{OnboardingService.ActivateOnboarding(id, !isActive)}}
                            /> */}
                        </Grid>
                        {PermissionsService.hasPermission(PermissionScopes.VIEW_ONBOARDING__DETAILS) && <Grid item xs={8}  style={{ display: 'flex',justifyContent:"end"}} >
                            <CustomeIconButton
                                id='view'
                                aria-label='view'
                                type={IconButtonsTypes.VIEW}
                                component={Link}
                                to={PagesRoute.PAGES.VIEW_ONBOARDING + invoicesBookId} />
                        </Grid>}
                    </Grid>
                )
            };
        }));
        
        setPagination({
            pageIndex: onboardingSearch.currentPage,
            pageSize: onboardingSearch.pageSize,
            totalCount: onboardingSearch.pagination.defaultContext !== undefined && onboardingSearch.pagination.defaultContext.totalCount,
            totalPages: onboardingSearch.totalPages
        });
    }, [onboardingSearch.onboarding]);
    //#endregion

    return {
        cardLabels, pagination, onboardingSource, changeCurrentPage: onboardingSearch.changeCurrentPage
    }
}

export default useOnboardingListHook