import { Integer, InvoicesBookStatuses, OnboardingStatuses } from '../../../Config/Settings/Enums';
import { FieldModel } from '../../../Models';

export class OnboardingModel {
    invoiceBook?: any;
    otp?: any;

    public static getFieldsModel(model?: OnboardingModel): any {
        if (!model) model = new OnboardingModel();
        let fildsObject = FieldModel.buildFromObject(model);

        fildsObject.invoiceBook.required = true;
        fildsObject.invoiceBook.validate = true;
        fildsObject.invoiceBook.value = null;
        fildsObject.otp.required = true;
        fildsObject.otp.validate = true;

        return fildsObject;
    }

    public static getOTPFieldsModel(model?: OnboardingModel): any {
        if (!model) model = new OnboardingModel();
        let fildsObject = FieldModel.buildFromObject(model);

        fildsObject.otp.required = true;
        fildsObject.otp.validate = true;

        return fildsObject;
    }

    public static addBoardingDBModel(model: OnboardingModel) {
        return {
            otp: model.otp.value,
        };
    }

    public static  onboardingStatusPercent = (status) => {
        let percVal = 0;
        switch (status) {
        case OnboardingStatuses.NEW:
            percVal = 0;
            break;
        case OnboardingStatuses.CSR_GENERATED:
        case OnboardingStatuses.CSR_GENERATION_FAILED:
            percVal = 25;
            break;
        case OnboardingStatuses.COMPLIANCE_CSID_OBTAINED:
        case OnboardingStatuses.COMPLIANCE_CSID_FAILED:
            percVal = 50;
            break;
        case OnboardingStatuses.COMPLIANCE_CHECK_DONE:
        case OnboardingStatuses.COMPLIANCE_CHECK_FAILED:
            percVal = 75;
            break;
        case OnboardingStatuses.COMPLETED:
        case OnboardingStatuses.PRODUCATION_CSID_FAILED:
        case OnboardingStatuses.RENEWAL_PRODUCTION_CSID_FAILED:
        case OnboardingStatuses.EXPIRED:
            percVal = 100;
            break;
        }
        return percVal;
    }

    public static  invoicesBookStatusPercent = (status) => {
        let percVal = 0;
        switch (status) {
        case InvoicesBookStatuses.CREATED:
            percVal = 50;
            break;
        case InvoicesBookStatuses.ONBOARDING_PENDING:
        case InvoicesBookStatuses.PROD_CSID_RENEWAL_PENDING:
            percVal = 75;
            break;
        case InvoicesBookStatuses.READY:
        case InvoicesBookStatuses.PROD_CSID_EXPIRED:
        case InvoicesBookStatuses.PROD_CSID_RENEWAL_FAILED:
            percVal = 100;
            break;
        }
        return percVal;
    }
}

export class OnboardingSearch {
    searchText?: any;
    public static getFieldsModel(model?: OnboardingSearch): any {
        if (!model) model = new OnboardingSearch();
        let fildsObject = FieldModel.buildFromObject(model);
        return fildsObject;
    }

    public static toDBModel(model: any) {
        let searchText = model.searchText.value;

        return {
            searchText
        };
    }
}
