import React, { useState } from 'react';
import MuiSpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

export interface SpeedDialProps {
    actions?: Array<any>;
    onClick?: any;
    direction?: any;
};

const SpeedDial = ({ actions = [], onClick, direction = 'up' }: SpeedDialProps) => {

    //#region :: State
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [hidden] = useState(false);
    //#endregion

    //#region :: Handlers
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    //#endregion

    return (
        <>
           <MuiSpeedDial className='mui-speed-dial'
                ariaLabel='SpeedDial Btn'
                hidden={hidden}
                icon={<AddIcon onClick={() => onClick && onClick()} />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction={direction}>
                {
                    actions.map((action) => (
                        action.to !== undefined
                            ? <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={() => action.to !== undefined ? navigate(action.to) : action.onClick && onClick} />
                            : <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.to !== undefined ? navigate(action.to) : action.onClick !== undefined ? action.onClick : handleClose} />
                    ))
                }
            </MuiSpeedDial>
        </>
    );
};

export default SpeedDial;