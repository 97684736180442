import { AppConfigService } from "./AppConfig";

await AppConfigService.init();
export const SERVICES_BASE_URL = process.env.REACT_APP_SERVICES_BASE_URL;
// export const TERMS_SERVICE_URL =process.env.REACT_APP_TERMS_SERVICE_URL;

export const MULTI_TENANT_BASE_URL = AppConfigService.getConfig().tenantBaseUrl;

// export const SERVICES_BASE_URL ="https://localhost:7173/api/";
// export const TERMS_SERVICE_URL = process.env.REACT_APP_TERMS_SERVICE_URL;
// export const MULTI_TENANT_BASE_URL = "https://localhost:7173";