import React, { useEffect } from 'react'
import CardList from '../../Components/CardList'
import useOnboardingListHook from './hooks/useOnboardingListHook'
import Search from './search';
import { Grid } from '@mui/material';
import Card from '../../Controls/Card';
import PagesRoute from '../../Config/Settings/PagesRoute';
import Button, { ButtonsTypes } from '../../Controls/Button';
import useTranslation from '../../Hooks/Shared/useTranslation';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { PermissionScopes, PermissionsService } from '../../Services/permissions.service';

const Onboarding = () => {
  const { T, Resources } = useTranslation();
  const navigate = useNavigate();

  const { cardLabels, onboardingSource, pagination, changeCurrentPage } = useOnboardingListHook();


  return (
    <>
      <Grid className='onboarding' container spacing={1}>
        <Grid item xs={12}>
          <Search />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardList
              ListData={onboardingSource}
              labels={cardLabels}
              pagination={pagination}
              changePageApi={changeCurrentPage} 
            />
          </Card>
        </Grid>

       {PermissionsService.hasPermission(PermissionScopes.ADD_ONBOARDING) && <Button
          color='primary'
          startIcon={<AddIcon />}
          onClick={() => navigate(PagesRoute.PAGES.ADD_ONBOARDING)}
          // buttonsAction={ButtonActions}
          type={ButtonsTypes.FLOATIONG} />}

      </Grid>
    </>
  )
}

export default Onboarding