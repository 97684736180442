import axios from "axios";

export interface IAppConfig {
    baseUrl: string;
    tenantBaseUrl: string;
    reactAppBaseUrl: string;
    versionNumber: string;
}


export class AppConfigService {

    static _config: IAppConfig;

    static getConfig(): IAppConfig {
        return this._config;
    }

    static setConfig(config: IAppConfig) {
        this._config = config;
    }

    static async init() {
        return await axios.get('./configs/AppConfig.json', { baseURL: '/', responseType: 'json', headers: { "accept": "*/*" } }).then(res => {
           
            this._config = { ...res.data };

        })

    }


}

