import React, { useEffect, useRef, useState } from "react";
import AutoComplete from "../AutoComplete";
import useTranslation from "../../Hooks/Shared/useTranslation";
import { ILookupsAutoCompleteSelectDeclarations } from './ILookupsAutoCompleteSelectDeclarations';
import { error } from "console";
import { LookupsService } from "../../Services/API/Lookups";

const AutoCompleteLookup = ({
  multiple = false,
  freeSolo = false,
  id,
  name,
  label,
  error,
  selected,
  handleInputChange,
  showIcon = true,
  showLabel = false,
  disabled = false,
  lookupType,
  withDependency = false,
  dependentId,
  defaultValue
}: ILookupsAutoCompleteSelectDeclarations) => {
  //#region :: State
  const { T, Resources } = useTranslation();
  const [inputValue, setInputValue] = useState<any>("");
  const [dropDownItems, setDropDownItems] = useState<any>([]);
  const [orgDataList, setOrgDataList] = useState<Array<any>>([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const localSelectedRef = useRef<any>("");
  const [defaultValueOption, setDefaultValueOption] = useState<any>(null);
  const defaultValueOptionRef = useRef<any>(null);

  const props: any = {
    multiple: multiple ? true : false,
    freeSolo: freeSolo ? true : false,
  };
  //#endregion

  //#region :: Handlers
  const inputChange = async ( newInputLable: any) => {
    setInputValue(newInputLable);
  };

  const handleChangeInput =async (newInputId: any) => {
    if (newInputId) {
      const select = orgDataList.find((item) => item.id == newInputId.id);
      inputChange( newInputId);
      if(select){
        handleInputChange(name, select);
      }else{
        let response: any;
        response = await LookupsService.GetLookupByValue(defaultValue);

        handleInputChange(name, {id: response?.id, label: response?.value} );
      }

    } else {
      handleInputChange(name, newInputId);
      inputChange( newInputId);
    }
  };

  const getDataSource = async () => {
    try {
        setLoader(true);
        let response: any;
    
        if(!searchText)
          await handleFetchDefault();
        else 
          defaultValueOptionRef.current = null;

        if(withDependency)
        {
          if(dependentId){
            response = await LookupsService.GetLookupSearch(lookupType, dependentId, searchText);
          }
          else {
            handleInputChange(name, null);
            setDropDownItems([]);
            setInputValue(null);
            return;
          }
        }
        else{
            response = await LookupsService.GetLookupSearch(lookupType, null, searchText);
        }

        setOrgDataList(response);
        let items: Array<any> = [];
        if (response?.length > 0) {
          response?.map((item: any) => {
            const obj = {
                id: item?.id,
                label:  T(Resources.AppResources[item.name]) ||  item?.name || item?.value,
                value: item?.value,
            };
            items.push(obj);
          });
        }
        
        // console.log("defaultValueOption", defaultValueOption);
        // console.log("itemsResponse", items);
        // console.log("defaultValueOptionRef.current", defaultValueOptionRef.current);
        if(defaultValueOptionRef.current){
          items = [
            {
              id:defaultValueOptionRef?.current?.id, 
              label: T(Resources.AppResources[defaultValueOptionRef?.current?.name]) || defaultValueOptionRef?.current?.name || defaultValueOptionRef?.current?.value, 
              name: defaultValueOptionRef?.current?.name,
              value: defaultValueOptionRef?.current?.value,
            }
            ,...items];
          await handleSetSelection(defaultValueOptionRef.current?.value, items);
        }
        

        setDropDownItems(items);
    } catch (error) {
        setDropDownItems([]);
    }finally{
        setLoader(false);
    }
  };
  
  const handleFetchDefault = async () => {
    try {
      setLoader(true);
      let response: any;
      // console.log("defaultValue", defaultValue);
      if(!(defaultValueOption && defaultValueOption.value == defaultValue) && defaultValue){
        response = await LookupsService.GetLookupByValue(defaultValue);
        setDefaultValueOption(response);
        setInputValue(response.id);
        defaultValueOptionRef.current = response;
        // console.log("defaultValueOptionResponse", response);
        // console.log("items", dropDownItems);
        
      }

    } catch (error) {
    }finally{
        setLoader(false);
    }
  };

  const handleSetSelection = async (valueSelected = selected, items = dropDownItems) =>{
    
    if(valueSelected && typeof(valueSelected) == "object")
    {
        setInputValue(valueSelected?.id)
        if(!(items.some(itm => itm.id == valueSelected?.id)))
          setDropDownItems([{...valueSelected, label: valueSelected.value }, ...dropDownItems])
    }
    else if(valueSelected){
        localSelectedRef.current = selected;
        let selectedItem:any;
        selectedItem = items.find(itm => itm.value == valueSelected);

        if(!selectedItem && items.length){
          selectedItem =  await LookupsService.GetLookupByValue(valueSelected);
          handleInputChange(name, selectedItem);
          const newItem = {...selectedItem, label: selectedItem.value };
          const tempList = [newItem, ...items]

          setOrgDataList(tempList);
          setDropDownItems([newItem, ...items]);
          setInputValue(newItem.id);
        }
        else{
        setInputValue(selectedItem?.id);
        handleInputChange(name, selectedItem);
        setDropDownItems([...items]);
      }
    }
    else if(valueSelected == 0){
      setInputValue(0);
    }
  }
  //#endregion

  //#region :: UseEffect
  useEffect(() => {
    getDataSource();
  }, [dependentId, searchText, defaultValue]);


  useEffect(() => {
    handleSetSelection();
  }, [selected]);

  //#endregion

  return (
    <AutoComplete
      id={name}
      name={name}
      defaultValue={inputValue || defaultValueOption?.id}
      value={inputValue }
      autoComplete={false}
      showLabel={showLabel}
      disabled={withDependency ? (disabled || !(dependentId)) : disabled}
      filterSelectedOptions={false}
      handlerSearch={(SearchText) => setSearchText(SearchText ?? "")}
      handleChange={handleChangeInput}
      error={error}
      options={dropDownItems}
      required={true}
      label={label && T(label)}
      multiple={false}
      noOptionsText={T(Resources.AppResources.NODATA)}
      filterOptions={(x) => x}
      isLoading={loader}
    />
  );
};

export default AutoCompleteLookup;
