import { useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Header from './MasterPage/Header/Header';
import DrawerMenu from './MasterPage/Drawer/DrawerMenu';

const MasterWarper = ({ children }: any) => {

    //#region :: State
    const screenSize = useMediaQuery((theme: any) => theme.breakpoints.up('MobileScreen'));
    const [open, setOpen] = useState<boolean>(true);
    //#endregion

    //#region :: Handlers
    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    //#endregion

    return (
        <StyledRootDiv>
            <CssBaseline />
            <Header open={open} handleDrawerOpen={handleDrawerOpen} />
            {screenSize
                ? <DrawerMenu
                    handleDrawerOpen={handleDrawerOpen}
                    collapseMenu={open} />
                : <SwipeableDrawer
                    open={open}
                    onOpen={handleDrawerOpen}
                    onClose={handleDrawerOpen}>
                    <DrawerMenu
                        handleDrawerOpen={handleDrawerOpen}
                        collapseMenu={open} />
                </SwipeableDrawer>}
            <StyledMainContent>
                <StyledEmptyToolbar className='hiddenHeaderPrint' />
                {children && children}
            </StyledMainContent>
        </StyledRootDiv>
    );
};

//#region :: Styled Components
const StyledRootDiv: any = styled.div(({ theme }: any) => ({
    display: theme.breakpoints.up('MobileScreen') ? 'flex' : 'relative'
}));

const StyledMainContent: any = styled.main(({ theme }) => ({
    width: '50%!important',
    background: '#fafafa !important',
    flexGrow: 1,
    padding: theme.spacing(0),
    minHeight: '91.5vh !important',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
}));

const StyledEmptyToolbar: any = styled.main(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));
//#endregion

//#region :: Validation PropTypes
MasterWarper.propTypes = {
    children: propTypes.any
};
//#endregion

export default MasterWarper;