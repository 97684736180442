
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const connectorDb = createSlice({
    name: 'connectorDb',
    initialState: {
        connectorDb: [],
        keys: [],
        connectorDbDetails: {}, 
        connectorDbList: [],     
        searchCriteria: {
            referenceNumber: '',
            sourceId: '',
            status: ''
        },
        isSearching: true,
        showError: false,
        error: null
    },
    reducers: {
        connectorDbReceived: (connectorDb: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            data.map((item: any) => {
                connectorDb.connectorDb[item.id] = item;
                connectorDb.keys = [...connectorDb.keys, item.id]
            });

            connectorDb.connectorDbDetails = null;
        },  
        setConnectorDbsDetails: (connectorDb: any, { payload }: PayloadAction<any>) => {
            const currentUser = payload.body;
            connectorDb.connectorDbDetails = currentUser;
        },
        
        setConnectorDbsList: (connectorDb: any, { payload }: PayloadAction<any>) => {
            const data = payload.body;
            connectorDb.connectorDbLists = data;
        },

      
        setSearchCriteria: (connectorDb: any, { payload }: PayloadAction<any>) => { 
            connectorDb.searchCriteria = payload.body;
        },

        setError: (connectorDb: any, { payload }: PayloadAction<any>) => {
            connectorDb.error = payload.response;
            connectorDb.showError = true;
        }
    }
});

export const { connectorDbReceived,   setConnectorDbsList, setConnectorDbsDetails, setSearchCriteria, setError } = connectorDb.actions;
export default connectorDb.reducer;

//#region :: Selectors
export const getConnectorDbData = (state: any) => {
    return state.connectorDb.connectorDb;
};

export const getConnectorDbDetails = (state: any) => {
    return state.connectorDb.connectorDbDetails;
};

export const getConnectorDbLists = (state: any) => {
    return state.connectorDb.connectorDbLists;
};

export const getSearchCriteria = (state: any) => {
    return state.connectorDb.searchCriteria;
};

export const getShowError = (state: any) => {
    return state.connectorDb.showError;
};

export const getError = (state: any) => {
    return state.connectorDb.error;
};

export const connectorDbSelectors = {
    getConnectorDbData,
    getSearchCriteria,
    getConnectorDbDetails,
    getConnectorDbLists,
    getShowError,
    getError,
};
//#endregion