import { PermissionScopes, PermissionsService } from "../../Services/permissions.service";

export interface IDrawerSubItem {
  NAME: string;
  PATH: string;
  PERMISSIONS: string;
}

const PagesRoute = {
  PAGES: {
    HOME: ``,
    CHANGE_PASSWORD: `/change_password`,
    RESET_PASSWORD: `/reset_password`,
    BLANK_PAGE: `/blankPage`,

    ORGANIZATION_MANAGEMENT: `/organization_management`,
    // (
    //   PermissionsService.hasPermission(PermissionScopes.ADD_ORGANIZATION) ||
    //   PermissionsService.hasPermission(PermissionScopes.EDIT_ORGANIZATION) ||
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_ORGANIZATIONS_LIST) ||
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_ORGANIZATION_DETAILS) 
    // )  ?  `/organization_management` : false,

    ADD_ORGANIZATION: `/organization_management/add_organization`,
    EDIT_ORGANIZATION: `/organization_management/edit_organization/`,
    VIEW_ORGANIZATION: `/organization_management/view_organization/`,

    INVOICES_BOOK_MANAGEMENT: `/invoices_book_management`,
    ADD_INVOICES_BOOK: `/invoices_book_management/add_invoices_book`,
    EDIT_INVOICES_BOOK: `/invoices_book_management/edit_invoices_book/`,
    VIEW_INVOICES_BOOK: `/invoices_book_management/view_invoices_book/`,

    
    ONBOARDING: `/onboarding` ,
    // (
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_ONBOARDING__LIST) ||
    //   PermissionsService.hasPermission(PermissionScopes.ADD_ONBOARDING_) ||
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_ONBOARDING__DETAILS) ||
    //   PermissionsService.hasPermission(PermissionScopes.SEARCH_ONBOARDING) 
    // )  ?  `/onboarding` : false,

    ADD_ONBOARDING: `/onboarding/add_onboarding`,
    EDIT_ONBOARDING: `/onboarding/edit_onboarding/`,
    VIEW_ONBOARDING: `/onboarding/view_onboarding/`,

    INVOICES: `/invoices`,
    // (
    //   PermissionsService.hasPermission(PermissionScopes.SEND_INVOICES) ||
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_LIST) ||
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_DETAILS) ||
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICE_QR_CODE) ||
    //   PermissionsService.hasPermission(PermissionScopes.DOWNLOAD_INVOICE) ||
    //   PermissionsService.hasPermission(PermissionScopes.SEARCH_FOR_AN_INVOICE) 
    // )  ?  `/invoices` : false,
    VIEW_INVOICE: `/invoices/view_invoice/`,

    ROLES_MANAGEMENT: `/roles_management/`,
    // (
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_ROLES_LIST) ||
    //   PermissionsService.hasPermission(PermissionScopes.ADD_NEW_ROLE) ||
    //   PermissionsService.hasPermission(PermissionScopes.EDIT_ROLE) ||
    //   PermissionsService.hasPermission(PermissionScopes.SEARCH_ROLE) ||
    //   PermissionsService.hasPermission(PermissionScopes.SEARCH_ROLE_PERMISSIONS)  
    // )  ?   `/roles_management/` : false,

    CREATEROLE: `/roles_management/createRole`,
    EDITROLE: `/roles_management/editRole/`,

    USERSMANAGEMENT: `/users_management`,
    // (
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_USERS_LIST_TENANT) ||
    //   PermissionsService.hasPermission(PermissionScopes.ADD_NEW_USER_TENANT) ||
    //   PermissionsService.hasPermission(PermissionScopes.EDIT_USER_TENANT) ||
    //   PermissionsService.hasPermission(PermissionScopes.VIEW_USER_DETAILS_TENANT) ||
    //   PermissionsService.hasPermission(PermissionScopes.CHANGE_USER_STATUS_TENANT) ||
    //   PermissionsService.hasPermission(PermissionScopes.SEARCH_USERS_TENANT) ||
    //   PermissionsService.hasPermission(PermissionScopes.EXPORS_USERS_TENANT)  
    // )  ?    `/users_management` : false,

    VIEW_USER: `/users_management/view_user/`,
    EDIT_USER: `/users_management/edit_user/`,

    ORGUSERSMANAGEMENT: `/org_users_management`,
    VIEW_ORG_USER: `/org_users_management/view_user/`,
    EDIT_ORG_USER: `/org_users_management/edit_user/`,

    LOGGERMANAGEMENT: `/loggers_management`, 
    VIEW_LOGGER: `/loggers_management/view_logger/`,
    
    RESPONSE_ERROR_MANAGEMENT: `/response_error`, 
    VALIDATION_LOG: `/validation_log`, 

    // FAWATEER: `/fawateer`,
    // FAWATEER_LIST: `/fawateer/fawateer_list`,

    // ABSENCE: `/Absence`,

    SYSTEMSETUP: [
      {
        NAME: 'INVOICES_BOOK_MANAGEMENT',
        PATH: `/invoices_book_management`,
        PERMISSIONS: "VIEW_INVOICES_BOOKS_LIST_ORGANIZATION,SEARCH_INVOICES_BOOKS_ORGANIZATION"
      },
      {
        NAME: 'USERSMANAGEMENT',
        PATH: `/org_users_management`,
        PERMISSIONS: "VIEW_USERS_LIST_ORGANIZATION,SEARCH_USERS_ORGANIZATION,EXPORS_USERS_ORGANIZATION"
      }
      // ...((
      //     PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_BOOKS_LIST_ORGANIZATION) ||
      //     PermissionsService.hasPermission(PermissionScopes.ADD_INVOICES_BOOK_ORGANIZATION) ||
      //     PermissionsService.hasPermission(PermissionScopes.EDIT_INVOICES_BOOK_ORGANIZATION) ||
      //     PermissionsService.hasPermission(PermissionScopes.VIEW_INVOICES_BOOK_DETAILS_ORGANIZATION) ||
      //     PermissionsService.hasPermission(PermissionScopes.CHANGE_INVOICES_BOOK_STATUS_ORGANIZATION) ||
      //     PermissionsService.hasPermission(PermissionScopes.SEARCH_INVOICES_BOOKS_ORGANIZATION) 
      // )
      // ? [
      //   {
      //     NAME: 'INVOICES_BOOK_MANAGEMENT',
      //     PATH: `/invoices_book_management`,
      //   }
      // ]
      // : []),
    ],

    TENANT_SYSTEMSETUP: [
      {
        NAME: 'ORGANIZATION_MANAGEMENT',
        PATH: `/organization_management`,
      },
      // ...((PermissionsService.hasPermission(PermissionScopes.VIEW_ORGANIZATIONS_LIST) ||
      //      PermissionsService.hasPermission(PermissionScopes.ADD_ORGANIZATION) ||
      //      PermissionsService.hasPermission(PermissionScopes.EDIT_ORGANIZATION) ||
      //      PermissionsService.hasPermission(PermissionScopes.VIEW_ORGANIZATION_DETAILS) ||
      //      PermissionsService.hasPermission(PermissionScopes.CHANGE_ORGANIZATION_STATUS) ||
      //      PermissionsService.hasPermission(PermissionScopes.SEARCH_ORGANIZATION) 
      // )
      // ? [{
      //     NAME: 'ORGANIZATION_MANAGEMENT',
      //     PATH: `/organization_management`,
      //   },
      // ]
      // : []),
      
      {
        NAME: 'USERSMANAGEMENT',
        PATH: `/users_management`,
      },
      // ...((PermissionsService.hasPermission(PermissionScopes.VIEW_USERS_LIST_TENANT) ||
      //      PermissionsService.hasPermission(PermissionScopes.ADD_NEW_USER_TENANT) ||
      //      PermissionsService.hasPermission(PermissionScopes.EDIT_USER_TENANT) ||
      //      PermissionsService.hasPermission(PermissionScopes.VIEW_USER_DETAILS_TENANT) ||
      //      PermissionsService.hasPermission(PermissionScopes.CHANGE_USER_STATUS_TENANT) ||
      //      PermissionsService.hasPermission(PermissionScopes.SEARCH_USERS_TENANT) ||
      //      PermissionsService.hasPermission(PermissionScopes.EXPORS_USERS_TENANT) 
      // )
      // ? [
      //   {
      //     NAME: 'USERSMANAGEMENT',
      //     PATH: `/users_management`,
      //   },
      // ]
      // : []),
      {
        NAME: 'ROLES_MANAGEMENT',
        PATH: `/roles_management`,
      },
      // ...((PermissionsService.hasPermission(PermissionScopes.VIEW_ROLES_LIST) ||
      //      PermissionsService.hasPermission(PermissionScopes.ADD_NEW_ROLE) ||
      //      PermissionsService.hasPermission(PermissionScopes.EDIT_ROLE) ||
      //      PermissionsService.hasPermission(PermissionScopes.SEARCH_ROLE) ||
      //      PermissionsService.hasPermission(PermissionScopes.SEARCH_ROLE_PERMISSIONS) 
      // )
      // ? [
      //   {
      //     NAME: 'ROLES_MANAGEMENT',
      //     PATH: `/roles_management`,
      //   }
      // ]
      // : []), 
      {
        NAME: 'Loggers_MANAGEMENT',
        PATH: `/loggers_management`,
      }

    ],
    SOURCES: [
      {
        NAME: 'VALIDATION_LOG',
        PATH: `/validation_log`,
        PERMISSIONS: "VIEW_INVOICES_BOOKS_LIST_ORGANIZATION,SEARCH_INVOICES_BOOKS_ORGANIZATION"
      }
    ]
  }
};

export default PagesRoute;