import APIConstants from "../../../Config/Settings/API";
import { WebServiceClient } from "../WebServiceClient";

export class InvoicesBookMetaService {

    public static SearchInvoicesBook = async (request: any, invoiceBookId: any) => {

        try {
            const response = await WebServiceClient.post(APIConstants.INVOICES_BOOK_META.SEARCH.replace("{invoiceBookId}",invoiceBookId), {body: request});
            return response?.data?.body;
        }
        catch (error: any) {
            WebServiceClient.getError(error);
        }
    }

    public static AddInvoiceBookMeta = async ( request: any, invoiceBookId: any) => {
        try {
            const response = await WebServiceClient.post(
                APIConstants.INVOICES_BOOK_META.POST.replace("{invoiceBookId}",invoiceBookId),
                {body: request});
            return response;
        }
        catch (error: any) {
            WebServiceClient.handleError(error);
            WebServiceClient.getError(error);
            //console.log({ error });
        }
    }

    public static UpdateInvoiceBookMeta = async (id:any, request: any, invoiceBookId: any) => {
        try {
            const response = await WebServiceClient.put(
                APIConstants.INVOICES_BOOK_META.PUT.replace("{invoiceBookId}",invoiceBookId).replace("{id}",id),
                {body: request});
            return response;
        }
        catch (error: any) {
            WebServiceClient.handleError(error);
            WebServiceClient.getError(error);
            //console.log({ error });
        }
    }

    public static DeleteInvoiceBookMeta = async (id:any) => {
        try {
            const response = await WebServiceClient.delete(
                APIConstants.INVOICES_BOOK_META.DELETE.replace("{id}",id));
            return response;
        }
        catch (error: any) {
            WebServiceClient.handleError(error);
            WebServiceClient.getError(error);
            //console.log({ error });
        }
    }
}
