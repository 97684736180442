import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportSelectors, setError, reportReceived } from '../Store/Reducers/Reports';
import { pageRequested, PaginationSelectors, PaginationModules } from '../Store/Reducers/Pagination';
import Constants from '../Config/Settings/API';
const useReportsSearch = () => {

    //#region :: State

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [report, setReport] = useState<Array<any>>([]);
    const _report = reportSelectors.getReportsBookData(state);
    const searchCriteria = reportSelectors.getSearchCriteria(state);

    const currentPage = PaginationSelectors.getCurrentPage(state, PaginationModules.REPORTS);
    const pageSize = PaginationSelectors.getPageSize(state, PaginationModules.REPORTS);
    const totalPages = PaginationSelectors.getTotalPages(state, PaginationModules.REPORTS);
    const pagination = PaginationSelectors.getPaginationModule(state, PaginationModules.REPORTS);

    const lastPageIndex = PaginationSelectors.getLastPageIndex(state, PaginationModules.REPORTS);
    const hasMore = PaginationSelectors.hasMore(state, PaginationModules.REPORTS) || false;
    const isPageFetching = PaginationSelectors.isPageFetching(state, PaginationModules.REPORTS);
    const pageKeys = PaginationSelectors.getPageKeys(state, PaginationModules.REPORTS, currentPage);

    const requestPage = (actionType: any, pageIndex: any, reset: boolean = false) => {

        //Prodaction
        if (isPageFetching) return;

        //Prodaction
        dispatch(pageRequested({
        url: Constants.INVOICES.REPORT,
            data: { body: { ...searchCriteria, pageIndex: pageIndex, pageSize: pageSize } },
            method: 'POST',
            onSuccess: actionType,
            onError: setError.type,
            args: {
                resetPages: reset,
                source: 'body',
                id: 'id',
                module: PaginationModules.REPORTS,
                page: pageIndex
            }
        }));
    };

    const changeCurrentPage = (page: any) => {
        requestPage(reportReceived.type, page);
    };

    useEffect(() => {
        if (!searchCriteria) return;
        requestPage(reportReceived.type, 0, true);
    }, [searchCriteria]);

    //Prodaction
    useEffect(() => {
        if (pageKeys && pageKeys.keys) {
            let items: Array<any> = [];

            pageKeys.keys.map((key: any) => {
                items.push(_report[key]);
            });
            setReport(items);
        }
    }, [pageKeys.keys]);

    useEffect(() => {
        setReport(_report);
    }, [_report]);

    return { report, hasMore, isPageFetching, lastPageIndex, currentPage, pagination, totalPages, pageSize, changeCurrentPage };
};

export default useReportsSearch;